// initial state
const state = {
    foods: [],
    stel: '',
    is_loading: true,
    shared_tname: '',
    user_count: 0,
    total_price: 0.0,
    no_data: true,
    msg: '',
    user_foods: {}
};

// getters
const getters = {
    get_shareddetail_msg(state) {
        return state.msg;
    }
};

// actions
const actions = {
    initData({ commit, rootState }, _post_data) {
        let aj_host = rootState.debug ? 'https://takeout.minitable.link' : '';
        fetch(aj_host + '/weapp/takeout/detail/get_share?oid=' + _post_data.oid + '&cartid=' + _post_data.cartid, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                let res_data = JSON.parse(res).data;

                if (res_data.code === 100000) {
                    let order_list = res_data.data.order_data || [];
                    let uid_list = res_data.data.uid_list || [];

                    let user_count = uid_list.length;
                    let total_price = 0;
                    let total_price_vip = 0;
                    let user_dict = {};
                    let user_orders_dict = {};

                    for (let m = 0; m < uid_list.length; m++) {
                        user_orders_dict[uid_list[m].uname] = [];
                    }

                    let foods_arr = [];
                    let _is_vip = 0;

                    for (let i = 0; i < order_list.length; i++) {
                        let _foods = order_list[i].ofoods;
                        let _oid = order_list[i].oid;

                        if (_oid.indexOf('-') == -1) {
                            foods_arr.push({
                                name: 'Original order',
                                foods: _foods
                            });
                        } else {
                            foods_arr.push({
                                name: 'Additional order-' + _oid.split('-')[1],
                                foods: _foods
                            });
                        }
                        total_price = total_price + parseFloat(order_list[i].oprice);
                        total_price_vip =
                            total_price_vip + parseFloat(order_list[i].oprice_vip || order_list[i].oprice);
                        _is_vip = order_list[i].is_vip;

                        for (let j = 0; j < _foods.length; j++) {
                            if (user_orders_dict[_foods[j].ouser_name]) {
                                user_orders_dict[_foods[j].ouser_name].push(_foods[j]);
                            } else {
                                if (user_orders_dict[_foods[j].ouser_name + '(quit)']) {
                                    user_orders_dict[_foods[j].ouser_name + '(quit)'].push(_foods[j]);
                                } else {
                                    user_orders_dict[_foods[j].ouser_name + '(quit)'] = [_foods[j]];
                                }
                            }

                            if (_foods[j].ouser_name && !user_dict[_foods[j].ouser_name]) {
                                // user_count = user_count + 1;
                                user_dict[_foods[j].ouser_name] = true;
                            }
                        }
                    }

                    commit('INIT_SHAREDORDERDETAIL_DATA', {
                        is_vip: _is_vip,
                        foods: foods_arr,
                        shared_tname: res_data.data.shared_tname,
                        total_price: total_price.toFixed(2),
                        total_price_vip: total_price_vip.toFixed(2),
                        user_count: user_count,
                        user_foods: user_orders_dict
                    });
                } else if (res.data.code === 100010) {
                    // 共享购物车已关闭
                    commit('APP_SHARED_CART_CLOSED', {
                        router: _post_data.router
                    });
                }
            });
    },
    goBill({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'https://takeout.minitable.link' : '';
        fetch(
            aj_host +
                '/weapp/takeout/table/gobill?tid=' +
                post_data.tid +
                '&oid=' +
                post_data.oid +
                '&type=' +
                post_data.type,
            {
                method: 'GET'
            }
        )
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                let res_data = JSON.parse(res).data;

                if (res_data.code === 100000) {
                    commit('SHAREDORDERDETAIL_SET_MSG', {
                        msg: 'We are preparing your bill, please wait.'
                    });
                }
            });
    }
};

// mutations
const mutations = {
    INIT_SHAREDORDERDETAIL_DATA(state, order_detail_data) {
        state.foods = order_detail_data.foods;
        state.is_loading = false;
        state.is_vip = order_detail_data.is_vip;
        state.shared_tname = order_detail_data.shared_tname;
        state.total_price = order_detail_data.total_price;
        state.total_price_vip = order_detail_data.total_price_vip;
        state.user_count = order_detail_data.user_count;
        state.no_data = order_detail_data.foods.length > 0 ? false : true;
        state.user_foods = order_detail_data.user_foods;
    },
    SHAREDORDERDETAIL_SET_MSG(state, _data) {
        state.msg = _data.msg;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
