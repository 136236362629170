const state = {};

const getters = {};

const actions = {
    setCheckoutPageData({ commit }, payload) {
        const { detail_data, mix_fcount, from, is_vip } = payload;
        commit('SET_DETAIL_DATA', detail_data);
        commit('SET_MIX_FCOUNT', mix_fcount);
        commit('SET_FROM', from);
        commit('SET_IS_VIP', is_vip);
    }
};

const mutations = {
    SET_DETAIL_DATA(state, payload) {
        state.detail_data = { ...payload };
    },
    SET_MIX_FCOUNT(state, payload) {
        state.mix_fcount = payload;
    },
    SET_FROM(state, payload) {
        state.from = payload;
    },
    SET_IS_VIP(state, payload) {
        state.is_vip = payload;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
