// initial state
const state = {
    is_loading: true,
    content_height: document.body.clientHeight - 70, // TODO fix this,  here it's 0-70
    now_menu: '',
    menu_dict: {},
    menu_list: [],
    food_dict: {},
    show_food_list: [],
    show_detail: false
};

// getters
const getters = {};

// actions
const actions = {
    initData({ commit, rootState }, sid) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5757' : '';
        // let aj_host = rootState.debug ? 'https://takeout.minitable.link' : '';
        fetch(aj_host + '/weapp/takeout/menu/get?sid=' + sid, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                let res_data = JSON.parse(res).data;

                if (res_data.code === 100000) {
                    commit('INIT_MENU_DATA', {
                        show_food_list: res_data.data.menu[res_data.data.first_menu].list,
                        now_menu: res_data.data.first_menu,
                        menu_dict: res_data.data.menu,
                        menu_list: res_data.data.menu_list,
                        food_dict: res_data.data.foods
                    });
                }
            });
    },
    doToggleDetail({ commit, rootState }, isSHow) {
        commit('MENU_TOGGLE_DETAIL', isSHow);
    }
};

// mutations
const mutations = {
    INIT_MENU_DATA(state, res_data) {
        state.show_food_list = res_data.show_food_list;
        state.now_menu = res_data.now_menu;
        state.menu_dict = res_data.menu_dict;
        state.menu_list = res_data.menu_list;
        state.food_dict = res_data.food_dict;
        state.is_loading = false;
    },
    SET_FOODS_DATA(state, _mid) {
        state.show_food_list = state.menu_dict[_mid].list;
        state.now_menu = _mid;
    },
    MENU_TOGGLE_DETAIL(state, show) {
        state.show_detail = show;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
