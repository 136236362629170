<template>
    <div class="pop" v-if="visible">
        <div class="pop_copy" @click="hide"></div>
        <div class="pop_main">
            <div class="pop_close" style="left: 12px;" @click="hide"><span></span></div>
            <div class="address_main">
                <!-- <div class="pop_title">{{ $t('checkout.addAddress') }}</div> -->
                <div class="pop_title">{{ $t('checkout.editDeliveryAddress') }}</div>
                <div class="pop_input_box">
                    <div class="pop_input_title">{{ $t('checkout.street') }} *</div>
                    <input type="text" :placeholder="$t('checkout.street')" v-model="street_address1" />
                </div>
                <div class="pop_input_box">
                    <div class="pop_input_title">{{ $t('checkout.aptSuite') }}</div>
                    <input type="text" :placeholder="$t('checkout.aptSuite')" v-model="street_address2" />
                </div>
                <div class="pop_flex">
                    <div class="pop_input_box input_city">
                        <div class="pop_input_title">{{ $t('checkout.city') }} *</div>
                        <input type="text" :placeholder="$t('checkout.city')" v-model="city" />
                    </div>
                    <div class="pop_input_box input_state">
                        <div class="pop_input_title">{{ $t('checkout.state') }} *</div>
                        <input type="text" :placeholder="$t('checkout.state')" v-model="state" />
                    </div>
                    <div class="pop_input_box input_code">
                        <div class="pop_input_title ">{{ $t('checkout.postCode') }} *</div>
                        <input type="text" :placeholder="$t('checkout.postCode')" v-model="zip_code" />
                    </div>
                </div>
                <div class="pop_btn">
                    <mt-button @click="saveAddress">{{ $t('checkout.save') }}</mt-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState, mapGetters, createNamespacedHelpers } from 'vuex';
    const App = createNamespacedHelpers('app');
    export default {
        name: 'AddressForm',
        components: {},
        data() {
            return {
                visible: false,
                street_address1: '',
                street_address2: '',
                zip_code: '',
                zip_code_index: '',
                city: '',
                state: ''
            };
        },
        computed: {
            ...App.mapState({
                street1: 'street_address1',
                street2: 'street_address2',
                zip: 'zip_code',
                zipIndex: 'zip_code_index',
                _city: 'city',
                _state: 'state'
            }),
            ...App.mapGetters({
                delivery_data: 'get_delivery_data',
                language: 'get_language'
            }),
            saveBtnText() {
                return this.language === 'eng' ? 'Save' : '保存';
            }
        },
        mounted() {
            this.street_address1 = this.street1;
            this.street_address2 = this.street2;
            this.zip_code = this.zip;
            this.zip_code_index = this.zipIndex;
            this.city = this._city;
            this.state = this._state;
        },
        methods: {
            ...App.mapMutations(['APP_SET_ADDRESS', 'APP_SET_MSG']),
            show() {
                this.visible = true;
            },
            hide() {
                this.visible = false;
            },
            set_address(address) {
                this.APP_SET_ADDRESS(address);
            },
            toggleAddressLayer() {
                this.$emit('toggleAddressLayer');
            },
            getAppStore(name) {
                return this.$store.state.app[name];
            },
            set_msg(msg) {
                this.APP_SET_MSG(msg);
            },
            saveAddress() {
                // if (this.street_address1 === '') {
                //     this.set_msg({ msg: this.language === 'chn' ? '请输入地址' : 'Address is required' });
                //     return;
                // }

                // if (this.zip_code === '') {
                //     this.set_msg({ msg: this.language === 'chn' ? '请输入邮政编码' : 'Zip code is required' });
                //     return;
                // }

                if (this.street_address1 === '' || this.city === '' || this.state === '' || this.zip_code === '') {
                    this.set_msg({ msg: 'Please fill in all required fields.' });
                    return;
                }

                // check zip code
                let zip_error_flag = true;
                for (let i = 0; i < this.delivery_data.delivery_area_list.length; i++) {
                    let temp_arr = this.delivery_data.delivery_area_list[i].zip_code_list.split(',');
                    for (let j = 0; j < temp_arr.length; j++) {
                        if (temp_arr[j] === this.zip_code) {
                            zip_error_flag = false;
                            this.zip_code_index = i;
                            break;
                        }
                    }

                    if (!zip_error_flag) {
                        break;
                    }
                }

                if (zip_error_flag) {
                    this.set_msg({ msg: this.language === 'chn' ? '不在配送区' : 'Not a service area' });
                    return;
                }

                this.set_address({
                    state: this.state,
                    city: this.city,
                    street_address1: this.street_address1,
                    street_address2: this.street_address2,
                    zip_code: this.zip_code,
                    zip_code_index: this.zip_code_index
                });
                this.has_saved_address = true;
                this.hide();
            }
        }
    };
</script>

<style lang="scss" scoped>
    .pop_copy {
        width: 100%;
        height: 100%;
        position: absolute;
    }
    .show_address {
        transform: translateY(0) !important;
        transition-duration: 235ms !important;
    }
    .address-layer {
        background: #fff;
        border-right: 1px solid rgba(0, 0, 0, 0.12);
        bottom: 0;
        font-size: 14px;
        overflow-y: auto;
        padding: 0px 0 40px;
        position: fixed;
        top: 0;
        transform: translateY(-100%);
        transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
        width: 280px;
        z-index: 100;
        opacity: 0.97;
        width: 100%;
        height: 100%;
    }
    .address_main .pop_title {
        padding-left: 58px;
        text-align: left;
    }
    .address_main .pop_input_title {
        color: #000;
        font-size: 18px;
        margin-bottom: 10px;
    }
    .pop .address_main .pop_input_box input {
        color: #000;
        background-color: #f7f7f7;
        border: none;
        padding: 0 16px;
        box-sizing: border-box;
    }
    .address_main .pop_flex {
        display: flex;
        justify-content: space-between;
        // flex-wrap: wrap;
    }
    .address_main .input_city, .address_main .input_state {
        flex: 2;
        padding-right: 16px;
        box-sizing: border-box;
    }
    .address_main .input_code {
        flex: 1;
    }
    .address_main .pop_btn {
        text-align: center;
        margin-top: 16px;
    }
    .address_main .pop_btn button {
        width: 123px;
        height: 32px;
        background-color: var(--theme_color);
        color: #fff;
    }
    @media screen and (max-width: 641px) {
        .address_main .pop_title {
            font-size: 1.125rem;
            padding-left: 40px;
            margin: 12px 0 15px;
        }
        .address_main .pop_input_title {
            font-size: 1rem;
        }
        .address_main .pop_flex {
            display: block;
        }
        .address_main .input_city, .address_main .input_state {
            padding-right: 0;
        }
    }
</style>
