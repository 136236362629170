<template>
    <div class="welcome_page">
        <div class="app">
            <!-- <div class="main flex-col-center" ref="mainBox" :style="{ top: mainTop + 'px'}"> -->
            <div class="app_content">
            <div class="welcome_loading" v-if="is_loading_welcome"></div> 
            <div class="main flex-col-center">
                <div class="logo flex-center" v-if="slogo" :style="{ backgroundImage: slogoBack }"></div>
                <div
                    class="title-text"
                    :style="{ color: theme_data.theme_color + ' !important' }"
                >
                    WELCOME
                    <!-- {{ $t("hello") }} -->
                </div>
                <div class="store-info">
                    <p>{{ sname }}</p>
                    <p v-if="saddress">{{ saddress }}</p>
                </div>
                <div class="btn-group flex-col-center">
                    <mt-button
                        type="danger"
                        @click="do_join"
                        :style="{ backgroundColor: theme_data.theme_color + ' !important' }"
                    >
                        {{ $t('welcome.orderNow') }}
                    </mt-button>
                    <mt-button
                        type="danger"
                        plain
                        :style="{ borderColor: theme_data.theme_color + ' !important' }"
                        data-bee="module_id=button&item_id=customer_notice"
                        @click="doShowPromo"
                        v-if="theme_data.notice_enable"
                    >
                        {{ $t('welcome.customerNotice') }}
                    </mt-button>
                </div>
                <div id="copy_box" class="notice_group" @click="copyUrl('copy_box')" data-clipboard-action="copy" :data-clipboard-text="store_url">
                    <span class="notoce_text" style="">{{ $t('welcome.copy_share') }}</span>
                    <img src="../assets/images/share_icon.svg" />
                    <!-- <img id="copy_btn" src="../assets/images/copy.svg" @click="copyUrl('copy_btn')" data-clipboard-action="copy" :data-clipboard-text="store_url" />
                    <img id="copy_btn_share" src="../assets/images/share.svg" @click="copyUrl('copy_btn_share')" data-clipboard-action="copy" :data-clipboard-text="store_url" /> -->
                </div>
                <!-- <div class="rules-group flex-col-center">
                    <p>By joining in, you agree to Minitable Tech, Inc</p>
                    <p class="rule-items">
                        <a href="http://info.minitable.net/termsofuse.html" class="link">Terms of Service</a>
                        <span> and </span>
                        <a href="http://info.minitable.net/privacypolicy.html" class="link">Privacy Policy.</a>
                    </p>
                </div> -->
            </div>
            </div>
        </div>
        <div class="back_img" :style="{ backgroundImage: feature_image }"></div>
        <PageFooter ref="mainFooter" :showPolicy="'true'"></PageFooter>
        <RecommendLayer v-show="showRecommend" :recommend-data="recommend_popup_setting" :closeFun="do_close">
        </RecommendLayer>
        <div class="pop" v-show="show_promo">
            <div class="pop_copy" @click="doClosePromo"></div>
            <div class="pop_main">
                <div class="notice_main">
                    <div class="pop_close" @click="doClosePromo"><span></span></div>
                    <!-- <div class="pop_title" :style="{ color: theme_data.theme_color}">NOTICE</div> -->
                    <div v-if="theme_data.notice_layer_subtitle" class="pop_title">
                        {{ theme_data.notice_layer_subtitle }}
                    </div>
                    <div v-else class="pop_title">
                        {{ $t('welcome.customerNotice') }}
                    </div>
                    <ul class="list">
                        <li style="padding: 5px 0 0; margin: 0" v-html="annoucement">
                        </li>
                    </ul>
                    <div class="tips" v-if="theme_data.notice_layer_ps_content">
                        {{ theme_data.notice_layer_ps_content }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
    html {
        height: 100%;
    }
    body {
        height: 100%;
    }

    .welcome_loading {
        position: absolute;
        width: 100%;
        height: 100%;
        background: url('../assets/images/loading_welcome.png') no-repeat #fff;
        background-size: 35% auto;
        background-position: center center;
        z-index: 999;
    }

    .flex-center {
        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    .flex-col-center {
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
        align-items: center;
    }

    .btn-group button {
        width: 12rem;
        height: 2.5rem;
        border-radius: 0.25rem;
        margin-bottom: 0.75rem;
        font-size: 0.875rem;
        font-weight: bold;
    }
    .mint-button--danger {
        background-color: var(--theme_color) !important;
    }
    .mint-button--danger.is-plain {
        border-color: var(--theme_color) !important;
        /* border-width: 2px !important; */
        background-color: #fff !important;
        color: var(--theme_color) !important;
    }

    .back_img {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-position: 50% -2.5rem;
        background-repeat: no-repeat;
        background-size: auto 100%;
        /* filter: blur(7px); */
        /* background-image: url('../assets/images/big_bg.png'); */
    }

    .welcome_page .app {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        padding-bottom: 80px;
        box-sizing: border-box;
    }
    .app_content {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .welcome_page .footer {
        position: fixed !important;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
    }

    .main {
        /* position: absolute;
        bottom: 4.875rem;
        left: 0; */
        /* padding-top: 3.75rem; */
        /* padding: 1.25rem 1rem; */
        padding: 20px 16px;
        width: 100%;
        /* height: 20.375rem; */
        background: rgba(255, 255, 255, 0.9);
        border-radius: 0.9375rem;
        z-index: 1;
        box-sizing: border-box;
    }
    .main .logo {
        /* width: 4.625rem;
        height: 4.625rem; */
        width: 148px;
        height: 148px;
        min-height: 148px;
        border-radius: 50%;
        background-color: #fff;
        /* position: absolute;
        top: -3.9375rem; */
        background-image: url('../assets/images/logo.png');
        background-repeat: no-repeat;
        /* background-size: auto 3.0625rem; */
        background-size: cover;
        background-position: center;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
    }
    @media screen and (min-width: 642px) {
        .main {
            /* bottom: 156px;
            left: 50%;
            margin-left: -9.4375rem; */
            /* width: 18.875rem; */
            /* height: 14.375rem; */
            width: 35%;
            padding-top: 30px;
            padding-bottom: 50px;
        }
        .btn-group button {
            width: 8.5rem;
            height: 1.75rem;
            font-size: 0.625rem;
        }
        .back_img {
            background-size: cover;
        }
    }
    .main .store-info {
        text-align: center;
        font-size: 12px;
        color: #393939;
        line-height: 1.5;
        margin: 30px 0;
        /* margin: 1rem 0; */
    }
    .main .store-info p:first-child {
        font-size: 32px;
        font-weight: 500;
        color: #000;
    }
    .main .store-info p:last-child {
        color: #636366;
        font-size: 18px;
        margin-top: 10px;
    }
    .main .title-text {
        color: #d63239;
        font-size: 32px;
        font-weight: 500;
        margin-top: 30px;
    }
    .main .rules-group {
        padding-top: 0.8125rem;
    }
    .main .rules-group p {
        font-size: 12px;
        color: #bcbcbc;
    }
    .main .rules-group a {
        color: #539cff;
        text-decoration: none;
    }
    .main .notice_group {
        /* display: flex;
        justify-content: center;
        align-items: center; */
        text-align: center;
        margin-top: 20px;
        cursor: pointer;
        line-height: 24px;
    }
    .main .notoce_text {
        font-size: 18px;
        color: #636366;
        margin-right: 10px;
    }

    .pop .notice_main {
        padding: 0 0.625rem;
        /* text-align: center; */
    }
    .pop .notice_main .pop_title {
        font-size: 24px;
        text-align: left;
        padding-left: 40px;
        color: #000;
        margin: 15px 0;
    }
    /* .notice_main .list {
        padding-left: 20px;
    } */
    .notice_main .list li {
        line-height: 21px;
        font-size: 16px;
        list-style: none;
        text-align: left;
        color: #000;
        /* list-style: decimal;
        list-style-position: inside; */
    }
    .pop .notice_main .pop_title.small_title {
        font-size: 14px;
        margin-bottom: 10px;
    }
    .notice_main .notice_input {
        width: 88%;
    }
    .notice_main .notice_input input {
        width: 100%;
        font-size: 12px;
        height: 18px;
        line-height: 18px;
        padding: 0;
    }
    .notice_main .notice_group img {
        width: 22px;
        height: 22px;
        cursor: pointer;
    }
    .notice_main .tips {
        margin: 30px 0 10px;
        font-size: 14px;
        color: #636366;
    }
    @media screen and (max-width: 1024px) {
        .main {
            width: 65%;
        }
    }
    @media screen and (max-width: 641px) {
        .welcome_loading {
            background-size: 92% auto;
        }
        .welcome_page .app {
            padding-bottom: 5.5rem;
        }
        .main {
            /* bottom: auto;
            top: 10rem; */
            width: 92%;
            /* margin: 0 4%; */
        }
        .main .logo {
            width: 80px;
            height: 80px;
            min-height: 80px;
        }
        .main .title-text, .main .store-info p:first-child {
            font-size: 24px;
        }
        .main .store-info p:last-child, .main .notoce_text {
            font-size: 14px;
        }
        .main .title-text {
            margin-top: 20px;
        }
        .main .store-info {
            margin: 20px 0;
        }
        /* .main .notoce_text {
            font-size: 16px;
        } */
        .pop .notice_main .pop_title {
            font-size: 20px;
            margin: 10px 0 15px;
        }
        .notice_main .list li {
            font-size: 14px;
        }
        .notice_main .tips {
            margin-top: 20px;
            font-size: 12px;
        }
        .main .notice_group img {
            width: 14px;
        }
    }
    /* @media screen and (min-width: 642px) and (max-height: 780px) {
        .main {
            bottom: 2.5rem;
        }
    } */
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import PageFooter from '@/components/PageFooter.vue';
    import RecommendLayer from '@/components/RecommendLayer';
    import Clipboard from 'clipboard';
    import { setLang } from '@/locales';

    export default {
        name: 'welcome',
        components: {
            PageFooter,
            RecommendLayer
        },
        data() {
            return {
                takeout_tid: this.$route.query.tid || '',
                name: this.$route.query.name || '',
                email: '',
                show_power: 1,
                has_shown_notice: window.localStorage.getItem('has_shown_notice') || 0,
                // show_promo: window.localStorage.getItem('has_shown_notice') == 1 ? false : true,
                show_promo: false,
                feature_img: '',
                store_url: '',
                showLaunch: false,
                showRecommend: false,
                mainTop: 0,
            };
        },
        watch: {
            recommend_popup_setting() {
                if (
                    this.recommend_popup_setting.recommend_popup_enable == 1 &&
                    this.recommend_popup_setting.recommend_popup_pic_url != ''
                ) {
                    this.showRecommend = true;
                }
            },
            short_url_v2() {
                this.store_url = this.short_url_v2;
            },
            language() {
                let lang = window.localStorage.getItem('language');
                if (!lang) {
                    lang = this.language;
                    localStorage.setItem('language', lang);
                }
                setLang(lang);
                this.set_language({
                    language: lang
                });
            },
            theme_data() {
                this.autoCustomerNotice();
                // setTimeout(this.computedMainTop, 100);
            }
        },
        created() {
            this.set_app_title(this.sname);
            if (!this.$route.query) {
                return;
            }
            const { tid, gid } = this.$route.query;
            const { sid } = this.$route.params;

            if ((sid && sid !== localStorage.client_sid) || (tid && tid !== localStorage.shared_tid)) {
                this.initData({
                    sid,
                    gid,
                    takeout_tid: tid,
                    router: this.$router
                });
                return;
            }

            if (this.$store.state.app.shared_cart_id !== '') {
                this.$router.replace({ path: '/menu' });
            }
        },
        mounted() {
            // todo notice clipboard
            window.localStorage.setItem('has_shown_notice', 1);

            let _link = '';
            const _takout_host = window.location.host;
            _link = 'https://' + _takout_host + '/v2/#/welcome/' + this.$route.params.sid;

            this.store_url = _link;

            if (window.localStorage.getItem('orderType')) {
                window.localStorage.removeItem('orderType');
            }

            if (window.localStorage.getItem('dineInTable')) {
                window.localStorage.removeItem('dineInTable');
            }

            // this.computedMainTop();

            // setTimeout(this.autoCustomerNotice, 3000);
        },
        computed: {
            ...mapGetters({
                sname: 'app/get_store_name',
                is_open: 'app/get_is_open',
                err_msg: 'app/get_err_msg',
                recommend_popup_setting: 'app/get_recommend_popup_setting',
                theme_data: 'app/get_theme_data',
                slogo: 'app/get_store_slogo',
                annoucement: 'app/get_annoucement',
                language: 'app/get_language',
                is_loading_welcome: 'app/get_is_loading_welcome',
            }),
            ...mapState({
                sname: (state) => state.app.sname,
                store_data: (state) => state.app.store_data,
                short_url_v2: (state) => state.app.short_url_v2,
            }),
            feature_image() {
                return 'url(' + this.theme_data.feature_image + ')';
            },
            slogoBack() {
                return 'url(' + this.slogo + ')';
            },
            annoucement_list() {
                return this.annoucement.split('<br>');
            },
            saddress() {
                const { street_address, locality, postal_code } = this.store_data;
                if (!street_address && !locality && !postal_code) {
                    return '';
                }
                const saddress = street_address + ' , ' + locality + ' , ' + postal_code;
                return saddress;
            }
        },
        methods: {
            ...mapActions({
                initData: 'welcome/initData',
                joinTable: 'app/joinTable'
            }),
            ...mapMutations({
                set_app_title: 'app/APP_SET_APP_TITLE',
                set_msg: 'app/APP_SET_MSG',
                set_language: 'app/APP_SET_LANGUAGE'
            }),
            do_close() {
                this.showRecommend = false;
            },
            do_join() {
                if (this.is_open == 0) {
                    this.set_msg({
                        msg: this.err_msg
                    });
                    return;
                }

                const { sid, tid, gid } = this.$route.query;

                this.joinTable({
                    sid: this.$store.state.app.sid,
                    tid: this.takeout_tid,
                    router: this.$router,
                    email: this.email,
                    type: 'takeout',
                    gid
                });

                // let localStorageLanguage = window.localStorage.getItem('language');
                // if (!localStorageLanguage) {
                //     const lang = this.language;
                //     localStorage.setItem('language', lang);
                //     setLang(lang);
                //     this.set_language({
                //         language: lang
                //     });
                // }
            },
            doShowPromo() {
                this.show_promo = true;
            },
            doClosePromo() {
                this.show_promo = false;
            },
            copyUrl(ele) {
                const clipboard = new Clipboard('#'+ele);
                clipboard.on('success', (e) => {
                    this.set_msg({
                        msg: this.$t('welcome.copy_share_suc')
                    });
                    clipboard.destroy();
                });

                clipboard.on('error', (e) => {
                    this.set_msg({
                        msg: this.$t('welcome.copy_share_err')
                    });
                    clipboard.destroy();
                });
            },
            autoCustomerNotice() {
                let has_customer_notice = window.localStorage.getItem('has_customer_notice');
                let customer_notice_text = this.$store.state.app.sid + '_customer_notice_v2';
                // console.log('has_customer_notice', has_customer_notice, customer_notice_text);
                // console.log(this.theme_data.notice_enable);

                if (this.theme_data.notice_enable && (this.theme_data.auto_notice_enable == 1 || (has_customer_notice == null || has_customer_notice != customer_notice_text))) {
                    this.doShowPromo();
                }
                window.localStorage.setItem('has_customer_notice', customer_notice_text);
            },
            computedMainTop() {
                const parentHeight = window.innerHeight;
                const mainHeight = this.$refs.mainBox.offsetHeight
                const footerHeight = this.$refs.mainFooter.$el.offsetHeight;
                if (parentHeight - mainHeight > footerHeight) {
                    this.mainTop = (parentHeight - mainHeight -footerHeight) / 2; 
                } else {
                    this.mainTop = 0;
                }
            }
        }
    };
</script>
