<template>
    <div class="plus_minus">
        <template v-if="count > 0">
            <button class="number_button minus_btn" @click.stop="minusCb">-</button>
            <div class="number">{{ count }}</div>
        </template>
        <button class="number_button plus_btn" @click.stop="plusCb">+</button>
    </div>
</template>

<script>
    export default {
        name: 'plus_minus',
        props: {
            minusCb: Function,
            plusCb: Function,
            count: Number,
            test: String
        },
        components: {},
        data() {
            return {};
        }
    };
</script>

<style lang="scss" scoped>
    .plus_minus {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .number {
            margin: 0 5px;
            font-size: 14px;
            color: #000;
        }
        button {
            // background-color: #f5f5f5;
            // color: #707070;
            text-indent: -20000px;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            border: none;
            // border: 1px solid #bcbcbc;
            cursor: pointer;
            -webkit-appearance: button;
            margin: 0;
            padding: 0;
            // text-align: center;
            // font-size: 12px;
            // line-height: 14px;
            box-sizing: border-box;
            // background-color: var(--theme_color);
            background-repeat: no-repeat;
            background-size: 100%;
            &.minus_btn {
                background-image: url('../assets/images/minus_icon.svg');
            }
            &.plus_btn {
                background-image: url('../assets/images/plus_icon.svg');
            }
        }
        
        // button {
        //     justify-content: center;
        //     -webkit-box-pack: center;
        //     align-items: center;
        //     -webkit-box-align: center;
        //     display: flex;
        //     background-color: #f5f5f5;
        //     color: #707070;
        //     height: 16px;
        //     width: 16px;
        //     border-radius: 2px;
        //     border: 1px solid #bcbcbc;
        //     cursor: pointer;
        //     -webkit-appearance: button;
        //     text-transform: none;
        //     margin: 0;
        //     font-size: 12px;
        //     line-height: 16px;
        //     box-sizing: border-box;
        // }
    }
    // .food_detail_content .plus_minus button {
    //     width: 18px;
    //     height: 18px;
    //     line-height: 16px;
    //     font-size: 16px;
    // }
</style>
