<template>
    <div class="pop" v-if="visible">
        <div class="pop_copy" @click="hide"></div>
        <div class="pop_main">
            <div class="pop_close" style="left: 12px;" @click="hide"><span></span></div>
            <div class="address_main">
                <div class="pop_title">{{ $t('checkout.editDeliveryAddress') }}</div>
                <div class="pop_input_box">
                    <div class="pop_input_title">Delivery Option *</div>
                    <div class="pop_select">
                        <div :class="showSelectOption ? 'select_main select_up' :'select_main'" @click="toggleSelect">{{ address_name }}</div>
                        <div class="select_option" v-if="showSelectOption">
                            <div
                                class="option_item"
                                v-for="(item, index) in specialStoreArr"
                                :key="index"
                                @click="chooseAddressName(item.name, index)"
                            >
                                {{item.name}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pop_input_box">
                    <div class="pop_input_title">Room Number *</div>
                    <input type="text" placeholder="Please enter your room number" v-model="room_number" />
                </div>
                <div class="pop_btn">
                    <mt-button @click="saveAddress">{{ $t('checkout.save') }}</mt-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState, mapGetters, createNamespacedHelpers } from 'vuex';
    const App = createNamespacedHelpers('app');
    export default {
        name: 'AddressFormSpecial',
        components: {},
        props: {
            specialStoreArr: Array,
            postData: Object,
        },
        data() {
            return {
                visible: false,
                zip_code_index: '',
                room_number: '',
                address_name: '--select address options--',
                address_index: null,
                showSelectOption: false
            };
        },
        computed: {
            ...App.mapState({
                street1: 'street_address1',
            }),
            ...App.mapGetters({
                delivery_data: 'get_delivery_data',
                language: 'get_language'
            }),
            saveBtnText() {
                return this.language === 'eng' ? 'Save' : '保存';
            }
        },
        mounted() {
            if (this.street1 && this.street1.indexOf('[') > -1) {
                let strArr = this.street1.split(', ');
                let roomArr = strArr[0].split('[')[1].split(']');
                // console.log(strArr, roomArr);
                this.address_name = strArr[1];
                this.room_number = roomArr[0];
            }
        },
        methods: {
            ...App.mapMutations(['APP_SET_ADDRESS', 'APP_SET_MSG']),
            show() {
                this.visible = true;
            },
            hide() {
                this.visible = false;
            },
            set_address(address) {
                this.APP_SET_ADDRESS(address);
            },
            toggleAddressLayer() {
                this.$emit('toggleAddressLayer');
            },
            getAppStore(name) {
                return this.$store.state.app[name];
            },
            set_msg(msg) {
                this.APP_SET_MSG(msg);
            },
            toggleSelect() {
                this.showSelectOption = !this.showSelectOption;
            },
            chooseAddressName(name, index){
                this.address_name = name;
                this.address_index = index;
                this.toggleSelect();
            },
            saveAddress() {

                if (this.address_index === null || this.room_number === '') {
                    this.set_msg({ msg: 'Please fill in all required fields.' });
                    return;
                }

                let address_obj = this.specialStoreArr[this.address_index];

                // check zip code
                let zip_error_flag = true;
                for (let i = 0; i < this.delivery_data.delivery_area_list.length; i++) {
                    let temp_arr = this.delivery_data.delivery_area_list[i].zip_code_list.split(',');
                    for (let j = 0; j < temp_arr.length; j++) {
                        if (temp_arr[j] === address_obj.zip_code) {
                            zip_error_flag = false;
                            this.zip_code_index = i;
                            break;
                        }
                    }

                    if (!zip_error_flag) {
                        break;
                    }
                }

                if (zip_error_flag) {
                    this.set_msg({ msg: this.language === 'chn' ? '不在配送区' : 'Not a service area' });
                    return;
                }

                this.set_address({
                    state: address_obj.state,
                    city: address_obj.city,
                    street_address1: 'Room [' + this.room_number + '], ' + this.address_name,
                    street_address2: address_obj.street,
                    zip_code: address_obj.zip_code,
                    zip_code_index: this.zip_code_index
                });
                this.has_saved_address = true;
                this.hide();
            }
        }
    };
</script>

<style lang="scss" scoped>
    .pop_copy {
        width: 100%;
        height: 100%;
        position: absolute;
    }
    .show_address {
        transform: translateY(0) !important;
        transition-duration: 235ms !important;
    }
    .address-layer {
        background: #fff;
        border-right: 1px solid rgba(0, 0, 0, 0.12);
        bottom: 0;
        font-size: 14px;
        overflow-y: auto;
        padding: 0px 0 40px;
        position: fixed;
        top: 0;
        transform: translateY(-100%);
        transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
        width: 280px;
        z-index: 100;
        opacity: 0.97;
        width: 100%;
        height: 100%;
    }
    .address_main .pop_title {
        padding-left: 58px;
        text-align: left;
    }
    .address_main .pop_input_title {
        color: #000;
        font-size: 18px;
        margin-bottom: 10px;
    }
    .pop .address_main .pop_input_box input {
        color: #000;
        background-color: #f7f7f7;
        border: none;
        padding: 0 16px;
        box-sizing: border-box;
    }
    .address_main .pop_select {
        position: relative;
    }
    .address_main .pop_select .select_main {
        display: block;
        width: 80%;
        height: 32px;
        line-height: 32px;
        color: #000;
        text-align: center;
        font-size: 14px;
        border: none;
        background: url('../assets/images/arrow_bottom_new.svg') no-repeat #f7f7f7 98% center;
        background-size: auto 50%;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
    }
    .address_main .pop_select .select_main.select_up {
        background-image: url('../assets/images/arrow_top_new.svg');
    }
    .address_main .pop_select .select_option {
        position: absolute;
        width: 80%;
        box-sizing: border-box;
        padding: 0 16px;
        top: 32px;
        left: 0;
        background-color: #fff;
    }
    .address_main .pop_select .option_item {
        display: block;
        width: 100%;
        font-size: 14px;
        height: 32px;
        line-height: 32px;
        color: #000;
    }
    .address_main .pop_flex {
        display: flex;
        justify-content: space-between;
        // flex-wrap: wrap;
    }
    .address_main .input_city, .address_main .input_state {
        flex: 2;
        padding-right: 16px;
        box-sizing: border-box;
    }
    .address_main .input_code {
        flex: 1;
    }
    .address_main .pop_btn {
        text-align: center;
        margin-top: 16px;
    }
    .address_main .pop_btn button {
        width: 123px;
        height: 32px;
        background-color: var(--theme_color);
        color: #fff;
    }
    @media screen and (max-width: 641px) {
        .address_main .pop_title {
            font-size: 1.125rem;
            padding-left: 40px;
            margin: 12px 0 15px;
        }
        .address_main .pop_input_title {
            font-size: 1rem;
        }
        .address_main .pop_flex {
            display: block;
        }
        .address_main .input_city, .address_main .input_state {
            padding-right: 0;
        }
    }
</style>
