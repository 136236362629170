<template>
    <div v-show="is_loading">
        <div class="custom-mask"></div>
        <div class="custom-loading">
            <img src="../assets/images/loading.svg" />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            is_loading: {
                type: Boolean,
                required: true
            }
        }
    };
</script>

<style lang="scss" scoped>
    .custom-mask {
        z-index: 1000;
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: #000;
        opacity: 0.4;
    }
    .custom-loading {
        animation: animal 0.7s infinite linear;
        -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
        transform-origin: center center;
        margin: 0 auto;
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 1001;
    }

    @keyframes animal {
        0% {
            transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
        }
        100% {
            transform: rotate(-360deg);
            -ms-transform: rotate(-360deg);
            -webkit-transform: rotate(-360deg);
        }
    }
</style>
