<template>
    <div class="pop" v-if="visible">
        <div class="pop_copy" @click="hide"></div>
        <div class="pop_main submit_layer">
            <div class="submit_layer_main">
                <div class="pop_title">{{ $t('review.submitNow') }}</div>
                <div class="submit_explain">{{ $t('review.submitOneFeedback') }}</div>
                <div class="pop_btn">
                    <mt-button type="danger" plain @click="hide">{{ $t('checkout.back') }}</mt-button>
                    <mt-button type="danger" @click="confirm">{{ $t('review.submit') }}</mt-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters, createNamespacedHelpers } from 'vuex';
    const App = createNamespacedHelpers('app');

    export default {
        name: 'RateLayer',
        components: {},
        props: {
            rateData: Object,
            rateSumbit: Function,
        },
        data() {
            return {
                visible: false,
            };
        },
        computed: {
            ...mapState({}),
            ...App.mapGetters({}),
        },
        mounted() {},
        methods: {
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG',
            }),
            show() {
                this.visible = true;
            },
            hide() {
                console.log('rateData', this.rateData);
                this.visible = false;
            },

            confirm() {
                // this.showRateInfo = true;
                // console.log('rateData', this.rateData);
                this.rateSumbit(this.rateData);
                this.hide();
                // this.submitOrder(this.postData);
            },
        }
    };
</script>
<style lang="scss" scoped>
    .pop .pop_main.submit_layer {
        max-width: 480px;
    }
    .submit_layer_main .pop_btn {
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        margin-top: 50px;
    }
    .submit_layer_main .pop_btn button {
        width: 180px;
        height: 42px;
        font-size: 16px;
    }
    .submit_layer_main .pop_btn button:first-child {
        margin-right: 60px;
    }
    .submit_explain {
        font-size: 14px;
        text-align: center;
        color: var(--theme_color);
    }

    @media screen and (max-width: 641px) {
        .pop .pop_title {
            margin: 0 0 10px 0;
            font-size: 18px;
        }
        .submit_layer_main {
            padding: 10px 0;
        }
        .submit_explain  {
            font-size: 14px;
        }
        .submit_layer_main .pop_btn {
            margin-top: 30px;
        }
        .submit_layer_main .pop_btn button {
            width: 80px;
            font-size: 14px;
            height: 32px;
        }
        .submit_layer_main .pop_btn button:first-child {
            margin-right: 30px;
        }
    }
</style>
