<template>
    <div class="pop" v-if="visible">
        <div class="pop_copy" @click="hide"></div>
        <div class="pop_main">
            <div class="language_main">
                <div class="pop_title">{{ $t('common.chooseLang') }}</div>
                <div class="pop_btn">
                    <template v-for="(item, index) in store_multi_lang">
                        <mt-button
                            type="danger"
                            :plain="language !== item.key"
                            @click="choose(item.key)"
                            v-bind:key="index"
                        >
                            {{ item.name }}
                        </mt-button>
                    </template>
                    <!-- <mt-button type="danger" :plain="language !== 'eng'" @click="choose('eng')">English </mt-button>
                    <mt-button type="danger" :plain="language !== 'chn'" @click="choose('chn')"> 中文简体 </mt-button>
                    <mt-button type="danger" :plain="language !== 'kor'" @click="choose('kor')"> 한국어 </mt-button> -->
                </div>
                <div class="language_footer">
                    <span>Powered by</span>
                    <span class="language_footer_icon"></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapMutations, mapGetters } from 'vuex';
    import { setLang } from '@/locales';
    export default {
        name: 'languagePop',
        data() {
            return {
                visible: false
            };
        },
        methods: {
            ...mapMutations({
                set_language: 'app/APP_SET_LANGUAGE'
            }),
            show() {
                this.visible = true;
            },
            hide() {
                this.visible = false;
            },
            choose(lang) {
                this.visible = false;
                localStorage.setItem('language', lang);
                setLang(lang);
                this.set_language({
                    language: lang
                });
            }
        },
        computed: {
            ...mapGetters({
                language: 'app/get_language',
                store_multi_lang: 'app/get_multi_lang',
            })
        }
    };
</script>

<style>
    .pop_copy {
        width: 100%;
        height: 100%;
        position: absolute;
    }
    .language_main .pop_title {
        text-align: center;
    }
    .language_main .pop_btn {
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
        align-items: center;
    }
    .language_main .pop_btn button {
        width: 123px;
        height: 32px;
        margin-bottom: 10px;
    }
    .language_main .mint-button--danger.is-plain {
        color: var(--theme_color)!important;
    }
    .language_main .language_footer {
        text-align: center;
        margin: 10px 0 20px;
        display: flex;
        justify-content: center;
    }
    .language_main .language_footer span {
        font-size: 12px;
        line-height: 20px;
    }
    .language_main .language_footer_icon {
        display: inline-block;
        width: 68px;
        height: 21px;
        background-image: url('../assets/images/footer_icon.png');
        background-repeat: no-repeat;
        margin-left: 5px;
        background-size: 100% auto;
    }
</style>
