<template>
    <div class="payment">
      <div class="title">Pay with card</div>
      <div class="body">
        <div class="flex">
          <img src="@/assets/images/creditcard.svg" class="card_icon" />
          <div id="tsep-cardNumDiv" class="tsep-cardNumDiv input_wrap"></div>
          <div id="tsep-datepickerDiv" class="tsep-datepickerDiv input_wrap"></div>
          <div id="tsep-cvv2Div" class="tsep-cvv2Div input_wrap"></div>
        </div>
      </div>
    </div>
</template>
<script>
  import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
  import { MessageBox, Toast } from 'mint-ui';
  export default {
      name: 'paymentCard',
      components: {},
      props: {
          total: String,
      },
      data() {
          return {
              waitingToken: false,
              errFields: {},
              cardNum: '',
              month: '',
              year: '',
              cvv: '',
              tokenEvent: null,
              is_development: this.$store.state.is_development,
              tempTotal: '',
          }
      },
      created() {
          const data = {
              vendor: 'creditcard',
              terminal: 'YIP',
              sid: this.sid,
              tid: this.tid,
              total: this.total
          }
          this.tempTotal = this.total;
          const host = this.debug ? 'http://localhost:5757' : '';
          fetch(host + '/weapp/takeout/getYuansferSecurePay', {
              method: 'post',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
          })
              .then((res) => {
                  return res.text()
              })
              .then((res) => {
                  res = JSON.parse(res)
                  const code = res.data.code
                  if (code != 100000) {
                      MessageBox({
                          title: '',
                          message: res.data.msg || 'system error',
                          showCancelButton: false,
                          confirmButtonText: this.language === 'eng' ? 'Confirm' : '确认',
                          confirmButtonClass: 'custom_confirm_class',
                      })
                  } else {
                      console.log('res.data.result', res.data.result);
                      const { manifest, deviceID, transactionNo } = res.data.result
                      this.transactionNo = transactionNo
                      let script = document.createElement('script')
                      script.type = 'text/javascript'
                      let _src =
                          'https://gateway.transit-pass.com/transit-tsep-web/jsView/' +
                          deviceID +
                          '?' +
                          manifest
                      if (this.is_development) {
                          _src =
                          'https://stagegw.transnox.com/transit-tsep-web/jsView/' +
                          deviceID +
                          '?' +
                          manifest
                      }
                      script.src = _src
                      document.getElementsByTagName('head')[0].appendChild(script)
                      window.tsepHandler = (eventType, event) => {
                          if (eventType === 'FieldValidationErrorEvent') {
                          const msg = event.message
                          this.tokenEvent = null
                          MessageBox({
                              title: '',
                              message:  msg,
                              showCancelButton: false,
                              confirmButtonText: this.language === 'eng' ? 'Confirm' : '确认',
                              confirmButtonClass: 'custom_confirm_class',
                          })
                          this.errFields[event.fieldName] = event.message
                          } else if (eventType === 'TokenEvent') {
                              this.tokenEvent = event
                              if (/^(FAIL|FAILURE)$/.test(event.status)) {
                                  MessageBox({
                                      title: '',
                                      message:  event.message,
                                      showCancelButton: false,
                                      confirmButtonText: this.language === 'eng' ? 'Confirm' : '确认',
                                      confirmButtonClass: 'custom_confirm_class',
                                  })
                                  this.toggleLoading(false)
                              } else {
                                  this.tokenEvent.cvv2 = this.tokenEvent.cvv2.replace(/^\s+/, '')
                              }
                          } else if (eventType === 'FocusOutEvent') {
                              this.errFields[event.fieldName] = ''
                              if (event.fieldName === 'tsep-cardNum') {
                                  const cardNumber = document.querySelector(
                                      '#tsep-cardNumDiv input'
                                  ).value
                                  if (!this.lastCardNumber) {
                                      this.lastCardNumber = cardNumber
                                      this.tokenEvent = null
                                  } else if (this.lastCardNumber !== cardNumber) {
                                      this.lastCardNumber = cardNumber
                                      this.tokenEvent = null
                                  }
                              }
                          }
                      }
                  }
              })
      },
      computed: {
          ...mapGetters({
              oid: 'get_shared_oid',
              tid: 'app/get_shared_tid',
          }),
          ...mapState({
              sid: (state) => state.app.sid,
              debug: (state) => state.debug,
          }),
      },
      methods: {
          ...mapActions({
          }),
          ...mapMutations({
          }),
          async waitToken() {
              let timmer = null;
              return new Promise((res, rej)=>{
                  timmer = setInterval(() => {
                      if(this.tokenEvent) {
                          clearInterval(timmer);
                          res();
                          return;
                      }
                  }, 100);
              });
          },
          async getToken() {
              if (this.total != this.tempTotal) {
                const data = {
                  vendor: 'creditcard',
                  terminal: 'YIP',
                  sid: this.sid,
                  tid: this.tid,
                  total: this.total
                }
                this.tempTotal = this.total;
                const host = this.debug ? 'http://localhost:5757' : '';
                await fetch(host + '/weapp/takeout/getYuansferSecurePay', {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                    .then((res) => {
                        return res.text()
                    })
                    .then((res) => {
                        res = JSON.parse(res)
                        const code = res.data.code
                        if (code != 100000) {
                            MessageBox({
                                title: '',
                                message: res.data.msg || 'system error',
                                showCancelButton: false,
                                confirmButtonText: this.language === 'eng' ? 'Confirm' : '确认',
                                confirmButtonClass: 'custom_confirm_class',
                            })
                        } else {
                            const { transactionNo } = res.data.result
                            this.transactionNo = transactionNo
                        }
                    })  
              }
              const cardEle = document.querySelector('#tsep-cardNumDiv input')
              const expireEle = document.querySelector('#tsep-datepickerDiv input')
              const cvvEle = document.querySelector('#tsep-cvv2Div input')
              if (!cardEle?.value || !expireEle?.value || !cvvEle?.value) {
                  Toast('请填写信用卡信息')
                  return;
              }
              if (!this.tokenEvent) {
                  await this.waitToken();
              }
              if (/^(FAIL|FAILURE)$/.test(this.tokenEvent.status)) {
                  MessageBox({
                      title: '',
                      message:  this.tokenEvent.message,
                      showCancelButton: false,
                      confirmButtonText: this.language === 'eng' ? 'Confirm' : '确认',
                      confirmButtonClass: 'custom_confirm_class',
                  })
                  return
              }
              const data = {
                  transactionNo: this.transactionNo,
                  cardNumber: this.tokenEvent.tsepToken,
                  type: 'Debit/Credit Card',
                  expirationDate: this.tokenEvent.expirationDate,
                  cardCvv: this.tokenEvent.cvv2,
                  cardType: this.tokenEvent.cardType,
                  status: 'PASS',
              }
              return data;
          },
      },
  }
</script>
<style lang="scss" scoped>
  .payment {
    background-color: #f7f7f7;
    border-radius: 6px;
    padding: 12px 5px 5px;
    .title {
      font-family: Lato;
      font-size: 14px;
      font-weight: normal;
      line-height: 17px;
      color: #4a4a4a;
      text-align: center;
      margin-bottom: 13px;
    }
    .body {
      background-color: #ffffff;
      border-radius: 6px;
      padding: 10px 8px 8px;
      .errMsg {
        color: red;
        font-size: 12px;
      }
      .button {
        margin-top: 10px;
        height: 42px;
        background-color: #3874de;
        border-radius: 4px;
        font-family: Lato;
        font-size: 17px;
        font-weight: normal;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .flex {
        display: flex;
        align-items: center;
        height: 20px;
        margin: 15px 0;
        > * {
          height: 100%;
        }
        input {
          border: none;
        }
        .card_icon {
          margin-right: 10px;
          height: 80%;
        }
        .expire {
          width: 30px;
          text-align: center;
          margin: 0 5px;
        }
        .expire_month {
          text-align: right;
        }
        .expire_year {
          text-align: left;
          width: 40px;
        }
        .cvv {
          width: 42px;
          text-align: center;
        }
        .number {
          flex-grow: 1;
          width: 100px;
        }
      }
    }
    .input_wrap {
        font-size: 15px;
      ::v-deep input {
        border: none;
      }
    }
    .tsep-cardNumDiv {
      flex-grow: 1;
    }
    .tsep-datepickerDiv {
      margin-right: 10px;
      ::v-deep input {
        width: 80px;
      }
    }
    .tsep-cvv2Div {
      ::v-deep input {
        width: 40px;
      }
    }
  }
</style>
