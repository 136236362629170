<template>
    <footer :class="showPolicy == 'true' ? 'footer policy_footer' : 'footer'">
        <p v-if="showPolicy == 'true'">
            By joining in, you agree to Minitable Tech, Inc
            <a href="http://info.minitable.net/termsofuse.html" class="link">Terms of Service</a>
            <span> and </span>
            <a href="http://info.minitable.net/privacypolicy.html" class="link">Privacy Policy.</a>
        </p>
        <a href="https://minitable.net/" target="_blank">
            <span>Powered by</span>
            <span class="text-img"></span>
        </a>
    </footer>
</template>

<style>
    .footer {
        width: 100%;
        height: 2.5rem;
        padding: .25rem 0;
        background-color: #fff;
        text-align: center;
        line-height: 2.5rem;
        z-index: 999 !important;
        position: static !important;
        box-sizing: border-box;
    }
    .footer a {
        color: #000;
        font-size: 12px;
        /* font-weight: bold; */
        text-decoration: none;
        display: inline-flex;
        line-height: 15px;
        align-items: center;
    }
    .footer .text-img {
        display: block;
        width: 76px;
        height: 15px;
        margin: -2px 0 0 0px;
        background-position: 100% 50%;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-image: url('../assets/images/footer_icon.png');
    }
    .footer.policy_footer {
        line-height: 1rem;
    }
    .footer.policy_footer p {
        font-size: 14px;
        color: #AEAEB2;
    }
    .footer.policy_footer p a {
        color: #539cff;
        font-size: 14px;
    }
    @media screen and (max-width: 641px) {
        .footer.policy_footer {
            line-height: 1rem;
            height: 5.5rem;
            padding: 0 2.5rem;
        }
        .footer.policy_footer p {
            padding-top: .75rem;
            font-size: 12px;
            margin-bottom: .75rem;
        }
        .footer.policy_footer p a {
            font-size: 12px;
        }
    }
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters, createNamespacedHelpers } from 'vuex';
    const App = createNamespacedHelpers('app');

    export default {
        name: 'PageFooter',
        components: {},
        props: {
            showPolicy: String
        },
        data() {
            return {};
        },
        computed: {
            ...mapState({}),
            ...App.mapGetters({}),
        },
        mounted() {},
        methods: {}
    };
</script>