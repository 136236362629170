<template>
    <div class="pop" v-if="visible">
        <div class="pop_copy" @click="hide"></div>
        <div class="pop_main">
            <div class="table_layer_main">
                <div class="pop_title">{{ $t('checkout.selectTable') }}</div>
                <div class="pop_radio_main">
                    <div class="pop_radio_row">
                        <div class="radio_button_box" v-for="(table, index) in dineInTables" :key="index">
                            <button
                                :class="tableName == table.table_name ? 'select' : ''"
                                @click="choose_table_num(table)"
                            >
                                {{table.table_name}}
                            </button>
                        </div>
                    </div>
                </div>
                <!-- <div class="pop_radio_main">
                    <div class="pop_radio_row">
                        <div class="radio_box" v-for="(table, index) in dineInTables" :key="index">
                            <div class="radio_item">
                                <div class="m_radio">
                                    <span class="big_circle">
                                        <b></b>
                                        <b></b>
                                    </span>
                                    <input
                                        :id="'select_table' + index"
                                        type="radio"
                                        name="select_table"
                                        v-model="tableName"
                                        :value="table.table_name"
                                        readonly
                                    />
                                    <span class="m_icon m_radio_icon"></span>
                                </div>
                                <label class="m_label" :for="'select_table' + index">{{ table.table_name }}</label>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="pop_btn">
                    <mt-button type="danger" plain @click="hide">{{ $t('common.cancel') }}</mt-button>
                    <mt-button type="danger" @click="confirm">{{ $t('common.confirm') }}</mt-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters, createNamespacedHelpers } from 'vuex';
    const App = createNamespacedHelpers('app');

    export default {
        name: 'TableLayer',
        components: {},
        data() {
            return {
                visible: false,
                tableName: window.localStorage.getItem('dineInTable') || ''
            };
        },
        computed: {
            ...mapState({
                dineInTable: (state) => state.app.dineInTable,
                dinein_setting: (state) => state.app.dinein_setting
            }),
            ...App.mapGetters({
                language: 'get_language',
                dineInTableEnable: 'get_dineInTableEnable',
                dineInTables: 'get_dineInTables'
            }),
            ifShow() {
                return this.dinein_setting?.enable_table_select == 1 && this.tables?.length > 0;
            },
            tables() {
                let tables = this.dinein_setting?.table_list || [];
                tables = tables.filter((table) => {
                    return table.enable === 1;
                });
                return tables;
            }
        },
        mounted() {
            // console.log('this.dineInTable', this.dineInTable);
            if (this.dineInTable) {
                this.tableName = this.dineInTable;
            }
        },
        methods: {
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG',
                APP_SET_DineInTable: 'app/APP_SET_DineInTable'
            }),
            show() {
                if (this.dineInTableEnable) {
                    this.visible = true;
                }
            },
            hide() {
                this.visible = false;
            },

            confirm() {
                if (!this.tableName) {
                    const msg = this.language == 'eng' ? 'Please select table' : '请选择您就座的桌子';
                    this.set_msg({
                        msg
                    });
                    return;
                }
                this.hide();
                this.APP_SET_DineInTable(this.tableName);
            },
            choose_table_num(table) {
                this.tableName = this.tableName == table.table_name ? '' : table.table_name;
            }
        }
    };
</script>
<style lang="scss" scoped>
    .pop_radio_row {
        display: flex;
        justify-content: center;
        font-size: 14px;
        flex-wrap: wrap;
    }
    .radio_button_box {
        padding: 0px 10px;
        button {
            padding: 0 20px;
            color: #000;
            font-weight: 500;
            border: none;
            background-color: #e9e9ea;
            font-size: 18px;
            height: 28px;
            line-height: 28px;
            border-radius: 5px;
            margin-bottom: 10px;
            &.select {
                color: #fff;
                background-color: var(--theme_color);
            }
        }
    }
    .pop_radio_main {
        margin-bottom: 20px;
    }
    .table_layer_main .pop_btn {
        display: flex;
        display: -webkit-flex;
        justify-content: center;
    }
    .table_layer_main .pop_btn button {
        width: 90px;
        height: 30px;
        font-size: 16px;
    }
    .table_layer_main .pop_btn button:first-child {
        margin-right: 60px;
    }

    @media screen and (max-width: 641px) {
        .pop .pop_title {
            margin: 0 0 10px 0;
            font-size: 18px;
        }
        .table_layer_main {
            padding: 10px 0;
        }
        .table_layer_main .pop_btn button {
            width: 80px;
            font-size: 14px;
        }
        .radio_button_box button {
            font-size: 16px;
        }
        .table_layer_main .pop_btn button:first-child {
            margin-right: 30px;
        }
    }

    .pop_radio_main .radio_box {
        margin-right: 20px;
    }
    .pop_radio_main .radio_item {
        height: 24px;
        line-height: 24px;
        padding-left: 24px;
    }
    .pop_radio_main .m_radio {
        width: 24px;
        height: 24px;
    }
    .pop_radio_main .m_radio .big_circle {
        width: 24px;
        height: 24px;
    }
    .pop_radio_main .m_radio .m_radio_icon {
        top: 5px;
        left: 5px;
        width: 12px;
        height: 12px;
    }
    .pop_radio_main .m_radio_icon::after {
        width: 8px;
        height: 8px;
        top: 2px;
        left: 2px;
    }

    .radio_box {
        display: flex;
        display: -webkit-flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
    }
    .radio_item {
        display: flex;
        height: 44px;
        line-height: 44px;
        padding-left: 44px;
        position: relative;
    }
    .m_radio {
        width: 44px;
        height: 44px;
        padding: 0;
        border-radius: 50%;
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
    }
    .m_radio .big_circle {
        width: 44px;
        height: 44px;
        position: absolute;
        border-radius: 50%;
        top: 0;
        left: 0;
        cursor: pointer;
    }
    .big_circle b {
        width: 20%;
        height: 20%;
        border-radius: 50%;
        display: block;
        background-color: rgba(255, 255, 255, 0.6);
        position: absolute;
        left: 40%;
        top: 40%;
        z-index: 1;
    }
    .m_radio input {
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        z-index: 3;
    }
    .m_icon {
        display: block;
        width: 22px;
        height: 22px;
        position: absolute;
        box-sizing: content-box;
        top: 11px;
        left: 0;
    }
    .m_radio_icon {
        border: 1px solid #707070;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background-color: #fff;
    }
    .m_radio .m_radio_icon {
        top: 11px;
        left: 11px;
        z-index: 2;
    }
    .m_icon::before,
    .m_icon::after {
        content: '';
        display: block;
        position: absolute;
    }
    .m_radio_icon::after {
        width: 14px;
        height: 14px;
        background-color: none;
        top: 4px;
        left: 4px;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        z-index: 2;
    }
    .m_radio input:checked + span.m_icon::after {
        background-color: var(--theme_color);
    }
</style>
