<template>
    <div class="pop" v-if="visible">
        <div class="pop_copy" @click="hide"></div>
        <div class="pop_main">
            <div class="pop_close" @click="hide"><span></span></div>
            <div class="asap_main">
                <div class="pop_title asap_title">Sorry.</div>
                <div class="pop_title">
                    ASAP <span class="capitalize">{{orderType}}</span> is not available now.
                </div>
                <p>Here is the schedule available for ASAP {{orderType}}: </p>
                <template v-if="pickup_asap_time.length > 0 && orderType == 'pickup'">
                    <ul
                        v-for="(item, index) in pickup_asap_time"
                        :key="index"
                    >
                        <li v-if="item.enable">
                            <span class="uppercase">{{item.key}}</span>
                            <span
                                v-for="(cell, i) in item.time_list"
                                :key="i"
                            >
                                {{cell.stime}} 
                                AM - 
                                {{cell.etime}} 
                                PM
                            </span>
                        </li>
                    </ul>
                </template>
                <template v-if="deliveryIndex !== -1 && delivery_asap_list[deliveryIndex].length > 0 && orderType == 'delivery'">
                    <ul
                        v-for="(item, index) in delivery_asap_list[deliveryIndex]"
                        :key="index"
                    >
                        <li v-if="item.enable">
                            <span class="uppercase">{{item.key}}</span>
                            <span
                                v-for="(cell, i) in item.time_list"
                                :key="i"
                            >
                                {{cell.stime}} 
                                AM - 
                                {{cell.etime}} 
                                PM
                            </span>
                        </li>
                    </ul>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState, mapGetters, createNamespacedHelpers } from 'vuex';
    const App = createNamespacedHelpers('app');
    export default {
        name: 'ASAPLayer',
        components: {},
        props: {
            orderType: String,
            deliveryIndex: Number
        },
        data() {
            return {
                visible: false,
            };
        },
        computed: {
            ...App.mapGetters({
                pickup_asap_time: 'get_pickup_asap_time',
                delivery_asap_list: 'get_delivery_asap_list',
            }),
        },
        mounted() {},
        methods: {
            show() {
                this.visible = true;
            },
            hide() {
                this.visible = false;
            },
        }
    };
</script>

<style lang="scss" scoped>
    .pop_copy {
        width: 100%;
        height: 100%;
        position: absolute;
    }
    .asap_main {
        text-align: center;
        color: #000;
        margin-bottom: 20px;
        .pop_title {
            font-weight: bolder;
        }
        .uppercase {
            text-transform: uppercase;
        }
        .capitalize {
            text-transform: capitalize;
        }
    }
    .asap_title {
        font-size: 26px;
        color: var(--theme_color);
        text-transform: uppercase;
    }
    .asap_main p {
        font-size: 18px;
    }
    .asap_main ul {
        font-size: 18px;
        margin-top: 20px;
    }

    @media screen and (max-width: 641px) {
        .asap_main .pop_title {
            font-size: 1.125rem;
        }
    }
</style>
