<template>
    <div class="pop" v-if="visible">
        <div class="pop_copy" @click="hide"></div>
        <div class="pop_main">
            <div class="pop_close" @click="hide" style="left: 20px; top: 15px;"><span></span></div>
            <div class="policy_layer_main">
                <div class="pop_title">
                    {{policyTitle}}
                </div>
                <div class="policy_text" v-html="policyText">
                    <!-- {{policyText}} -->
                </div>
                <div class="pop_btn">
                    <mt-button type="danger" @click="hide">
                        {{ $t('menu.gotIt') }}
                    </mt-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters, createNamespacedHelpers } from 'vuex';
    const App = createNamespacedHelpers('app');

    export default {
        name: 'PolicyLayer',
        components: {},
        props: {
            policyTitle: String,
            policyText: String
        },
        data() {
            return {
                visible: false
            };
        },
        computed: {
            ...mapState({}),
            ...App.mapGetters({}),
        },
        mounted() {},
        methods: {
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG',
            }),
            show() {
                this.visible = true;
            },
            hide() {
                this.visible = false;
            }
        }
    };
</script>
<style lang="scss" scoped>
    .pop .pop_main {
        max-height: 60vh;
        overflow-y: scroll;
        word-break: break-word;
    }
    .policy_layer_main {
        padding: 0 37px;
        margin-bottom: 20px;
        text-align: center;
    }
    .policy_layer_main .pop_title {
        margin-top: 10px;
        color: #000;
        font-size: 20px;
    }
    .policy_layer_main .policy_text {
        font-size: 16px;
        color: #000;
        margin-bottom: 40px;
        text-align: left;
    }
    .policy_layer_main .pop_btn button {
        width: 131px;
        height: 53px;
        line-height: 53px;
        border-radius: 15px;
        font-size: 20px;
        color: #fff;
    }
    @media screen and (max-width: 641px) {
        .policy_layer_main {
            padding: 0 26px;
        }
        .policy_layer_main .pop_btn button {
            width: 105px;
            height: 39px;
            line-height: 39px;
            border-radius: 8px;
            font-size: 16px;
        }
    }
</style>
