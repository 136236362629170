import querystring from 'querystring';

const interface_list = {
    submit_order: { url: '/weapp/takeout/order/edit', method: 'post' },
    submit_order_by_stripe: { url: '/weapp/takeout/order/stripe_submit', method: 'post' },
    submit_order_by_yuansfer_creditCard: { url: '/weapp/takeout/order/yuansfer_creditCard_submit', method: 'post' },
    submit_order_by_paypal: { url: '/weapp/takeout/order/paypal_submit', method: 'post' },
    complete_pay: { url: '/weapp/takeout/order/complete_pay', method: 'post' },
    cancel_order: { url: '/weapp/takeout/order/cancel', method: 'post' }
};

function fetchApi({ request_name, params, customHeaders = {}, aj_host = '' }) {
    const conf = JSON.parse(JSON.stringify(interface_list[request_name]));
    const token = window.localStorage.getItem('token') || '';

    if (!conf) {
        return Promise.reject({
            code: 0,
            msg: '',
            data: {
                code: 100010,
                msg: 'no ajax register',
                data: {}
            }
        });
    }

    const headers = new Headers();

    Object.keys(customHeaders).forEach((k) => {
        headers.append(k, customHeaders[k]);
    });

    headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
    headers.append('Authorization', 'Bearer ' + token);

    const fetch_optins = {
        method: conf.method,
        headers,
        credentials: 'omit'
    };

    if (conf.method == 'get') {
        conf.url = conf.url + '?' + querystring.stringify(params);
    } else if (conf.method == 'post') {
        // const body = JSON.stringify(params);
        // fetch_optins.body = body;

        // const searchParams = new URLSearchParams();
        // for (const prop in params) {
        //     searchParams.set(prop, params[prop]);
        // }
        const searchParams = Object.keys(params)
            .map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
            })
            .join('&');

        fetch_optins.body = searchParams;
    }

    return fetch(aj_host + conf.url, fetch_optins)
        .then((response) => {
            if (response.status >= 400) {
                throw new Error('Bad response from server');
            }

            if (response.headers.get('Authorization')) {
                let token = response.headers.get('Authorization').split(' ')[1];
                if (token) {
                    window.localStorage.setItem('token', token);
                }
            }
            return response.json();
        })
        .then((data) => {
            if (data.code == -1) {
                Promise.reject({
                    code: 0,
                    msg: '',
                    data: {
                        code: 100010,
                        msg: data.error,
                        data: {}
                    }
                });
                return;
            }

            if (data.data.code === 100000) {
                return Promise.resolve(data);
            } else if (data.data.code === 100002) {
                window.localStorage.removeItem('sid');
                window.localStorage.removeItem('token');
                window.location.replace('/login');
                return Promise.reject(data);
            } else {
                return Promise.reject(data);
            }
        })
        .catch((err) => {
            console.log(err);
            return Promise.reject(err);
        });
}

export default ({ commit }, opts) => {
    return fetchApi(opts)
        .then((data) => {
            return Promise.resolve(data);
        })
        .catch((res) => {
            commit && commit('app/APP_SET_MSG', { msg: res.data.msg, type: 'error' }, { root: true });
            return Promise.reject(res);
        });
};
