<template>
    <div>
        <div class="check_header" :style="{ backgroundImage: feature_image }">
            <!-- <div class="white_bg"></div> -->
            <!-- <h3>REVIEW</h3> -->
            <div class="header_main">
                <mt-button type="danger" plain @click="stratNewOrder"> {{ $t('review.startNewOrder') }} </mt-button>
            </div>
        </div>
        <div class="thanks_section">
            <div class="thanks_pot">
                <div class="pot_info">
                    <div class="pot_logo" v-if="store_data.slogo" :style="{ backgroundImage: slogoBack }"></div>
                    <div>
                        <div class="pot_name">{{ store_data.sname }}</div>
                        <!-- <div class="pot_address">sss</div> -->
                    </div>
                </div>
                <!-- <div class="state_info" v-if="this.takeout_info.takeout_tstatus == -1">
                    <div class="state_text red">** Order Canceled **</div>
                </div>
                <template v-else> -->
                <div class="state_info">
                    <!-- <div class="state_icon"></div> -->
                    <div class="state_text" v-if="this.takeout_info.customer_name">
                        <!-- Hi {{ this.takeout_info.customer_name }}, thanks for your order! -->
                        {{ takeout_info.takeout_tstatus == -1 ? $t('review.orderCanceledNew', { name: this.takeout_info.customer_name }) : $t('review.thanksForOrder', { name: this.takeout_info.customer_name }) }}
                    </div>
                    <div class="state_text" v-else>{{ $t('review.thankYouForOrder') }}</div>
                </div>
                <!-- </template> -->
                <div class="pot_btn">
                    <mt-button type="danger" @click="contactUs">{{ $t('review.contactUs') }}</mt-button>
                </div>
            </div>
            <div class="payment_box" v-if="takeout_info.pay_fail_time != '' && takeout_info.paid == 0 && takeout_info.takeout_tstatus != -1">
                <template v-if="(payment_setting.some(item=>item.payment==='Debit/Credit Card') || payment_setting.some(item=>item.payment==='paypal')) && maxtime > 0">
                    <div class="payment_title"> {{ $t('review.notPaid') }} </div>
                    <div class="payment_time">
                        {{ countDownTxt }}
                    </div>
                    <div class="payment_pay">
                        <div class="pay_right">
                            <div class="goods_list_inner">
                                <div class="price_list_top">
                                    <div class="details_item">
                                        <div class="details_row">
                                            <span>{{ $t('common.subtotal') }}</span>
                                            <span>${{ subtotal }}</span>
                                        </div>
                                    </div>
                                    <div class="details_item" v-if="specialOffer > 0">
                                        <div class="details_row fc_theme_color">
                                            <span>{{ $t('cart.specialOffer') }}</span>
                                            <span>-${{ specialOffer }}</span>
                                        </div>
                                    </div>
                                    <div class="details_item" v-if="pickupDiscount > 0">
                                        <div class="details_row fc_theme_color">
                                            <span>{{ $t('cart.pickupDiscountNew') }}</span>
                                            <span>-${{ pickupDiscount }}</span>
                                        </div>
                                    </div>
                                    <div class="details_item" v-if="itemDiscount > 0">
                                        <div class="details_row fc_theme_color">
                                            <span>{{ $t('review.itemDiscount') }}</span>
                                            <span>-${{ itemDiscount }}</span>
                                        </div>
                                    </div>
                                    <div class="details_item" v-if="orderDiscount > 0">
                                        <div class="details_row fc_theme_color">
                                            <span>{{ $t('review.orderDiscount') }}</span>
                                            <span>-${{ orderDiscount }}</span>
                                        </div>
                                    </div>
                                    <div class="details_item" v-if="newDiscount !== 0">
                                        <div class="details_row fc_theme_color">
                                            <span>{{ this.new_discount_price }}</span>
                                            <span>-${{ newDiscountShow * -1 }}</span>
                                        </div>
                                    </div>
                                    <div class="details_item" v-if="discount !== 0">
                                        <div class="details_row fc_theme_color">
                                            <span>
                                                <!-- {{ fullCouponObj.type === 'fixed' ? '$' + fullCouponObj.discount : fullCouponObj.discount + '%' }} Off Orders Over ${{ fullCouponObj.full }} -->
                                                {{
                                                    $t('cart.fullDiscountText', {
                                                        total_price: fullCouponObj.full,
                                                        discount_price: fullCouponObj.type === 'fixed' ? '$' + fullCouponObj.discount : fullCouponObj.discount + '%'
                                                    })
                                                }}
                                            </span>
                                            <span>-${{ discountShow * -1 }}</span>
                                        </div>
                                    </div>
                                    <div class="details_item">
                                        <div class="details_row" v-if="taxPrice > 0">
                                            <span>{{ $t('common.taxAndFee') }}</span>
                                            <span>${{ taxPrice }}</span>
                                        </div>
                                    </div>
                                    <div class="details_item" v-if="deliveryFee > 0">
                                        <div class="details_row">
                                            <span>{{ $t('common.deliveryFee') }}</span>
                                            <span>${{ deliveryFee }}</span>
                                        </div>
                                    </div>
                                    <div class="details_item" v-if="processingFee > 0">
                                        <div class="details_row">
                                            <span>{{ $t('common.processingFee') }}</span>
                                            <span>${{ processingFee }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="details_item details_tip" v-if="tip > 0">
                                    <div class="details_row">
                                        <span>{{ $t('cart.tip') }}</span>
                                        <span>${{ tip }}</span>
                                    </div>
                                </div>
                                <div class="details_item details_total">
                                    <div class="details_row">
                                        <span class="fw_bold">{{ $t('common.placeTotal') }}</span>
                                        <span>${{ total }}</span>
                                    </div>
                                </div>
                                <div class="details_item details_refund" v-if="order_data.refund_detail && order_data.refund_detail.length > 0">
                                    <div class="refund_item"
                                        v-for="(refund_item, index) in order_data.refund_detail"
                                        :key="index"
                                    >
                                        <div class="refund_date">{{ refund_item.refundType == 'full' ? $t('review.fullyRefundedDate', { date: refund_item.create_time }) : $t('review.partiallyRefundedDate', { date: refund_item.create_time }) }}</div>
                                        <div class="details_row">
                                            <span class="fc_red">{{ $t('review.refund') }}</span>
                                            <span class="fc_red">-${{ refund_item.amount }}</span>
                                        </div>
                                    </div>
                                    <div class="details_row">
                                        <span class="fw_bold">{{ $t('review.netPayment') }}</span>
                                        <span>${{ refund }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="pay_right_btn">
                                <mt-button type="danger" v-if="pay_method !== 'paypal'" @click="to_pay">Pay Now</mt-button>
                            </div>
                        </div>
                        <div class="pay_left">
                            <div class="pay_left_title">Payment</div>
                            <div class="checkout_method">
                                <div class="method_main">
                                    <div class="method_select" ref="methodSelect" @click="toggleSelect">
                                        <span class="select_arrow"></span>
                                        <div v-if="pay_method == 'creditCard'" class="method_select_item">
                                            <span class="pay_credit"></span>
                                            {{ $t('checkout.debitCard') }}
                                        </div>
                                        <div v-else-if="pay_method == 'paypal'" class="method_select_item">
                                            <span class="pay_paypal"></span>
                                            PayPal
                                        </div>
                                        <div v-else-if="pay_method == 'pickup'" class="method_select_item">
                                            <span class="pay_pickup"></span>
                                            {{ $t('checkout.payAtPickup') }}
                                        </div>
                                        <div v-else class="method_select_item pay_default">
                                            {{ $t('checkout.choosePaymentMethod') }}
                                        </div>
                                    </div>
                                    <div class="method_option" ref="methodOption" style="display: none;">
                                        <div
                                            v-if="payment_setting.some(item=>item.payment==='Debit/Credit Card')"
                                            class="method_option_item"
                                            @click="choosePaymentType('creditCard')"
                                        >
                                            <span class="pay_credit"></span>
                                            {{ $t('checkout.debitCard') }}
                                        </div>
                                        <div
                                            v-if="payment_setting.some(item=>item.payment==='paypal')"
                                            class="method_option_item"
                                            @click="choosePaymentType('paypal')"
                                        >
                                            <span class="pay_paypal"></span>
                                            PayPal
                                        </div>
                                        <!-- <div
                                            v-if="payment_setting.some(item=>item.payment==='pickup')"
                                            class="method_option_item"
                                            @click="choosePaymentType('pickup')"
                                        >
                                            <span class="pay_pickup"></span>
                                            {{ $t('checkout.payAtPickup') }}
                                        </div> -->
                                    </div>
                                </div>
                                <div class="pay_info" style="margin-top: 20px">
                                    <div
                                        v-show="is_wechat === 0 && pay_method === 'paypal'"
                                        id="paypal-button-container"
                                    ></div>
                                    <div
                                        v-if="is_wechat === '1' && pay_method === 'paypal'"
                                        style="font-size: 13px; color: red"
                                    >
                                        Paypal is not support in Wechat. Please reopen this page in browser!
                                    </div>
                                    <Stripe v-show="creditCard_strip_show" @setStripe="setStripe" />
                                    <div v-if="stripe_card_errors">
                                        <mdc-text typo="body2" tag="span" adjust-margin>
                                            {{ stripe_card_errors }}
                                        </mdc-text>
                                    </div>
                                    <CreditCardYuansfer :total="total + ''" v-if="creditCard_yuansfer_show" ref="yuansfer_creditCard" />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="payment_title"> {{ $t('review.notPaidNew') }} </div>
                </template>
            </div>
            <div class="thanks_inner">
                <div class="thanks_left">
                    <div class="thanks_card">
                        <div class="thanks_state">
                            <!-- <div class="state_info" v-if="this.takeout_info.takeout_tstatus == -1">
                                <div class="state_text red">** Order Canceled **</div>
                            </div> -->
                            <!-- <template v-else> -->
                                <!-- <div class="state_info">
                                    <div class="state_icon"></div>
                                    <div class="state_text red" v-if="this.takeout_info.customer_name">
                                        Hi {{ this.takeout_info.customer_name }}, thanks for your order!
                                    </div>
                                    <div class="state_text red" v-else>Hi mini, thanks for your order!</div>
                                </div> -->
                                <div class="thanks_type">
                                    <div v-if="this.takeout_info.type == 'Dine-in'" class="thanks_type_item dinein_type">
                                        <span></span>
                                        {{ $t('checkout.dineIn') }}
                                    </div>
                                    <div v-else-if="this.takeout_info.type == 'pickup'" class="thanks_type_item pickup_type">
                                        <span></span>
                                        {{ $t('checkout.pickup') }}
                                    </div>
                                    <div v-else-if="this.takeout_info.type == 'delivery'" class="thanks_type_item delivery_type">
                                        <span></span>
                                        {{ $t('checkout.delivery') }}
                                    </div>
                                    <div v-else-if="this.takeout_info.type == 'third_party'" class="thanks_type_item pickup_type">
                                        <span></span>
                                        Third-Party Platform
                                    </div>
                                    <div class="thanks_list_number">
                                        <!-- {{ $t('review.orderPlaced') }}: {{ this.takeout_info.order_time }} -->
                                        #{{ theme_data.show_rank_enable ? takeout_info.rank : oidLastFive }}
                                    </div>
                                </div>
                                <template v-if="takeout_info.takeout_tstatus == -1">
                                    <div class="state_explain">
                                        <span class="state_explain_time">
                                            {{ $t('review.canceled') }}
                                        </span>
                                    </div>
                                    <div class="state_main canceled_main">
                                        <div class="state_list" id="pay_state">
                                            <div class="state_item_first">
                                                <span class="state_step">1</span>
                                            </div>
                                            <div class="state_item">
                                                <span class="state_step">2</span>
                                            </div>
                                            <div class="state_item">
                                                <span class="state_step">3</span>
                                            </div>
                                            <div class="state_item">
                                                <span class="state_step">4</span>
                                            </div>
                                        </div>
                                        <div class="state_img state_img_canceled"></div>
                                    </div>
                                    <div class="state_msg">{{ $t('review.orderCanceled') }}</div>
                                </template>
                                <template v-else>
                                    <div class="state_explain" v-if="takeout_info.takeout_tstatus >= 10">
                                        <span class="state_explain_time">
                                            {{
                                                getEstimatedTime(this.order_data.update_time)
                                            }}
                                        </span>
                                        <span>
                                            {{ $t('review.orderCompleted') }}
                                        </span>
                                    </div>
                                    <!-- order_delivered 的 takeout_tstatus 为 8 -->
                                    <div class="state_explain" v-else-if="takeout_info.takeout_tstatus >= 7">
                                        <span class="state_explain_time" v-if="this.takeout_info.type == 'delivery'">
                                            {{
                                                getEstimatedTime(this.takeout_info.delivered_time)
                                            }}
                                        </span>
                                        <span class="state_explain_time" v-else>
                                            {{
                                                getEstimatedTime(this.takeout_info.order_ready_time)
                                            }}
                                        </span>
                                        <span>
                                            {{ $t('review.orderReadyTime') }}
                                        </span>
                                    </div>
                                    <div class="state_explain" v-else>
                                        <!-- <span class="state_explain_time"> {{ getNowTime() }}</span> -->
                                        <span class="state_explain_time">
                                            <!-- Estimated time: -->
                                            <!-- {{
                                                this.takeout_info.estimated_time && this.takeout_info.estimated_time !== ''
                                                    ? this.takeout_info.estimated_time
                                                    : this.takeout_info.pickup_time
                                            }} -->
                                            {{
                                                getEstimatedTime(this.takeout_info.estimated_time && this.takeout_info.estimated_time !== ''
                                                    ? this.takeout_info.estimated_time
                                                    : this.takeout_info.pickup_time)
                                            }}
                                            <template v-if="takeout_info.pickup_date">
                                                <b v-if="takeout_info.pickup_date == 'today'" class="state_pickup_time">{{ $t('review.today') }}</b>
                                                <b v-else class="state_pickup_time">{{ takeout_info.pickup_date }}</b>
                                            </template>
                                        </span>
                                        <!-- <span>
                                            Estimated 
                                            {{ this.takeout_info.type }} 
                                            Time
                                        </span> -->
                                        <span v-if="takeout_info.type == 'Dine-in'">{{ $t('review.readyTime') }}</span>
                                        <span v-else-if="takeout_info.type == 'pickup'">{{ $t('review.pickupTime') }}</span>
                                        <span v-else-if="takeout_info.type == 'delivery'">{{ $t('review.deliveryTime') }}</span>
                                        <span v-else-if="takeout_info.type == 'third_party'">{{ $t('review.pickupTime') }}</span>
                                    </div>
                                    <div class="state_main">
                                        <div class="state_list" id="pay_state">
                                            <div class="state_item_first">
                                                <span class="state_step">1</span>
                                                <!-- <span>Submitted</span> -->
                                            </div>
                                            <div class="state_item">
                                                <span class="state_step">2</span>
                                                <!-- <span>Confirm</span> -->
                                            </div>
                                            <div class="state_item">
                                                <span class="state_step">3</span>
                                                <!-- <span v-if="this.takeout_info.type == 'delivery'">Out for Delivery</span>
                                                <span v-else>Ready for Pickup</span> -->
                                            </div>
                                            <div class="state_item">
                                                <span class="state_step">4</span>
                                                <!-- <span>Complete</span> -->
                                            </div>
                                        </div>
                                        <div v-if="takeout_info.takeout_tstatus >= 10" class="state_img state_img_complete"></div>
                                        <template v-else-if="takeout_info.takeout_tstatus >= 7">
                                            <div v-if="takeout_info.type == 'Dine-in'" class="state_img state_img_dinein"></div>
                                            <div v-else-if="takeout_info.type == 'pickup'" class="state_img state_img_pickup"></div>
                                            <div v-else-if="takeout_info.type == 'delivery'" class="state_img state_img_delivery"></div>
                                            <div v-else-if="takeout_info.type == 'third_party'" class="state_img state_img_pickup"></div>
                                        </template>
                                        <div v-else-if="takeout_info.takeout_tstatus >= 5" class="state_img state_img_cook"></div>
                                        <div v-else class="state_img"></div>
                                    </div>
                                    <div v-if="takeout_info.takeout_tstatus >= 10"  class="state_msg">{{ $t('review.enjoyFood') }}</div>
                                    <template v-else-if="takeout_info.takeout_tstatus >= 7">
                                        <div v-if="takeout_info.type == 'Dine-in'" class="state_msg">{{ $t('review.readyForCollection') }}</div>
                                        <div v-else-if="takeout_info.type == 'pickup'" class="state_msg">{{ $t('review.readyForPickup') }}</div>
                                        <div v-else-if="takeout_info.type == 'delivery'" class="state_msg">{{ $t('review.outForDelivery') }}</div>
                                        <div v-else-if="takeout_info.type == 'third_party'" class="state_msg">{{ $t('review.readyForPickup') }}</div>
                                    </template>
                                    <div v-else-if="takeout_info.takeout_tstatus >= 5" class="state_msg">{{ $t('review.prepareOrder', {store_name: store_data.sname}) }}</div>
                                    <div v-else class="state_msg">{{ $t('review.orderIsSent', {store_name: store_data.sname}) }}</div>
                                </template>
                            <!-- </template> -->
                        </div>
                        <div class="thanks_payment thanks_info">
                            <!-- <h5>#{{ this.takeout_info.rank }}</h5> -->
                            <!-- <h5>
                                Payment Status：
                                <span class="red" style="text-transform: capitalize">{{
                                    this.takeout_info.takeout_pay_status
                                }}</span>
                            </h5> -->
                            <!-- <div class="thanks_info_title">
                                <span v-if="theme_data.show_rank_enable" class="title_rank">#{{ this.takeout_info.rank }}</span>
                                <span v-else class="title_rank">#{{ oidLastFive }}</span>
                                <span class="title_status">
                                    {{ this.takeout_info.takeout_pay_status }}
                                </span>
                                <span class="title_type">
                                    {{ this.takeout_info.type }}
                                </span>
                            </div> -->
                            <div class="thanks_info_inner">
                                <div class="thanks_info_item">
                                    <div class="info_title">{{ $t('review.contact') }} :</div>
                                    <div class="info_text">{{ takeout_info.type == 'Dine-in' ? order_data.tel : cphone }}</div>
                                </div>
                                <div class="thanks_info_item">
                                    <div class="info_title">{{ $t('review.orderPlaced') }} :</div>
                                    <div class="info_text">{{ takeout_info.order_time }}</div>
                                </div>
                                <div class="thanks_info_item" v-if="this.takeout_info.type == 'third_party'">
                                    <div class="info_title">Channel:</div>
                                    <div class="info_text">{{ takeout_info.source }} (ID: {{ takeout_info.third_oid }})</div>
                                </div>
                                <div class="thanks_info_item">
                                    <div class="info_title">{{ $t('review.orderID') }} :</div>
                                    <div class="info_text" style="word-break: break-word;">{{ takeout_info.oid }}</div>
                                </div>
                                <div class="thanks_info_item">
                                    <div class="info_title">{{ $t('review.paymentMethod') }} :</div>
                                    <div class="info_text">
                                        <template v-if="order_data.opay_type">
                                            Pay via 
                                            <span v-if="order_data.opay_type == 'yuansfer_terminal'">Offline Credit/Debit Card</span>
                                            <span v-else-if="order_data.opay_type == 'yuansfer' || order_data.opay_type == 'stripe'">Online Credit/Debit Card</span>
                                            <span v-else style="text-transform: capitalize;">{{ this.order_data.opay_type }}</span>
                                        </template>
                                        <span v-if="takeout_info.takeout_pay_status == 'paid'" style="color: green; text-transform: uppercase;"> ({{ $t('review.paid') }}) </span>
                                        <span v-else-if="takeout_info.takeout_pay_status == 'refunded' && order_data.refund_detail.length > 0" style="color: red;">
                                            <template v-if="order_data.refund_detail[0].refundType == 'full'"> (Fully Refunded) </template>
                                            <template v-else-if="order_data.refund_detail[0].refundType == 'partial'"> (Partially Refunded) </template>
                                        </span>
                                        <span v-else style="color: red; text-transform: uppercase;"> ({{ $t('review.unpaid') }}) </span>
                                    </div>
                                </div>
                                <!-- <div class="thanks_a_line">
                                    <div class="thanks_info_item">
                                        <div class="info_title">Guest name :</div>
                                        <div class="info_text">{{ this.takeout_info.customer_name }}</div>
                                    </div>
                                    <div class="thanks_info_item">
                                        <div class="info_title">Phone number :</div>
                                        <div class="info_text">{{ this.order_data.tel }}</div>
                                    </div>
                                </div> -->
                                <div class="thanks_info_item">
                                    <div class="info_title">{{ $t('review.note') }} :</div>
                                    <div class="info_text">{{ this.takeout_info.note ? this.takeout_info.note : '( none )' }}</div>
                                </div>
                                <div class="thanks_info_item" v-if="this.takeout_info.dinein_tname">
                                    <div class="info_title">{{ $t('review.table') }} :</div>
                                    <div class="info_text">
                                        {{ this.takeout_info.dinein_tname }}
                                    </div>
                                </div>
                                <div class="thanks_info_item" v-if="this.takeout_info.type == 'delivery'">
                                    <div class="info_title">{{ $t('review.address') }} :</div>
                                    <div class="info_text" v-if="this.delivery_address.city">
                                        {{ deliveryAddress }}
                                    </div>
                                </div>
                                <!-- 用户端不显示Remark和相关信息 -->
                                <!-- <div class="thanks_info_item">
                                    <div class="info_title">Remark :</div>
                                    <div class="info_text" v-if="this.takeout_info.remark">
                                        {{ this.takeout_info.remark }}
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="thanks_summary" v-if="order_data.ofoods_detail">
                        <h5>{{ $t('review.orderTotal') }}: ${{ total }}</h5>
                        <div class="summary_box red">
                            <span>{{ $t('review.orderSummary') }}</span>
                            <span @click="toggleOrderDetails" class="summary_arrow"></span>
                        </div>
                        <div class="item_details">
                            <div class="details_list">
                                <div class="details_goods_list">
                                    <!-- <div class="details_item details_goods">
                                        <div class="details_row">
                                            <span class="fw_bold">{{food.fname}}</span>
                                            <span>x{{food.count}}</span>
                                            <span>${{food.fprice}}</span>
                                        </div>
                                    </div> -->
                                    <div class="list_title">
                                        {{ $t('review.itemsInOrder') }} ({{order_data.ofoods_detail.length}})
                                    </div>
                                    <div class="goods_list_inner">
                                        <div
                                            class="details_item details_goods"
                                            v-for="(food, index) in order_data.ofoods_detail"
                                            :key="index"
                                        >
                                            <div class="details_goods_fpic">
                                                <img v-if="food.fpic !== ''" :src="food.fpic" />
                                                <!-- <img v-else-if="store_data.slogo" :src="store_data.slogo" /> -->
                                                <img v-else src="../assets/images/placeholder_square.png" />
                                            </div>
                                            <div class="details_goods_right">
                                                <div class="details_name">{{ food.fname }}</div>
                                                <div class="details_row">
                                                    <!-- <span>
                                                        <span style="font-weight: bold">{{ food.fname }}</span>
                                                        <br>
                                                        <span v-if="food.ofeature_name" style="padding-left: 10px">({{ food.ofeature_name }})</span>
                                                    </span> -->
                                                    <!-- <span>${{ getFoodSumPrice(food, index) }}</span> -->
                                                    <div v-if="!pickup_discount_enable || takeout_info.type !== 'pickup'">
                                                        <div
                                                            class="weui-media-box__info__meta"
                                                            v-if="!is_vip || !food.fprice_vip"
                                                            style="float: right; color: black; padding-right: 0"
                                                        >
                                                            <template v-if="food.fprice_original">
                                                                <span v-if="food.addon_total_price !== undefined && food.addon_total_price">
                                                                    <span style="margin-right: 5px; text-decoration: line-through;">${{ ((food.fprice_original * food.count) + (food.addon_total_price * food.count)).toFixed(2) }}</span>
                                                                    <span style="color: var(--theme_color);">${{ parseFloat(((food.fprice * food.count) + (food.addon_total_price * food.count)).toFixed(2)) }}</span>
                                                                </span>
                                                                <template v-else>
                                                                    <span style="margin-right: 5px; text-decoration: line-through;">${{ parseFloat((food.fprice_original * food.count).toFixed(2)) }}</span>
                                                                    <span style="color: var(--theme_color);">${{ parseFloat((food.fprice * food.count).toFixed(2)) }}</span>
                                                                </template>
                                                            </template>
                                                            <template v-else>
                                                                <span v-if="food.addon_total_price !== undefined && food.addon_total_price">
                                                                    ${{ parseFloat(((food.fprice * food.count) + (food.addon_total_price * food.count)).toFixed(2)) }}
                                                                </span>
                                                                <span v-else>
                                                                    ${{ parseFloat((food.fprice * food.count).toFixed(2)) }}
                                                                </span>
                                                            </template>
                                                        </div>
                                                        <div
                                                            class="weui-media-box__info__meta"
                                                            v-if="is_vip && food.fprice_vip"
                                                            style="float: right; color: black; padding-right: 0"
                                                        >
                                                            <span v-if="food.addon_total_price !== undefined && food.addon_total_price">${{ ((food.fprice_vip * food.count) + (food.addon_total_price * food.count)).toFixed(2) }}</span>
                                                            <span v-else>${{ (food.fprice_vip * food.count).toFixed(2) }}</span>
                                                        </div>
                                                        <div
                                                            class="weui-media-box__info__meta"
                                                            v-if="is_vip && food.fprice_vip"
                                                            style="float: right; padding-right: 10px; text-decoration: line-through"
                                                        >
                                                            <span v-if="food.addon_total_price !== undefined && food.addon_total_price">${{ ((food.fprice * food.count) + (food.addon_total_price * food.count)).toFixed(2) }}</span>
                                                            <span v-else>${{ (food.fprice * food.count).toFixed(2) }}</span>
                                                        </div>
                                                    </div>
                                                    <div v-if="pickup_discount_enable && takeout_info.type === 'pickup'">
                                                        <div
                                                            class="weui-media-box__info__meta"
                                                            v-if="food.fprice_pickup"
                                                            style="float: right; color: var(--theme_color); padding-right: 0"
                                                        >
                                                            <template v-if="food.addon_total_price !== undefined && food.addon_total_price">
                                                                <span
                                                                    style="margin-right: 5px; text-decoration: line-through; color: #000;"
                                                                    v-if="food.fprice_original"
                                                                >
                                                                    ${{ parseFloat(((food.fprice_original * food.count) + (food.addon_total_price * food.count)).toFixed(2)) }}
                                                                </span>
                                                                <span
                                                                    style="margin-right: 5px; text-decoration: line-through; color: #000;"
                                                                    v-else-if="food.fprice"
                                                                >
                                                                    ${{ parseFloat(((food.fprice * food.count) + (food.addon_total_price * food.count)).toFixed(2)) }}
                                                                </span>
                                                                <span>${{ parseFloat(((food.fprice_pickup * food.count) + (food.addon_total_price_pickup * food.count)).toFixed(2)) }}</span>
                                                            </template>
                                                            <template v-else>
                                                                <span
                                                                    style="margin-right: 5px; text-decoration: line-through; color: #000;"
                                                                    v-if="food.fprice_original"
                                                                >
                                                                    ${{ parseFloat((food.fprice_original * food.count).toFixed(2)) }}
                                                                </span>
                                                                <span
                                                                    style="margin-right: 5px; text-decoration: line-through; color: #000;"
                                                                    v-else-if="food.fprice"
                                                                >
                                                                    ${{ parseFloat((food.fprice * food.count).toFixed(2)) }}
                                                                </span>
                                                                <span>${{ parseFloat((food.fprice_pickup * food.count).toFixed(2)) }}</span>
                                                            </template>
                                                        </div>
                                                        <div
                                                            class="weui-media-box__info__meta"
                                                            v-if="!food.fprice_pickup"
                                                            style="float: right; color: #000; padding-right: 0"
                                                        >
                                                            <template v-if="food.fprice_original">
                                                                <span v-if="food.addon_total_price !== undefined && food.addon_total_price">
                                                                    <span style="margin-right: 5px; text-decoration: line-through;">${{ parseFloat(((food.fprice_original * food.count) + (food.addon_total_price * food.count)).toFixed(2)) }}</span>
                                                                    <span style="color: var(--theme_color);">${{ parseFloat(((food.fprice * food.count) + (food.addon_total_price_pickup * food.count)).toFixed(2)) }}</span>
                                                                </span>
                                                                <template v-else>
                                                                    <span style="margin-right: 5px; text-decoration: line-through;">${{ parseFloat((food.fprice_original * food.count).toFixed(2)) }}</span>
                                                                    <span style="color: var(--theme_color);">${{ parseFloat((food.fprice * food.count).toFixed(2)) }}</span>
                                                                </template>
                                                            </template>
                                                            <template v-else>
                                                                <span v-if="food.addon_total_price !== undefined && food.addon_total_price">
                                                                    <template v-if="food.addon_total_price != food.addon_total_price_pickup">
                                                                        <span style="margin-right: 5px; text-decoration: line-through; color: #000;">${{ parseFloat(((food.fprice * food.count) + (food.addon_total_price * food.count)).toFixed(2)) }}</span>
                                                                        <span style="color: var(--theme_color);">${{ parseFloat(((food.fprice * food.count) + (food.addon_total_price_pickup * food.count)).toFixed(2)) }}</span>
                                                                    </template>
                                                                    <template v-else>
                                                                        ${{ parseFloat(((food.fprice * food.count) + (food.addon_total_price_pickup * food.count)).toFixed(2)) }}
                                                                    </template>
                                                                </span>
                                                                <span v-else>
                                                                    ${{ parseFloat((food.fprice * food.count).toFixed(2)) }}
                                                                </span>
                                                            </template>
                                                        </div>
                                                    </div>
                                                    <span class="details_count">{{ food.count }}</span>
                                                </div>
                                                <div class="details_option" v-if="food.ofeature_name">({{ food.ofeature_name }})</div>
                                                <div class="details_note" v-if="food.note">
                                                    <span>Note: {{ food.note }}</span>
                                                </div>
                                                <div class="details_info_box" v-if="food.addon_counts">
                                                    <div
                                                        class="details_info"
                                                        v-for="(addonCount, index) in food.addon_counts.split('|')"
                                                        :key="index"
                                                    >
                                                        <!-- <span>{{ getAddonCount(food.count, addonCount) }} *</span> -->
                                                        <span>{{ addonCount }} *</span>
                                                        <span class="details_addon_name">{{ food.addon_names.split('|')[index] }}</span>
                                                        <template v-if="pickup_discount_enable && takeout_info.type === 'pickup'">
                                                            <span v-if="food.addon_pickup_discounts && food.addon_pickup_discounts.split('|')[index] < 1">
                                                                (
                                                                    <span style="margin-right: 5px; text-decoration: line-through;">
                                                                        ${{
                                                                            getAddonSumPrice(
                                                                                food.count,
                                                                                food.addon_counts,
                                                                                food.addon_prices,
                                                                                index
                                                                            )
                                                                        }}
                                                                    </span>
                                                                    <span style="color: var(--theme_color);">
                                                                        ${{
                                                                            getAddonPickupPrice(
                                                                                food.count,
                                                                                food.addon_counts,
                                                                                food.addon_prices,
                                                                                food.addon_pickup_discounts,
                                                                                index
                                                                            )
                                                                        }}
                                                                    </span>
                                                                )
                                                            </span>
                                                            <span v-else>
                                                                (${{
                                                                    getAddonPickupPrice(
                                                                        food.count,
                                                                        food.addon_counts,
                                                                        food.addon_prices,
                                                                        food.addon_pickup_discounts,
                                                                        index
                                                                    )
                                                                }})
                                                            </span>
                                                        </template>
                                                        
                                                        <span v-else>
                                                            (${{
                                                                getAddonSumPrice(
                                                                    food.count,
                                                                    food.addon_counts,
                                                                    food.addon_prices,
                                                                    index
                                                                )
                                                            }})
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="details_info" v-if="food.addon_names">
                                                <div class="details_info_column">
                                                    <div
                                                        v-for="(addonCount, index) in food.addon_counts.split('|')"
                                                        :key="index"
                                                    >
                                                        {{ getAddonCount(food.count, addonCount) }} x
                                                    </div>
                                                </div>
                                                <div class="details_info_column">
                                                    <div
                                                        v-for="(addonName, index) in food.addon_names.split('|')"
                                                        :key="index"
                                                    >
                                                        {{ addonName }}
                                                    </div>
                                                </div>
                                                <div class="details_info_column">
                                                    <div
                                                        v-for="(addonPrice, index) in food.addon_prices.split('|')"
                                                        :key="index"
                                                    >
                                                        ${{
                                                            getAddonSumPrice(
                                                                food.count,
                                                                food.addon_counts,
                                                                food.addon_prices,
                                                                index
                                                            )
                                                        }}
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="price_list">
                                    <div class="list_title">
                                        {{ $t('review.details') }}
                                    </div>
                                    <div class="goods_list_inner">
                                        <div class="price_list_top">
                                            <div class="details_item">
                                                <div class="details_row">
                                                    <span>{{ $t('common.subtotal') }}</span>
                                                    <span>${{ subtotal }}</span>
                                                </div>
                                            </div>
                                            <div class="details_item" v-if="specialOffer > 0">
                                                <div class="details_row fc_theme_color">
                                                    <span>{{ $t('cart.specialOffer') }}</span>
                                                    <span>-${{ specialOffer }}</span>
                                                </div>
                                            </div>
                                            <div class="details_item" v-if="pickupDiscount > 0">
                                                <div class="details_row fc_theme_color">
                                                    <span>{{ $t('cart.pickupDiscountNew') }}</span>
                                                    <span>-${{ pickupDiscount }}</span>
                                                </div>
                                            </div>
                                            <div class="details_item" v-if="itemDiscount > 0">
                                                <div class="details_row fc_theme_color">
                                                    <span>{{ $t('review.itemDiscount') }}</span>
                                                    <span>-${{ itemDiscount }}</span>
                                                </div>
                                            </div>
                                            <div class="details_item" v-if="orderDiscount > 0">
                                                <div class="details_row fc_theme_color">
                                                    <span>{{ $t('review.orderDiscount') }}</span>
                                                    <span>-${{ orderDiscount }}</span>
                                                </div>
                                            </div>
                                            <div class="details_item" v-if="newDiscount !== 0">
                                                <div class="details_row fc_theme_color">
                                                    <span>{{ this.new_discount_price }}</span>
                                                    <span>-${{ newDiscountShow * -1 }}</span>
                                                </div>
                                            </div>
                                            <div class="details_item" v-if="discount !== 0">
                                                <div class="details_row fc_theme_color">
                                                    <span>
                                                        <!-- {{ fullCouponObj.type === 'fixed' ? '$' + fullCouponObj.discount : fullCouponObj.discount + '%' }} Off Orders Over ${{ fullCouponObj.full }} -->
                                                        {{
                                                            $t('cart.fullDiscountText', {
                                                                total_price: fullCouponObj.full,
                                                                discount_price: fullCouponObj.type === 'fixed' ? '$' + fullCouponObj.discount : fullCouponObj.discount + '%'
                                                            })
                                                        }}
                                                    </span>
                                                    <span>-${{ discountShow * -1 }}</span>
                                                </div>
                                            </div>
                                            <div class="details_item">
                                                <div class="details_row" v-if="taxPrice > 0">
                                                    <span>{{ $t('common.taxAndFee') }}</span>
                                                    <span>${{ taxPrice }}</span>
                                                </div>
                                            </div>
                                            <div class="details_item" v-if="deliveryFee > 0">
                                                <div class="details_row">
                                                    <span>{{ $t('common.deliveryFee') }}</span>
                                                    <span>${{ deliveryFee }}</span>
                                                </div>
                                            </div>
                                            <div class="details_item" v-if="processingFee > 0">
                                                <div class="details_row">
                                                    <span>{{ $t('common.processingFee') }}</span>
                                                    <span>${{ processingFee }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="details_item details_tip" v-if="tip > 0">
                                            <div class="details_row">
                                                <span>{{ $t('cart.tip') }}</span>
                                                <span>${{ tip }}</span>
                                            </div>
                                        </div>
                                        <!-- <div class="details_item" v-if="originalPrice > 0">
                                            <div class="details_row fc_red">
                                                <span>Original price</span>
                                                <span>${{ originalPrice }}</span>
                                            </div>
                                        </div> -->
                                        <div class="details_item details_total">
                                            <div class="details_row">
                                                <span class="fw_bold">{{ $t('common.placeTotal') }}</span>
                                                <span>${{ total }}</span>
                                            </div>
                                        </div>
                                        <div class="details_item details_refund" v-if="order_data.refund_detail && order_data.refund_detail.length > 0">
                                            <div class="refund_item"
                                                v-for="(refund_item, index) in order_data.refund_detail"
                                                :key="index"
                                            >
                                                <div class="refund_date">{{ refund_item.refundType == 'full' ? $t('review.fullyRefundedDate', { date: refund_item.create_time }) : $t('review.partiallyRefundedDate', { date: refund_item.create_time }) }}</div>
                                                <div class="details_row">
                                                    <span class="fc_red">{{ $t('review.refund') }}</span>
                                                    <span class="fc_red">-${{ refund_item.amount }}</span>
                                                </div>
                                            </div>
                                            <div class="details_row">
                                                <span class="fw_bold">{{ $t('review.netPayment') }}</span>
                                                <span>${{ refund }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="thanks_info">
                        <h5>{{ $t('review.restaurantContact') }}</h5>
                        <div class="thanks_info_inner">
                            <div class="thanks_info_item" v-if="saddress">
                                <div class="info_title">{{ $t('review.address') }}:</div>
                                <div class="info_text">{{ saddress }}</div>
                                <div id="address_copy" class="info_copy" @click="copyAddress('address_copy')" data-clipboard-action="copy" :data-clipboard-text="saddress"></div>
                            </div>
                            <div class="thanks_info_item" v-if="sphone">
                                <div class="info_title">{{ $t('review.phone') }}:</div>
                                <div class="info_text">{{ sphone }}</div>
                            </div>
                            <div class="thanks_info_item" v-if="store_data.semail">
                                <div class="info_title">{{ $t('review.email') }}:</div>
                                <div class="info_text">{{ store_data.semail }}</div>
                            </div>
                            <div id="map_box" class="thanks_map" v-if="map_src">
                                <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.9916256937586!2d2.2922926156743895!3d48.858370079287475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e2964e34e2d%3A0x8ddca9ee380ef7e0!2sEiffel%20Tower!5e0!3m2!1sen!2sjp!4v1642055662866!5m2!1sen!2sjp" width="800" height="600" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
                                <iframe width="600" height="450" style="border:0" loading="lazy" allowfullscreen :src=map_src></iframe>
                            </div>
                        </div>
                        <!-- <div class="thanks_map" v-if="saddress" style="display: none">
                            <img src="../assets/images/map.png" />
                        </div> -->
                    </div>
                </div>
                <div class="thanks_right">
                    <!-- 注册pass 这个卡片整体可以删除 -->
                    <!-- <div class="congrats" v-if="isVip">
                        <div class="congrats_inner">
                            <h4>Congrats!</h4>
                            <p>Your earned {{ this.points }} points.</p>
                            <div class="congrats_btn">
                                <mt-button type="danger" @click="activate" data-bee="module_id=button&item_id=check_your_point">Check your point</mt-button>
                            </div>
                        </div>
                    </div>
                    <div class="congrats" v-else>
                        <div class="congrats_inner">
                            <h4>Your registration is almost done!</h4>
                            <p>Collect your point, and get more discounts!</p>
                            <div class="congrats_btn">
                                <mt-button type="danger" @click="activate">Activate Pass Member</mt-button>
                            </div>
                            <div class="congrats_link">
                                <a href="javascript:;" @click="activate">Already have account?</a>
                            </div>
                        </div>
                    </div> -->
                    <div class="rate">
                        <div v-if="showRateInfo" class="rate_hidden"></div>
                        <div class="rate_inner">
                            <div class="rate_title">{{ $t('review.rateExperience') }}</div>
                            <div class="rate_star">
                                <div class="star_item"
                                    v-for="(starItem, index) in starAry"
                                    :key="index"
                                >
                                    <div class="star_radio">
                                        <input
                                            :id="'rate_star' + starItem.value"
                                            name="rate_star"
                                            type="radio"
                                            :value="starItem.value"
                                            @click="changeStar(starItem.value)"
                                        />
                                        <span :class="starItem.show ? 'radio_icon radio_curr' : 'radio_icon'"></span>
                                    </div>
                                    <label :for="'rate_star' + starItem.value">{{ starItem.value }}</label>
                                </div>
                            </div>
                            <div class="rate_input">
                                <textarea
                                    :placeholder="$t('review.shareOrder')"
                                    rows="6"
                                    v-model="experience"
                                    cols="40"
                                ></textarea>
                            </div>
                            <div class="rate_btn submitted_btn" v-if="showRateInfo">
                                <mt-button type="danger" plain @click="showRateLayer">{{ $t('review.submitted') }}</mt-button>
                            </div>
                            <div class="rate_btn" v-else>
                                <mt-button type="danger" plain @click="showRateLayer">{{ $t('review.submit') }}</mt-button>
                            </div>
                        </div>
                    </div>
                    <!-- 旧的 survey+ 卡片不要了 -->
                    <!-- <div class="congrats">
                        <div class="congrats_inner">
                            <h4>Join our survey+</h4>
                            <p>Collect your point, and get more discounts!</p>
                            <div class="congrats_btn">
                                <mt-button type="danger" @click="appraiseSurvey" data-bee="module_id=button&item_id=start">Start</mt-button>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="" style="height: 20px"></div>
        </div>
        <PageFooter></PageFooter>
        <RateLayer
            ref="rateLayer"
            :rateSumbit="rateSumbit"
            :rateData="rate_data"
        />
        <SuccessLayer
            ref="successLayer"
            :payBoxHide="payBoxHide"
        />
        <FullScreenLoading :is_loading="is_loading" />
    </div>
</template>

<style>
    .fc_green {
        color: green;
    }
    .fc_red {
        color: red;
    }
    .fc_theme_color {
        color: var(--theme_color)!important;
    }
    .check_header {
        width: 100%;
        /* height: 9.0625rem;
        line-height: 9.0625rem; */
        /* padding-top: 3.625rem; */
        height: 120px;
        box-sizing: border-box;
        background-image: url('../assets/images/header_bg2.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        position: relative;
    }
    .check_header h3 {
        font-size: 1rem;
        text-transform: uppercase;
        text-align: center;
        color: #3b3b3b;
        position: relative;
        z-index: 2;
    }
    .check_header .header_main {
        width: 1160px;
        height: 46px;
        padding-top: 40px;
        margin: 0 auto;
    }
    .check_header .header_main button {
        float: right;
        padding: 0 20px;
    }

    .thanks_section {
        width: 1160px;
        padding-top: 40px;
        margin: 0 auto;
    }
    .thanks_inner {
        display: flex;
        justify-content: space-between;
    }
    .thanks_left {
        width: 710px;
    }
    .thanks_inner h5 {
        font-size: 23px;
        color: #3b3b3b;
        font-weight: bold;
    }
    .thanks_inner .red {
        color: var(--theme_color);
    }
    .thanks_card {
        border: 1px solid var(--theme_color);
        border-radius: 15px;
        overflow: hidden;
    }
    .thanks_type {
        display: flex;
        justify-content: space-between;
        line-height: 27px;
        font-size: 18px;
        margin-bottom: 20px;
    }
    .thanks_type_item {
        padding-left: 40px;
        color: var(--theme_color);
        position: relative;
    }
    .thanks_type_item span {
        display: block;
        width: 36px;
        height: 19px;
        position: absolute;
        left: 7px;
        top: 4px;
        mask-repeat: no-repeat;
        mask-size: auto 100%;
        background-color: var(--theme_color);
    }
    .thanks_type_item.delivery_type {
        padding-left: 48px;
    }
    .thanks_type_item.pickup_type span {
        mask-image: url('../assets/images/pickup_icon.svg');
    }
    .thanks_type_item.dinein_type span {
        mask-image: url('../assets/images/dinein_icon.svg');
    }
    .thanks_type_item.delivery_type span {
        mask-image: url('../assets/images/delivery_icon.svg');
    }
    .thanks_list_number {
        color: #000;
        font-size: 28px;
    }
    .thanks_state {
        /* margin-bottom: 30px; */
        padding: 30px 40px;
    }
    .state_icon {
        width: 3rem;
        height: 3rem;
        margin: 0 auto;
        mask: url('../assets/images/submitted.svg') no-repeat;
        background-size: contain;
        mask-size: 100%;
        background-repeat: no-repeat;
        background-color: var(--theme_color);
    }
    .state_text {
        color: var(--theme_color);
        font-size: 36px;
        margin-top: 15px;
        line-height: 46px;
        /* text-align: center;
        margin: 30px 0 40px; */
    }
    .state_main {
        width: 500px;
        position: relative;
        height: 40px;
    }
    .state_img {
        width: 106px;
        height: 80px;
        position: absolute;
        top: -60px;
        right: -140px;
        mask: url('../assets/images/submitted.svg') no-repeat;
        background-size: contain;
        mask-size: auto 80px;
        mask-position: top right;
        background-color: var(--theme_color);
        background-repeat: no-repeat;
    }
    .state_img.state_img_cook {
        mask-image: url('../assets/images/state_cooking.svg');
    }
    .state_img.state_img_dinein {
        mask-image: url('../assets/images/state_dinein.svg');
    }
    .state_img.state_img_pickup {
        mask-image: url('../assets/images/state_pickup.svg');
    }
    .state_img.state_img_delivery {
        mask-image: url('../assets/images/state_delivery.svg');
    }
    .state_img.state_img_complete {
        mask-image: url('../assets/images/state_complete.svg');
    }
    .state_img.state_img_canceled {
        mask-image: url('../assets/images/state_canceled.svg');
    }
    .state_list {
        width: 100%;
        /* height: 22px;
        background-color: #efefef;
        border-radius: 2px;
        margin-bottom: 5px; */
        height: 2px;
        background: url('../assets/images/dash_line.svg') repeat-x;
        /* border-bottom: 1px dashed #979797; */
        display: flex;
    }
    .canceled_main .state_list {
        background: #e5e5ea;
    }
    .canceled_main .state_step {
        color: #e5e5ea;
        background-color: #e5e5ea;
    }
    .state_item_first {
        position: absolute;
        width: 1px;
        height: 1px;
        display: flex;
        align-items: center;
        font-size: 12px;
        /* color: #b4b4b4; */
        justify-content: flex-end;
    }
    .state_item {
        flex: 1;
        /* padding-left: 20px; */
        display: flex;
        align-items: center;
        font-size: 12px;
        /* color: #b4b4b4; */
        justify-content: flex-end;
    }
    .state_step {
        width: 20px;
        height: 20px;
        min-width: 20px;
        background-color: #b4b4b4;
        border-radius: 50%;
        color: #b4b4b4;
        line-height: 20px;
        text-align: center;
        /* margin-right: 6px; */
    }
    .state_item.current {
        color: #fff;
        /* mask: url('../assets/images/part_bg.svg') no-repeat;
        mask-size: auto 100%;
        mask-position: center right; */
        background-color: var(--theme_color);
        /* background-image: url('../assets/images/part_bg.svg');
        background-repeat: no-repeat;
        background-position: center right;
        background-size: auto 100%; */
    }
    .state_item.done {
        background-color: var(--theme_color);
        color: #fff;
    }
    .state_item_first.current {
        width: 40px;
        left: 0;
    }
    .state_item_first.current .state_step, .state_item.current .state_step {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 20px;
        color: #fff;
        background-color: var(--theme_color);
    }
    .state_item_first.done .state_step,
    .state_item.done .state_step {
        background-color: var(--theme_color);
        color: var(--theme_color);
    }
    .state_explain {
        /* display: flex; */
        color: #757575;
        font-size: 16px;
        margin-bottom: 30px;
    }
    .state_explain_time {
        color: var(--theme_color);
        font-size: 28px;
        margin-right: 30px;
    }
    .state_pickup_time {
        font-size: 16px;
        font-weight: normal;
        margin-left: 5px;
    }
    /* .state_explain span {
        flex: 1;
        padding-left: 40px;
    } */
    .state_msg {
        color: var(--theme_color);
        font-size: 22px;
        margin-top: 20px;
    }
    .thanks_pot {
        /* padding: 0.9375rem 0; */
        position: relative;
        padding-right: 135px;
        margin-bottom: 45px;
        /* display: flex;
        justify-content: space-between;
        align-items: self-start;
        height: 68px; */
    }
    .pot_info {
        display: flex;
    }
    .pot_logo {
        width: 34px;
        min-width: 34px;
        height: 34px;
        max-height: 34px;
        border-radius: 17px;
        /* background-color: var(--theme_color); */
        background-image: url('../assets/images/logo.png');
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: center;
        margin-right: 0.5rem;
    }
    .pot_name {
        font-size: 26px;
        color: #3b3b3b;
        line-height: 34px;
    }
    .pot_btn {
        position: absolute;
        top: 27px;
        right: 0;
    }
    .pot_btn button {
        font-size: 14px;
        /* padding-left: 40px;
        background-image: url('../assets/images/phone_icon.png');
        background-repeat: no-repeat;
        background-size: auto 60%;
        background-position: 10px center; */
    }

    .thanks_payment .thanks_info_inner {
        background-color: #f2f2f7;
        padding: 15px 40px 30px;
    }

    .thanks_info,
    .thanks_summary {
        /* padding: 0.5rem 0;
        border-top: 2px solid var(--theme_color); */
        margin-top: 40px;
    }
    .thanks_payment.thanks_info {
        margin-top: 0;
    }
    /* .thanks_info_inner {
        display: flex;
        flex-wrap: wrap;
    } */
    .thanks_info_item {
        font-size: 14px;
        color: #3b3b3b;
        line-height: 20px;
        margin-top: 15px;
        display: flex;
    }
    .thanks_info_item .info_title {
        font-weight: bold;
        margin-right: 10px;
        /* min-width: 62px; */
    }
    .thanks_info_item .info_text {
        word-break: break-word;
    }
    .thanks_info_item .info_copy {
        min-width: 20px;
        height: 20px;
        width: 20px;
        background-image: url('../assets/images/copy.png');
        background-repeat: no-repeat;
        background-size: auto 100%;
        margin-left: 10px;
        cursor: pointer;
    }
    .thanks_map {
        margin-top: 0.75rem;
    }
    .thanks_map iframe {
        width: 100%;
    }
    .summary_box {
        font-size: 19px;
        margin: 10px 0 20px;
        height: 26px;
        line-height: 26px;
        display: flex;
    }
    .summary_arrow {
        display: inline-block;
        width: 18px;
        height: 26px;
        mask: url('../assets/images/arrow_top.svg') no-repeat;
        background-size: contain;
        background-color: var(--theme_color);
        background-repeat: no-repeat;
        mask-size: 18px auto;
        mask-position: left center;
        margin-left: 5px;
        cursor: pointer;
    }
    .summary_arrow.down_arrow {
        mask-image: url('../assets/images/arrow_bottom.svg');
    }
    .item_details {
        background-color: #fff;
        border: 1px solid #d1d1d6;
        border-radius: 15px;
        overflow: hidden;
        /* padding: 0.5rem 0.9375rem; */
    }
    .details_list {
        display: flex;
    }
    .details_goods_list, .price_list {
        flex: 1;
        width: 355px;
        box-sizing: border-box;
    }
    .price_list {
        border-left: 1px solid #e5e5ea;
    }
    .price_list .details_row {
        padding: 5px 0;
    }
    .item_details .list_title {
        background-color: #f2f2f7;
        font-size: 18px;
        line-height: 48px;
        height: 48px;
        padding: 0 16px ;
        color: #000;
    }
    .goods_list_inner {
        padding: 0 16px;
    }
    .price_list_top {
        margin-top: 15px;
        margin-bottom: 10px;
    }
    .details_name {
        font-weight: 500;
        font-size: 16px;
        color: #000;
        line-height: 24px;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        max-height: 48px;
        overflow: hidden;
        word-break: break-all;
    }
    .details_option {
        font-size: 14px;
        line-height: 25px;
        color: #626262;
    }
    .details_row {
        display: flex;
        align-items: flex-start;
        line-height: 24px;
        justify-content: space-between;
        font-size: 16px;
        color: #000;
        padding: 10px 0;
    }
    .details_count {
        display: block;
        height: 23px;
        border-radius: 12px;
        min-width: 23px;
        color: #fff;
        background-color: #AEAEB2;
        text-align: center;
    }
    .details_goods {
        border-bottom: 1px solid #eaeaea;
        overflow: hidden;
        padding: 20px 0;
    }
    .details_item.details_tip, .details_item.details_total, .details_item.details_refund {
        border-top: 1px solid #d1d1d6;
        padding: 10px 0;
    }
    .details_refund .refund_date {
        font-size: 14px;
        color: #757575;
    }

    .details_item.details_goods:last-child {
        /* margin-bottom: 20px; */
        /* padding-bottom: 10px; */
        border-bottom: 0;
    }
    .details_goods_fpic {
        float: left;
        width: 75px;
        /* padding: 10px 0; */
    }
    .details_goods_fpic img {
        display: block;
        width: 100%;
        border-radius: 5px;
    }
    .details_goods_right {
        padding-left: 85px;
    }
    .details_goods .details_row {
        /* line-height: 40px; */
        line-height: 23px;
        padding: 10px 0;
    }
    .details_info {
        font-size: 14px;
        line-height: 25px;
        color: #626262;
        display: flex;
        justify-content: normal;
        flex-wrap: wrap;
    }
    .details_info .details_addon_name {
        margin: 0 10px;
        max-width: 160px;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        max-height: 25px;
        overflow: hidden;
        word-break: break-all;
    }
    /* .details_info_column {
        display: flex;
        flex-direction: column;
        line-height: 18px;
    }
    .details_info_column:first-child,
    .details_row span:first-child {
        width: 300px;
    }
    .details_info_column:first-child,
    .details_row span:nth-child(2), .details_info_box .details_info span:first-child {
        width: 30px;
    }
    .details_info_column:last-child,
    .details_row span:last-child, .details_info_box .details_info span:last-child {
        width: 80px;
    }
    .details_info_box .details_info span:nth-child(2) {
        padding: 0 10px;
        box-sizing: border-box;
        width: 360px;
    } */
    .details_note {
        font-size: 13px;
        margin-bottom: 10px;
    }
    .fw_bold {
        font-weight: bold;
    }

    .thanks_right {
        width: 400px;
    }
    .rate {
        border-radius: 15px;
        padding: 35px;
        background-color: #fff;
        border: 1px solid #c7c7c7;
        margin-bottom: 1.25rem;
        position: relative;
    }
    .rate_hidden {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
    }
    .rate_title {
        color: var(--theme_color);
        font-size: 24px;
        text-align: center;
    }
    .rate_star {
        margin: 30px 0;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
    }
    /* .star_item {
        width: 40px;
        height: 40px;
    } */
    .rate_star label {
        display: none;
    }
    .star_radio {
        position: relative;
    }
    .star_radio input {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        z-index: 1;
        cursor: pointer;
    }
    .star_radio .radio_icon {
        display: block;
        width: 40px;
        height: 40px;
        mask: url('../assets/images/star.svg') no-repeat;
        background-size: contain;
        background-color: #d8d8d8;
        background-repeat: no-repeat;
        mask-size: 100% auto;
        mask-position: left center;
        cursor: pointer;
    }
    .star_radio .radio_icon.radio_curr {
        background-color: #fc0;
    }

    .rate_input textarea {
        width: 100%;
        border: none;
        border-radius: 8px;
        background-color: rgba(116,116,128, 0.08);
        font-size: 18px;
        padding: 18px 24px;
        box-sizing: border-box;
    }
    .rate_btn {
        text-align: center;
        margin-top: 38px;
    }
    .rate_btn button {
        width: 120px;
    }
    .rate_btn.submitted_btn  button {
        border-color: transparent !important;
        color: #c7c7c7 !important;
    }

    .congrats {
        padding: 1.5625rem 1.875rem;
        background-color: #fdfdfd;
        border: 1px solid #f2f2f2;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.08);
        margin-bottom: 1.25rem;
    }
    .congrats_inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .congrats_inner h4 {
        font-size: 33px;
        color: var(--theme_color);
        line-height: 1.75rem;
    }
    .congrats_inner p {
        font-size: 17px;
        color: #475252;
        line-height: 1.0625rem;
    }
    .congrats_btn,
    .congrats_link {
        margin-top: 16px;
    }
    .congrats_btn button {
        width: 274px;
    }
    .congrats_link a {
        color: var(--theme_color);
        display: inline-block;
        font-size: 17px;
        line-height: 1.5rem;
        border-bottom: 2px solid var(--theme_color);
    }

    .thanks_a_line, .thanks_info_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .thanks_info_title .title_rank {
        font-size: 25px;
        font-weight: bold;
    }
    .thanks_info_title .title_status{
        text-transform: capitalize;
        font-size: 23px;
        color: var(--theme_color);
    }
    .thanks_info_title .title_type{
        text-transform: capitalize;
    }

    .payment_box {
        margin-top: -20px;
        margin-bottom: 20px;
        position: relative;
    }
    .payment_box .payment_title {
        width: 100%;
        max-width: 708px;
        color: #E90112;
        border: 1px solid #E90112;
        border-radius: 5px;
        font-size: 20px;
        line-height: 30px;
        padding: 10px 10px 10px 48px;
        background-repeat: no-repeat;
        background-size: auto 30px;
        background-position: 10px 10px;
        background-image: url('../assets/images/warning.svg');
        box-sizing: border-box;
    }
    .payment_box .payment_time {
        position: absolute;
        top: 0;
        right: 0;
        color: #E90112;
        padding-top: 20px;
    }
    .payment_box .payment_pay {
        margin-top: 20px;
        box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        padding: 10px;
        overflow: hidden;
    }
    .payment_box .pay_left {
        width: 708px;
        float: left;
    }
    .payment_box .pay_left_title {
        padding: 10px;
        color: #000;
    }
    .payment_box .pay_right {
        width: 400px;
        float: right;
        border-left: 1px solid #d1d1d6;
    }
    .payment_box .price_list_top {
        margin: 5px 0;
    }
    .payment_box .details_row {
        padding: 2px 8px;
    }
    .payment_box .details_item.details_tip, .payment_box .details_item.details_total, .payment_box .details_item.details_refund {
        padding: 5px 0px;
    }
    .payment_box .pay_right_btn {
        padding: 10px;
    }
    .payment_box .pay_right_btn button {
        width: 100%;
        height: 48px;
    }

    .method_main {
        position: relative;
        z-index: 101;
    }
    .method_select {
        width: 600px;
        height: 48px;
        line-height: 46px;
        border: 1px solid var(--theme_color);
        border-radius: 7px;
        color: #000;
        padding: 0 15px;
        font-size: 18px;
        box-sizing: border-box;
        background-color: #fff;
        position: relative;
    }
    .method_select span.select_arrow {
        display: block;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 9px;
        right: 10px;
        mask: url('../assets/images/arrow_bottom.svg') no-repeat;
        background-size: contain;
        background-color: var(--theme_color);
        background-repeat: no-repeat;
        mask-size: 100% auto;
        mask-position: right center;
        cursor: pointer;
    }

    .method_select.isOpen span.select_arrow {
        mask-image: url('../assets/images/arrow_top.svg');
    }
    .method_select_item {
        padding-left: 40px;
    }
    .method_select_item span {
        position: absolute;
        width: 24px;
        height: 46px;
        left: 15px;
        top: 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: left center;
    }
    .method_select_item.pay_default {
        padding-left: 0;
    }
    .method_option {
        position: absolute;
        width: 600px;
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
        z-index: 5;
        padding: 10px 0;
    }
    .method_option_item {
        padding: 10px 15px 10px 55px;
        font-size: 18px;
        line-height: 24px;
        position: relative;
    }
    .method_option_item span {
        position: absolute;
        width: 24px;
        height: 44px;
        left: 15px;
        top: 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: left center;
    }
    .method_main .pay_credit {
        background-image: url('../assets/images/credit_card.png');
    }
    .method_main .pay_pickup {
        /* // background-image: url('../assets/images/payatpickup.png'); */
        mask: url('../assets/images/pickup_icon.svg') no-repeat;
        background-size: contain;
        background-color: var(--theme_color);
        background-repeat: no-repeat;
        mask-size: 100% auto;
        mask-position: right center;
    }
    .method_main .pay_paypal {
        background-image: url('../assets/images/paypal.png');
    }
    .method_inner {
        display: flex;
        /* // justify-content: space-between; */
        flex-wrap: wrap;
    }
    .method_item {
        width: 227px;
        height: 53px;
        background-color: #fafafa;
        font-size: 15px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        box-sizing: border-box;
        margin-top: 10px;
        margin-right: 10px;
    }
    .method_inner .method_item:last-child {
        margin-right: 0;
    }
    .checkout_btn button {
        width: 100%;
    }

    @media screen and (max-width: 1160px) {
        .thanks_section {
            width: 100%;
        }
        .thanks_inner {
            display: block;
        }
        .thanks_left,
        .thanks_right {
            margin: 0 auto;
        }
        .payment_title {
            margin: 0 auto;
        }
        .payment_box .payment_time {
            position: static;
            text-align: center;
        }
        .payment_pay {
            width: 100%;
            max-width: 710px;
            box-sizing: border-box;
            margin: 0 auto;
        }
        .payment_box .payment_pay {
            overflow: visible;
        }
        .payment_box .pay_right, .payment_box .pay_left {
            float: none;
            margin: 0 auto;
            border: none;
        }
    }
    @media screen and (max-width: 1140px) {
        .check_header {
            height: 7.0625rem;
            line-height: 7.0625rem;
        }
    }
    @media screen and (max-width: 960px) {
        .check_header {
            height: 6.0625rem;
            line-height: 6.0625rem;
        }
    }
    /* @media screen and (max-width: 840px) {
        .check_header {
            height: 6.0625rem;
            padding-top: 1.625rem;
        }
    } */
    @media screen and (max-width: 724px) {
        .check_header {
            height: 5.0625rem;
            line-height: 5.0625rem;
        }
        .check_header h3 {
            font-size: 1.125rem;
        }
        .details_info_box .details_info span:nth-child(2) {
            width: 300px;
        }

        .check_header .header_main {
            width: 100%;
            padding-top: 20px;
            padding-right: 1rem;
            box-sizing: border-box;
        }
        .thanks_pot {
            padding: 1rem;
            margin-bottom: 1rem;
        }
        .pot_btn {
            top: 15px;
            right: 1rem;
        }
        .thanks_section {
            padding-top: 1.25rem;
        }
        .thanks_state {
            padding: .625rem 1rem;
        }
        .thanks_payment .thanks_info_inner {
            padding: .25rem 1rem .625rem;
        }
        .state_main {
            width: 100%;
        }
        .state_explain span {
            display: block;
        }
        .state_img {
            top: -80px;
            right: 0;
            width: 80px;
            height: 50px;
            mask-size: auto 50px;
            mask-position: bottom right;
        }
        .state_msg {
            margin-top: 0px;
        }
        .details_list {
            display: block;
        }
        .details_goods_list, .price_list {
            width: 100%;
        }
        .thanks_type_item {
            padding-left: 30px;
        }
        .thanks_type_item span {
            left: 0;
        }
        .thanks_type_item.delivery_type {
            padding-left: 35px;
        }
        .thanks_list_number {
            font-size: 20px;
        }
        .state_item_first {
            top: 0;
            left: 16px;
        }
        /* .pot_logo {
            min-width: 34px;
            max-height: 34px;
        } */
        .pot_name {
            max-width: 150px;
            word-break: break-all;
        }
        .rate {
            padding: 1.5rem;
        }
        .rate_title {
            font-size: 20px;
        }
        .rate_star {
            margin: 1.25rem 0;
        }
        .star_radio .radio_icon {
            width: 35px;
            height: 35px;
        }
        .rate_input textarea {
            padding: 1.25rem 1rem;
            font-size: 16px;
        }
        .rate_btn {
            margin-top: 2.25rem;
        }
        .payment_box {
            padding: 0 0.75rem;
        }
        .payment_box .payment_title, .method_select, .method_option_item, .payment_box .pay_right_btn button {
            font-size: 1rem;
        }
        .payment_box .pay_right, .payment_box .pay_left {
            width: 100%;
        }
        .method_select, .method_option {
            width: 100%;
            box-sizing: border-box;
        }
        .payment_box .goods_list_inner {
            padding: 0 .75rem;
        }
        .payment_box .details_row {
            padding: .375rem .625rem;
            font-size: 1rem;
        }
        .payment_box .pay_right_btn {
            padding: .625rem 1rem;
        }
        .pay_left_title {
            font-size: 1.125rem;
        }
    }
    @media screen and (max-width: 572px) {
        .check_header {
            height: 5rem;
            line-height: 5rem;
        }
        .thanks_section,
        .thanks_map img,
        .congrats_btn button {
            width: 100%;
        }
        .thanks_inner {
            display: block;
        }
        .thanks_left,
        .thanks_right {
            width: 100%;
            padding: 0 0.75rem;
            box-sizing: border-box;
        }
        .thanks_right {
            margin-top: 50px;
        }
        .state_text,
        .congrats_inner h4 {
            font-size: 1.25rem;
        }
        .pot_name,
        .thanks_inner h5,
        .thanks_info_title .title_status {
            font-size: 1.0625rem;
        }
        .thanks_info_title .title_rank {
            font-size: 1.25rem;
        }
        .thanks_info_title .title_type, .summary_box, .price_list .details_row {
            font-size: 1rem;
        }
        .pot_btn button,
        .congrats_inner p,
        .congrats_link a {
            font-size: 0.875rem;
        }
        .thanks_info_item,
        .details_row {
            font-size: 0.8125rem;
        }
        .state_item {
            padding-left: 0;
        }
        .thanks_section button {
            height: 2rem;
            min-width: 132px;
            font-size: 0.75rem;
        }

        .state_explain {
            display: block;
        }
        /* .state_explain span {
            display: block;
            margin-top: 10px;
            padding-left: 0;
        }
        .state_list {
            height: 40px;
        } */
    }
    @media screen and (max-width: 321px) {
        .details_info_box .details_info span:nth-child(2) {
            width: 230px;
        }
    }
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import PageFooter from '@/components/PageFooter.vue';
    import Clipboard from 'clipboard';
    import { setLang } from '@/locales';

    import FullScreenLoading from '../components/FullScreenLoading';

    import Stripe from '../components/Stripe';
    import CreditCardYuansfer from '../components/CreditCardYuansfer';

    import RateLayer from '@/components/RateLayer.vue';
    import SuccessLayer from '@/components/SuccessLayer.vue';

    export default {
        name: 'thanks',
        components: {
            PageFooter,
            RateLayer,
            FullScreenLoading,
            CreditCardYuansfer,
            Stripe,
            SuccessLayer
        },
        data() {
            return {
                // info: null,
                sid: this.$route.query.sid || '',
                oid: this.$route.query.oid || '',
                tid: '',
                store_data: {},
                takeout_info: {},
                order_data: {},
                delivery_address: {},
                vip_data: {},
                point: 0,
                isVip: false,
                server_host: '',
                theme_data: {},
                map_src: '',
                new_discount_price: '',
                full_coupon: [],
                showDetails: true,
                experience: '',
                showRateInfo: false,
                starAry: [
                    { show: false, value: 1 },
                    { show: false, value: 2 },
                    { show: false, value: 3 },
                    { show: false, value: 4 },
                    { show: false, value: 5 }
                ],
                starValue: 0,
                rate_data: {},
                showSelectOption: false,
                pay_method: '',
                is_wechat: 0,
                payment_setting: [],
                opay_type: this.creditCard_strip_show ? 'stripe' : (this.creditCard_yuansfer_show ? 'yuansfer': this.pay_method),
                stripe: null,
                elements: null,
                card: null,
                stripe_card_errors: '',
                countDownTimer: null,
                maxtime: 180,
                countDownTxt: '',
                cancel_flag: false,
            };
        },
        async created() {
            // let _title = this.language == 'eng' ? 'Placed orders' : '已下订单';
            // this.set_app_title(_title);

            const { hostname } = window.location;
            if (hostname.indexOf('.net') > -1) {
                this.server_host = "https://takeout.minitable.net";
            } else {
                this.server_host = "https://takeout.minitable.link";
            }

            if (this.sid) {
                await this.getStoreDate();
            }
            if (this.oid) {
                try {
                    await this.getData(this.oid);
                    await this.getSurveyData(this.oid);
                    // await this.ifVip();
                } finally {
                    console.log('finally');
                }
            }

            this.fobiddenBack();
        },
        watch: {},
        // created() {
        // },
        mounted() {
            setTimeout(this.loadPaypal, 2000);
        },
        computed: {
            ...mapGetters({
                // msg_opts: 'app/get_app_msg'
                pickup_discount_enable: 'app/get_pickup_discount_enable',
                is_vip: 'app/get_if_is_vip',
                language: 'app/get_language',
                is_loading: 'app/get_if_loading',
            }),
            ...mapState({
                // store_data: (state) => state.app.store_data,
                // sid: (state) => state.app.sid
            }),
            feature_image() {
                return 'url(' + this.theme_data.feature_image + ')';
            },
            slogoBack() {
                return 'url(' + this.store_data.slogo + ')';
            },
            sphone() {
                const { stel } = this.store_data;
                if (!stel) {
                    return '';
                }
                let temp = stel.replace(/\D/g, '');
                let sphone = temp.replace(/(\d{1,3})(\d{1,3})?(\d{1,4})?/, function (a, b, c, d) {
                    if (b) {
                        if (!c) {
                            return '(' + b + ')';
                        }
                        if (!d) {
                            return '(' + b + ') ' + c;
                        }
                        return '(' + b + ') ' + c + '-' + d;
                    }
                });
                return sphone;
            },
            cphone() {
                const { ori_tel } = this.order_data;
                if (!ori_tel) {
                    return '';
                }
                let telArr = ori_tel.split(' ');
                let temp = telArr[1];
                let cphone = temp.replace(/(\d{1,3})(\d{1,3})?(\d{1,4})?/, function (a, b, c, d) {
                    if (b) {
                        if (!c) {
                            return  b;
                        }
                        if (!d) {
                            return b + '-' + c;
                        }
                        return b + '-' + c + '-' + d;
                    }
                });
                cphone = '(' + telArr[0] + ') ' + cphone;
                return cphone;
            },
            saddress() {
                const { street_address, locality, postal_code } = this.store_data;
                if (!street_address && !locality && !postal_code) {
                    return '';
                }
                const saddress = street_address + ' , ' + locality + ' , ' + postal_code;
                return saddress;
            },
            deliveryAddress() {
                if (this.takeout_info.delivery_address) {
                    const { street_address1, street_address2, city, state } = this.takeout_info.delivery_address;
                    if (!street_address1 && !street_address2  && !city && !state) {
                        return '';
                    }
                    if (street_address2) {
                        const address = street_address1 + ' , ' + street_address2 + ' , ' + city + ' , ' + state;
                        return address;
                    } else {
                        const address = street_address1 + ' , ' + city + ' , ' + state;
                        return address;
                    }
                    
                }
                return '';
            },
            discount() {
                // console.log('this.order_data', this.order_data);
                return -Math.abs(parseFloat(this.order_data?.charge_detail?.full_coupon_discount));
            },
            discountShow() {
                if (this.order_data?.charge_detail?.full_coupon_discount === 0) {
                    return '$0.00';
                } else {
                    // return '-$' + Math.abs(parseFloat(this.order_data?.charge_detail.full_coupon_discount)).toFixed(2);
                    return parseFloat((this.order_data?.charge_detail?.full_coupon_discount * 1).toFixed(2));
                }
            },
            taxPrice() {
                return parseFloat((this.order_data?.charge_detail?.tax * 1).toFixed(2));
            },
            tip() {
                return parseFloat((this.order_data?.charge_detail?.tip * 1).toFixed(2)) || 0;
            },
            processingFee() {
                return parseFloat((this.order_data?.charge_detail?.processing_fee * 1).toFixed(2)) || 0;
            },
            deliveryFee() {
                return parseFloat((this.order_data?.charge_detail?.delivery_fee * 1).toFixed(2)) || 0;
            },
            originalPrice() {
                // if (
                //     this.order_data?.charge_detail?.new_discount < 0 ||
                //     this.order_data?.charge_detail?.full_coupon_discount < 0
                // ) {
                //     let original_price = (
                //         this.order_data?.oprice -
                //         this.order_data?.charge_detail?.new_discount -
                //         this.order_data?.charge_detail?.full_coupon_discount
                //     )?.toFixed(2);
                //     return original_price;
                // } else {
                //     return 0;
                // }

                let total_original = 0;
                let order_food_list = this.order_data?.ofoods_detail;
                if (order_food_list.length > 0) {
                    for(let i = 0; i < order_food_list.length; i++) {
                        let extra_price = order_food_list[i].addon_total_price || 0;
                        let _fprice = order_food_list[i].fprice;
                        total_original = total_original + order_food_list[i].count * (parseFloat(_fprice) + parseFloat(extra_price));
                        total_original = parseFloat((total_original * 1).toFixed(2));
                    }
                }
                    
                return total_original;
            },
            newDiscount() {
                return -Math.abs(parseFloat(this.order_data?.charge_detail?.new_discount));
            },
            newDiscountShow() {
                if (this.order_data?.charge_detail?.new_discount === 0) {
                    return '0.00';
                } else {
                    // return '-$' + Math.abs(parseFloat(this.order_data?.charge_detail.new_discount)).toFixed(2);
                    return parseFloat((this.order_data?.charge_detail?.new_discount * 1).toFixed(2));
                }
            },
            subtotal() {
                // let oprice_original = parseFloat(parseFloat(this.order_data?.charge_detail?.oprice_original).toFixed(2))
                // return oprice_original ? oprice_original : parseFloat(parseFloat(this.order_data?.charge_detail?.price).toFixed(2));
                
                let total_original = 0;
                let order_food_list = this.order_data?.ofoods_detail;
                if (order_food_list.length > 0) {
                    for(let i = 0; i < order_food_list.length; i++) {
                        let extra_price = order_food_list[i].addon_total_price || 0;
                        let _fprice = order_food_list[i].fprice_original ? order_food_list[i].fprice_original : order_food_list[i].fprice;
                        total_original = total_original + order_food_list[i].count * (parseFloat(_fprice) + parseFloat(extra_price));
                    }
                    total_original = parseFloat((total_original * 1).toFixed(2));
                }
                    
                return total_original;
            },
            total() {
                // const total = (this.subtotal + this.taxPrice + this.tip + this.discount)?.toFixed(2) || 0;
                return parseFloat((this.order_data?.oprice * 1).toFixed(2));
            },
            refund() {
                let _total = 0;
                let refund_detail = this.order_data?.refund_detail || [];
                if (refund_detail.length > 0) {
                    for(let i = 0; i < refund_detail.length; i++) {
                        let amount_price = refund_detail[i].amount || 0;
                        _total = _total + amount_price * 1;
                    }
                }
                let _price = parseFloat((this.total - _total).toFixed(2));

                return _price;
            },
            specialOffer() {
                let _price = parseFloat((this.subtotal - this.originalPrice).toFixed(2));
                return _price < 0 ? 0 : _price;
            },
            pickupDiscount() {
                let _price = parseFloat((this.originalPrice - this.order_data?.charge_detail?.price).toFixed(2));
                return _price < 0 ? 0 : _price; 
            },
            itemDiscount() {
                let _discountObj = this.order_data?.discount_detail_v2 || {};
                let _itemDiscount = 0;

                if (_discountObj) {
                    if (_discountObj.product_discount) {
                        _itemDiscount += _discountObj.product_discount.discount_val * 1;
                    }

                    if (_discountObj.product_reduction) {
                        _itemDiscount += _discountObj.product_reduction.discount_val * 1;
                    }

                    _itemDiscount = parseFloat((_itemDiscount * 1).toFixed(2));
                }

                return _itemDiscount;
            },
            orderDiscount() {
                let _orderDiscount = (Math.abs(this.order_data?.charge_detail?.discount) - this.itemDiscount).toFixed(2);
                _orderDiscount = parseFloat(_orderDiscount);
                return _orderDiscount;
            },
            fullCouponPrice() {
                if (this.order_data?.charge_detail?.full_coupon_discount === 0) {
                    return '0';
                } else {
                    // return '-$' + Math.abs(parseFloat(this.order_data?.charge_detail.full_coupon_discount)).toFixed(2);
                    return Math.abs(parseFloat(this.order_data?.charge_detail?.full_coupon_discount));
                }
            },
            fullCouponTotal() {
                let price = Math.abs(parseFloat(this.order_data?.charge_detail?.full_coupon_discount));
                if (this.full_coupon.length > 0) {
                    for (let i = 0; i < this.full_coupon.length; i++) {
                        if (price == this.full_coupon[i].minus) {
                            return this.full_coupon[i].full;
                        }
                    }
                }
                return '';
            },
            fullCouponObj() {
                let _price = 0;
                let _type = '';
                let _minus = 0;
                let _discount = Math.abs(parseFloat(this.order_data?.charge_detail?.full_coupon_discount));
                if (_discount) {
                    let _full_coupon_list = JSON.parse(JSON.stringify(this.full_coupon));
                    _full_coupon_list = _full_coupon_list.sort((a, b) => {
                        return b.full - a.full;
                    });

                    let _cart_price = this.originalPrice;
                    if (this.pickupDiscount) {
                        _cart_price = (this.originalPrice - this.pickupDiscount) || this.cart_price;
                    }

                    for (let i = 0; i < _full_coupon_list.length; i++) {
                        if (_full_coupon_list[i].type && _full_coupon_list[i].type == 'bysubtotal' && (_cart_price * _full_coupon_list[i].minus * 0.01).toFixed(2) == _discount && _cart_price >= _full_coupon_list[i].full) {
                            _price = _full_coupon_list[i].full;
                            _type = 'bysubtotal';
                            _minus = _full_coupon_list[i].minus;
                            break;
                        } else if (_full_coupon_list[i].minus == _discount && _full_coupon_list[i].type != 'bysubtotal' && _cart_price >= _full_coupon_list[i].full) {
                            _price = _full_coupon_list[i].full;
                            _type = 'fixed';
                            _minus = _full_coupon_list[i].minus;
                            break;
                        }
                    }
                    return {
                        full: _price,
                        discount: _minus,
                        type: _type || 'fixed'
                    }
                }
                return {
                        full: 0,
                        discount: 0,
                        type: 'fixed'
                }
            },
            oidLastFive() {
                let oidStr = this.takeout_info.oid;
                if (oidStr) {
                    return oidStr.substring(oidStr.length - 5);
                } else {
                    return '';
                }
            },
            creditCard_strip_show() {
                return this.pay_method === 'creditCard' && this.payment_setting.some(item=>item.payment==='Debit/Credit Card' && item.platform==='stripe');
            },
            creditCard_yuansfer_show() {
                return this.pay_method === 'creditCard' && this.payment_setting.some(item=>item.payment==='Debit/Credit Card' && item.platform==='yuansfer');
            },
        },
        methods: {
            ...mapActions({
                joinTable: 'app/joinTable',
                complete_pay: 'app/complete_pay',
                cancel_order: 'app/cancel_order'
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG',
                set_language: 'app/APP_SET_LANGUAGE',
                setLoading: 'app/APP_SET_IF_LOADING',
            }),
            activate() {
                // const origin = window.location.origin;
                // const passOrigin = origin.replace(/takeout\./, 'pass.');
                // window.location.href = passOrigin + '/#/login?sid=' + this.sid;
                // https://client.minitable.link/consumer_fb?sid=85a0dc31e266b4de1d1d9d6c77760531
                const { hostname } = window.location;
                if (hostname.indexOf('.net') > -1) {
                    window.location.href = 'https://minipass.minitable.net/#/signin/' + this.sid;
                } else {
                    window.location.href = 'https://minipass.minitable.link/#/signin/' + this.sid;
                }
            },
            appraiseSurvey() {
                const { hostname } = window.location;
                if (hostname.indexOf('.net') > -1) {
                    window.location.href = 'https://client.minitable.net/consumer_fb?sid=' + this.sid;
                } else {
                    window.location.href = 'https://client.minitable.link/consumer_fb?sid=' + this.sid;
                }
            },
            getPayStatus() {
                let pay_state = document.getElementById('pay_state');
                let pay_state_arys = pay_state.childNodes;
                // const arys_length = pay_state_arys.length;
                const tstatus = this.takeout_info.takeout_tstatus;
                if (tstatus >= 10) {
                    pay_state_arys[0].className = 'state_item_first done';
                    pay_state_arys[1].className = 'state_item done';
                    pay_state_arys[2].className = 'state_item done';
                    pay_state_arys[3].className = 'state_item current';
                } else if (tstatus >= 7) {
                    pay_state_arys[0].className = 'state_item_first done';
                    pay_state_arys[1].className = 'state_item done';
                    pay_state_arys[2].className = 'state_item current';
                } else if (tstatus >= 5) {
                    pay_state_arys[0].className = 'state_item_first done';
                    pay_state_arys[1].className = 'state_item current';
                } else if (tstatus >= 0) {
                    pay_state_arys[0].className = 'state_item_first current';
                }
            },
            contactUs() {
                if (this.store_data.stel) {
                    window.location.href = 'tel:' + this.store_data.stel;
                }
            },
            getOid(url) {
                let array = url.split('?');
                for (let i = 1; i < array.length; i++) {
                    let aryItem = array[i].split('=');
                    if (aryItem[0] == 'oid') {
                        return aryItem[1];
                    }
                }
            },
            getAddonCount(parentCount, count) {
                return parseInt(parentCount) * parseInt(count);
            },
            getFoodSumPrice(food, index) {
                const foodSinglePrice = parseFloat(food.fprice);
                let sum = foodSinglePrice * parseInt(food.count);
                if (food.addon_counts) {
                    let addSum = 0;
                    const addonCounts = food.addon_counts.split('|');
                    const addonPrices = food.addon_prices.split('|');
                    for (let i = 0; i < addonCounts.length; i++) {
                        addSum += parseInt(addonCounts[i]) * addonPrices[i];
                    }
                    addSum *=  parseInt(food.count);
                    sum += addSum;
                }
                return (sum).toFixed(2);
            },
            getAddonSumPrice(parentCount, counts, prices, index) {
                const addonCounts = counts.split('|');
                const addonPrices = prices.split('|');
                return parseFloat((parseInt(addonCounts[index]) * addonPrices[index]).toFixed(2));
                // return (parseInt(parentCount) * parseInt(addonCounts[index]) * addonPrices[index]).toFixed(2);
            },
            getAddonPickupPrice(parentCount, counts, prices, discounts, index) {
                const addonCounts = counts.split('|');
                const addonPrices = prices.split('|');
                const addonDiscounts = discounts.split('|');
                return parseFloat((parseInt(addonCounts[index]) * addonPrices[index] * addonDiscounts[index]).toFixed(2));
                // return (parseInt(parentCount) * parseInt(addonCounts[index]) * addonPrices[index] * addonDiscounts[index]).toFixed(2);
            },
            // 暂时没有会员功能，后期做判断
            ifVip() {
                let phone = this.order_data.ori_tel;
                // 注意一下请求的手机号，要去掉空格，保留加号
                phone = phone.replace(/\s+/g, '');
                phone = encodeURIComponent(phone);
                if (phone) {
                    return fetch(
                        // 'https://api.mintbl.net/loyalty_dev/merchants/'+ this.sid +'/members/phone?phone=' + phone,
                        this.server_host + '/weapp/takeout/vip/get_vip_by_phone?phone=' +
                            phone +
                            '&sid=' +
                            this.sid,
                        {
                            method: 'GET'
                        }
                    )
                        .then((res) => {
                            return res.text();
                        })
                        .then((res) => {
                            res = JSON.parse(res);
                            if (res.data.vip_data) {
                                this.vip_data = res.data.vip_data[0];
                                let { pass_enable, pass_convert_rate } = res.data.takeoutsetting_data[0];
                                if (pass_enable == 1) {
                                    this.isVip = true;
                                    this.points = Math.round(this.total * pass_convert_rate);
                                }
                            }
                        });
                }
            },
            getStoreDate() {
                return fetch(this.server_host + '/weapp/takeout/store/get?sid=' + this.sid, {
                    method: 'GET'
                })
                    .then((res) => {
                        return res.text();
                    })
                    .then((res) => {
                        res = JSON.parse(res);
                        if (res.data.code === 100000) {
                            this.theme_data = res.data.data;
                            this.store_data = res.data.data.store_data;

                            if (this.theme_data.first_discount_type == 'fixed') {
                                // this.new_discount_price = this.theme_data.first_discount_amount * -1;
                                this.new_discount_price = this.$t('cart.newDiscountText', { discount_price: this.theme_data.first_discount_amount });
                            } else if (this.theme_data.first_discount_type == 'bysubtotal') {
                                // this.new_discount_price = this.theme_data.first_discount_amount * 100 + '%';
                                // this.new_discount_price = this.$t('cart.newDiscountPer', { discount_price: this.theme_data.first_discount_amount * 100 });

                                if (this.language == 'chn') {
                                    this.new_discount_price = this.$t('cart.newDiscountPer', {
                                        discount_price: 10 - this.theme_data.first_discount_amount * 10
                                    });
                                } else {
                                    this.new_discount_price = this.$t('cart.newDiscountPer', {
                                        discount_price: this.theme_data.first_discount_amount * 100
                                    });
                                }
                            }

                            this.full_coupon = this.theme_data.full_coupon;

                            const placeId = this.store_data.place_id;
                            if (placeId) {
                                const myKey = "AIzaSyBxk9XT20SWi24iARSZ_n9HrgcFPWA6WFk";
                                this.map_src = "https://www.google.com/maps/embed/v1/place?q=place_id:" + placeId +"&key=" + myKey + "&language=en";
                            }
                        }
                    });
            },
            getPaymethodData(oid) {
                let firstUrl = this.server_host + '/weapp/takeout/paymethod/get?sid=' + this.sid + '&oid=' + oid;
                return fetch(firstUrl, {
                    method: 'GET'
                })
                    .then((res) => {
                        return res.text();
                    })
                    .then((res) => {
                        res = JSON.parse(res);
                        if (res.data.code === 100000) {
                            this.payment_setting = res.data.data.payment_setting;
                        }
                    });
            },
            getData(oid) {
                // let oid = this.getOid(window.location.href);
                // if (this.$route.query.oid) {
                //     oid = this.$route.query.oid;
                // }
                let firstUrl = this.server_host + '/weapp/takeout/order/get?sid=' + this.sid + '&oid=' + oid;
                return fetch(firstUrl, {
                    method: 'GET'
                })
                    .then((res) => {
                        return res.text();
                    })
                    .then((res) => {
                        res = JSON.parse(res);
                        if (res.data.code === 100000) {
                            this.tid = res.data.data.order_data[0].tid;
                            let realUrl =
                                this.server_host + '/weapp/takeout/info/get?sid=' +
                                this.sid +
                                '&tid=' +
                                this.tid;
                            return fetch(realUrl, {
                                method: 'GET'
                            })
                                .then((res1) => {
                                    return res1.text();
                                })
                                .then((res1) => {
                                    res1 = JSON.parse(res1);
                                    if (res1.data.code === 100000) {
                                        res1 = res1.data.data;
                                        this.order_data = res1.order_data;
                                        this.takeout_info = res1.takeout_info;
                                        this.delivery_address = res1.takeout_info?.delivery_address;
                                        this.getPayStatus();
                                        this.getPaymethodData();
                                        this.ifVip();
                                        if (res1.takeout_info.pay_fail_timestamp) {
                                            let nowTime = new Date().getTime();
                                            let leftTime = parseInt((nowTime - res1.takeout_info.pay_fail_timestamp) / 1000);
                                            if (leftTime < 180) {
                                                this.maxtime = 180 - leftTime;
                                                this.cancel_flag = true;
                                                this.countDownTimer = setInterval(this.countDown, 1000);
                                            } else if (leftTime >= 180 && res1.takeout_info.paid == 0 && res1.takeout_info.takeout_tstatus != -1) {
                                                this.cancel_order({
                                                    sid: this.sid,
                                                    oid: this.oid,
                                                    tid: this.tid,
                                                    cancel_reason: 'online payment failure'
                                                });
                                            } else {
                                                this.countDownTxt = '0:00';
                                                this.maxtime = -1;
                                            }
                                        }
                                        
                                    }
                                });
                        }
                    });
            },
            getSurveyData(oid) {
                let firstUrl = this.server_host + '/weapp/takeout/survey/get?sid=' + this.sid + '&oid=' + oid;
                return fetch(firstUrl, {
                    method: 'GET'
                })
                    .then((res) => {
                        return res.text();
                    })
                    .then((res) => {
                        res = JSON.parse(res);
                        if (res.data.code === 100000) {
                            let rateList = res.data.data.rate_list;
                            if (rateList && rateList.length > 0) {
                                let rateObj = rateList[0];
                                this.showRateInfo = true;
                                this.experience = rateObj.online_ordering_comment;
                                this.starValue = rateObj.online_ordering_star;

                                for (var j = 0; j < this.starValue; j++) {
                                    this.starAry[j].show = true;
                                }
                            }
                        }
                    });
            },
            addSurveyData(obj) {
                let firstUrl = this.server_host + '/weapp/takeout/survey/add?sid=' + obj.sid + '&oid=' + obj.oid + '&star=' + obj.star + '&suggestion=' + obj.suggestion + '&tel=' + obj.tel + '&name=' + obj.name;
                return fetch(firstUrl, {
                    method: 'GET'
                })
                    .then((res) => {
                        return res.text();
                    })
                    .then((res) => {
                        res = JSON.parse(res);
                        if (res.data.code === 100000) {
                            console.log('success');
                        }
                    });
            },
            // 禁用浏览器返回
            fobiddenBack() {
                //防止页面后退
                history.pushState(null, null, document.URL);
                window.addEventListener('popstate', function () {
                    history.pushState(null, null, document.URL);
                });
            },
            stratNewOrder() {
                const { sid, tid, gid } = this.$route.query;

                window.localStorage.setItem('client_sid', this.$store.state.app.sid);

                this.joinTable({
                    sid: this.$store.state.app.sid,
                    tid: this.takeout_tid,
                    router: this.$router,
                    email: this.email,
                    type: 'takeout',
                    gid
                });
            },
            getNowTime() {
                let date = new Date();
                let hour = date.getHours();
                let minute = date.getMinutes();
                let text = hour < 12 ? ' AM' : ' PM';
                let hour_text = hour < 12 ? hour : (hour - 12);
                hour_text = hour_text < 10 ? '0' + hour_text : hour_text;
                let minute_text = minute < 10 ? '0' + minute : minute;
                let time = hour_text + ':' + minute_text + text;
                return time;
            },
            getEstimatedTime(time) {
                if (time == 'ASAP') return time;
                
                let str = time ? time : ' ';
                let arr = str.split(' ');
                let newArr = arr[1].split(':');
                if (newArr.length > 0) {
                    let hour = newArr[0];
                    let text = hour < 12 ? ' AM' : ' PM';
                    let hour_text = hour < 13 ? (hour * 1) : (hour - 12);
                    let _minutes = newArr[1] || '';
                    return hour_text + ':' + _minutes + text;
                } else {
                    return '';
                }
            },
            getOrderTime(time) {
                let str = time ? time : ' ';
                let arr = str.split(' ');
                let timeArr = arr[1].split(':');
                let dateArr = arr[0].split('/');
                let _time = '';
                if (timeArr.length > 0) {
                    let hour = timeArr[0];
                    let text = hour < 12 ? ' AM' : ' PM';
                    let hour_text = hour < 12 ? hour : (hour - 12);
                    let _minutes = timeArr[1] || '';
                    _time = hour_text + ':' + _minutes + text;
                }
                return dateArr[1] + ' ' + dateArr[2] + 'th,' + dateArr[0] + ' at ' + _time;
            },
            copyAddress(ele) {
                const clipboard = new Clipboard('#' + ele);
                clipboard.on('success', (e) => {
                    this.set_msg({
                        msg: this.$t('review.copy_address_suc')
                    });
                    clipboard.destroy();
                });

                clipboard.on('error', (e) => {
                    this.set_msg({
                        msg: this.$t('review.copy_address_err')
                    });
                    clipboard.destroy();
                });
            },
            toggleOrderDetails(e) {
                if (this.showDetails) {
                    this.showDetails = false;
                    e.target.className = 'summary_arrow down_arrow';
                    e.target.parentNode.nextSibling.style.display = 'none';
                } else{
                    this.showDetails = true;
                    e.target.className = 'summary_arrow';
                    e.target.parentNode.nextSibling.style.display = 'block';
                }
            },
            showRateLayer() {
                // console.log('experience', this.experience);

                // if (!this.experience) {
                //     return false;
                // }

                // 如果没有评星，点击submit无效
                if (this.starValue <= 0) {
                    return false;
                }

                this.rate_data = {
                    sid: this.sid,
                    oid: this.oid,
                    name: this.takeout_info.customer_name,
                    tel: encodeURIComponent(this.order_data.ori_tel),
                    suggestion: this.experience,
                    star: this.starValue
                }

                this.$refs.rateLayer.show();
            },
            changeStar(num) {
                num = num || 0;
                this.starValue = num;

                for (var i = 0; i < this.starAry.length; i++) {
                    this.starAry[i].show = false;
                }

                for (var j = 0; j < this.starValue; j++) {
                    this.starAry[j].show = true;
                }

            },
            rateSumbit(obj) {
                this.showRateInfo = true;
                this.addSurveyData(obj);
            },
            async to_pay() {
                this.cancel_flag = false;
                // console.log('to pay...');
                let stripe_token = '';
                let yuansfer_creditCard_data = null;
                let opay_type = '';

                let result = null;

                if (this.creditCard_strip_show) {
                    this.setLoading({ is_loading: true });
                    const { token, error } = await this.stripe.createToken(this.card);
                    this.setLoading({ is_loading: false });
                    if (error) {
                        this.stripe_card_errors = error.message;
                        return;
                    } else {
                        stripe_token = token.id;
                    }

                    opay_type = 'stripe';
                    result = await this.complete_pay({
                        sid: this.sid,
                        oid: this.oid,
                        opay_type: opay_type,
                        stripe_token
                    });

                }else if(this.creditCard_yuansfer_show) {
                    this.setLoading({ is_loading: true });
                    try{
                        yuansfer_creditCard_data = await this.$refs.yuansfer_creditCard.getToken();
                    }finally{
                        this.setLoading({ is_loading: false });
                    }
                    if (!yuansfer_creditCard_data) {
                        return;
                    }

                    opay_type = 'yuansfer';
                    result = await this.complete_pay({
                        sid: this.sid,
                        oid: this.oid,
                        opay_type: opay_type,
                        yuansfer_creditCard_data: JSON.stringify(yuansfer_creditCard_data)
                    });
                }
                // console.log('stripe_token', stripe_token);

                if (result.pay == 'success') {
                    // console.log('opay_type', this.opay_type);
                    this.opay_type = opay_type == 'stripe' ? 'stripe' : 'yuansfer';
                    // console.log('opay_type 222', this.opay_type);
                    clearInterval(this.countDownTimer);
                    this.$refs.successLayer.show();
                } else if (result.pay == 'failed') {
                    let msg = result.msg || 'Payment failed';
                    this.set_msg({ msg });
                    if (this.maxtime < 0) {
                        this.cancel_order({
                            sid: this.sid,
                            oid: this.oid,
                            tid: this.tid,
                            cancel_reason: 'online payment failure'
                        });
                    } else {
                        this.cancel_flag = true;
                    }
                }

                // let post_data = this.buildRequestData(false);

                // this.submitData = {post_data, stripe_token, el: e.srcElement, yuansfer_creditCard_data};
                // this.$refs.submitLayer.show();
            },
            payBoxHide() {
                // console.log('ok~~~~');
                this.takeout_info.paid = 1;
                this.takeout_info.takeout_pay_status = 'paid';
                this.order_data.opay_type = this.opay_type;
            },
            toggleSelect() {
                if (this.showSelectOption) {
                    this.hideSelect();
                } else{
                    this.showSelectOption = true;
                    this.$refs.methodOption.style.display = 'block';
                    this.$refs.methodSelect.className = 'method_select isOpen';
                }
            },
            hideSelect() {
                this.showSelectOption = false;
                this.$refs.methodOption.style.display = 'none';
                this.$refs.methodSelect.className = 'method_select';
            },
            choosePaymentType(value) {
                this.pay_method = value;
                // console.log('this.pay_method', this.pay_method);
                this.hideSelect();
            },
            setStripe(data) {
                const { stripe, card } = data;
                this.stripe = stripe;
                this.card = card;
            },
            async paypal_checkout(paypal_orderid) {
                this.cancel_flag = false;
                // console.log('paypal_orderid', paypal_orderid);
                let opay_type = 'paypal';
                let result = await this.complete_pay({
                    sid: this.sid,
                    oid: this.oid,
                    opay_type: opay_type,
                    paypal_orderid
                });
                // console.log('result_paypal', result);
                if (result.pay == 'success') {
                    // console.log('opay_type', this.opay_type);
                    this.opay_type = opay_type;
                    // console.log('opay_type 222', this.opay_type);
                    clearInterval(this.countDownTimer);
                    this.$refs.successLayer.show();
                } else if (result.pay == 'failed') {
                    if (this.maxtime < 0) {
                        this.cancel_order({
                            sid: this.sid,
                            oid: this.oid,
                            tid: this.tid,
                            cancel_reason: 'online payment failure'
                        });
                    } else {
                        this.cancel_flag = true;
                    }
                }
                return;
            },
            loadPaypal() {
                if (!window.paypal) {
                    return;
                }
                paypal
                    .Buttons({
                        onClick: (data, actions) => {
                            return actions.resolve();
                        },
                        createOrder: (data, actions) => {

                            return actions.order.create({
                                purchase_units: [
                                    {
                                        amount: {
                                            currency_code: 'USD',
                                            value: this.total
                                        },
                                        description: this.sname + ' @@ ' + this.sid
                                    }
                                ],
                                application_context: {
                                    brand_name: this.sid
                                }
                            });
                        },
                        onApprove: (data, actions) => {
                            // Capture the funds from the transaction
                            return actions.order.capture().then((details) => {
                                // alert('Transaction completed by ' + details.payer.name.given_name);
                                return this.paypal_checkout(data.orderID);
                            });
                        },
                        onError: (err) => {}
                    })
                    .render('#paypal-button-container');
            },
            countDown() {
                if (this.maxtime >= 0) {
                    let minutes = Math.floor(this.maxtime / 60);
                    let seconds = Math.floor(this.maxtime % 60);
                    seconds = seconds < 10 ? '0' + seconds : seconds;
                    this.countDownTxt = minutes + ":" + seconds;
                    // console.log('this.maxtime', this.maxtime);
                    if (this.maxtime == 0 && this.cancel_flag == true) {
                        clearInterval(this.countDownTimer);
                        this.cancel_flag == false;
                        this.cancel_order({
                            sid: this.sid,
                            oid: this.oid,
                            tid: this.tid,
                            cancel_reason: 'online payment failure'
                        });
                    }
                    --this.maxtime;
                } else {
                    this.cancel_flag = false;
                    clearInterval(this.countDownTimer);
                }

            },
        }
    };
</script>
