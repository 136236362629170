import axios from 'axios';

const COUPON_API = {
    // TODO, better way to check if is Dev
    baseURL(isDev) {
        return isDev ? 'http://localhost:5757/weapp/takeout/coupon/' : '/weapp/takeout/coupon/'; // TODO set prod URL,
    },
    useCoupon(isDev, tenant_id, shortid) {
        return `${COUPON_API.baseURL(isDev)}use_coupon?tenant_id=${tenant_id}&shortId=${shortid}`;
    },
    getRecordByTenantID(isDev, tenant_id) {
        return `${COUPON_API.baseURL(isDev)}${tenant_id}/records`;
    },
    getCouponByID(isDev, tenant_id, shortid) {
        return `${COUPON_API.baseURL(isDev)}get_coupon_by_id?tenant_id=${tenant_id}&shortid=${shortid}`;
    }
};

const state = {
    // sample data
    currentCoupon: {
        usage_limit_per_user: 1,
        shortid: 'eSMlLE_Gg',
        invited_numbers: ['+112345678', '+187654321'],
        expire_date: '2020-07-01',
        discount_type: 1,
        exclude_products: ['P0001', 'P0002'],
        min_spend: 19.99,
        usage_limit: 0,
        coupon_type: 1,
        create_date: '2020-08-16T08:23:28.444Z',
        blocked_numbers: ['+12345678'],
        tenant_id: 't0001',
        exclude_sale: false,
        amount: 0,
        exclude_categories: ['CTG1', 'CTG2'],
        coupon_desc: 'a test for coupon4',
        coupon_name: 'test coupon4'
    }
};

const getters = {
    isDev(state, getters, rootState) {
        return rootState.debug;
    }
};

const mutations = {
    SET_COUPON(state, payload) {
        state.currentCoupon = { ...payload };
    }
};

const actions = {
    async getCouponByID({ state, getters, commit, dispatch }, payload) {
        const { couponId, tenant_id } = payload;
        if (!couponId || !tenant_id) {
            return;
        }
        commit('app/APP_SET_IF_LOADING', { is_loading: true }, { root: true });
        try {
            const result = await axios.get(COUPON_API.getCouponByID(getters.isDev, tenant_id, couponId));
            const { data, code } = result.data;
            console.log(data);
            if (code !== 100000) {
                // TODO handle error
                commit('SET_COUPON', null);
                return null;
            }
            console.log(data);
            commit('SET_COUPON', data);
            return data;
        } catch (e) {
            console.log(e);
            commit('SET_COUPON', null);
            return null;
        } finally {
            commit('app/APP_SET_IF_LOADING', { is_loading: false }, { root: true });
        }
    },
    async useCoupon({ state, getters, commit, dispatch, rootState }, payload) {
        const { couponId, tenant_id, userId, oid } = payload;
        if (!couponId || !tenant_id || !userId || !oid) {
            return false;
        }
        commit('app/APP_SET_IF_LOADING', { is_loading: true }, { root: true });
        try {
            const result = await axios.put(COUPON_API.useCoupon(getters.isDev, tenant_id, couponId));
            const { data } = result.data;
            if (result.code !== '10000') {
                // TODO handle error
                return false;
            }
            return data;
        } catch (e) {
            console.log(e);
            return false;
        } finally {
            commit('app/APP_SET_IF_LOADING', { is_loading: false }, { root: true });
        }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};
