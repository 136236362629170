<template lang="html">
    <div>
        <div v-if="store_data.online_ordering_enable == 0" class="page_not_found">
            <span>The page you’re looking for can’t be found.</span>
        </div>
        <div v-else id="app">
            <router-view></router-view>
        </div>
    </div>
</template>
<style>
    .page_not_found {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #efefef;
        text-align: center;
        z-index: 10000;
    }
    .page_not_found span {
        display: block;
        font-size: 16px;
        line-height: 1.5;
        padding: 50px 20px 0px 20px;
        color: #000;
    }
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import { setLang } from '@/locales';
    import { Toast } from 'mint-ui';

    let HAS_TOAST = false;

    export default {
        name: 'app',
        data() {
            return {};
        },
        beforeUpdate() {},
        updated() {},
        beforeCreate() {},
        created() {
            const lang = localStorage.language || 'eng';
            setLang(lang);
            this.set_language({
                language: lang
            });

            if (!this.$route.query) {
                return;
            }
            const { tid, gid } = this.$route.query;
            let { sid } = this.$route.params;

            if (!sid) {
                sid = this.$route.query.sid;

                // 判断是旧版本的登录地址，则切换成新版，不能影响其他页面
                if (this.$route.path == '/welcome') {
                    this.$router.replace({ path: `/welcome/${sid}` });
                }
            }

            if (sid) {
                this.setSid({
                    sid,
                    tid,
                    gid
                });
            }

            setTimeout(() => {
                this.initData(sid);
            }, 100);

            const { shared_tid, shared_cart_id, shared_foods_timer } = this.$store.state.app;

            if (shared_tid && shared_cart_id && shared_foods_timer === -1) {
                this.getSharedCart({
                    cartid: shared_cart_id,
                    router: this.$router
                });
            }

            if (this.$store.state.app.menu_timer === -1) {
                setTimeout(() => {
                    this.get_menu(this.$store.state.app.sid);
                }, 300);
                let menu_timer = setInterval(() => {
                    if (this.$store.state.app.shared_tid != '' && this.$store.state.app.shared_cart_id != '') {
                        this.get_menu(this.$store.state.app.sid);
                    }
                }, 30 * 1000);
                this.set_menu_timer(menu_timer);
            }

            if (this.$store.state.app.check_timer == -1) {
                let check_timer = setInterval(() => {
                    this.do_check(this.$router);
                }, 7000);
                this.set_timer(check_timer);
            }
        },
        watch: {
            '$route'() {
                window['__logger__'] && window['__logger__']({
                    view: 1,
                    click: 0
                });
            },
            msg() {
                if (this.msg && !HAS_TOAST) {
                    HAS_TOAST = true;
                    let temp_toast_instance = Toast(this.msg);
                    setTimeout(() => {
                        temp_toast_instance.close();
                        this.set_msg('');
                        HAS_TOAST = false;
                    }, 2000);
                }
            }
        },
        computed: {
            ...mapGetters({
                msg: 'app/get_app_msg'
            }),
            ...mapState({
                store_data: (state) => state.app.store_data,
            }),
        },
        methods: {
            ...mapActions({
                do_check: 'app/do_check',
                initData: 'app/initData',
                getSharedCart: 'app/get_shared_cart',
                quit_table: 'app/quitTable',
                get_menu: 'app/get_menu'
            }),
            ...mapMutations({
                setSid: 'app/APP_SET_SID',
                set_msg: 'app/APP_SET_MSG',
                set_timer: 'app/APP_SET_CHECK_TIMER',
                set_menu_timer: 'app/APP_SET_MENU_TIMER',
                set_language: 'app/APP_SET_LANGUAGE'
            })
        }
    };
</script>
