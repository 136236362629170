<template>
    <div class="your_items">
        <div class="items_title">
            <span>{{ $t('cart.yourItems') }} ({{ cart_food_list.length }})</span>
            <span @click="toggleOrderList" class="items_arrow down_arrow"></span>
        </div>
        <div class="order_list" ref="orderList" style="display: none;">
            <div class="order_list_inner">
                <div v-for="(item, index) in cart_food_list" class="food_item" :key="index">
                    <!-- <div class="order_fpic">
                        <img v-if="item.fpic !== ''" :src="item.fpic" />
                        <img v-else src="../assets/images/placeholder_square.png" />
                    </div> -->
                    <div class="order_right">
                        <div class="order_text">
                            <!-- {{ trans(item, 'fname') }} -->
                            {{ translate('item', item.fname) }}
                        </div>
                        <div class="order_item order_item_inner" :class="{ withNote: item.note }">
                            <!-- <div class="product_price" style="color: #000;">
                                <span
                                    style="margin-right: 5px; text-decoration: line-through"
                                    v-if="item.fprice_original"
                                >
                                    ${{ item.fprice_original }}
                                </span>
                                <span
                                    v-if="item.fprice_original"
                                    style="color: var(--theme_color)"
                                >
                                    ${{ item.fprice }}
                                </span>
                                <span
                                    v-if="item.fprice && item.fprice_original == ''"
                                >
                                    ${{ item.fprice }}
                                </span>
                                <span
                                    v-if="
                                        pickup_discount_enable &&
                                        item.fprice_pickup &&
                                        item.fprice_original == ''
                                    "
                                >
                                    ${{ food.fprice_pickup }}
                                </span>
                            </div> -->
                            <div v-if="!pickup_discount_enable || type !== 'pickup'">
                                <div
                                    class="weui-media-box__info__meta"
                                    v-if="!is_vip || !item.fprice_vip"
                                    style="float: right; color: black; padding-right: 0"
                                >
                                    <!-- <span v-if="item.addon_total_price !== undefined && item.addon_total_price">${{ ((item.fprice * item.fcount) + (item.addon_total_price * item.fcount)).toFixed(2) }}</span>
                                    <span v-else>${{ (item.fprice * item.fcount).toFixed(2) }}</span> -->
                                    
                                    <template v-if="item.fprice_original">
                                        <span v-if="item.addon_total_price !== undefined && item.addon_total_price">
                                            <span style="margin-right: 5px; text-decoration: line-through;">${{ ((item.fprice_original * item.fcount) + (item.addon_total_price * item.fcount)).toFixed(2) }}</span>
                                            <span style="color: var(--theme_color);">${{ parseFloat(((item.fprice * item.fcount) + (item.addon_total_price * item.fcount)).toFixed(2)) }}</span>
                                        </span>
                                        <template v-else>
                                            <span style="margin-right: 5px; text-decoration: line-through;">${{ parseFloat((item.fprice_original * item.fcount).toFixed(2)) }}</span>
                                            <span style="color: var(--theme_color);">${{ parseFloat((item.fprice * item.fcount).toFixed(2)) }}</span>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <span v-if="item.addon_total_price !== undefined && item.addon_total_price">
                                            ${{ parseFloat(((item.fprice * item.fcount) + (item.addon_total_price * item.fcount)).toFixed(2)) }}
                                        </span>
                                        <span v-else>
                                            ${{ parseFloat((item.fprice * item.fcount).toFixed(2)) }}
                                        </span>
                                    </template>
                                </div>
                                <div
                                    class="weui-media-box__info__meta"
                                    v-if="is_vip && item.fprice_vip"
                                    style="float: right; color: black; padding-right: 0"
                                >
                                    <span v-if="item.addon_total_price !== undefined && item.addon_total_price">${{ ((item.fprice_vip * item.fcount) + (item.addon_total_price * item.fcount)).toFixed(2) }}</span>
                                    <span v-else>${{ (item.fprice_vip * item.fcount).toFixed(2) }}</span>
                                </div>
                                <div
                                    class="weui-media-box__info__meta"
                                    v-if="is_vip && item.fprice_vip"
                                    style="float: right; padding-right: 10px; text-decoration: line-through"
                                >
                                    <span v-if="item.addon_total_price !== undefined && item.addon_total_price">${{ ((item.fprice * item.fcount) + (item.addon_total_price * item.fcount)).toFixed(2) }}</span>
                                    <span v-else>${{ (item.fprice * item.fcount).toFixed(2) }}</span>
                                </div>
                            </div>
                            <div v-if="pickup_discount_enable && type === 'pickup'">
                                <div
                                    class="weui-media-box__info__meta"
                                    v-if="item.fprice_pickup"
                                    style="float: right; color: var(--theme_color); padding-right: 0"
                                >
                                    <template v-if="item.addon_total_price !== undefined && item.addon_total_price">
                                        <span
                                            style="margin-right: 5px; text-decoration: line-through; color: #000;"
                                            v-if="item.fprice_original"
                                        >
                                            ${{ parseFloat(((item.fprice_original * item.fcount) + (item.addon_total_price * item.fcount)).toFixed(2)) }}
                                        </span>
                                        <span
                                            style="margin-right: 5px; text-decoration: line-through; color: #000;"
                                            v-else-if="item.fprice"
                                        >
                                            ${{ parseFloat(((item.fprice * item.fcount) + (item.addon_total_price * item.fcount)).toFixed(2)) }}
                                        </span>
                                        <span>${{ parseFloat(((item.fprice_pickup * item.fcount) + (item.addon_total_price_pickup * item.fcount)).toFixed(2)) }}</span>
                                    </template>
                                    <template v-else>
                                        <span
                                            style="margin-right: 5px; text-decoration: line-through; color: #000;"
                                            v-if="item.fprice_original"
                                        >
                                            ${{ parseFloat((item.fprice_original * item.fcount).toFixed(2)) }}
                                        </span>
                                        <span
                                            style="margin-right: 5px; text-decoration: line-through; color: #000;"
                                            v-else-if="item.fprice"
                                        >
                                            ${{ parseFloat((item.fprice * item.fcount).toFixed(2)) }}
                                        </span>
                                        <span>${{ parseFloat((item.fprice_pickup * item.fcount).toFixed(2)) }}</span>
                                    </template>
                                </div>
                                <div
                                    class="weui-media-box__info__meta"
                                    v-if="!item.fprice_pickup"
                                    style="float: right; color: #000; padding-right: 0"
                                >
                                    <template v-if="item.fprice_original">
                                        <span v-if="item.addon_total_price !== undefined && item.addon_total_price">
                                            <span style="margin-right: 5px; text-decoration: line-through;">${{ parseFloat(((item.fprice_original * item.fcount) + (item.addon_total_price * item.fcount)).toFixed(2)) }}</span>
                                            <span style="color: var(--theme_color);">${{ parseFloat(((item.fprice * item.fcount) + (item.addon_total_price_pickup * item.fcount)).toFixed(2)) }}</span>
                                        </span>
                                        <template v-else>
                                            <span style="margin-right: 5px; text-decoration: line-through;">${{ parseFloat((item.fprice_original * item.fcount).toFixed(2)) }}</span>
                                            <span style="color: var(--theme_color);">${{ parseFloat((item.fprice * item.fcount).toFixed(2)) }}</span>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <span v-if="item.addon_total_price !== undefined && item.addon_total_price">
                                            <template v-if="item.addon_total_price != item.addon_total_price_pickup">
                                                <span style="margin-right: 5px; text-decoration: line-through; color: #000;">${{ parseFloat(((item.fprice * item.fcount) + (item.addon_total_price * item.fcount)).toFixed(2)) }}</span>
                                                <span style="color: var(--theme_color);">${{ parseFloat(((item.fprice * item.fcount) + (item.addon_total_price_pickup * item.fcount)).toFixed(2)) }}</span>
                                            </template>
                                            <template v-else>
                                                ${{ parseFloat(((item.fprice * item.fcount) + (item.addon_total_price_pickup * item.fcount)).toFixed(2)) }}
                                            </template>
                                        </span>
                                        <span v-else>
                                            ${{ parseFloat((item.fprice * item.fcount).toFixed(2)) }}
                                        </span>
                                    </template>
                                    <!-- <span v-if="item.addon_total_price !== undefined && item.addon_total_price">
                                        ${{ ((item.fprice * item.fcount) + (item.addon_total_price * item.fcount)).toFixed(2) }}
                                    </span>
                                    <span v-else>
                                        ${{ (item.fprice * item.fcount).toFixed(2) }}
                                    </span> -->
                                </div>
                            </div>
                            <span class="food_fcount">{{ item.fcount }}</span>
                        </div>
                        <div class="food_option" v-if="item.feature_name">
                            <!-- ({{ trans(item, 'feature_name') }}) -->
                            ({{ translate('feature_value', item.feature_name) }})
                        </div>
                        <div
                            v-for="(addon_name_item, addon_index) in item.addon_names.split('@@')"
                            v-bind:key="addon_name_item + '_item_name'"
                        >
                            <div
                                v-for="(item_name, item_index) in addon_name_item.split('|')"
                                v-bind:key="item_name + '_item_val'"
                                class="food_addon"
                            >
                                <div class="addon_count" v-if="addon_name_item">
                                    <span style="font-weight: bold">
                                        {{
                                            item.addon_counts.split('@@')[addon_index].split('|')[item_index]
                                        }}
                                    </span> *
                                </div>
                                <div class="addon_name">
                                    <!-- {{ item_name }} -->
                                    {{ translate('addon_value', item_name) }}
                                </div>
                                <template v-if="!pickup_discount_enable || type !== 'pickup'">
                                    <div class="addon_price" v-if="addon_name_item">
                                        (${{
                                            (
                                                item.addon_counts.split('@@')[addon_index].split('|')[item_index] *
                                                item.addon_prices.split('@@')[addon_index].split('|')[item_index]
                                            ).toFixed(2)
                                        }})
                                    </div>
                                </template>
                                <template v-if="pickup_discount_enable && type === 'pickup'">
                                    <div class="addon_price" v-if="addon_name_item">
                                        (
                                            <template v-if="item.addon_pickup_discounts.split('@@')[addon_index].split('|')[item_index] < 1">
                                                <span
                                                    style="margin-right: 5px; text-decoration: line-through;"
                                                >
                                                    ${{
                                                        (
                                                            item.addon_counts.split('@@')[addon_index].split('|')[item_index] *
                                                            item.addon_prices.split('@@')[addon_index].split('|')[item_index]
                                                        ).toFixed(2)
                                                    }}
                                                </span>
                                                <span style="color: var(--theme_color);">
                                                    ${{
                                                        (
                                                            item.addon_counts.split('@@')[addon_index].split('|')[item_index] *
                                                            (item.addon_prices.split('@@')[addon_index].split('|')[item_index] *
                                                                item.addon_pickup_discounts.split('@@')[addon_index].split('|')[
                                                                    item_index
                                                                ])
                                                        ).toFixed(2)
                                                    }}
                                                </span>
                                            </template>
                                            <span v-else>
                                                ${{
                                                    (
                                                        item.addon_counts.split('@@')[addon_index].split('|')[item_index] *
                                                        (item.addon_prices.split('@@')[addon_index].split('|')[item_index] *
                                                            item.addon_pickup_discounts.split('@@')[addon_index].split('|')[
                                                                item_index
                                                            ])
                                                    ).toFixed(2)
                                                }}
                                            </span>
                                        )
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div v-if="item.note" class="note">{{ $t('cart.note') }} {{ item.note }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapGetters, mapMutations, createNamespacedHelpers } from 'vuex';
    const App = createNamespacedHelpers('app');

    export default {
        name: 'yourItem',
        // props: {
        //     showPayBtn: Boolean,
        //     hideSelf: Function,
        //     showOrder: Boolean,
        //     couponShow: Function,
        //     zipCode: String
        // },
        data() {
            return {
                showOrderList: false
            };
        },
        // created() {

        //     setTimeout(this.setOrderListHeight, 3000);
        // },
        computed: {
            ...App.mapState(['type']),
            ...mapGetters({
                translate: 'app/translate',
                is_vip: 'app/get_if_is_vip',
                cart_food_list: 'app/get_cart_food_list',
                pickup_discount_enable: 'app/get_pickup_discount_enable',
            }),
        },
        // watch: {
        //     tip() {
        //         console.log('tip', this.tip);
        //         this.setOrderListHeight();
        //     }
        // },
        methods: {
            toggleOrderList(e) {
                if (this.showOrderList) {
                    this.showOrderList = false;
                    e.target.className = 'items_arrow down_arrow';
                    e.target.parentNode.nextSibling.style.display = 'none';
                } else{
                    this.showOrderList = true;
                    e.target.className = 'items_arrow';
                    e.target.parentNode.nextSibling.style.display = 'block';
                }
            },
            setOrderListHeight() {
                if (window.innerWidth > 641) {
                    let orderOffsetHeight = this.$refs.checkoutOrder.offsetHeight;
                    // console.log('orderOffsetHeight', orderOffsetHeight);
                    let customTopHeight = this.$refs.customTop.offsetHeight;
                    // console.log('customTopHeight', this.customTopHeight);
                    let fixedHeight = orderOffsetHeight - customTopHeight  - 182;
                    let orderList = this.$refs.orderList;
                    orderList.style.height = fixedHeight + 'px';
                }
            },
        }
    };
</script>

<style scoped lang="scss">
    .order_item {
        display: flex;
        align-items: flex-start;
        line-height: 25px;
        justify-content: space-between;
        font-size: 16px;
        color: #000;
        .order_text {
            font-weight: bold;
            line-height: 24px;
        }
    }

    .food_item {
        border-bottom: 1px solid #eaeaea;
        overflow: hidden;
        &:last-child {
            border-bottom: 1px dashed #979797;
        }
        .order_right {
            padding: 20px 0 20px 85px;
        }
        .order_fpic{
            width: 75px;
            padding: 20px 0;
            float: left;
            img {
                display: block;
                width: 100%;
                border-radius: 5px;
            }
        }
        .order_text {
            font-weight: 500;
            font-size: 16px;
            color: #000;
            line-height: 24px;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            max-height: 48px;
            overflow: hidden;
            word-break: break-all;
        }
        .note {
            word-break: break-all;
            padding-bottom: 10px;
        }
        .food_option {
            font-size: 14px;
            line-height: 25px;
            color: #626262;
        }
        .food_addon {
            // padding-left: 16px;
            display: flex;
            justify-content: normal;
            font-size: 14px;
            line-height: 25px;
            color: #626262;
            flex-wrap: wrap;
            .addon_name {
                margin: 0 10px;
                // max-width: 126px;
                max-width: 160px;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                max-height: 25px;
                overflow: hidden;
                word-break: break-all;
            }
        }
    }
    .order_item.order_item_inner {
        // line-height: 52px;
        line-height: 24px;
        padding: 10px 0;
        position: relative;
    }
    .order_item.order_item_inner:last-child {
        border-bottom: none;
    }

    .food_fcount {
        display: block;
        width: 36px;
        height: 36px;
        line-height: 36px;
        background-color: #d8d8d8;
        color: #000;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 4px;
    }

    .your_items{
        padding-top: 20px;
        .order_list {
            // padding: 0 16px;
            padding: 0;
            height: 500px;
            overflow-y: scroll;
        }
        .items_title {
            color: #000;
            font-weight: bold;
            // padding: 0 16px 10px;
            padding: 0 0 10px;
            font-size: 18px;
            line-height: 25px;
            display: flex;
            border-bottom: 1px solid #979797;
        }
        .items_arrow {
            display: inline-block;
            width: 28px;
            height: 26px;
            mask: url('../assets/images/arrow_top.svg') no-repeat;
            background-size: contain;
            background-color: var(--theme_color);
            background-repeat: no-repeat;
            mask-size: 28px auto;
            mask-position: left center;
            margin-left: 5px;
            cursor: pointer;
        }
        .items_arrow.down_arrow {
            mask-image: url('../assets/images/arrow_bottom.svg');
        }
        .order_right {
            padding-left: 0;
        }
    }

    @media screen and (max-width: 641px) {
        .your_items .order_list {
            height: auto;
            overflow: auto;
        }
    }
</style>
