import { render, staticRenderFns } from "./CouponLayer.vue?vue&type=template&id=1ad6a859&scoped=true&"
import script from "./CouponLayer.vue?vue&type=script&lang=js&"
export * from "./CouponLayer.vue?vue&type=script&lang=js&"
import style0 from "./CouponLayer.vue?vue&type=style&index=0&id=1ad6a859&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ad6a859",
  null
  
)

export default component.exports