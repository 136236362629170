<template>
    <div style="width: 100%">
        <div class="detail_mask" v-if="show_detail"></div>
        <div class="detail_container" style="position: absolute; margin: 0 auto" v-if="show_detail">
            <div class="detail_layer">
                <div class="detail_close" @click="close_layer"></div>
                <img style="max-width: 100vw; max-height: 90vh; display:block;" :src="recommendData['recommend_popup_pic_url']" />
            </div>
            <div class="detail-background"></div>
        </div>
    </div>
</template>
<style>
    .recommend_layer {
        width: 100% !important;
        height: 100% !important;
        min-height: 0 !important;
        max-height: 100% !important;
    }
    .detail_mask {
        height: 100%;
        width: 100%;
        opacity: 0.6;
        background: #aaa;
        position: fixed;
        top: 0vw;
        left: 0vw;
        z-index: 5;
    }
    .detail_container {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        z-index: 1000;
    }

    .detail_container .detail_close {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAMAAAANmfvwAAAAWlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////9ZMre9AAAAHXRSTlMAEs76FrJ+bMfv7Mi0qD05BvEhoGBaaVKfYVnzIyC2q60AAAEKSURBVDjLjZRZloMgFERLH444Rk3UpPa/zT7YJIFWG+uL4R7eDBypcdalSKnnUeFITSL8SJJmB9wqc561qutUmxm6uvnE2rNIndejtGA/uURK1n/Mq5pMXULu2OkuX2alPHCgh3C1nvbMcKiM/a/PFWucqGa15YOFOkNUQZOf5O1UnkefmPP8HUgCKBH7iGYcWSLmhtjb0XD2wjDuYrMxYmYGl/EJZByg2cJjPAItNUoqeIxHQLGEsIPHeAQ6yhUkbCjsbowhGHQ4dYtJsd3mJwVwy6i/ZdROGcPNEG6pq42J6by9p/CQXB01a+vJIo38gX1O8PTaj/3r/8+DSXOcqGWIzRcUD4vr2A8eSxYWvARjnwAAAABJRU5ErkJggg==');
        background-repeat: no-repeat;
        background-size: 100%;
        width: 30px;
        height: 30px;
        position: absolute;
        right: 2px;
        top: 2px;
        background-color: #000;
        border-radius: 50%;
    }
    .detail_container .detail_layer {
        position: relative;
        background-color: #fff;
        box-shadow: 0 0.013333rem 0.08rem 0 rgba(0, 0, 0, 0.1);
        box-shadow: 0 0.133333vw 0.8vw 0 rgba(0, 0, 0, 0.1);
        border-radius: 1.066667vw;
        z-index: 13;
        overflow: hidden;
    }

    .detail_container .detail_layer image {
        max-height: 250px;
        width: 100%;
    }

    .detail_container .detail_layer .food-operate {
        margin-top: 5px;
    }

    .detail_container .detail-background {
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0vw;
        left: 0vw;
    }
</style>
<script>
    import { mapState, mapActions, mapMutations } from 'vuex';

    export default {
        name: 'RecommendLayer',
        props: ['recommendData', 'closeFun'],
        data() {
            return {
                show_detail: true
            };
        },
        methods: {
            close_layer(e) {
                this.closeFun();
            }
        }
    };
</script>
