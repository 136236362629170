<template>
    <div class="pop" v-if="visible">
        <div class="pop_copy" @click="hide"></div>
        <div class="pop_main">
            <div class="pop_close" @click="hide"><span></span></div>
            <div class="coupon_layer_main">
                <div class="full_minus">
                    {{fullMinusType == "fixed" ? "$" + fullMinusItem : fullMinusItem + "%"}} OFF
                </div>
                <div class="pop_title">
                    WHEN YOU SPEND $ {{fullMinusTotal}}
                </div>
                <div class="pop_btn">
                    <mt-button type="danger" @click="go_menu">
                        ADD 
                        <span class="ft_bolder">${{fullMinusDiff}}</span> 
                        ITEMS TO GET IT!
                    </mt-button>
                    <mt-button type="danger" plain @click="go_checkout">
                        NO, CHECK OUT 
                        <span class="ft_bolder">WITHOUT </span>
                        DISC.
                    </mt-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters, createNamespacedHelpers } from 'vuex';
    const App = createNamespacedHelpers('app');

    export default {
        name: 'CouponLayer',
        components: {},
        props: {
            hideOrder: Function
        },
        data() {
            return {
                visible: false,
                fullMinusDiff: 5,
                fullMinusTotal: 30,
                fullMinusItem: 2,
                fullMinusType: 'fixed'
            };
        },
        computed: {
            ...mapState({}),
            ...App.mapGetters({}),
        },
        mounted() {},
        methods: {
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG',
            }),
            show() {
                this.visible = true;
            },
            hide() {
                this.visible = false;
            },

            go_menu() {
                // this.$router.push({
                //     name: 'menu'
                // });
                this.visible = false;
                if(window.innerWidth <= 640) {
                    this.hideOrder();
                }
                this.$router.replace({ path: '/menu' });
                // console.log('this.$router', this.$router);
            },
            go_checkout() {
                this.$router.push({
                    name: 'checkout'
                });
            }
        }
    };
</script>
<style lang="scss" scoped>
    .coupon_layer_main {
        padding-top: 20px;
        margin-bottom: 20px;
        text-align: center;
    }
    .coupon_layer_main .full_minus {
        font-size: 60px;
        color: #333;
        font-weight: bolder;
    }
    .coupon_layer_main .pop_title {
        color: #333;
        margin: 5px 0 35px;
    }
    .coupon_layer_main .ft_bolder {
        font-weight: bolder;;
    }
    .coupon_layer_main .pop_btn {
        display: flex;
        display: -webkit-flex;
        justify-content: center;
    }
    .coupon_layer_main .pop_btn button {
        width: 250px;
        height: 30px;
        font-size: 14px;
    }
    .coupon_layer_main .pop_btn button:first-child {
        margin-right: 50px;
    }
    @media screen and (max-width: 641px) {
        .coupon_layer_main .full_minus {
            font-size: 32px;
        }
        .coupon_layer_main .pop_title {
            font-size: 14px;
            margin: 3px 0 20px;
        }
        .coupon_layer_main .pop_btn button {
            width: auto;
            height: 50px;
            font-size: 13px;
        }
        .coupon_layer_main .pop_btn button:first-child {
            margin-right: 10px;
        }
    }
</style>
