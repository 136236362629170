import Vue from 'vue';
import Router from 'vue-router';
import store from './store';

import WelcomePage from './views/Welcome.vue';
import MenuPage from './views/Menu.vue';
import CheckoutPage from './views/Checkout.vue';
import ThanksPage from './views/Thanks.vue';
import FoodDetailPage from './views/FoodDetail.vue';

Vue.use(Router);

const router = new Router({
    mode: 'hash',
    linkExactActiveClass: 'is-active',
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        { path: '/detail', component: FoodDetailPage },
        { path: '/review', component: ThanksPage },
        { path: '/checkout', component: CheckoutPage, name: 'checkout' },
        { path: '/menu', component: MenuPage },
        { path: '/welcome/:sid', component: WelcomePage },
        // { path: '*', redirect: '/welcome' }
    ]
});

router.beforeEach(async (to, from, next) => {
    store.commit('app/APP_SET_IF_LOADING', { is_loading: false });
    next();
});

export default router;
