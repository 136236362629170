// initial state
const state = {
    table_info: {}
};

// getters
const getters = {};

// actions
const actions = {
    initData({ commit, rootState }, post_data) {
        // let aj_host = rootState.debug ? 'https://client.minitable.link' : '';
        // fetch(aj_host + '/weapp/user/table/pre_get?sid=' + post_data.sid + '&pre_tid=' + post_data.pre_tid, {
        //     method: 'GET'
        // })
        // .then((res)=>{
        //     return res.text()
        // })
        // .then((res)=>{
        //     res = JSON.parse(res);
        //     if (res.data.code == 100000) {
        //         let table_info = res.data.data;
        //         commit('LADING_INIT_DATA', table_info);
        //         window.localStorage.setItem('party_type', table_info[0].party_type);
        //         window.localStorage.setItem('wait_code', table_info[0].wait_code);
        //         window.localStorage.setItem('wait_rank', table_info[0].rank);
        //         commit('app/APP_SET_PARTY_DATA',{
        //             party_type: table_info[0].party_type || '',
        //             wait_code: table_info[0].wait_code || '',
        //             rank: table_info[0].rank || 0
        //         }, { root: true });
        //     } else {
        //         post_data.router.replace({ path: '/sub/thanks' });
        //     }
        // });
    }
};

// mutations
const mutations = {
    LADING_INIT_DATA(state, table_info) {
        state.table_info = table_info;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
