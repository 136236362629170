<template>
    <div class="food_detail" v-if="visible">
        <div class="check_header"></div>
        <div class="pop">
            <div class="pop_main">
                <div class="pop_close"></div>
                <div class="food_detail_content">
                    <div class="food_name">Fried Stuffed Bean Curd Paste</div>
                    <div class="food_text">
                        Thinly sliced marinated beef and mushroom, Rice, Organic Edamame, Potato Croquettes, Cabbage
                        Salad, Thinly sliced marinated beef and mushroom, Rice
                    </div>
                    <div class="food_img">
                        <img src="../assets/images/info_image.png" width="100%" />
                        <div class="food_tab">
                            <div class="old_price">
                                <span class="fs_16">$27 | $25 </span>
                                <span>for pickup</span>
                            </div>
                            <div class="new_price">
                                <span class="fs_16">$17</span>
                                <span>promo price</span>
                            </div>
                        </div>
                    </div>
                    <div class="food_level">
                        <div class="level_icon">Required</div>
                        <h4>Spicy Level</h4>
                        <div class="spicy_outer">
                            <div class="spicy_inner">
                                <div class="radio_box">
                                    <div class="radio_item">
                                        <div class="m_radio">
                                            <span class="big_circle">
                                                <b></b>
                                                <b></b>
                                            </span>
                                            <input
                                                id="spicy_level01"
                                                type="radio"
                                                name="spicy_level"
                                                value="Super Spicy"
                                                readonly
                                            />
                                            <span class="m_icon m_radio_icon"></span>
                                        </div>
                                        <label class="m_label" for="spicy_level01">Super Spicy</label>
                                    </div>
                                </div>
                                <div class="radio_box">
                                    <div class="radio_item">
                                        <div class="m_radio">
                                            <span class="big_circle">
                                                <b></b>
                                                <b></b>
                                            </span>
                                            <input
                                                id="spicy_level02"
                                                type="radio"
                                                name="spicy_level"
                                                value="Spicy"
                                                readonly
                                            />
                                            <span class="m_icon m_radio_icon"></span>
                                        </div>
                                        <label class="m_label" for="spicy_level02">Spicy</label>
                                    </div>
                                </div>
                                <div class="radio_box">
                                    <div class="radio_item">
                                        <div class="m_radio">
                                            <span class="big_circle">
                                                <b></b>
                                                <b></b>
                                            </span>
                                            <input
                                                id="spicy_level03"
                                                type="radio"
                                                name="spicy_level"
                                                value="Mild"
                                                readonly
                                            />
                                            <span class="m_icon m_radio_icon"></span>
                                        </div>
                                        <label class="m_label" for="spicy_level03">Mild</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="food_side">
                        <h4>
                            Add-on Extra Side
                            <span>( Choose one item)</span>
                        </h4>
                        <div class="side_inner">
                            <div class="side_item">
                                <div class="radio_box">
                                    <div class="radio_item">
                                        <div class="m_radio">
                                            <span class="big_circle">
                                                <b></b>
                                                <b></b>
                                            </span>
                                            <input
                                                id="choose_time02"
                                                type="radio"
                                                name="choose_time"
                                                value="Make a schedule"
                                                readonly
                                            />
                                            <span class="m_icon m_radio_icon"></span>
                                        </div>
                                        <label class="m_label" for="choose_time02">Make a schedule</label>
                                    </div>
                                </div>
                                <div class="side_price">$0.65</div>
                            </div>
                            <div class="side_item">
                                <div class="radio_box">
                                    <div class="radio_item">
                                        <div class="m_radio">
                                            <span class="big_circle">
                                                <b></b>
                                                <b></b>
                                            </span>
                                            <input
                                                id="choose_time02"
                                                type="radio"
                                                name="choose_time"
                                                value="Make a schedule"
                                                readonly
                                            />
                                            <span class="m_icon m_radio_icon"></span>
                                        </div>
                                        <label class="m_label" for="choose_time02">Make a schedule</label>
                                    </div>
                                </div>
                                <div class="side_price">$0.65</div>
                            </div>
                            <div class="side_item">
                                <div class="radio_box">
                                    <div class="radio_item">
                                        <div class="m_radio">
                                            <span class="big_circle">
                                                <b></b>
                                                <b></b>
                                            </span>
                                            <input
                                                id="choose_time02"
                                                type="radio"
                                                name="choose_time"
                                                value="Make a schedule"
                                                readonly
                                            />
                                            <span class="m_icon m_radio_icon"></span>
                                        </div>
                                        <label class="m_label" for="choose_time02">Make a schedule</label>
                                    </div>
                                </div>
                                <div class="side_price">$0.65</div>
                            </div>
                        </div>
                    </div>
                    <div class="food_special">
                        <h4>Special instructions</h4>
                        <div class="special_info">
                            <textarea
                                placeholder="Dressing on the side? No pickles? Let us know here."
                                rows="2"
                                cols="40"
                            ></textarea>
                        </div>
                    </div>
                    <div class="food_quantity">
                        <h4>Quantity</h4>
                        <div class="quantity_num">
                            <button class="quantity_num_button">-</button>
                            <div class="quantity_num_input">
                                <input class="" type="number" value="1" />
                            </div>
                            <button class="quantity_num_button">+</button>
                        </div>
                        <div class="quantity_btn">
                            <button class="">
                                <span>Add to cart</span>
                                <span>$10.99</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .food_detail_content h4 {
        font-size: 16px;
        line-height: 34px;
    }
    .food_detail_content h4 span {
        font-size: 13px;
    }
    .food_name {
        font-size: 21px;
        line-height: 34px;
    }
    .food_text {
        font-size: 12px;
        line-height: 16px;
        text-align: left;
        margin: 5px 0 20px;
    }
    .food_img {
        position: relative;
        max-height: 258px;
        overflow: hidden;
        margin-bottom: 20px;
    }
    .food_tab {
        position: absolute;
        width: 100%;
        height: 32px;
        line-height: 32px;
        left: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.5);
        display: flex;
    }
    .food_tab span {
        color: #6f7070;
        font-size: 12px;
    }
    .food_tab .fs_16 {
        font-size: 16px;
    }
    .old_price {
        margin-right: 20px;
        text-decoration: line-through;
    }
    .new_price span {
        color: #d63239;
    }
    .food_level,
    .food_side {
        padding: 10px 7px;
        background-color: #f7f7f7;
        border-radius: 4px;
        position: relative;
        margin-bottom: 10px;
        box-sizing: border-box;
    }
    .level_icon {
        position: absolute;
        width: 58px;
        padding-right: 3px;
        height: 18px;
        right: 0px;
        top: 7px;
        background-image: url('../assets/images/required_icon.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        color: #fff;
        line-height: 18px;
        text-align: right;
        font-size: 12px;
    }
    .special_info {
        margin-top: 8px;
    }
    .special_info textarea {
        display: block;
        width: 100%;
        height: 112px;
        padding: 10px 5px;
        border: 1px solid #b1b1b1;
        box-sizing: border-box;
        border-radius: 4px;
        font-size: 15px;
    }
    .food_quantity {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }
    .quantity_btn button {
        border: none;
        display: flex;
        justify-content: space-between;
        padding: 8px 16px;
        max-width: 50%;
        border-radius: 4px;
        color: #fff;
        font-size: 16px;
        min-width: 180px;
        background-color: var(--theme_color);
    }
    .quantity_num {
        width: 79px;
        height: 27px;
        border: 1px solid #707070;
        border-radius: 14px;
        box-sizing: border-box;
        line-height: 25px;
        display: inline-flex;
        justify-content: center;
        -webkit-box-pack: center;
        align-items: center;
        -webkit-box-align: center;
    }
    .quantity_num_button {
        background-color: #fff;
        color: #707070;
        width: 16px;
        border: none;
        cursor: pointer;
        -webkit-appearance: button;
        text-transform: none;
        margin: 0;
        font-size: 16px;
        line-height: 16px;
        box-sizing: border-box;
    }
    .order_item_number .order_custom_input {
        display: inline-flex;
    }
    .quantity_num_input {
        display: flex;
        align-items: center;
    }
    .quantity_num_input input {
        width: 24px;
        border: 0;
        font-size: 14px;
        color: #707070;
        text-align: center;
    }
    /* .spicy_outer {
    position: relative;
    height: 40px;
} */
    .spicy_inner {
        display: flex;
        font-size: 12px;
    }
    .spicy_inner .radio_box {
        margin-right: 16px;
    }
    .side_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
    }

    .radio_box {
        display: flex;
        display: -webkit-flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
    }
    .radio_item {
        display: flex;
        height: 44px;
        line-height: 44px;
        padding-left: 44px;
        position: relative;
    }
    .m_radio {
        width: 44px;
        height: 44px;
        padding: 0;
        border-radius: 50%;
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
    }
    .m_radio .big_circle {
        width: 44px;
        height: 44px;
        position: absolute;
        border-radius: 50%;
        top: 0;
        left: 0;
        cursor: pointer;
    }
    .big_circle b {
        width: 20%;
        height: 20%;
        border-radius: 50%;
        display: block;
        background-color: rgba(255, 255, 255, 0.6);
        position: absolute;
        left: 40%;
        top: 40%;
        z-index: 1;
    }
    .m_radio input {
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        z-index: 3;
    }
    .m_icon {
        display: block;
        width: 22px;
        height: 22px;
        position: absolute;
        box-sizing: content-box;
        top: 11px;
        left: 0;
    }
    .m_radio_icon {
        border: 1px solid #707070;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background-color: #fff;
    }
    .m_radio .m_radio_icon {
        top: 11px;
        left: 11px;
        z-index: 2;
    }
    .m_icon::before,
    .m_icon::after {
        content: '';
        display: block;
        position: absolute;
    }
    .m_radio_icon::after {
        width: 14px;
        height: 14px;
        background-color: none;
        top: 4px;
        left: 4px;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        z-index: 2;
    }
    .m_radio input:checked + span.m_icon::after {
        background-color: var(--theme_color);
    }

    .food_detail_content .radio_item {
        height: 24px;
        line-height: 24px;
        padding-left: 24px;
    }
    .food_detail_content .m_radio {
        width: 24px;
        height: 24px;
    }
    .food_detail_content .m_radio .big_circle {
        width: 24px;
        height: 24px;
    }
    .food_detail_content .m_radio .m_radio_icon {
        top: 5px;
        left: 5px;
        width: 12px;
        height: 12px;
    }
    .food_detail_content .m_radio_icon::after {
        width: 8px;
        height: 8px;
        top: 2px;
        left: 2px;
    }

    @media screen and (max-width: 641px) {
        .food_detail_content h4 {
            font-size: 0.875rem;
        }
        .food_name {
            font-size: 1.125rem;
        }
        .quantity_btn button {
            padding: 0.25rem 0.5rem;
            font-size: 0.875rem;
            min-width: 9.5rem;
        }
        .special_info textarea {
            font-size: 0.75rem;
        }
        .quantity_num {
            width: 4.5rem;
            height: 1.5rem;
            line-height: 1.375rem;
        }
        .quantity_num_button {
            width: 0.875rem;
            font-size: 0.875rem;
        }
        .quantity_num_input input {
            background-color: transparent;
        }
    }
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    export default {
        name: 'foodDetail',
        components: {},
        data() {
            return {
                visible: false
            };
        },
        watch: {},
        created() {},
        mounted() {},
        computed: {
            ...mapGetters({})
        },
        methods: {
            ...mapActions({}),
            ...mapMutations({}),
            show() {
                this.visible = true;
            },
            hide() {
                this.visible = false;
            }
        }
    };
</script>
