<template>
    <div class="pop" v-if="visible">
        <div class="pop_copy" @click="hide"></div>
        <div class="pop_main">
            <div class="pop_main_scroll" ref="detailScroll">
                <div class="name_fixed">{{ translate('item', detail_data.fname) }}</div>
                <div class="pop_close detail_close" @click="hide" data-bee="module_id=button&item_id=hide_food_detail">
                    <span></span>
                </div>
                <div class="food_detail_content" ref="detailContent">
                    <div :class="detail_data.fpic ? 'food_img' : 'food_img no_img'">
                        <!-- <img src="../assets/images/info_image.png" width="100%" /> -->
                        <img v-if="detail_data.fpic" :src="detail_data.fpic" :style="{width: imgWidth}" />
                        <!-- <img v-else src="../assets/images/placeholder_web.png" :style="{width: imgWidth}" /> -->
                        <div class="img_div" v-else></div>
                        <div class="food_tab">
                            <div
                                v-if="detail_data.once_limit > 0"
                                class="header_desc limit"
                            >
                                limit {{ detail_data.once_limit }} per order
                            </div>
                            <div
                                class="header_desc left"
                                v-if="
                                    detail_data.daily_limit > 0 &&
                                    detail_data.daily_limit - detail_data.daily_sales_count > 0
                                "
                            >
                                {{ detail_data.daily_limit - detail_data.daily_sales_count + ' left today' }}
                            </div>
                        </div>
                    </div>
                    <div class="food_detail_info">
                        <div class="food_info_top">
                            <div class="food_name">
                                <!-- {{ trans(detail_data, 'fname') }} -->
                                {{ translate('item', detail_data.fname) }}
                                <span class="header_required" v-if="detail_data.is_must == 1"
                                    >{{ $t('common.required') }}
                                </span>
                            </div>
                            <div class="tips">
                                <!-- <div
                                    class="header_desc"
                                    style="
                                        display: inline-block;
                                        color: #000;
                                        background-color: #ddd;
                                        border-radius: 8px;
                                        padding: 0 5px;
                                    "
                                >
                                    {{ $t('menu.totalAdded') }} {{ mix_fcount }}
                                </div> -->
                                <div class="tips_item tbd" v-if="detail_data.is_tbd">TBD</div>
                                <div class="tips_item" v-else>
                                    <div class="food_price" v-if="detail_data.fprice_original == ''">
                                        <span>${{ detail_data.fprice }}</span>
                                    </div>
                                    <div class="food_price" v-if="detail_data.fprice_original">
                                        <span class="old_price">
                                            ${{ detail_data.fprice_original }}
                                        </span>
                                        <span class="theme_color">
                                            ${{ detail_data.fprice }}
                                        </span>
                                        <span class="theme_color">promo price</span>
                                    </div>
                                    <div class="pickup_price" v-if="pickup_discount_enable && detail_data.fprice_pickup">
                                        <span class="theme_color">
                                            ${{ detail_data.fprice_pickup }}
                                        </span>
                                        <span class="theme_color">for pickup</span>
                                    </div>
                                </div>
                            </div>
                            <div class="food_tag">
                                <div class="food_tag_leaf" v-if="detail_data.feature_tag">
                                    {{ transTag(detail_data.feature_tag, 'feature') }}
                                </div>
                                <div class="food_tag_warning" v-if="detail_data.allergy_tag">
                                    {{ $t('menu.foodTag', {food_tags: transTag(detail_data.allergy_tag, 'allergy') }) }}
                                </div>
                            </div>
                            <div class="food_text" v-html="translate('food_desc', detail_data.fdesc)">
                                <!-- {{ trans(detail_data, 'fdesc') }} -->
                                <!-- {{ translate('food_desc', detail_data.fdesc) }} -->
                            </div>
                            <div class="food_line" ref="foodLine"></div>
                        </div>
                        <div class="food_level" v-if="detail_data.feature_default_value">
                            <!-- <div class="level_icon">{{ $t('common.required') }}</div> -->
                            <!-- <h4>{{ trans(detail_data, 'feature_name') }}</h4> -->
                            <h4 style="color: #000;">
                                {{ translate('feature_name', detail_data.feature_name) }}
                                <span>({{ $t('common.required') }})</span>
                            </h4>
                            <div class="spicy_outer">
                                <div class="spicy_inner">
                                    <div class="radio_box" v-for="(option_item, index) in option_list" :key="index">
                                        <div class="radio_item">
                                            <input
                                                :id="'spicy_level' + index"
                                                type="radio"
                                                name="feature_index"
                                                :value="option_item"
                                                v-model="feature_name"
                                            />
                                            <span class="checkbox_bg"></span>
                                            <!-- <label class="m_label" :for="'spicy_level' + index">{{ option_item }}</label> -->
                                            <label class="m_label" :for="'spicy_level' + index">{{ translate('feature_value', option_item) }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="food_side" v-for="(item, index) in addon_list" :key="item.addon_id">
                            <!-- <div class="level_icon" v-if="item.addon_is_required == '1'">
                                {{ $t('common.required') }}
                            </div>
                            <div class="level_icon" v-else>
                                {{ $t('common.optional') }}
                            </div>
                            <div
                                class="level_discount"
                                v-if="pickup_discount_enable && item.pickup_discount < 1"
                            >
                                <span>({{ item.pickup_discount * 100 + '% for pickup' }})</span>
                            </div> -->
                            <h4>
                                <!-- Add-on Extra Side 去掉这个addon/加码字样 -->
                                <!-- <div style="color: #000;">{{ $t('common.addon') }}-</div> -->
                                <!-- {{ item.addon_name }} -->
                                <div style="color: #000;">
                                    {{ translate('addon_name', item.addon_name) }}
                                    <span class="" v-if="item.addon_is_required == '1'">
                                        ({{ $t('common.required') }})
                                    </span>
                                    <span class="" v-else>
                                        ({{ $t('common.optional') }})
                                    </span>
                                </div>
                                <div style="color: #7e7e7e;">
                                    <span>{{ item.addon_choose_num_text }}</span>
                                    <span v-if="item.addon_choose_num_text && (item.total_item_count > 0 || item.max_item_count > 0 || item.min_item_count > 0)">, </span>
                                    <span class="item_title_extra" v-if="item.total_item_count > 0">
                                        {{ $t('menu.total_item_count', {
                                            addon_num: item.total_item_count
                                        }) }}
                                        <!-- {{ 'select ' + item.total_item_count + ' items' }} -->
                                    </span>
                                    <span class="item_title_extra" v-else-if="item.max_item_count > 0 && item.min_item_count > 0">
                                        {{ $t('menu.selectMinToMax', {
                                            addon_max: item.max_item_count,
                                            addon_min: item.min_item_count
                                        }) }}
                                        <!-- {{ '(select ' + item.min_item_count + '-' + item.max_item_count + ' items)' }} -->
                                    </span>
                                    <span class="item_title_extra" v-else-if="item.max_item_count > 0 && item.min_item_count == 0">
                                        {{ $t('menu.selectMax', {
                                            addon_max: item.max_item_count
                                        }) }}
                                        <!-- {{ '(select up to ' + item.max_item_count + ' items)' }} -->
                                    </span>
                                    <span class="item_title_extra" v-else-if="item.min_item_count > 0 && item.max_item_count == 0">
                                        {{ $t('menu.selectMin', {
                                            addon_min: item.min_item_count
                                        }) }}
                                        <!-- {{ '(select at least ' + item.min_item_count + ' items)' }} -->
                                    </span>
                                </div>
                                <div
                                    class="level_discount"
                                    v-if="pickup_discount_enable && item.pickup_discount < 1"
                                >
                                    <span>{{ 100 - item.pickup_discount * 100 + '% Off Pickup' }}</span>
                                </div>
                            </h4>
                            <div class="side_inner">
                                <div class="side_item" v-for="(addon_item, index2) in item.items" v-bind:key="index2">
                                    <div class="addon_info" v-if="item.addon_available_number == 1">
                                        <div class="radio_box">
                                            <div class="radio_item">
                                                <input
                                                    :id="'choose_time_' + index + '_' + index2"
                                                    type="checkbox"
                                                    name="choose_time"
                                                    :disabled="addon_item.availability == 0"
                                                    @click="choose_addon_one(addon_item, $event, item, index2)"
                                                    value="0"
                                                    :style="addon_count_dict[item.addon_id + '_' + index2] > 0 ? 'z-index: -1;' : ''"
                                                />
                                                
                                                <span class="checkbox_bg is_add" :style="addon_count_dict[item.addon_id + '_' + index2] > 0 ? 'display: none' : ''"></span>
                                                <div class="">
                                                    <PlusMinus
                                                        style="margin-right: 10px"
                                                        class="side_count"
                                                        :plusCb="() => change_addon_count(addon_item, item, 'add', index2)"
                                                        :minusCb="() => change_addon_count(addon_item, item, 'minus', index2, index)"
                                                        :count="addon_count_dict[item.addon_id + '_' + index2]"
                                                        v-if="
                                                            addon_count_dict[item.addon_id + '_' + index2] > 0
                                                        "
                                                    />
                                                </div>
                                                <label class="m_label" :for="'choose_time_' + index + '_' + index2"
                                                    >{{ translate('addon_value', addon_item.name)
                                                    }}{{ addon_item.availability == 1 ? '' : $t('menu.soldOut') }}</label
                                                >
                                            </div>
                                        </div>
                                        <div class="side_price">
                                            <template v-if="addon_count_dict[item.addon_id + '_' + index2] > 0">
                                                <span >+</span>
                                                ${{ parseFloat((addon_item.price * addon_count_dict[item.addon_id + '_' + index2]).toFixed(2)) }}
                                                <span class="side_price_per">${{ addon_item.price }} ea</span>
                                            </template>
                                            <template v-else>
                                                ${{ addon_item.price }}
                                            </template>
                                        </div>
                                    </div>
                                    <div class="addon_info" v-else>
                                        <div class="radio_box">
                                            <div class="radio_item">
                                                <!-- <input
                                                    :id="'choose_time_' + index + '_' + index2"
                                                    type="checkbox"
                                                    name="choose_time"
                                                    :disabled="addon_item.availability == 0"
                                                    @click="choose_addon_one(addon_item, $event, item, index2)"
                                                    value="0"
                                                /> -->
                                                <template v-if="item.multiple_choice == 1">
                                                    <input
                                                        :id="'choose_time_' + index + '_' + index2"
                                                        type="checkbox"
                                                        name="choose_time"
                                                        :disabled="addon_item.availability == 0"
                                                        @click="choose_addon(addon_item, $event, item, index2)"
                                                        value="0"
                                                        :style="addon_count_dict[item.addon_id + '_' + index2] > 0 ? 'z-index: -1;' : ''"
                                                    />
                                                </template>
                                                <template v-else>
                                                    <input
                                                        :id="'choose_time_' + index + '_' + index2"
                                                        type="checkbox"
                                                        name="choose_time"
                                                        :disabled="addon_item.availability == 0"
                                                        @click="choose_addon(addon_item, $event, item, index2)"
                                                        value="0"
                                                    />
                                                </template>
                                                <template v-if="item.multiple_choice == 1">
                                                    <span v-if="addon_count_dict[item.addon_id + '_' + index2] > 0" class="checkbox_bg is_add" style="display: none"></span>
                                                    <span v-else class="checkbox_bg is_add"></span>
                                                </template>
                                                <!-- <span v-if="item.multiple_choice == 1" class="checkbox_bg is_add"></span> -->
                                                <span v-else class="checkbox_bg"></span>
                                                <div class="">
                                                    <PlusMinus
                                                        style="margin-right: 10px"
                                                        class="side_count"
                                                        :plusCb="() => change_addon_count(addon_item, item, 'add', index2)"
                                                        :minusCb="() => change_addon_count(addon_item, item, 'minus', index2, index)"
                                                        :count="addon_count_dict[item.addon_id + '_' + index2]"
                                                        v-if="
                                                            item.multiple_choice == 1 && addon_count_dict[item.addon_id + '_' + index2] > 0
                                                        "
                                                    />
                                                </div>
                                                <label class="m_label" :for="'choose_time_' + index + '_' + index2"
                                                    >{{ translate('addon_value', addon_item.name)
                                                    }}{{ addon_item.availability == 1 ? '' : $t('menu.soldOut') }}</label
                                                >
                                            </div>
                                        </div>
                                        <div class="side_price">
                                            <template v-if="addon_count_dict[item.addon_id + '_' + index2] > 0">
                                                <span >+</span>
                                                ${{ parseFloat((addon_item.price * addon_count_dict[item.addon_id + '_' + index2]).toFixed(2)) }}
                                                <span class="side_price_per">${{ addon_item.price }} ea</span>
                                            </template>
                                            <template v-else>
                                                ${{ addon_item.price }}
                                            </template>
                                        </div>
                                    </div>
                                    <!-- <div class="addon_info">
                                        <div class="radio_box">
                                            <div class="radio_item">
                                                <input
                                                    v-if="item.addon_available_number == 1"
                                                    :id="'choose_time_' + index + '_' + index2"
                                                    type="checkbox"
                                                    name="choose_time"
                                                    :disabled="addon_item.availability == 0"
                                                    @click="choose_addon_one(addon_item, $event, item, index2)"
                                                    value="0"
                                                />
                                                <input
                                                    v-else
                                                    :id="'choose_time_' + index + '_' + index2"
                                                    type="checkbox"
                                                    name="choose_time"
                                                    :disabled="addon_item.availability == 0"
                                                    @click="choose_addon(addon_item, $event, item, index2)"
                                                    value="0"
                                                />
                                                <span v-if="item.multiple_choice == 1" class="checkbox_bg is_add"></span>
                                                <span v-else class="checkbox_bg"></span>
                                                <label class="m_label" :for="'choose_time_' + index + '_' + index2"
                                                    >{{ translate('addon_value', addon_item.name)
                                                    }}{{ addon_item.availability == 1 ? '' : $t('menu.soldOut') }}</label
                                                >
                                            </div>
                                        </div>
                                        <div class="side_price">
                                            <template v-if="addon_count_dict[item.addon_id + '_' + index2] > 0">
                                                <span >+</span>
                                                ${{ parseFloat((addon_item.price * addon_count_dict[item.addon_id + '_' + index2]).toFixed(2)) }}
                                            </template>
                                            <template v-else>
                                                ${{ addon_item.price }}
                                            </template>
                                        </div>
                                    </div> -->
                                    <!-- <PlusMinus
                                        style="margin: 0 0 20px 0px"
                                        class="side_count"
                                        :plusCb="() => change_addon_count(addon_item, item, 'add', index2)"
                                        :minusCb="() => change_addon_count(addon_item, item, 'minus', index2, index)"
                                        :count="addon_count_dict[item.addon_id + '_' + index2]"
                                        v-if="
                                            item.multiple_choice == 1 && addon_count_dict[item.addon_id + '_' + index2] > 0
                                        "
                                    /> -->
                                </div>
                            </div>
                        </div>
                        <div class="food_special">
                            <h4>{{ $t('menu.specialInstructions') }}</h4>
                            <div class="special_info">
                                <textarea
                                    :placeholder="$t('common.letUsKnow')"
                                    rows="2"
                                    cols="40"
                                    v-model="note"
                                ></textarea>
                            </div>
                        </div>
                        <div class="food_quantity">
                            <!-- <h4>{{ $t('menu.quantity') }}</h4> -->
                            <PlusMinus
                                class="food_count"
                                :plusCb="() => change_food_count('add')"
                                :minusCb="() => change_food_count('minus')"
                                :count="choose_count"
                            />
                            <div class="quantity_btn">
                                <button class="" @click="do_save" data-bee="module_id=button&item_id=add_x_to_cart">
                                    <span>{{ $t('cart.addToCart', { num: choose_count }) }}</span>
                                    <span class="quantity_price">${{ total_price }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .tbd {
        font-size: 16px;
    }
    .pop_copy {
        width: 100%;
        height: 100%;
        position: absolute;
    }
    .pop .pop_main {
        max-height: 96%;
        padding: 0;
        overflow: hidden;
        max-width: 600px;
        // padding: 0.625rem 0;
        // overflow-y: scroll;
    }
    .pop_main_scroll {
        max-height: 96vh;
        overflow-y: scroll;
        // padding: 0 20px;
        box-sizing: border-box;
        scrollbar-width: none; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */
    }
    .pop_main_scroll::-webkit-scrollbar {
        display: none; /* Chrome Safari */
    }
    .name_fixed {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        background-color: #fff;
        line-height: 70px;
        padding: 0 65px;
        height: 70px;
        width: 100%;
        box-sizing: border-box;
        box-shadow: 0px 6px 14px rgba(0,0,0, 0.15);
        font-size: 24px;
        color: #000;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        font-weight: bold;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        word-break: break-all;
        display: none;
    }
    .header_required {
        background: #000;
        padding: 5px;
        border-radius: 5px;
        color: #fff;
        display: inline-block;
        font-size: 14px;
        height: 15px;
        line-height: 15px;
        margin-left: 10px;
    }
    // .plus_minus button {
    //     font-size: 14px;
    // }
    // .food_detail_content .plus_minus button {
    //     width: 22px;
    //     height: 22px;
    //     line-height: 20px;
    //     font-size: 18px;
    //     border-color: #2f2f2f;
    //     color: #2f2f2f;
    //     background-color: #fff;
    // }
    .tips {
        margin-bottom: 20px;
        .tips_item {
            font-size: 22px;
            display: flex;
            color: #000;
            font-weight: 500;
        }
        .theme_color {
            color: var(--theme_color);;
        }
        .header_desc {
            margin-right: 10px;
            line-height: 20px;
            color: #262626;
            font-family: UberMoveText-Regular, Helvetica, sans-serif;
            -webkit-font-smoothing: antialiased;
            font-size: 14px;
            word-break: break-all;
        }
    }
    .pop .pop_close.detail_close {
        top: 12px;
        left: 12px;
    }
    // .pop .pop_close {
    //     cursor: pointer;
    //     width: 48px;
    //     height: 48px;
    //     right: auto;
    //     left: 21px;
    //     top: 27px;
    //     z-index: 3;
    //     background: none;
    //     mask: none;
    //     background-color: #fff;
    //     border-radius: 50%;
    // }
    // .pop .pop_close span {
    //     display: block;
    //     position: absolute;
    //     top: 30%;
    //     left: 30%;
    //     width: 40%;
    //     height: 40%;
    //     mask: url('../assets/images/close.svg') no-repeat;
    //     background-size: contain;
    //     mask-size: 100%;
    //     background-repeat: no-repeat;
    //     background-color: var(--theme_color);
    // }
    .food_detail_content {
        // padding-top: 0.875rem;
        padding-top: 0;
    }
    .food_detail_content h4 {
        font-size: 18px;
        color: #000;
        line-height: 1.3;
        font-weight: 500;
        margin-bottom: 10px;
        padding-right: 172px;
    }
    .food_detail_content h4 span {
        font-size: 13px;
    }
    .food_detail_info {
        padding: 0 0.625rem 0.625rem;
    }
    .food_name {
        font-size: 26px;
        font-weight: bold;
        line-height: 34px;
        display: flex;
        align-items: center;
        color: #262626;
        margin-bottom: 10px;
        word-break: break-all;
    }
    .food_text {
        font-size: 18px;
        line-height: 24px;
        text-align: left;
        margin: 10px 0 20px;
    }
    .food_img {
        position: relative;
        // max-height: 465px;
        max-height: 450px;
        overflow: hidden;
        margin-bottom: 20px;
        min-height: 32px;
        &.no_img {
            overflow: visible;
            .food_tab {
                bottom: -8px;
            }
        }
        .img_div {
            height: 60px;
        }
    }
    .food_img img {
        display: block;
        width: 100%;
        // height: 465px;
        height: 450px;
        margin: 0 auto;
    }
    .food_tag {
        display: flex;
        // padding: 0 10px;
        box-sizing: border-box;
        justify-content: left;
        flex-wrap: wrap;
        .food_tag_leaf, .food_tag_warning  {
            padding-left: 32px;
            background-repeat: no-repeat;
            background-size: auto 22px;
            background-position: 0 3px;
            font-size: 18px;
            line-height: 28px;
            margin-right: 10px;
            word-break: break-word;
        }
        .food_tag_leaf {
            background-image: url('../assets/images/leaf.svg');
            color: #419F5A;
        }
        .food_tag_warning {
            background-image: url('../assets/images/warning.svg');
            color: #E90112;
        }
    }
    .food_tab {
        position: absolute;
        width: 100%;
        height: 32px;
        line-height: 32px;
        left: 0;
        bottom: 10px;
        // background-color: rgba(255, 255, 255, 0.65);
        display: flex;
        // padding: 0 10px;
        box-sizing: border-box;
        justify-content: right;
        .header_desc {
            margin-right: 25px;
            height: 28px;
            line-height: 28px;
            color: #262626;
            font-family: UberMoveText-Regular, Helvetica, sans-serif;
            -webkit-font-smoothing: antialiased;
            font-size: 18px;
            word-break: break-all;
            display: inline-block;
            border-radius: 8px;
            padding: 0 10px;
            border: 1px solid var(--theme_color);
        }
        .header_desc.limit {
            color: #fff;
            background-color: var(--theme_color);
        }
        .header_desc.left {
            color: var(--theme_color);
            background-color: #fff;
        }
    }
    .food_tab span {
        color: #6f7070;
        font-size: 12px;
        margin-right: 5px;
    }
    .food_tab .fs_16 {
        font-size: 18px;
    }
    .food_price {
        margin-right: 30px;
    }
    .old_price {
        margin-right: 30px;
        text-decoration: line-through;
    }
    .new_price {
        span {
            color: black !important;
        }
    }
    .pickup_price {
        // margin-left: 12px;
        span {
            // color: #d63239 !important;
            color: var(--theme_color);
        }
    }
    .food_line {
        width: 100%;
        height: 1px;
        background-color: #d8d8d8;
        margin-bottom: 30px;
    }
    .food_level,
    .food_side {
        // padding: 10px 7px;
        // background-color: #f7f7f7;
        // border-radius: 4px;
        position: relative;
        margin-bottom: 20px;
        box-sizing: border-box;
        h4 {
            background-color: #f7f7f7;
            border-radius: 8px;
            padding: 10px;
            margin-bottom: 20px;
            font-size: 18px;
            font-weight: 500;
            span {
                font-size: 16px;
                font-weight: normal;
            }
        }
    }
    .level_icon {
        position: absolute;
        width: 58px;
        padding-right: 3px;
        height: 18px;
        right: 0px;
        top: 7px;
        background-image: url('../assets/images/required_icon.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        color: #fff;
        line-height: 18px;
        text-align: right;
        font-size: 12px;
    }
    .level_discount {
        // position: absolute;
        // width: 108px;
        // padding-right: 3px;
        // height: 18px;
        // right: 68px;
        // top: 7px;
        // background-color: #26a2ff;
        // border-radius: 9px;
        // text-align: center;
        // color: #fff;
        line-height: 18px;
        font-size: 12px;
        color: var(--theme_color);
    }
    .food_special {
        margin-bottom: 100px;
    }
    .special_info textarea {
        display: block;
        width: 100%;
        height: 120px;
        padding: 10px 5px;
        // border: 1px solid #b1b1b1;
        border: none;
        background-color: #f7f7f7;
        color: #626262;
        box-sizing: border-box;
        border-radius: 8px;
        font-size: 15px;
    }
    .food_quantity {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        // background-color: #fff;
        border-radius: 0px 0px 25px 25px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 1) 29%, rgba(255, 255, 255, 1) 72%, rgba(255, 255, 255, 1) 100%);
        // margin-top: 20px;
        padding: 28px 20px;
        box-sizing: border-box;
        h4 {
            margin-bottom: 0;
            padding-right: 0;
        }
        .food_count {
            min-width: 120px;
            height: 43px;
            // border: 1px solid #707070;
            // border-radius: 14px;
            box-sizing: border-box;
            line-height: 25px;
            display: inline-flex;
            justify-content: center;
            -webkit-box-pack: center;
            align-items: center;
            -webkit-box-align: center;
            ::v-deep .number_button {
                width: 43px;
                height: 43px;
                line-height: 43px;
            }
            ::v-deep .number{
                font-size: 24px;
                color: #000;
                margin: 0 15px;
            }
        }
        .quantity_price {
            font-size: 18px;
            position: absolute;
            right: 16px;
            top: 0;
        }
    }
    .quantity_btn button {
        border: none;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        padding: 0 16px;
        max-width: 50%;
        border-radius: 10px;
        color: #fff;
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        min-width: 420px;
        background-color: var(--theme_color);
        position: relative;
    }
    .quantity_num {
        width: 79px;
        height: 27px;
        border: 1px solid #707070;
        border-radius: 14px;
        box-sizing: border-box;
        line-height: 25px;
        display: inline-flex;
        justify-content: center;
        -webkit-box-pack: center;
        align-items: center;
        -webkit-box-align: center;
    }
    .quantity_num_button {
        background-color: #fff;
        color: #707070;
        width: 16px;
        border: none;
        cursor: pointer;
        -webkit-appearance: button;
        text-transform: none;
        margin: 0;
        font-size: 16px;
        line-height: 16px;
        box-sizing: border-box;
    }
    .order_item_number .order_custom_input {
        display: inline-flex;
    }
    .quantity_num_input {
        display: flex;
        align-items: center;
    }
    .quantity_num_input input {
        width: 24px;
        border: 0;
        font-size: 14px;
        color: #707070;
        text-align: center;
    }
    /* .spicy_outer {
    position: relative;
    height: 40px;
} */
    .side_inner {
        padding: 0 10px;
        .side_count {
            ::v-deep .number_button {
                width: 21px;
                height: 21px;
                line-height: 21px;
                border-radius: 0;
            }
            ::v-deep  button.minus_btn {
                background-image: url(../assets/images/minus_square.svg);
            }
            ::v-deep  button.plus_btn {
                background-image: url(../assets/images/plus_square.svg);
            }
        }
    }
    .spicy_inner {
        // display: flex;
        font-size: 12px;
        padding: 0 10px;
        color: #000;
    }
    .spicy_inner .radio_box {
        // margin-right: 16px;
        margin-bottom: 20px;
    }
    .spicy_inner .radio_box:last-child {
        margin-bottom: 0px;
    }
    .side_item {
        color: #000;
        .addon_info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            margin-bottom: 20px;
        }
    }

    .radio_box {
        display: flex;
        display: -webkit-flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
    }
    .radio_item {
        display: flex;
        height: 44px;
        line-height: 44px;
        padding-left: 0;
        position: relative;
        align-items: center;
        font-size: 16px;
        input {
            width: 21px;
            height: 21px;
            margin-right: 10px;
            min-width: 21px;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            &:checked + span.checkbox_bg {
                background-image: url('../assets/images/checkbox_cur.svg');
            }
            &:checked + span.checkbox_bg.is_add {
                background-image: url('../assets/images/checkbox_cur.svg');
            }
        }
        span.checkbox_bg {
            display: block;
            width: 21px;
            height: 21px;
            margin-right: 10px;
            min-width: 21px;
            background-image: url('../assets/images/checkbox.svg');
            background-repeat: no-repeat;
            background-size: 100% auto;
        }
        span.checkbox_bg.is_add {
            background-image: url('../assets/images/checkbox_add.svg');
        }
    }
    .m_radio {
        width: 44px;
        height: 44px;
        padding: 0;
        border-radius: 50%;
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
    }
    .m_radio .big_circle {
        width: 44px;
        height: 44px;
        position: absolute;
        border-radius: 50%;
        top: 0;
        left: 0;
        cursor: pointer;
    }
    .big_circle b {
        width: 20%;
        height: 20%;
        border-radius: 50%;
        display: block;
        background-color: rgba(255, 255, 255, 0.6);
        position: absolute;
        left: 40%;
        top: 40%;
        z-index: 1;
    }
    .m_icon {
        display: block;
        width: 22px;
        height: 22px;
        position: absolute;
        box-sizing: content-box;
        top: 11px;
        left: 0;
    }
    .m_radio_icon {
        border: 1px solid #707070;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background-color: #fff;
    }
    .m_radio .m_radio_icon {
        top: 11px;
        left: 11px;
        z-index: 2;
    }
    .m_icon::before,
    .m_icon::after {
        content: '';
        display: block;
        position: absolute;
    }
    .m_radio_icon::after {
        width: 14px;
        height: 14px;
        background-color: none;
        top: 4px;
        left: 4px;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        z-index: 2;
    }
    .m_radio input:checked + span.m_icon::after {
        background-color: var(--theme_color);
    }

    .food_detail_content .radio_item {
        // height: 24px;
        height: auto;
        line-height: 1.3;
        // padding-left: 24px;
    }
    .food_detail_content .m_radio {
        width: 24px;
        height: 24px;
    }
    .food_detail_content .m_radio .big_circle {
        width: 24px;
        height: 24px;
    }
    .food_detail_content .m_radio .m_radio_icon {
        top: 5px;
        left: 5px;
        width: 12px;
        height: 12px;
    }
    .food_detail_content .m_radio_icon::after {
        width: 8px;
        height: 8px;
        top: 2px;
        left: 2px;
    }
    .side_price {
        min-width: 68px;
        text-align: right;
        position: relative;
    }
    .side_price_per {
        position: absolute;
        right: 0;
        bottom: -13px;
        color: #626262;
        font-size: 12px;
    }
    @media screen and (max-width: 800px) {
        .pop .pop_main {
            max-width: 512px;
        }
        // .pop_main_scroll {
        //     padding: 0 15px;
        // }
        .food_img {
            max-height: 362px;
            img {
                height: 362px;
            }
        }
    }
    @media screen and (max-width: 641px) {
        .pop .pop_main {
            max-width: 333px;
            padding-bottom: 2.625rem;
            width: 90%;
            max-height: 86%;
        }
        .pop_main_scroll {
            max-height: 86vh;
        }
        // .pop .pop_close {
        //     width: 32px;
        //     height: 32px;
        //     top: 18px;
        //     left: 18px;
        // }
        .name_fixed {
            height: 56px;
            line-height: 56px;
            font-size: 16px;
            padding: 0 50px;
        }
        .food_img {
            max-height: 260px;
            &.no_img .food_tab {
                bottom: -20px;
            }
            img {
                height: 260px;
            }
            .img_div {
                height: 30px;
            }
        }
        .food_tab {
            bottom: 0;
        }
        .food_tab .header_desc {
            height: 24px;
            line-height: 24px;
            font-size: 14px;
            margin-right: 12px;
        }
        .food_special {
            margin-bottom: 140px;
        }
        .tips {
            margin-bottom: 15px;
            .tips_item {
                font-size: 1.125rem;
            }
        }
        .food_detail_content h4 {
            font-size: 1rem;
            padding-right: 118px;
        }
        .level_discount {
            right: 0;
            top: 40px;
        }
        .food_name {
            font-size: 1.25rem;
        }
        .food_tag .food_tag_leaf, .food_tag .food_tag_warning {
            background-size: auto 18px;
            background-position: 0 4px;
            font-size: .875rem;
            line-height: 26px;
            padding-left: 28px;
        }
        .food_text {
            font-size: .875rem;
        }
        .food_quantity {
            flex-wrap: wrap;
            justify-content: center;
            // padding-bottom: 2rem;
            // position: relative;
            // margin-bottom: 0.75rem;
            h4 {
                padding-right: 0;
                margin-right: .75rem;
                font-weight: bold;
            }
            .food_count {
                margin-bottom: 10px;
                ::v-deep .number_button {
                    width: 38px;
                    height: 38px;
                    line-height: 38px;
                }
                ::v-deep .number{
                    font-size: 18px;
                }
            }
        }
        .quantity_btn {
            // margin-top: .75rem;
            // position: absolute;
            // width: 100%;
            // padding: 0 10px;
            // left: 0;
            // bottom: 0.625rem;
            width: 100%;
            .quantity_price {
                font-size: 0.875rem;
                // top: 0.75rem;
                right: 0.75rem;
            }
        }
        .quantity_btn button {
            padding: 0 0.75rem;
            font-size: 1rem;
            height: 2.5rem;
            line-height: 2.5rem;
            // min-width: 8.25rem;
            min-width: 100%;
            width: 100%;
            margin: 0 auto;
            box-sizing: border-box;
        }
        .special_info textarea {
            font-size: 0.75rem;
            height: 80px;
        }
        .quantity_num {
            width: 4.5rem;
            height: 1.5rem;
            line-height: 1.375rem;
        }
        .quantity_num_button {
            width: 0.875rem;
            font-size: 0.875rem;
        }
        .quantity_num_input input {
            background-color: transparent;
        }
        .side_item .addon_info,
        .spicy_inner {
            font-size: 0.875rem;
        }
        .side_inner {
            padding: 0 10px;
            .side_count {
                ::v-deep .number_button {
                    width: 17px;
                    height: 17px;
                    line-height: 17px;
                }
            }
        }
        .radio_item {
            // font-size: 13px;
            font-size: .875rem;
            input {
                width: 18px;
                height: 18px;
                margin-right: 5px;
                min-width: 18px;
            }
            span.checkbox_bg {
                width: 18px;
                height: 18px;
                margin-right: 5px;
                min-width: 18px;
            }
        }
        .food_level h4 span, .food_side h4 span {
            font-size: .875rem;
        }
        // .addon_info .radio_item {
        //     height: auto;
        // }
        // .addon_info .radio_item input {
        //     min-width: 18px;
        // }
    }
    @media screen and (max-width: 370px) {
        .pop .pop_main {
            max-width: 260px;
        }
        .food_img {
            max-height: 180px;
            img {
                height: 180px;
            }
        }
    }
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters, createNamespacedHelpers } from 'vuex';
    import PlusMinus from '@/components/Plus_Minus';
    const FoodStore = createNamespacedHelpers('Food');

    export default {
        name: 'foodDetail',
        components: {
            PlusMinus
        },
        data() {
            return {
                note: '',
                addon_chosen_count: 0,
                addon_total_price_pickup: 0,
                addon_total_price: 0,
                choose_count: 1,
                addon_dict: {},
                visible: false,
                addon_count_dict: {},
                feature_name: '',
                imgWidth: '100%'
            };
        },
        watch: {
            choose_count() {
                // this.btn_choose_count = this.choose_count;
                let _price =
                    this.is_vip && this.detail_data.fprice_vip ? this.detail_data.fprice_vip : this.detail_data.fprice;
                this.total_price = (
                    this.choose_count *
                    (parseFloat(_price) + parseFloat(this.addon_total_price))
                ).toFixed(2);
            },
            addon_chosen_count() {
                // this.btn_choose_count = this.choose_count;
                let _price =
                    this.is_vip && this.detail_data.fprice_vip ? this.detail_data.fprice_vip : this.detail_data.fprice;
                this.total_price = (
                    this.choose_count *
                    (parseFloat(_price) + parseFloat(this.addon_total_price))
                ).toFixed(2);
            },
            visible() {
                if (this.visible) {
                    this.$nextTick(() => {
                        this.$refs.detailScroll.addEventListener('scroll', this.detailContentScroll);
                        // console.log('querySelector', this.$refs);
                    });
                } else {
                    this.$refs.detailScroll.removeEventListener('scroll', this.detailContentScroll);
                }
            }
        },
        created() {},
        mounted() {
            this.feature_name = this.detail_data.feature_default_value || '';
            // console.log('detail_data', this.detail_data.fpic);
            if (this.detail_data.fpic) {
                let imgTemporary = new Image();
                imgTemporary.src = this.detail_data.fpic;
                if (imgTemporary.width < imgTemporary.height) {

                    let oHeight = 465;
                    let oWidth = oHeight * (imgTemporary.width / imgTemporary.height);
                    let ratio = oWidth / 620;
                    this.imgWidth = ratio * 100 + '%';
                    // console.log('foodPic', this.imgWidth);
                }
            }
        },
        computed: {
            ...mapGetters({
                translate: 'app/translate',
                is_vip: 'app/get_if_is_vip',
                pickup_discount_enable: 'app/get_pickup_discount_enable',
                language: 'app/get_language',
            }),
            ...FoodStore.mapState(['detail_data', 'pageOpen', 'mix_fcount']),
            // resetData() {
            //     this.$data = {
            //         ...this.$data,
            //         addon_chosen_count: 0,
            //         addon_total_price_pickup: 0,
            //         addon_total_price: 0,
            //         choose_count: 1,
            //         addon_dict: {},
            //         addon_count_dict: {}
            //     };
            // },
            option_list() {
                const list = this.detail_data.feature_values.split(',');
                const options = [];
                list.forEach((item) => {
                    options.push(this.translate('feature_value', item));
                });
                return list;
                // return this.transNames({ obj: this.detail_data, key: 'feature_values', split: ',' });
            },
            addon_list() {
                let addon_data = this.detail_data.addon_data;
                if (addon_data && addon_data.length > 0) {
                    let addon_arr_data = [];
                    for (let i = 0; i < addon_data.length; i++) {
                        let pickup_discount = addon_data[i].pickup_discount;
                        let total_item_count = addon_data[i].total_item_count;
                        let max_item_count = addon_data[i].max_item_count;
                        let min_item_count = addon_data[i].min_item_count;
                        let addon_names = addon_data[i].addon_values_name.split('|');
                        // let addon_names_chn = addon_data[i].addon_values_name_chn.split('|');
                        // let addon_names = this.transNames({ obj: addon_data[i], key: 'addon_values_name', split: '|' });
                        let addon_prices = addon_data[i].addon_values_price.split('|');
                        let addon_availabilitys = addon_data[i].addon_values_availability.split('|');
                        let addon_one_item = [];
                        for (let i = 0, len = addon_names.length; i < len; i++) {
                            addon_one_item.push({
                                name_eng: addon_names[i],
                                name: this.translate('addon_value', addon_names[i]),
                                // name: addon_names[i],
                                price: addon_prices[i] || '0.00',
                                pickup_discount: pickup_discount,
                                availability: addon_availabilitys[i]
                            });
                        }

                        let temp_addon_choose_num = '';
                        let _num = addon_data[i].addon_available_number;
                        // temp_addon_choose_num = 'Choose 1';
                        temp_addon_choose_num = this.$t('menu.addon_type_available');

                        if (_num == 'all') {
                            // _num = addon_names.length;
                            temp_addon_choose_num = '';
                        }

                        if (parseInt(_num) > 1) {
                            // temp_addon_choose_num = 'Choose 1 - ' + _num + ' types';
                            temp_addon_choose_num = this.$t('menu.addon_type_availables', { num: _num });
                        }

                        addon_arr_data.push({
                            addon_available_number: addon_data[i].addon_available_number,
                            addon_choose_num_text: temp_addon_choose_num,
                            addon_id: addon_data[i].addon_id,
                            addon_is_required: addon_data[i].addon_is_required,
                            // addon_name: this.translate('addon_name', addon_data[i].addon_name),
                            // addon_name: this.trans(addon_data[i], 'addon_name'),
                            addon_name: addon_data[i].addon_name,
                            // addon_name_chn: addon_data[i].addon_name_chn || addon_data[i].addon_name,
                            items: addon_one_item,
                            pickup_discount: pickup_discount,
                            multiple_choice: addon_data[i].multiple_choice,
                            total_item_count: total_item_count,
                            max_item_count: max_item_count,
                            min_item_count: min_item_count
                        });
                    }

                    return addon_arr_data;
                }

                return [];
            },
            total_price: {
                get() {
                    let _price =
                        this.is_vip && this.detail_data.fprice_vip
                            ? this.detail_data.fprice_vip
                            : this.detail_data.fprice;
                    return (this.choose_count * (parseFloat(_price) + parseFloat(this.addon_total_price))).toFixed(2);
                },
                set() {}
            }
        },
        methods: {
            ...mapActions({
                edit_food: 'app/edit_cart_food'
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
            do_save() {
                let _food_data = JSON.parse(JSON.stringify(this.detail_data));
                let addon_names = [];
                let addon_names_eng = [];
                let addon_prices = [];
                let addon_counts = [];
                let addon_pickup_discounts = [];

                if (_food_data.once_limit > 0 && this.mix_fcount + this.choose_count * 1 > _food_data.once_limit) {
                    this.set_msg({
                        msg: this.$t('cart.exceedOrder')
                    });
                    return;
                }

                if (
                    _food_data.daily_limit > 0 &&
                    this.mix_fcount + this.choose_count * 1 > _food_data.daily_limit - _food_data.daily_sales_count
                ) {
                    this.set_msg({
                        msg: this.$t('cart.exceedDay')
                    });
                    return;
                }

                for (let o in this.addon_dict) {
                    if (this.addon_dict[o] && this.addon_dict[o].length > 0) {
                        let items = this.addon_dict[o];
                        let one_addon_names = [];
                        let one_addon_names_eng = [];
                        let one_addon_prices = [];
                        let one_addon_counts = [];
                        let one_addon_discounts = [];

                        for (let i = 0; i < items.length; i++) {
                            one_addon_names.push(items[i].item_name);
                            one_addon_names_eng.push(items[i].item_name_eng);
                            one_addon_prices.push(items[i].item_price);
                            one_addon_counts.push(this.addon_count_dict[o + '_' + items[i].item_index]);
                            one_addon_discounts.push(items[i].pickup_discount);
                        }

                        addon_names.push(one_addon_names.join('|'));
                        addon_names_eng.push(one_addon_names_eng.join('|'));
                        addon_prices.push(one_addon_prices.join('|'));
                        addon_counts.push(one_addon_counts.join('|'));
                        addon_pickup_discounts.push(one_addon_discounts.join('|'));
                    }
                }

                let false_flag = false;
                for (let i = 0; i < this.addon_list.length; i++) {
                    // console.log('addon_list[i]', this.addon_list[i]);
                    if (this.addon_list[i].addon_is_required == 1) {
                        if (
                            !this.addon_dict[this.addon_list[i].addon_id] ||
                            (this.addon_dict[this.addon_list[i].addon_id] &&
                                this.addon_dict[this.addon_list[i].addon_id].length == 0)
                        ) {
                            this.set_msg({
                                msg: this.$t('menu.chooseAddon')
                            });
                            false_flag = true;
                            break;
                        }
                    }

                    if (this.addon_list[i].total_item_count > 0) {
                        let choose_addons = this.addon_dict[this.addon_list[i].addon_id] || [];
                        let count = 0;

                        choose_addons.map((item) => {
                            count = count + this.addon_count_dict[this.addon_list[i].addon_id + '_' + item.item_index];
                        });

                        if ((this.addon_list[i].addon_is_required == 1 || this.addon_list[i].addon_is_required == 0 && count != 0) && count != this.addon_list[i].total_item_count) {
                            // this.set_msg({
                            //     msg:
                            //         '[' +
                            //         this.addon_list[i].addon_name +
                            //         ']Please choose ' +
                            //         this.addon_list[i].total_item_count +
                            //         ' addons'
                            // });
                            this.set_msg({
                                msg: this.$t('menu.chooseAddons', {
                                    addon_name: this.addon_list[i].addon_name,
                                    addon_total: this.addon_list[i].total_item_count
                                })
                            });
                            false_flag = true;
                            break;
                        }
                    } else if (this.addon_list[i].max_item_count > 0 || this.addon_list[i].min_item_count > 0) {
                        let choose_addons = this.addon_dict[this.addon_list[i].addon_id] || [];
                        let count = 0;

                        choose_addons.map((item) => {
                            count = count + this.addon_count_dict[this.addon_list[i].addon_id + '_' + item.item_index];
                        });

                        if (this.addon_list[i].addon_is_required == 1 || this.addon_list[i].addon_is_required == 0 && count != 0) {
                            if (this.addon_list[i].max_item_count > 0 && count > this.addon_list[i].max_item_count) {
                                this.set_msg({
                                    msg: this.$t('menu.maxMsg', {
                                        addon_name: this.addon_list[i].addon_name,
                                        addon_max: this.addon_list[i].max_item_count
                                    })
                                });
                                false_flag = true;
                                break;
                            }

                            if (this.addon_list[i].min_item_count > 0 && count < this.addon_list[i].min_item_count) {
                                this.set_msg({
                                    msg: this.$t('menu.minMsg', {
                                        addon_name: this.addon_list[i].addon_name,
                                        addon_min: this.addon_list[i].min_item_count
                                    })
                                });
                                false_flag = true;
                                break;
                            }
                            
                        }
                    }
                }

                if (false_flag) {
                    return;
                }

                _food_data.fcount = this.choose_count * 1;
                _food_data.fprice_vip = _food_data.fprice_vip ? _food_data.fprice_vip : '';
                _food_data.fprice_pickup =
                    this.pickup_discount_enable && _food_data.fprice_pickup ? _food_data.fprice_pickup : '';

                let save_data = {
                    cartid: this.$store.state.app.shared_cart_id,
                    tid: this.$store.state.app.shared_tid,
                    uid: this.$store.state.app.email,
                    uname: this.$store.state.app.uname,
                    router: this.$router,
                    type: 'add',
                    is_vip: this.is_vip,
                    language: this.language,

                    food_data: _food_data,
                    extra_data: {
                        feature_name: this.feature_name,
                        note: this.note,
                        // addon_names: addon_names.join('@@'),
                        addon_names: addon_names_eng.join('@@'),
                        // addon_names_eng: addon_names_eng.join('@@'),
                        addon_prices: addon_prices.join('@@'),
                        addon_counts: addon_counts.join('@@'),
                        addon_pickup_discounts: addon_pickup_discounts.join('@@'),
                        addon_total_price: this.addon_total_price,
                        addon_total_price_pickup: this.addon_total_price_pickup,
                        add_count: this.choose_count * 1,
                        once_limit: _food_data.once_limit,
                        daily_limit: _food_data.daily_limit
                    }
                };

                setTimeout(async () => {
                    await this.edit_food(save_data);
                    this.visible = false;
                }, 500);
            },
            change_food_count(type) {
                if (type == 'add') {
                    let _food_data = JSON.parse(JSON.stringify(this.detail_data));
                    if (
                        _food_data.once_limit > 0 &&
                        this.mix_fcount + parseInt(this.choose_count) + 1 > _food_data.once_limit
                    ) {
                        this.set_msg({
                            msg: this.$t('cart.exceedOrder')
                        });
                        return;
                    }
                    if (
                        _food_data.daily_limit > 0 &&
                        this.mix_fcount + parseInt(this.choose_count) + 1 >
                            _food_data.daily_limit - _food_data.daily_sales_count
                    ) {
                        this.set_msg({
                            msg: this.$t('cart.exceedDay')
                        });
                        return;
                    }
                    this.choose_count = this.choose_count * 1 + 1;
                } else if (type == 'minus' && this.choose_count > 1) {
                    this.choose_count = this.choose_count * 1 - 1;
                }
            },
            show() {
                this.visible = true;
                // this.resetData();
            },
            hide() {
                this.visible = false;
            },
            change_addon_count(addon_item, item, type, index, index_old) {
                let now_value = this.addon_count_dict[item.addon_id + '_' + index];

                if (type === 'minus' && now_value == 1) {
                    let inputEle = document.getElementById('choose_time_' + index_old + '_' + index);
                    inputEle.click();
                    return;
                    // this.choose_addon(addon_item, inputEle, item, index);
                }

                if (type === 'minus' && now_value > 1) {
                    this.$set(this.addon_count_dict, item.addon_id + '_' + index, now_value - 1);
                    this.addon_total_price = (
                        parseFloat(this.addon_total_price) - parseFloat(addon_item.price)
                    ).toFixed(2);
                    this.addon_total_price_pickup = (
                        parseFloat(this.addon_total_price_pickup) - parseFloat(addon_item.price * item.pickup_discount)
                    ).toFixed(2);
                }

                if (type === 'add') {
                    this.$set(this.addon_count_dict, item.addon_id + '_' + index, now_value + 1);
                    this.addon_total_price = (
                        parseFloat(this.addon_total_price) + parseFloat(addon_item.price)
                    ).toFixed(2);
                    this.addon_total_price_pickup = (
                        parseFloat(this.addon_total_price_pickup) + parseFloat(addon_item.price * item.pickup_discount)
                    ).toFixed(2);
                }
            },
            choose_addon(addon_item, e, addon_all_data, index) {
                if (
                    e.target.checked &&
                    addon_all_data.addon_available_number != 'all' &&
                    this.addon_dict[addon_all_data.addon_id] &&
                    this.addon_dict[addon_all_data.addon_id].length >= parseInt(addon_all_data.addon_available_number)
                ) {
                    e.preventDefault();
                    return;
                }

                if (e.target.checked) {
                    if (!this.addon_dict[addon_all_data.addon_id]) {
                        this.addon_dict[addon_all_data.addon_id] = [];
                        this.addon_count_dict[addon_all_data.addon_id + '_dict'] = {};
                    }
                    this.$set(this.addon_count_dict, addon_all_data.addon_id + '_' + index, 1);

                    this.addon_dict[addon_all_data.addon_id].push({
                        // item_name: this.trans(addon_item, 'name'),
                        // item_name: this.translate('addon_value', addon_item).name,
                        item_name: addon_item.name,
                        item_name_eng: addon_item.name_eng,
                        item_index: index,
                        item_price: addon_item.price,
                        pickup_discount: addon_all_data.pickup_discount
                    });
                    this.addon_total_price = (
                        parseFloat(this.addon_total_price) + parseFloat(addon_item.price)
                    ).toFixed(2);
                    this.addon_total_price_pickup = (
                        parseFloat(this.addon_total_price_pickup) +
                        parseFloat(addon_item.price * addon_all_data.pickup_discount)
                    ).toFixed(2);
                    this.addon_chosen_count = this.addon_chosen_count + 1;
                } else {
                    let now_count = this.addon_count_dict[addon_all_data.addon_id + '_' + index];
                    let addon_item_list = JSON.parse(JSON.stringify(this.addon_dict[addon_all_data.addon_id]));
                    for (let i = 0; i < addon_item_list.length; i++) {
                        if (addon_item_list[i].item_name === addon_item.name) {
                            addon_item_list.splice(i, 1);
                            this.$set(this.addon_count_dict, addon_all_data.addon_id + '_' + index, 0);
                            delete this.addon_count_dict[addon_all_data.addon_id + '_' + index];
                        }
                    }
                    this.addon_dict[addon_all_data.addon_id] = addon_item_list;
                    this.addon_total_price = (
                        parseFloat(this.addon_total_price) -
                        now_count * parseFloat(addon_item.price)
                    ).toFixed(2);
                    this.addon_total_price_pickup = (
                        parseFloat(this.addon_total_price_pickup) -
                        now_count * parseFloat(addon_item.price * addon_all_data.pickup_discount)
                    ).toFixed(2);
                    this.addon_chosen_count = this.addon_chosen_count - 1;
                }
            },
            choose_addon_one(addon_item, e, addon_all_data, index) {

                // addon_all_data.addon_available_number == 1 才会执行这个函数

                // if (
                //     e.target.checked &&
                //     addon_all_data.addon_available_number != 'all' &&
                //     this.addon_dict[addon_all_data.addon_id] &&
                //     this.addon_dict[addon_all_data.addon_id].length >= parseInt(addon_all_data.addon_available_number)
                // ) {
                //     e.preventDefault();
                //     return;
                // }

                // console.log('this.addon_dict[addon_all_data.addon_id]', this.addon_dict[addon_all_data.addon_id]);

                if (e.target.checked) {
                    if (!this.addon_dict[addon_all_data.addon_id]) {
                        this.addon_dict[addon_all_data.addon_id] = [];
                        this.addon_count_dict[addon_all_data.addon_id + '_dict'] = {};
                    } else {
                        let input_item = this.addon_dict[addon_all_data.addon_id][0];
                        const input_index = input_item.item_index;
                        let input_id = e.target.id;
                        input_id = input_id.substring(0, input_id.length - 1);
                        input_id = input_id + input_index;
                        let input_el = document.getElementById(input_id);
                        input_el.checked = false;

                        let now_count = this.addon_count_dict[addon_all_data.addon_id + '_' + input_index];
                        let addon_item_list = JSON.parse(JSON.stringify(this.addon_dict[addon_all_data.addon_id]));

                        addon_item_list.splice(0, 1);
                        this.$set(this.addon_count_dict, addon_all_data.addon_id + '_' + input_index, 0);
                        delete this.addon_count_dict[addon_all_data.addon_id + '_' + input_index];

                        this.addon_dict[addon_all_data.addon_id] = addon_item_list;
                        this.addon_total_price = (
                            parseFloat(this.addon_total_price) -
                            now_count * parseFloat(input_item.item_price)
                        ).toFixed(2);
                        this.addon_total_price_pickup = (
                            parseFloat(this.addon_total_price_pickup) -
                            now_count * parseFloat(input_item.item_price * input_item.pickup_discount)
                        ).toFixed(2);
                        this.addon_chosen_count = this.addon_chosen_count - 1;

                    }
                    this.$set(this.addon_count_dict, addon_all_data.addon_id + '_' + index, 1);

                    this.addon_dict[addon_all_data.addon_id].push({
                        // item_name: this.trans(addon_item, 'name'),
                        // item_name: this.translate('addon_value', addon_item).name,
                        item_name: addon_item.name,
                        item_name_eng: addon_item.name_eng,
                        item_index: index,
                        item_price: addon_item.price,
                        pickup_discount: addon_all_data.pickup_discount
                    });
                    this.addon_total_price = (
                        parseFloat(this.addon_total_price) + parseFloat(addon_item.price)
                    ).toFixed(2);
                    this.addon_total_price_pickup = (
                        parseFloat(this.addon_total_price_pickup) +
                        parseFloat(addon_item.price * addon_all_data.pickup_discount)
                    ).toFixed(2);
                    this.addon_chosen_count = this.addon_chosen_count + 1;
                } else {
                    let now_count = this.addon_count_dict[addon_all_data.addon_id + '_' + index];
                    let addon_item_list = JSON.parse(JSON.stringify(this.addon_dict[addon_all_data.addon_id]));
                    for (let i = 0; i < addon_item_list.length; i++) {
                        if (addon_item_list[i].item_name === addon_item.name) {
                            addon_item_list.splice(i, 1);
                            this.$set(this.addon_count_dict, addon_all_data.addon_id + '_' + index, 0);
                            delete this.addon_count_dict[addon_all_data.addon_id + '_' + index];
                        }
                    }
                    // this.addon_dict[addon_all_data.addon_id] = addon_item_list;
                    this.addon_dict[addon_all_data.addon_id] = undefined;
                    this.addon_total_price = (
                        parseFloat(this.addon_total_price) -
                        now_count * parseFloat(addon_item.price)
                    ).toFixed(2);
                    this.addon_total_price_pickup = (
                        parseFloat(this.addon_total_price_pickup) -
                        now_count * parseFloat(addon_item.price * addon_all_data.pickup_discount)
                    ).toFixed(2);
                    this.addon_chosen_count = this.addon_chosen_count - 1;
                }
            },
            transTag(str, type) {
                let newStr = '';
                let arr = str ? str.split(',') : [];
                if (type === 'allergy') {
                    for (let i = 0; i < arr.length; i++) {
                        newStr += this.$t('allergy.' + arr[i]) + ', ';
                    }
                } else if (type === 'feature') {
                    for (let i = 0; i < arr.length; i++) {
                        newStr += this.$t('feature.' + arr[i]) + ', ';
                    }
                }
                
                newStr = newStr.substr(0, newStr.length - 2);
                return newStr;
            },
            detailContentScroll(event) {
                // console.log('event', event);
                // let eleHeight = event.target.offsetHeight;
                let eleScrollTop = event.target.scrollTop;
                let imgHeight = event.target.lastChild.firstChild.offsetHeight;
                let lineHeight = event.target.lastChild.lastChild.firstChild.offsetHeight;
                let totalHeight = imgHeight + lineHeight + 20;
                if(eleScrollTop > totalHeight) {
                    event.target.firstChild.style = 'display: -webkit-box';
                } else {
                    event.target.firstChild.style = 'display: none';
                }
            }
        }
    };
</script>
