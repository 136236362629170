// initial state
const state = {
    foods: [],
    order_data: {},
    stel: '',
    is_loading: true,
    shared_tname: '',
    user_count: 0,
    total_price: 0
};

// getters
const getters = {};

// actions
const actions = {
    initData({ commit, rootState }, oid) {
        let aj_host = rootState.debug ? 'https://takeout.minitable.link' : '';
        fetch(aj_host + '/weapp/takeout/detail/get?oid=' + oid, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                let res_data = JSON.parse(res).data;

                if (res_data.code === 100000) {
                    let food_list = res_data.data.foods;
                    let user_count = 0;
                    let total_price = 0;
                    let user_dict = {};

                    for (let i = 0; i < food_list.length; i++) {
                        total_price = total_price + food_list[i].count * food_list[i].fprice;
                        if (food_list[i].ouser_name && !user_dict[food_list[i].ouser_name]) {
                            user_count = user_count + 1;
                            user_dict[food_list[i].ouser_name] = true;
                        }
                    }

                    commit('INIT_ORDERDETAIL_DATA', {
                        foods: res_data.data.foods,
                        order_data: res_data.data.others,
                        shared_tname: res_data.data.shared_tname,
                        total_price: total_price.toFixed(2),
                        user_count: user_count
                    });
                }
            });
    }
};

// mutations
const mutations = {
    INIT_ORDERDETAIL_DATA(state, order_detail_data) {
        state.foods = order_detail_data.foods;
        state.order_data = order_detail_data.order_data;
        state.is_loading = false;
        state.shared_tname = order_detail_data.shared_tname;
        state.total_price = order_detail_data.total_price;
        state.user_count = order_detail_data.user_count;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
