<template>
    <div class="menu_body">
        <div class="menu_loading" v-if="is_loading_menu">
            <div class="menu_header">
                <div class="header_bg"></div>
                <div class="menu_header_info">
                    <h3>Store Name</h3>
                    <div class="order_type">
                        <div class="order_type_main"></div>
                    </div>
                </div>
            </div>
            <div class="menu_main">
                <div class="menu_section">
                    <div class="category_zone"></div>
                    <div class="menu-container-outter">
                        <div class="menu-container-inner">
                            <div class="category_container">
                                <div class="product_container">
                                    <div class="product_item">
                                        <div class="product_outter">
                                            <div class="product_inner">
                                                <div class="product_info"></div>
                                                <div class="product_img"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="product_item">
                                        <div class="product_outter">
                                            <div class="product_inner">
                                                <div class="product_info"></div>
                                                <div class="product_img"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="menu_header" ref="menuHeader">
            <!-- <div class="white_bg"></div> -->
            <div class="header_bg" :style="{ backgroundImage: feature_image }">
                <div class="opt_item">
                    <span class="opt_icon" @click="showLang">
                        <span></span>
                    </span>
                    <span class="go_back" @click="goBack"></span>
                </div>
            </div>
            <div class="menu_header_info">
                <h3>{{ sname }}</h3>
                <p class="header_info_address" v-if="saddress">
                    <span>{{ saddress }}</span>
                </p>
                <p class="header_info_phone" v-if="sphone">
                    <span>{{ sphone }}</span>
                </p>
                <p class="header_info_desc" v-if="store_data.show_store_desc_enable" v-html="store_data.sdesc"></p>
                <div class="order_type">
                    <div class="pick-type" :class="{ noDelivery: delivery_setting.enable != 1 }">
                        <div
                            class="pick-type-item"
                            @click="choosePickType('dineIn')"
                            v-if="canDineIn"
                            :class="{ active: orderType === 'dineIn' }"
                        >
                            {{ $t('checkout.dineIn') }}
                        </div>
                        <div
                            class="pick-type-item"
                            :class="{ active: orderType === 'pickup' }"
                            @click="choosePickType('pickup')"
                            v-if="pickup_setting.enable == 1"
                        >
                            {{ $t('checkout.pickup') }}
                        </div>
                        <div
                            class="pick-type-item"
                            @click="choosePickType('delivery')"
                            :class="{ active: orderType === 'delivery' }"
                            v-if="deliveryEnable"
                        >
                            {{ $t('checkout.delivery') }}
                        </div>
                    </div>
                    <div class="order_type_main" v-if="orderType === 'dineIn' && dinein_setting && (dinein_setting.policy || dinein_setting.enable_table_select == '1')">
                        <div class="pickup_box dinein_box" ref="dineinPolicy" v-if="dinein_setting && dinein_setting.policy">
                            <div class="order_type_title">{{ $t('menu.notes') }}</div>
                            <div
                                class="pickup_content"
                                @click="showPolicyLayer('dinein')"
                                v-html="dinein_setting.policy"
                            >
                                <!-- {{dinein_setting.policy}} -->
                            </div>
                        </div>
                        <div class="dinein_table" v-if="dinein_setting && dinein_setting.enable_table_select == '1'">
                            <div>{{ $t('menu.tableSelection') }}</div>
                            <div class="tableBox" v-if="dineInTable">
                                <div class="tableName" @click="chooseTable">
                                    {{ dineInTable }}
                                </div>
                                <div class="tableEdit" @click="chooseTable">{{ $t('menu.edit') }}</div>
                            </div>
                            <div v-else class="tableEdit" @click="chooseTable">{{ $t('menu.edit') }}</div>
                        </div>
                    </div>
                    <div class="order_type_main" v-if="orderType === 'pickup' && pickup_setting && pickup_setting.policy">
                        <div class="pickup_box" ref="pickupPolicy">
                            <div class="order_type_title">{{ $t('menu.notes') }}</div>
                            <div
                                class="pickup_content"
                                @click="showPolicyLayer('pickup')"
                                v-html="pickup_setting.policy"
                            >
                                <!-- {{pickup_setting.policy}} -->
                            </div>
                        </div>
                    </div>
                    <div class="order_type_main" v-if="orderType === 'delivery' && delivery_setting.enable == 1 && !this.special_store[this.$store.state.app.sid]">
                        <div class="zip_code"
                            ref="zipCodeBox"
                        >
                            <span class="order_type_title">{{ $t('menu.checkArea') }}</span>
                            <div class="zip_code_input">
                                <input placeholder="Zip Code" v-model="zipCode" />
                                <button @click="checkCode"></button>
                            </div>
                            <div class="zip_msg zip_msg_err" v-if="zipMsg === 'error'">{{ $t('menu.notDeliver') }}</div>
                            <div class="zip_msg zip_msg_succ" v-if="zipMsg === 'success'">{{ $t('menu.canDeliver') }}</div>
                        </div>
                    </div>
                </div>
                <!-- 优惠标签 -->
                <div class="promo_tag">
                    <template v-if="promotion_data.first_discount_amount">
                        <div
                            class="promo_item promo_vector"
                            v-if="promotion_data.first_discount_type === 'fixed'"
                            @click="showDiscountLayer('first', promotion_data.first_discount_amount, promotion_data.first_discount_limit_amount, 'fixed')"
                        >
                            {{ $t('menu.fixedDiscountNew', { price: promotion_data.first_discount_amount }) }}
                        </div>
                        <div
                            v-else
                            class="promo_item promo_teeny"
                            @click="showDiscountLayer('first', promotion_data.first_discount_amount, promotion_data.first_discount_limit_amount, 'bysubtotal')"
                        >
                            {{ $t('menu.percentDiscountNew', { price: language == 'chn' ? (100 - promotion_data.first_discount_amount * 100) / 10 : promotion_data.first_discount_amount * 100 }) }}
                        </div>
                    </template>
                    <template v-if="promotion_data.full_coupon.length > 0">
                        <template v-for="(coupon_item, index) in promotion_data.full_coupon">
                            <div
                                v-if="coupon_item.type && coupon_item.type === 'bysubtotal'"
                                class="promo_item promo_teeny"
                                @click="showDiscountLayer('full', coupon_item.minus, coupon_item.full, 'bysubtotal')"
                                :key=index
                            >
                                {{ $t('menu.percentDiscount', { price: language == 'chn' ? (100 - coupon_item.minus) / 10 : coupon_item.minus, total: coupon_item.full }) }}
                            </div>
                            <div
                                class="promo_item promo_vector"
                                v-else
                                @click="showDiscountLayer('full', coupon_item.minus, coupon_item.full, 'fixed')"
                                :key=index
                            >
                                {{ $t('menu.fixedDiscount', { price: coupon_item.minus, total: coupon_item.full }) }}
                            </div>
                        </template>
                    </template>
                    <!-- <div class="promo_item promo_food">Free Food</div> -->
                </div>
                <div ref="flatCart" class="float_cart" @click="toggleOrderZone" data-bee="module_id=button&item_id=view_cart">
                    <div class="float_cart_icon"></div>
                    <span class="float_cart_num">{{ total_food_count }}</span>
                </div>
                <div class="float_cart_mb" v-if="total_food_count>0" @click="showOrderZone" data-bee="module_id=button&item_id=view_cart">
                    <span>{{ $t('menu.viewCart') }} ({{ total_food_count }})</span>
                </div>

            </div>
        </div>
        <div class="menu_main">
            <div class="menu_section">

                <Populars />

                <div id="sub_category_zone" style="height: 46px; margin-bottom: 10px; display: none;"></div>

                <div class="category_zone" ref="categoryZone">
                    <div class="category_outer">
                        <div class="category_inner">
                            <div class="category_inner_box" id="category_box">
                                <div
                                    class="category_item"
                                    :class="activeMenuId === item.mid && 'current'"
                                    v-for="(item, index) in menu_list"
                                    :data-bee="'module_id=category&item_id=' + item.mid"
                                    @click="choose_menu_nav(item)"
                                    v-bind:key="item.mid + '_menu_nav'"
                                >
                                    <!-- {{ trans(item, 'mname') }} -->
                                    {{ translate('category', item.mname) }}
                                </div>
                            </div>
                        </div>
                        <div class="category_last">
                            <span @click="categoryBoxScroll('left')"></span>
                        </div>
                        <div class="category_next">
                            <span @click="categoryBoxScroll('right')"></span>
                        </div>
                        <!-- 搜索按钮需要隐藏，因为该版本暂不交付搜索功能 -->
                        <div class="search_item">
                            <span class="search_icon"></span>
                        </div>
                    </div>
                </div>
                <!-- 
          二级品类，暂时不做
          div class="second_category_zone">
          <div class="second_category_outer">
            <div class="second_category_inner">
              <div class="second_category_item">Soft Drink</div>
              <div class="second_category_item current">Beer</div>
              <div class="second_category_item">Sake</div>
              <div class="second_category_item">Soft Drink</div>
              <div class="second_category_item">Beer</div>
              <div class="second_category_item">Sake</div>
            </div>
          </div>
        </div> -->
                <div class="menu-container-outter">
                    <div class="menu-container-inner">
                        <div class="category_container">
                            <div class="product_container">
                                <div
                                    class="product_item"
                                    v-for="(food, index) in foods"
                                    :data-bee="'module_id=item&item_id=' + food.fid"
                                    :key="index"
                                    @click="showFood(food)"
                                >
                                    <div class="product_outter">
                                        <div class="product_inner">
                                            <div class="product_info">
                                                <h4 class="product_name">
                                                    <!-- {{ trans(food, 'fname') }} -->
                                                    {{ translate('item', food.fname) }}
                                                    <!-- 此处不再显示数量 -->
                                                    <!-- <span
                                                        v-if="
                                                            cart_food_dict[food.fid] &&
                                                            cart_food_dict[food.fid].mix_fcount > 0
                                                        "
                                                    >
                                                        (<span style="color: red">{{
                                                            cart_food_dict[food.fid].mix_fcount
                                                        }}</span
                                                        >)
                                                    </span> -->
                                                </h4>
                                                <div class="product_desc" v-html="translate('food_desc', food.fdesc)">
                                                    <!-- {{ trans(food, 'fdesc') }} -->
                                                    <!-- {{ translate('food_desc', food.fdesc) }} -->
                                                </div>
                                                <div
                                                    style="font-weight: bold"
                                                    class="product_desc"
                                                    v-if="
                                                        food.is_out_of_stock === 0 &&
                                                        food.daily_limit > 0 &&
                                                        food.daily_limit - food.daily_sales_count > 0
                                                    "
                                                >
                                                    {{ food.daily_limit - food.daily_sales_count + ' left today' }}
                                                </div>
                                                <div class="product_tag">
                                                    <div class="product_tag_leaf" v-if="food.feature_tag">
                                                        {{ transTag(food.feature_tag, 'feature') }}
                                                    </div>
                                                    <div class="product_tag_warning" v-if="food.allergy_tag">
                                                        {{ $t('menu.foodTag', {food_tags: transTag(food.allergy_tag, 'allergy') }) }}
                                                    </div>
                                                </div>
                                                <div
                                                    class="product_extra"
                                                    v-if="currentMenu.is_active && food.is_out_of_stock == 0"
                                                >
                                                    <div class="extra_left" v-if="food.is_tbd">TBD</div>
                                                    <div class="extra_left" v-else>
                                                        <span
                                                            class="product-price"
                                                            style="margin-right: 5px; text-decoration: line-through"
                                                            v-if="food.fprice_original"
                                                        >
                                                            ${{ food.fprice_original }}
                                                        </span>
                                                        <span
                                                            class="product-price product-price-tag"
                                                            v-if="food.fprice_original"
                                                        >
                                                            <div class="price_tag_icon"></div>
                                                            ${{ food.fprice }}
                                                        </span>
                                                        <span
                                                            class="product-price"
                                                            v-if="food.fprice && food.fprice_original == ''"
                                                        >
                                                            ${{ food.fprice }}
                                                        </span>
                                                        <span
                                                            class="product-price product-price-pickup"
                                                            v-if="
                                                                pickup_discount_enable &&
                                                                food.fprice_pickup &&
                                                                food.fprice_original == ''
                                                            "
                                                        >
                                                            <div class="pickup_icon"></div>
                                                            ${{ food.fprice_pickup }}
                                                        </span>
                                                    </div>
                                                    <!-- <button
                                                        class="product_options"
                                                        v-if="foodWidthOptionsOrAddons(food)"
                                                    >
                                                        {{ foodWidthOptionsOrAddons(food) }}
                                                    </button>
                                                    <PlusMinus
                                                        :count="getCartCount(food)"
                                                        :plusCb="() => change_food_count('plus', food)"
                                                        :minusCb="() => change_food_count('minus', food)"
                                                        v-else-if="plusMinusVisible(food)"
                                                    /> -->
                                                </div>
                                                <div class="product_extra" v-if="!currentMenu.is_active">
                                                    <span>{{ $t('menu.notAvailable') }}</span>
                                                </div>
                                                <div
                                                    class="product_extra"
                                                    v-if="currentMenu.is_active && food.is_out_of_stock === 1"
                                                    @click="showOutOfStock"
                                                >
                                                    <span class="out_of_stock">{{
                                                        $t('menu.outofStock')
                                                    }}</span>
                                                </div>
                                            </div>
                                            <div
                                                class="product_img"
                                                v-if="food.fpic !== ''"
                                                v-lazy:background-image="getLazyLoadImg(food)"
                                            ></div>
                                            <!-- <div
                                                class="product_img"
                                                v-else-if="slogo"
                                                :style="{ backgroundImage: slogoBack }"
                                            ></div> -->
                                            <div
                                                class="product_img"
                                                v-else
                                            >
                                                <div class="placeholder_img"></div>
                                            </div>
                                            <!-- 所有菜品卡片，不管是否有优惠，都不再使用这个图标 -->
                                            <!-- <div class="product_required_wrap"  v-if="food.fprice_original">
                                                <div class="product_required"></div>
                                            </div> -->
                                            <div class="product_promote" v-if="food.promote_tag !== ''">
                                                <!-- {{
                                                    language === 'chn'
                                                        ? food.promote_tag_text_chn
                                                        : food.promote_tag_text_eng
                                                }} -->
                                                {{ $t('tag.' + food.promote_tag) }}
                                                <span></span>
                                            </div>
                                            <template v-if="foodWidthOptionsOrAddons(food)">
                                                <div class="food_num" v-if="getCartCount(food) > 0">
                                                    {{ getCartCount(food) }}
                                                </div>
                                                <template v-else>
                                                    <PlusMinus
                                                        :count="getCartCount(food)"
                                                        :plusCb="() => change_food_count('plus', food)"
                                                        :minusCb="() => change_food_count('minus', food)"
                                                        v-if="!(currentMenu.is_active && food.is_out_of_stock === 1)"
                                                    />
                                                </template>
                                                <div
                                                    class="hide_plus_minus"
                                                ></div>
                                            </template>
                                            <template v-else>
                                                <template v-if="getCartCount(food) > 0">
                                                    <div
                                                        class="food_num"
                                                        @click.stop="showPlusMinus"
                                                    >
                                                        {{ getCartCount(food) }}
                                                    </div>
                                                    <PlusMinus
                                                        style="display: none;"
                                                        :count="getCartCount(food)"
                                                        :plusCb="() => change_food_count('plus', food)"
                                                        :minusCb="() => change_food_count('minus', food)"
                                                        v-if="!(currentMenu.is_active && food.is_out_of_stock === 1)"
                                                    />
                                                </template>
                                                
                                                <template v-else>
                                                    <PlusMinus
                                                        :count="getCartCount(food)"
                                                        :plusCb="() => change_food_count('plus', food)"
                                                        :minusCb="() => change_food_count('minus', food)"
                                                        v-if="!(currentMenu.is_active && food.is_out_of_stock === 1)"
                                                    />
                                                </template>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="notice_zone">
                    <p>{{ theme_data.notice_layer_ps_content }}</p>
                </div>
                <div class="" style="height: 30px"></div>
            </div>
        </div>
        <div class="fixed_black" ref="blackBg"></div>
        <YourOrder
            ref="orderZone"
            :hideSelf="hideOrderZone"
            :showOrder="showOrder"
            :couponShow="couponShow"
            :zipCode="zipCodeOrder"
        >
        </YourOrder>
        <PageFooter></PageFooter>
        <LanguageLayer ref="lang"></LanguageLayer>
        <Detail
            ref="detail"
            :key="detailKey"
        />
        <TableLayer ref="table" />
        <CouponLayer
            ref="coupon"
            :hideOrder="hideOrderZone"
        />
        <PolicyLayer
            ref="policy"
            :policyTitle="policyTitle"
            :policyText="policyText"
        />
        <DiscountLayer
            ref="discount"
            :discountTitle="discountTitle"
            :discountText="discountText"
        />
        <!-- <div id="#paypal-button-container" style="display: none"></div> -->
    </div>
</template>

<style lang="scss">
    .menu_loading {
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: #fff;
        overflow: hidden;
        // background: url('../assets/images/loading_menu_pc.png') no-repeat #fff;
        // background-size: 100% auto;
        // background-position: top left;
        z-index: 999;
        .header_bg, .menu_header_info h3, .order_type_main, .product_inner .product_info {
            background-color: #d8d8d8;
            color: #d8d8d8;
            border-color: #d8d8d8;
        }
        .menu_header_info h3 {
            width: 300px;
        }
    }
    .pickup_icon{
        margin-left: 8px;
        mask: url('../assets/images/store.svg') no-repeat;
    }
    .price_tag_icon{
        mask: url('../assets/images/price_tag.svg') no-repeat;
    }
    .pickup_icon, .price_tag_icon{
        display: inline-block;
        background-size: contain;
        width: 17px;
        height: 20px;
        position: relative;
        top: 6px;
        mask-size: 17px;
        background-color: var(--theme_color);
    }
    .category_container .plus_minus button {
        width: 22px;
        height: 22px;
        line-height: 20px;
        font-size: 18px;
    }
    /* 定义滚动条整体样式 */
    // .category_inner::-webkit-scrollbar {
    //     width: 10px;
    //     height: 10px;
    // }
    /* 滚动条里面小方块 */
    // .category_inner::-webkit-scrollbar-thumb {
    //     border-radius: 10px;
    //     box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    //     background: #535353;
    // }
    // /* 定义滚动条轨道 内阴影+圆角 */
    // .category_inner::-webkit-scrollbar-track {
    //     box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    //     border-radius: 10px;
    //     background: #ededed;
    // }

    .menu_body .order_zone {
        z-index: 11;
    }

    .menu_header {
        width: 100%;
        // height: 11.5rem;
        // padding-top: 2.25rem;
        // box-sizing: border-box;
        // background-image: url('../assets/images/header_bg1.png');
        // background-repeat: no-repeat;
        // background-size: 100% auto;
        // border-bottom: 4px solid #bcbcbc;
        // position: relative;
        .header_bg{
            width: 100%;
            height: 150px;
            background-repeat: no-repeat;
            background-size: 100% auto;
            position: relative;
            .opt_item {
                width: 930px;
                margin: 0 auto;
                position: relative;
            }
            .opt_icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 55px;
                height: 55px;
                border-radius: 32px;
                background-color: #fff;
                // background-repeat: no-repeat;
                // background-image: url('../assets/images/trans_icon.svg');
                // background-size: 100% auto;
                cursor: pointer;
                position: absolute;
                top: 1.25rem;
                right: 0;
                z-index: 2;
                span {
                    display: block;
                    width: 31px;
                    height: 31px;
                    mask: url('../assets/images/trans_icon.svg') no-repeat;
                    mask-size: 100%;
                    background-size: contain;
                    background-color: var(--theme_color);
                }
            }
            .go_back {
                display: block;
                width: 47px;
                height: 47px;
                border-radius: 24px;
                background-repeat: no-repeat;
                background-image: url('../assets/images/go_back_icon.svg');
                background-size: 100% auto;
                cursor: pointer;
                position: absolute;
                top: 1.25rem;
                left: 0;
                z-index: 2;
            }
        }
        // h3, p {
        //     text-align: center;
        //     color: #3b3b3b;
        // }
        h3 {
            font-size: 30px;
            margin-top: 30px;
            margin-bottom: 5px;
            // height: 40px;
            line-height: 40px;
            color: var(--theme_color);
        }
        p {
            font-size: 16px;
            line-height: 24px;
            // width: 500px;
            // margin: 0 auto;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            max-height: 24px;
            overflow: hidden;
            word-break: break-all;
            color: #626262;
        }
        .header_info_desc {
            margin-top: 15px;
            padding-left: 20px;
            border-left: 3px solid var(--theme_color); 
            font-size: 14px;
            // width: 400px;
            -webkit-line-clamp: 2;
            max-height: 48px;
            // -webkit-box-orient: vertical;
            // display: -webkit-box;
            // overflow: hidden;
            // word-break: break-all;
            // margin: 0 auto;
        }
    }
    .menu_header_info {
        position: relative;
        z-index: 11;
        width: 930px;
        margin: 0 auto;
    }
    .header_info_address span, .header_info_phone span {
        display: inline-block;
        padding-left: 29px;
        height: 24px;
        background-repeat: no-repeat;
        background-size: auto 14px;
        background-position: 0 center;
    }
    .header_info_address span {
        background-image: url('../assets/images/home_icon_grey.png');
    }
    .header_info_phone span {
        background-image: url('../assets/images/phone_icon_grey.png');
    }
    .menu_main {
        width: 100%;
        // padding-bottom: 2.5rem;
        // max-width: 1440px;
        // margin: 0 auto;
    }
    .menu_section {
        padding-top: 10px;
        position: relative;
        width: 100%;
        background-color: #fff;
    }
    .order_type {
        // position: absolute;
        width: 100%;
        min-height: 30px;
        // height: 113px;
        // background-color: #f7f7f7;
        // border-radius: 8px;
        margin-top: 30px;
        position: relative;
        // top: -80px;
        // left: 0;
    }
    .pick-type {
        display: flex;
        align-items: center;
        justify-content: left;
        position: absolute;
        top: -20px;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
        &.noDelivery {
            .pick-type-item:last-child {
                margin-right: 0;
            }
        }
        .pick-type-item {
            cursor: pointer;
            font-size: 16px;
            // margin-right: 15px;
            height: 40px;
            // color: var(--theme_color);
            color: #626262;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            // border: 1px solid var(--theme_color);
            background-color: #fff;
            padding: 0 0.9375rem;
            box-sizing: border-box;
            // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
            &.active {
                background-color: var(--theme_color);
                color: #fff;
                // box-shadow: 0 4px 10px var(--theme_color);
            }
        }
    }
    .pick-up {
        margin: 0 80px 0 20px;
        font-size: 15px;
    }
    .order_type_main {
        padding: 35px 20px 0 32px;
        min-height: 88px;
        font-size: 16px;
        color: #000;
        background-color: #f7f7f7;
        border-radius: 8px;
    } 
    .order_type_title {
        font-weight: 500;
    }
    .dinein_box {
        margin-bottom: 15px;
    }
    .dinein_table {
        font-weight: 500;
        padding-bottom: 15px;
    }
    .tableName, .tableEdit {
        padding: 0px 15px;
        color: var(--theme_color);
        border: 1px solid var(--theme_color);
        display: inline-block;
        border-radius: 8px;
        line-height: 28px;
        margin-top: 10px;
        cursor: pointer;
        background-color: #fff;
    }
    // .tableEdit {
    //     cursor: pointer;
    // }
    .tableBox {
        display: flex;
        .tableEdit {
            border: none;
            text-decoration: underline;
            background-color: transparent;
            padding: 0;
            margin-left: 15px;
        }
    }
    .pickup_content {
        margin-top: 5px;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        max-height: 40px;
        overflow: hidden;
        word-break: break-all;
        overflow: hidden;
    }
    .zip_code {
        margin-top: 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        // justify-content: center;
        .order_type_title {
            margin-right: 17px;
        }
        .zip_msg {
            font-size: 14px;
            padding-left: 36px;
            height: 23px;
            line-height: 23px;
            background-repeat: no-repeat;
            background-size: auto 23px;
            background-position: 0 center;
        }
        .zip_msg_succ {
            color: #68B361;
            background-image: url('../assets/images/success_icon.svg');
        }
        .zip_msg_err {
            color: #E90112;
            background-image: url('../assets/images/warning.svg');
            // mask: url('../assets/images/warning.svg') no-repeat;
            // mask-size: auto 23px;
            // mask-position: 0 center;
            // background-color: #E90112;
        }
    }
    .zip_code_input {
        // margin-left: 16px;
        // display: flex;
        position: relative;
        width: 200px;
        height: 36px;
        margin-right: 30px;
        input {
            height: 36px;
            font-size: 15px;
            width: 100%;
            border: 1px solid #979797;
            border-radius: 8px;
            padding-left: 18px;
            padding-right: 30px;
            box-sizing: border-box;
        }
        button {
            position: absolute;
            right: 5px;
            border: none;
            background-color: transparent;
            // color: var(--theme_color);
            width: 30px;
            height: 36px;
            line-height: 36px;
            // background-repeat: no-repeat;
            // background-size: auto 15px;
            // background-position: center center;
            // background-image: url('../assets/images/arrow_right.svg');
            // border-bottom: 2px solid var(--theme_color);
            // color: #979797;
            mask: url('../assets/images/arrow_right.svg') no-repeat;
            mask-size: auto 24px;
            mask-position: center center;
            // background-size: contain;
            background-color: #979797;
        }
    }
    .promo_tag {
        // margin-top: 15px;
        // height: 42px;
        line-height: 42px;
        display: flex;
        flex-wrap: wrap;
        .promo_item {
            margin-top: 15px;
            height: 42px;
            border-radius: 10px;
            background-color: var(--theme_color);
            margin-right: 11px;
            padding: 0 20px 0 56px;
            font-size: 16px;
            color: #fff;
            cursor: pointer;
            background-repeat: no-repeat;
            background-size: auto 26px;
            background-position: 15px center;
        }
        .promo_vector {
            background-image: url('../assets/images/promo_vector.svg');
        }
        .promo_teeny {
            background-image: url('../assets/images/promo_teenyicons.svg');
        }
        .promo_food {
            padding-left: 63px;
            background-image: url('../assets/images/promo_pizza.svg');
        }
    }
    .popular_items {
        display: none;
        /* width: 75%; */
        width: 1104px;
        background-color: #f2f2f2;
        height: 244px;
        padding: 8px 0;
    }
    .popular_title {
        color: #d63239;
        font-size: 23px;
        height: 106px;
        line-height: 106px;
        padding-left: 6.625rem;
        background-image: url('../assets/images/start_icon.png');
        background-repeat: no-repeat;
        background-size: auto 40%;
        background-position: 4.375rem center;
    }
    .product_container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .product_item {
        width: 455px;
        min-height: 150px;
        // border: 1px solid #bcbcbc;
        box-sizing: border-box;
        display: flex;
        // border-radius: 10px;
        background-color: #fff;
        position: relative;
        margin-bottom: 20px;
        .product_outter {
            display: flex;
            flex-direction: column;
            width: 100%;
            // padding-left: 12px;
            .product_inner {
                border-radius: 0;
                flex-grow: 1;
                -webkit-box-flex: 1;
                cursor: pointer;
                position: relative;
                display: flex;
                width: 100%;
                .plus_minus {
                    position: absolute;
                    right: 12px;
                    bottom: 12px;
                    background-color: #000;
                    border-radius: 24px;
                    .number {
                        font-size: 20px;
                        color: #fff;
                    }
                    button {
                        width: 40px;
                        height: 40px;
                        &.plus_btn {
                            background-image: url('../assets/images/plus_black.svg');
                        }
                        &.minus_btn {
                            background-image: url('../assets/images/minus_black.svg');
                        }
                    }
                }
            }
        }
    }
    .product_inner .product_info {
        // border: none;
        min-width: 0;
        flex-direction: column;
        flex: 1;
        position: relative;
        display: flex;
        color: #707070;
        padding: 12px;
        border: 2px solid #eaeaea;
        border-radius: 10px 0 0 10px;
        border-right: none;
    }
    .product_info .product_name {
        line-height: 24px;
        font-size: 20px;
        font-family: UberMoveText-Regular, Helvetica, sans-serif;
        -webkit-font-smoothing: antialiased;
        word-break: break-word;
        color: #000;
        font-weight: 600;
    }
    .product_info .product_desc,
    .product_info .product_extra {
        line-height: 20px;
        font-size: 14px;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }
    .product_info .product_desc {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        max-height: 40px;
        overflow: hidden;
        word-break: break-all;
        color: #626262;
        margin-top: 5px;
    }
    .product_info .product_desc i, .food_text i {
        font-style: italic;
    }
    .product_tag {
        margin-top: 5px;
        .product_tag_leaf, .product_tag_warning  {
            padding-left: 21px;
            background-repeat: no-repeat;
            background-size: auto 15px;
            background-position: 0 3px;
            font-size: 14px;
            line-height: 21px;
            margin-right: 10px;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            max-height: 21px;
            overflow: hidden;
            word-break: break-all;
        }
        .product_tag_leaf {
            background-image: url('../assets/images/leaf.svg');
            color: #419F5A;
        }
        .product_tag_warning {
            background-image: url('../assets/images/warning.svg');
            color: #E90112;
        }
    }
    .product_info .product_extra {
        margin-top: 8px;
        flex-grow: 1;
        -webkit-box-flex: 1;
        align-items: flex-end;
        -webkit-box-align: end;
        flex-wrap: wrap;
        -webkit-box-lines: multiple;
        display: flex;
        font-size: 18px;
        /* justify-content: space-between; */
        .extra_left {
            color: #000;
            flex-grow: 1;
            margin-top: 8px;
            .product-price-pickup, .product-price-tag {
                color: var(--theme_color);
            }
        }
        .out_of_stock {
            color: var(--theme_color);
            font-weight: bold;
        }
    }
    .product_extra .product_number,
    .product_extra .product_options {
        display: inline-flex;
        justify-content: center;
        -webkit-box-pack: center;
        align-items: center;
        -webkit-box-align: center;
    }
    .product_extra .product_options {
        color: #707070;
        border: 1px solid #bcbcbc;
        height: 22px;
        border-radius: 11px;
        font-size: 13px;
        padding: 0 10px;
        background-color: #fff;
        margin-top: 8px;
    }
    .product_number .custom_input,
    .product_number button {
        justify-content: center;
        -webkit-box-pack: center;
        align-items: center;
        -webkit-box-align: center;
        display: flex;
    }
    .product_number .custom_input {
        display: inline-flex;
    }
    .product_number .number_input {
        width: 24px;
        border: 0;
        font-size: 14px;
        color: #707070;
        display: flex;
        text-align: center;
    }
    .product_number button {
        background-color: #f5f5f5;
        color: #707070;
        height: 16px;
        width: 16px;
        border-radius: 2px;
        border: 1px solid #bcbcbc;
        cursor: pointer;
        -webkit-appearance: button;
        text-transform: none;
        margin: 0;
        font-size: 12px;
        line-height: 16px;
        box-sizing: border-box;
    }
    .product_inner .product_img {
        // margin-left: 8px;
        // width: 6.1875rem;
        height: 100%;
        // flex-basis: 6.1875rem;
        width: 45%;
        flex-basis: 45%;
        background-position: 50%;
        background-repeat: no-repeat;
        flex-shrink: 0;
        background-size: cover;
        overflow: hidden;
        position: relative;
        border-radius: 0 10px 10px 0;
        background-color: #eaeaea;
    }
    .product_inner .product_img .placeholder_img {
        position: absolute;
        width: 100%;
        height: 100%;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: #eaeaea;
        background-image: url(../assets/images/placeholder.png);
    }
    .product_inner .product_img img {
        display: block;
        width: 100%;
    }
    .product_required_wrap {
        position: absolute;
        top: -17px;
        right: -25px;
        width: 57px;
        height: 56px;
        &::after{
            z-index: 1;
            content: '';
            display: block;
            position: absolute;
            width: 36px;
            height: 36px;
            top: 50%;
            margin-top: -20px;
            left: 50%;
            margin-left: -20px;
            border-radius: 50%;
            background-color: white;
        }
        .product_required {
            position: relative;
            z-index: 9;
            width: 100%;
            height: 100%;
            mask: url('../assets/images/ratio.svg') no-repeat;
            mask-size: 90%;
            background-color: var(--theme_color);
            background-size: contain;
        }
    }
    .food_num {
        position: absolute;
        right: 12px;
        bottom: 12px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: #000;
        color: #fff;
        font-size: 20px;
        border-radius: 20px;
        z-index: 1;
    }
    .hide_plus_minus {
        position: absolute;
        width: 120px;
        height: 60px;
        right: 10px;
        bottom: 10px;
        z-index: 0;
    }
    .product_promote {
        position: absolute;
        top: 0;
        // right: 6.1875rem;
        left: 55%;
        // background: #000;
        padding: 5px 2px 5px 10px;
        background-color: #000;
        // border-radius: 10px;
        color: #fff;
        font-size: 14px;
        span {
            display: block;
            width: 16px;
            height: 100%;
            background-image: url(../assets/images/must_icon.svg);
            background-repeat: no-repeat;
            background-size: auto 100%;
            background-position: top right;
            position: absolute;
            right: -16px;
            top: 0;
        }
    }

    .popular_box {
        padding-left: 140px;
        /* padding-right: 98px; */
    }
    .popular_box .product_item {
        width: 278px;
        height: 89px;
        border-color: #d8d8d8;
        margin-right: 0.5rem;
    }
    .popular_box .product_item .product_outter {
        padding-left: 16px;
    }
    .popular_box .product_inner .product_img {
        width: 132px;
        height: 87px;
        flex-basis: 132px;
    }

    .category_zone {
        // width: 75%;
        // width: 1104px;
        // border-bottom: 3px solid #bcbcbc;
        // margin: 10px 0;
        // height: 43px;
        padding-top: 10px;
        margin-bottom: 10px;
        height: 34px;
        font-size: 16px;
        border-bottom: 2px solid #eaeaea;
        line-height: 34px;
    }
    // .category_content {
    //     width: 1104px;
    //     margin: 0 auto;
    //     overflow: hidden;
    // }
    .category_outer {
        // padding: 50px 0 0 140px;
        // width: 1104px;
        width: 930px;
        margin: 0 auto;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        // height: 43px;
        height: 34px;
        position: relative;
        .category_last, .category_next {
            display: none;
            position: absolute;
            top: 3px;
            width: 67px;
            height: 28px;
            opacity: 1;
            // text-align: center;
            span {
                display: inline-block;
                width: 28px;
                height: 28px;
                // mask-size: auto 100%;
                // mask-position: center center;
                // mask-repeat: no-repeat;
                // background-color: var(--theme_color);
                background-size: auto 100%;
                background-position: center center;
                background-repeat: no-repeat;
                background-image: url('../assets/images/next_button.svg');
                cursor: pointer;
            }
        }
        .category_last {
            left: 0;
            background: linear-gradient(-90deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 1) 61%);
            span {
                // mask-image: url('../assets/images/go_back_icon.svg');
                transform: rotate(180deg);
            }
        }
        .category_next {
            right: 0;
            text-align: right;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 1) 61%);
            // span {
            //     // mask-image: url('../assets/images/go_back_icon.svg');
            //     // transform: rotate(180deg);
            // }
        }
    }
    .category_zone .search_item {
        width: 80px;
        display: none;
    }
    .category_zone .search_icon {
        display: block;
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        background-image: url('../assets/images/search.png');
        background-size: 100% auto;
        cursor: pointer;
        margin-top: 15px;
        margin-left: 10px;
    }
    .category_inner {
        width: 930px;
        overflow-x: scroll;
        scrollbar-width: none; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */
    }
    .category_inner::-webkit-scrollbar {
        display: none; /* Chrome Safari */
    }
    
    .category_inner_box {
        display: flex;
        width: 220%;
        min-width: 100%;
    }
    .category_item {
        // padding: 20px 0 10px;
        margin-right: 50px;
        // padding: 5px 10px;
        // font-size: 16px;
        // font-weight: bold;
        // color: var(--theme_color);
        // border-bottom: 3px solid transparent;
        text-transform: capitalize;
        cursor: pointer;
        // border: 1px solid var(--theme_color);
        // margin-right: 10px;
        // border-radius: 5px;
        // min-width: 120px;
        color: #000;
        font-weight: 500;
        font-size: 20px;
        position: relative;
        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 3px;
            background-color: transparent;
            border-radius: 2px;
            left: 0;
            bottom: 0;
        }
    }
    .category_item.current::after {
        background-color: #000;
        // border-color: var(--theme_color);
        // background-color: var(--theme_color);
        // color: #fff;
        // font-size: 16px;
    }
    .category_inner_box .category_item:last-child {
        margin-right: 0;
    }
    .menu-container-outter,
    .second_category_zone {
        // width: 1104px;
        width: 100%;
        padding-top: 20px;
    }
    // .menu-container-inner,
    // .second_category_outer {
    //     padding-left: 140px;
    // }
    .category_container,
    .second_category_inner {
        width: 930px;
        margin: 0 auto;
    }
    .second_category_zone {
        display: none;
        margin-bottom: 30px;
    }
    .second_category_inner {
        height: 50px;
        background-color: #e3e0e0;
        border-radius: 8px;
        display: flex;
        justify-content: center;
    }
    .second_category_item {
        font-size: 17px;
        font-weight: bold;
        color: #7d7d7d;
        border-bottom: 7px solid transparent;
        cursor: pointer;
        margin: 0 36px;
        line-height: 43px;
    }
    .second_category_item.current {
        border-color: #d63239;
    }
    .notice_zone {
        // width: 1104px;
        width: 930px;
        margin: 0 auto;
        font-size: 12px;
        color: #707070;
        text-align: center;
    }

    .float_cart {
        position: absolute;
        left: 950px;
        top: 0px;
        // color: #3d3d3d;
        min-width: 100px;
        height: 40px;
        box-sizing: border-box;
        cursor: pointer;
        border-radius: 20px;
        background-color: var(--theme_color);
        padding: 0 25px 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .float_cart_icon{
            width: 28px;
            height: 40px;
            background: url('../assets/images/cart_white.svg') no-repeat;
            background-size: 28px auto;
            background-position: center center;
            margin-right: 30px;
            // background-color: var(--theme_color);
            // background-size: contain;
        }
        .float_cart_num{
            font-size: 20px;
            // position: absolute;
            // top: 6px;
            // right: 12px;
            // border-radius: 50%;
            // background-color: #000;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            color: white;
        }
    }
    .float_cart .float_price {
        float: right;
        padding-right: 10px;
        display: none;
    }

    .category_fixed_zone.category_zone {
        position: fixed;
        top: 0;
        // margin: 0;
        // padding-top: 10px;
        background-color: #fff;
        z-index: 10;
        width: 100%;
        // padding-bottom: 10px;
        // border: none;
        // .category_inner {
        //     padding: 5px 0;
        // }
        // .category_must_center {
        //     justify-content: center;
        // }
        // .category_item {
        //     color: #3b3b3b;
        //     border: none;
        //     background-color: #fff;
        //     border-right: 2px solid var(--theme_color);
        //     padding: 0 10px;
        //     border-radius: 0;
        //     position: relative;
        //     margin-right: 0;
        //     &:last-child {
        //         border: none;
        //     }
        //     &.current::after {
        //         content: '';
        //         position: absolute;
        //         width: 50%;
        //         height: 3px;
        //         background-color: var(--theme_color);
        //         border-radius: 5px;
        //         bottom: -5px;
        //         left: 25%;
        //     }
        // }
    }

    // @media (min-width: 1241px) and (max-width: 1570px) {
    @media (min-width: 1276px) and (max-width: 1622px) {
        .menu_header .header_bg .opt_item,
        .menu_header_info,
        .category_outer,
        .notice_zone {
            margin-right: 346px;
        }
        .category_container {
            margin-right: 346px;
        }
    }
    // @media (min-width: 1105px) and (max-width: 1240px) {
    //     .menu_header .header_bg .opt_item,
    //     .menu_header_info,
    //     .category_outer,
    //     .notice_zone {
    //         margin-right: 355px;
    //     }
    //     .category_container {
    //         margin-right: 355px;
    //     }
    // }
    .fixed_black {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 11;
        background-color: rgba(0,0,0,.6);
        display: none;
    }
    .float_cart_mb {
        display: none;
    }
    @media screen and (max-width: 1275px) {
        .menu_header .header_bg .opt_item {
            width: 100%;
        }
        .float_cart{
            display: none;
        }
        .float_cart_mb{
            background-color: var(--theme_color);
            display: flex;
            // align-items: center;
            justify-content: center;
            color: white;
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 65px;
            z-index: 10;
            text-align: center;
            line-height: 58px;
            font-weight: bold;
            font-size: 20px;
            box-shadow: 0 -4px 14px rgba(0, 0, 0, 0.25);
        }
        .popular_title {
            background-position: 3.375rem center;
        }
        .popular_box {
            width: 890px;
            padding-left: 1rem;
            padding-top: 1rem;
        }
        .menu-container-inner,
        .category_outer,
        .second_category_outer,
        .notice_zone {
            padding: 0 1rem;
        }
        .category_outer {
            width: 100%;
            box-sizing: border-box;
        }
        .category_inner_box {
            width: 300%;
        }
        .popular_items,
        .menu-container-outter,
        .notice_zone,
        .category_container,
        .product_item {
            width: 100%;
            box-sizing: border-box;
        }
        .popular_box_outer,
        .category_zone,
        .second_category_zone {
            width: 100%;
            overflow-x: scroll;
        }
        // .product_promote {
        //     left: 70%;
        // }
        // .pick-up {
        //     display: none;
        // }
        // .zip_code {
        //     display: none;
        // }
    }
    @media screen and (max-width: 641px) {
        // .menu_loading {
        //     background-image: url('../assets/images/loading_menu_mobile.png');
        // }
        .menu_header {
            // padding-top: 1rem;
            // height: 14rem;
            .menu_header_info {
                width: 100%;
                padding: 0 1rem;
                box-sizing: border-box;
            }
            h3 {
                margin-top: 15px;
                font-size: 26px;
                line-height: 36px;
                margin-bottom: 3px;
            }
            p {
                font-size: 14px;
            }
            .header_info_desc {
                width: 80%;
                font-size: .75rem;
                line-height: 1.3;
                -webkit-line-clamp: 3;
                max-height: 3rem;
            }
            .white_bg {
                height: 18rem;
            }
            .header_bg {
                height: 140px;
                .opt_item {
                    width: 100%;
                }
                .go_back {
                    width: 34px;
                    height: 34px;
                    top: 62px;
                    left: 21px;
                }
                .opt_icon {
                    width: 47px;
                    height: 47px;
                    top: 51px;
                    right: 21px;
                    span {
                       width: 25px;
                       height: 25px; 
                    }
                }
            }
            .order_type {
                min-height: 20px;
            }
            .order_type_main {
                padding: 30px 20px 0;
                font-size: 14px;
            }
            .pickup_content {
                max-height: 60px;
                -webkit-line-clamp: 3;
            }
            .dinein_box {
                margin-bottom: 10px;
            }
            .dinein_table {
                padding-bottom: 10px;
            }
            .tableName, .tableEdit {
                padding: 0px 15px;
                line-height: 24px;
                margin-top: 10px;
            }
            .promo_tag {
                // height: 35px;
                line-height: 35px;
                .promo_item {
                    padding: 0 15px 0 40px;
                    height: 35px;
                    font-size: 14px;
                    border-radius: 7px;
                    background-size: auto 18px;
                    background-position: 11px center;
                }
            }
        }
        // .menu_header .opt_icon, .menu_header .go_back {
        //     width: 1.75rem;
        //     height: 1.75rem;
        //     top: 0.75rem;
        // }
        // .menu_header .opt_icon {
        //     right: 0.75rem;
        // }
        // .menu_header .go_back {
        //     left: 0.75rem;
        // }
        // .menu_header p {
        //     font-size: 0.8125rem;
        //     width: 80%;
        // }
        .category_inner {
            width: 100%;
            padding-left: 16px;
            box-sizing: border-box;
        }
        .category_inner_box {
            width: 600%;
        }
        // .float_cart {
        //     display: block;
        // }
        // .order_zone {
        //     display: none;
        // }
        // .order_type {
        //     overflow-x: scroll;
        //     top: -52px;
        // }
        // .pick-type {
        //     min-width: 640px;
        // }
        // .pick-type.noDelivery {
        //     min-width: 100%;
        // }
        // .menu_body .order_total {
        //     margin-bottom: 75px;
        // }
        // .menu_body .check_out {
        //     width: 96%;
        //     padding: 8px;
        //     box-sizing: border-box;
        // }
        .product_item .product_outter .product_inner {
            .plus_minus {
                right: 10px;
                bottom: 10px;
                border-radius: 16px;
                .number {
                    font-size: 14px;
                }
                button {
                    width: 32px;
                    height: 32px;
                }
            }
        }
        .product_inner {
            .product_info {
                padding: .5rem .625rem;
                .product_extra {
                    font-size: 1rem;
                    margin-top: 0;
                }
                .product_desc {
                    font-size: .875rem;
                }
                .product_name {
                    font-size: 1.0625rem;
                }
            }
        }
            
        .product_tag .product_tag_leaf, .product_tag .product_tag_warning {
            font-size: .875rem;
            max-height: 1.25rem;
            line-height: 1.25rem;
            margin-right: 1.25rempx;
            background-size: auto .875rem;
        }
        .zip_code {
            margin-top: 0;
            .zip_code_input {
                margin-top: 8px;
                width: 175px;
                height: 29px;
                input {
                    font-size: 0.75rem;
                    height: 29px;
                }
                button {
                    height: 29px;
                    line-height: 29px;
                    mask-size: auto 18px;
                    right: 2px;
                }
            }
            .zip_msg {
                margin-top: 8px;
                padding-left: 24px;
                height: 16px;
                line-height: 16px;
                background-size: auto 16px;
            }
        }
        .category_outer {
            padding: 0;
            .category_item {
                font-size: 1rem;
                margin-right: 25px;
            }
        }
        .food_num {
            width: 32px;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            bottom: 10px;
            right: 10px;
            z-index: 0;
        }
    }
    @media screen and (max-width: 376px) {
        .menu_header {
            // height: 14.5rem;
            height: auto;
        }
    }
    @media screen and (max-width: 321px) {
        .menu_header {
            // height: 16.25rem;
            height: auto;
            .header_info_desc {
                max-height: 4rem;
            }
        }
    }
    @media screen and (max-height: 1366px) {
        .menu-container-outter {
            min-height: 811px;
        }
    }
    @media screen and (max-height: 1024px) {
        .menu-container-outter {
            min-height: 470px;
        }
    }
    @media screen and (max-height: 1001px) {
        .menu-container-outter {
            min-height: 448px;
        }
    }
    @media screen and (max-height: 812px) {
        .menu-container-outter {
            min-height: 396px;
        }
    }
    @media screen and (max-height: 736px) {
        .menu-container-outter {
            min-height: 312px;
        }
    }
    @media screen and (max-height: 720px) {
        .menu-container-outter {
            min-height: 235px;
        }
    }
    @media screen and (max-height: 667px) {
        .menu-container-outter {
            min-height: 250px;
        }
    }
    @media screen and (max-height: 653px) {
        .menu-container-outter {
            min-height: 256px;
        }
    }
    @media screen and (max-height: 568px) {
        .menu-container-outter {
            min-height: 186px;
        }
    }
</style>

<script>
    import { mapState, mapActions, mapMutations, mapGetters, createNamespacedHelpers } from 'vuex';
    const App = createNamespacedHelpers('app');
    const FoodStore = createNamespacedHelpers('Food');
    import { setLang } from '@/locales';
    import PageFooter from '@/components/PageFooter.vue';
    import YourOrder from '@/components/YourOrder.vue';
    import LanguageLayer from '@/components/LanguageLayer.vue';
    import TableLayer from '@/components/TableLayer.vue';
    import CouponLayer from '@/components/CouponLayer.vue';
    import Populars from '@/components/Populars.vue';
    import Detail from '@/components/FoodDetail.vue';
    import PlusMinus from '@/components/Plus_Minus';
    import PolicyLayer from '@/components/PolicyLayer.vue';
    import DiscountLayer from '@/components/DiscountLayer.vue';

    export default {
        name: 'menu_minitable',
        components: {
            PageFooter,
            YourOrder,
            LanguageLayer,
            TableLayer,
            Populars,
            PlusMinus,
            Detail,
            CouponLayer,
            PolicyLayer,
            DiscountLayer
        },
        data() {
            return {
                zipCode: '',
                detailKey: Math.random(),
                activeMenuId: '',
                FOOD_FEATURE_DICT: {},
                showOrder: false,
                categoryBox: null,
                isFixed: false,
                isCartFixed: false,
                zipCodeOrder: '',
                policyText: '',
                policyTitle:'',
                zipMsg: '',
                discountText: '',
                discountTitle: ''
            };
        },
        watch: {
            menu_list: {
                immediate: true,
                handler(val, oldVal) {
                    if ((!oldVal || oldVal.length === 0) && val && val.length > 0) {
                        this.activeMenuId = val[0].mid;
                        localStorage.activeMenuId = this.activeMenuId;
                    }
                }
            },
            total_food_count() {
                if (this.total_food_count > 0) {
                    // console.log('class', this.$refs.flatCart.className);
                    this.$refs.flatCart.className = "float_cart had";
                    // console.log(window.innerWidth);
                    if(window.innerWidth > 640) {
                        this.showOrderZone();
                    }
                } else {
                    // console.log('class2', this.$refs.flatCart.className);
                    this.$refs.flatCart.className = "float_cart";
                }

                // 判断购物车是否过长，购物车过长，支付按钮需要固定到底部
                setTimeout(this.checkoutIsFixed, 100);
            },
            language() {
                // let categoryBox = document.getElementById('category_box');
                // if (this.categoryBox) {
                //     this.categoryBox.style.width = 10000 + 'px';
                //     setTimeout(this.css_category, 3000);
                // }

                // 解决在刷新页面后语言设置混乱问题
                let lang = window.localStorage.getItem('language');
                if (lang) {
                    setLang(lang);
                    this.setLanguage({
                        language: lang
                    });
                }
            },
            menuList() {
                if (this.categoryBox) {
                    this.categoryBox.style.width = 100000 + 'px';
                    setTimeout(this.css_category, 500);
                }
            },
            orderTypeList() {
                if (!this.orderType && this.orderTypeList.length >= 1) {
                    // console.log('this.orderTypeList[0]', this.orderTypeList[0]);
                    // this.setOrderType({ type:  this.orderTypeList[0]});
                    this.choosePickType(this.orderTypeList[0]);
                }
            }
        },
        created() {
            const activeMenuId = localStorage.activeMenuId;
            this.activeMenuId = activeMenuId;
        },
        mounted() {
            // console.log('mounted', categoryZone);

            window.addEventListener('scroll', this.scrollOverHeader);
            if (this.total_food_count > 0) {
                this.$refs.flatCart.className = "float_cart had";
                if(window.innerWidth > 640) {
                    this.showOrderZone();
                }
            }
            window.addEventListener('scroll', this.scrollOverCartBtn);
            this.categoryBox = document.getElementById('category_box');
            if (this.categoryBox) {
                this.categoryBox.style.width = 100000 + 'px';
                // setTimeout(this.css_category, 3000);
            }

            this.zipCodeOrder = this.zip_code_order ? this.zip_code_order : '';

            let orderTypeStr = window.localStorage.getItem('orderType');
            // 自动选择最高优先级就餐方式
            if (orderTypeStr) {
                // this.setOrderType({ type:  orderTypeStr});
                this.choosePickType(orderTypeStr);
            } else if (!this.orderType && this.orderTypeList.length >= 1) {
                // this.setOrderType({ type:  this.orderTypeList[0]});
                this.choosePickType(this.orderTypeList[0]);
            }

            let dineInTableStr = window.localStorage.getItem('dineInTable');
            // 刷新后保留选择的桌子
            if (dineInTableStr) {
                this.setDineInTable(dineInTableStr);
            }

            // 判断购物车是否过长，购物车过长，支付按钮需要固定到底部
            setTimeout(this.checkoutIsFixed, 3000);

        },
        updated() {},
        computed: {
            ...App.mapGetters({
                theme_data: 'get_theme_data',
                language: 'get_language',
                canDineIn: 'get_canDineIn',
                pickup_setting: 'get_pickup_setting',
                delivery_data: 'get_delivery_data',
                delivery_setting: 'get_delivery_setting',
                zip_code_order: 'get_zipCodeOrder',
                dinein_setting: 'get_dinein_setting',
                promotion_data: 'get_promotion_data',
                special_store: 'get_special_store',
            }),
            ...mapGetters({
                translate: 'app/translate',
                is_vip: 'app/get_if_is_vip',
                cart_food_list: 'app/get_cart_food_list',
                cart_food_dict: 'app/get_cart_food_dict',
                pickup_discount_enable: 'app/get_pickup_discount_enable',
                menu_dict: 'app/get_menu_dict',
                menu_list: 'app/get_menu_list',
                slogo: 'app/get_store_slogo',
                is_loading_menu: 'app/get_is_loading_menu',
            }),
            ...mapState({
                orderType: (state) => state.app.type,
                sname: (state) => state.app.sname,
                store_data: (state) => state.app.store_data,
                total_food_count: (state) => state.app.cart_data.total_food_count,
                zip_code: (state) => state.app.zip_code,
            }),
            dineInTable() {
                const name = this.$store.state.app.dineInTable;
                if (this.orderType === 'dineIn' && name) {
                    return name;
                }
                return '';
            },
            saddress() {
                const { street_address, locality, postal_code } = this.store_data;
                if (!street_address && !locality && !postal_code) {
                    return '';
                }
                const saddress = street_address + ' , ' + locality + ' , ' + postal_code;
                return saddress;
            },
            sphone() {
                const { stel } = this.store_data;
                if (!stel) {
                    return '';
                }
                let temp = stel.replace(/\D/g, '');
                let sphone = temp.replace(/(\d{1,3})(\d{1,3})?(\d{1,4})?/, function (a, b, c, d) {
                    if (b) {
                        if (!c) {
                            return '(' + b + ')';
                        }
                        if (!d) {
                            return '(' + b + ') ' + c;
                        }
                        return '(' + b + ') ' + c + '-' + d;
                    }
                });
                return sphone;
            },
            currentMenu() {
                return this.menu_dict?.[this.activeMenuId];
            },
            foods() {
                return this.currentMenu?.list || [];
            },
            feature_image() {
                return 'url(' + this.theme_data.feature_image + ')';
            },
            slogoBack() {
                return 'url(' + this.slogo + ')';
            },
            orderTypeList() {  
                let tempArr = [];
                if (this.canDineIn) {
                    tempArr.push('dineIn');
                }
                if (this.pickup_setting.enable == 1) {
                    tempArr.push('pickup');
                }
                if (this.delivery_setting.enable == 1) {
                    tempArr.push('delivery');
                }
                return tempArr;
            },
            deliveryEnable() {
                if (this.delivery_setting.enable == 1) {
                    if (this.delivery_data.delivery_area_list.length > 1) {
                        return true;
                    } else if (this.delivery_data.delivery_area_list.length == 1) {
                        if (this.delivery_data.delivery_area_list[0].zip_code_list && this.delivery_data.delivery_area_list[0].name) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }
                return false;
            },
            menuList() {
                return this.menu_list;
            }
        },
        methods: {
            ...FoodStore.mapActions(['setDetailPageData']),
            ...mapActions({
                edit_food: 'app/edit_cart_food',
                edit_cart_food_count: 'app/edit_cart_food_count',
                quit_table: 'app/quitTable'
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            }),
            ...App.mapMutations({
                setOrderType: 'APP_SET_ORDER_TYPE',
                setZipCode: 'APP_SET_ZIPCODE',
                setLanguage: 'APP_SET_LANGUAGE',
                setDineInTable: 'APP_SET_DineInTable'
            }),
            showLang() {
                this.$refs.lang.show();
            },
            checkCode() {
                if (!this.zipCode) {
                    this.zipMsg = '';
                    return;
                }
                let zipCodes = [];
                this.delivery_data.delivery_area_list.forEach((item) => {
                    zipCodes = [...zipCodes, ...item.zip_code_list.split(',')];
                });
                const result = zipCodes.some((code) => code === this.zipCode);
                // const msg = result ? this.$t('common.serviceArea') : this.$t('common.notServiceArea');
                // this.set_msg({ msg });
                this.zipMsg = result ? 'success' : 'error';
                if (result && !this.zip_code) {
                    this.zipCodeOrder = this.zipCode;
                    this.setZipCode({ zipCodeOrder: this.zipCode });
                } else {
                    this.zipCodeOrder = '';
                    this.setZipCode({ zipCodeOrder: '' });
                }
                
            },
            plusMinusVisible(food) {
                const foodsInCart = this.cart_food_list.filter((item) => item.fid === food.fid);
                if (foodsInCart?.length > 1) {
                    return false;
                }
                return true;
            },
            get_total_price({ food, count }) {
                let _price = this.is_vip && food.fprice_vip ? food.fprice_vip : food.fprice;
                return (parseFloat(_price) * count).toFixed(2);
            },
            getCartCount(food) {
                return this.cart_food_dict[food.fid]?.mix_fcount || 0;
            },
            change_food_count(action, food) {
                // console.log('food', food);
                // console.log('language', this.language);
                const foodInCart = this.cart_food_list.filter((item) => item.fid === food.fid)?.[0];
                let fcount = foodInCart?.fcount || 0;
                const mix_fcount = this.cart_food_dict[food.fid]?.mix_fcount || 0;
                let type = 'set';
                let note = foodInCart?.note || '';
                let newCount = action === 'plus' ? fcount + 1 : fcount - 1;
                if (action == 'plus') {
                    if (fcount === 0) {
                        type = 'add';
                    }
                    let _food_data = JSON.parse(JSON.stringify(food));
                    if (_food_data.once_limit > 0 && mix_fcount + 1 > _food_data.once_limit) {
                        const msg = this.$t('cart.exceedOrder');
                        // let msg = this.language == 'eng' ? 'Exceed the limit per order' : '超出每单限额';
                        this.set_msg({ msg });
                        return;
                    }
                    if (
                        _food_data.daily_limit > 0 &&
                        mix_fcount + +1 > _food_data.daily_limit - _food_data.daily_sales_count
                    ) {
                        const msg = this.$t('cart.exceedDay');
                        // let msg = this.language == 'eng' ? 'Exceed the limit per day' : '超出每日限额';
                        this.set_msg({ msg});
                        return;
                    }
                }
                this.do_save({ food, count: newCount, type, note });
            },
            async do_save({ food, count, type, note }) {
                let _food_data = JSON.parse(JSON.stringify(food));

                // console.log('_food_data', _food_data);

                _food_data.fcount = count;
                _food_data.fprice_original = _food_data.fprice_original ? _food_data.fprice_original : '';
                _food_data.fprice_vip = _food_data.fprice_vip ? _food_data.fprice_vip : '';
                _food_data.fprice_pickup =
                    this.pickup_discount_enable && _food_data.fprice_pickup ? _food_data.fprice_pickup : '';

                let save_data = {
                    cartid: this.$store.state.app.shared_cart_id,
                    tid: this.$store.state.app.shared_tid,
                    uid: this.$store.state.app.email,
                    uname: this.$store.state.app.uname,
                    router: this.$router,
                    type,
                    is_vip: this.is_vip,
                    language: this.language
                };
                let action = this.edit_food;
                if (type === 'add') {
                    save_data = {
                        ...save_data,
                        food_data: _food_data,
                        extra_data: {
                            feature_name: '',
                            note: '',
                            addon_names: '',
                            addon_prices: '',
                            addon_counts: '',
                            addon_pickup_discounts: '',
                            addon_total_price: 0,
                            addon_total_price_pickup: 0,
                            add_count: 1,
                            once_limit: _food_data.once_limit,
                            daily_limit: _food_data.daily_limit
                        }
                    };
                } else if (type === 'set') {
                    action = this.edit_cart_food_count;
                    save_data = {
                        ...save_data,
                        fid: _food_data.fid,
                        fname: _food_data.fname,
                        fname_chn: _food_data.fname_chn,
                        fprice: _food_data.fprice,
                        fprice_original: _food_data.fprice_original || '',
                        fprice_vip: _food_data.fprice_vip || '',
                        addon_counts: '',
                        feature_name: '',
                        note,
                        addon_names: '',
                        addon_prices: '',
                        total_price: this.get_total_price({ food, count }),
                        set_count: count
                    };
                }
                await action(save_data);
                this.$forceUpdate();
            },
            showFood(food) {
                // const { food_data, is_out_of_stock, is_active } = data;
                const food_data = food;
                const { is_out_of_stock } = food;
                const is_active = this.currentMenu.is_active;

                if (!is_active || is_out_of_stock == 1) {
                    return;
                }
                let _food_data = JSON.parse(JSON.stringify(food_data));

                if (!_food_data.fprice) {
                    return;
                }

                if (this.FOOD_FEATURE_DICT[_food_data.fid]) {
                    _food_data.feature_default_value = this.FOOD_FEATURE_DICT[_food_data.fid];
                }

                for (let i = 0; i < this.cart_food_list.length; i++) {
                    if (
                        this.cart_food_list[i].fid == _food_data.fid &&
                        this.cart_food_list[i].feature_default_value == _food_data.feature_default_value &&
                        this.cart_food_list[i].uname == this.$store.state.app.uname
                    ) {
                        _food_data.fcount = parseInt(this.cart_food_list[i].fcount);
                    }
                }

                this.target_food_count =
                    (this.cart_food_dict[food_data.fid] && this.cart_food_dict[food_data.fid].mix_fcount) || 0;
                this.detail_data = _food_data;
                this.setDetailPageData({
                    detail_data: this.detail_data,
                    mix_fcount: this.target_food_count,
                    from: 'menu'
                });
                this.detailKey = Math.random();
                this.$nextTick(() => {
                    this.$refs.detail.show();
                });
            },
            showOutOfStock() {
                // console.log('language', this.language);
                const msg = this.$t('menu.itemOutOfStock');
                // let msg = this.language == 'eng' ? 'This item is out of stock' : '该产品已售罄';
                this.set_msg({ msg });
            },
            foodWidthOptionsOrAddons(food) {
                if (food.feature_name && !food.addon_id) {
                    return 'options';
                } else if (food.addon_id && !food.feature_name) {
                    return 'addons';
                } else if (food.feature_name && food.addon_id) {
                    return 'addons & options';
                }
                return '';
            },
            choose_menu_nav(item) {
                this.activeMenuId = item.mid;
                localStorage.activeMenuId = this.activeMenuId;
                let menuHeaderHeight = this.$refs.menuHeader.offsetHeight;
                if (window.scrollY > menuHeaderHeight) {
                    window.scrollTo(0, menuHeaderHeight);
                }
            },
            getLazyLoadImg(product_item) {
                return {
                    src: product_item.fpic,
                    loading: require('../assets/images/image-loading.svg')
                };
            },
            choosePickType(value) {
                this.setOrderType({ type: value });
                // if (value === 'dineIn' && !this.dineInTable) {
                //     this.$refs.table.show();
                // }
            },
            toggleOrderZone() {
                if (this.showOrder) {
                    this.showOrder = false;
                    this.$refs.orderZone.$el.style.display = 'none';
                } else{
                    this.showOrder = true;
                    this.$refs.orderZone.$el.style.display = 'block';
                }
            },
            showOrderZone() {
                this.showOrder = true;
                if (window.innerWidth <= 640) {
                    this.$refs.blackBg.style.display = 'block';
                    this.$refs.orderZone.$el.style.right = '0';
                    this.$refs.orderZone.$el.lastChild.lastChild.style.right = '0';
                } else {
                    this.$refs.orderZone.$el.style.display = 'block';
                }
            },
            hideOrderZone() {
                if (this.showOrder) {
                    this.showOrder = false;
                    if (window.innerWidth <= 640) {
                        this.$refs.blackBg.style.display = 'none';
                        this.$refs.orderZone.$el.style.right = '-100%';
                        this.$refs.orderZone.$el.lastChild.lastChild.style.right = '-100%';
                    } else {
                        this.$refs.orderZone.$el.style.display = 'none';
                    }
                }
            },
            css_category(num) {
                num = num ? num : (window.innerWidth > 640 ? 50 : 25);
                let categoryZone = document.getElementById('category_box'),
                    categoryChildren = categoryZone.childNodes,
                    categoryItem = null,
                    allWidth = 0;

                for (let i = 0; i <= categoryChildren.length; i++) {
                    categoryItem = categoryChildren[i];
                    if (categoryItem != null && categoryItem.nodeType == '1') {
                        allWidth += parseFloat(categoryItem.getBoundingClientRect().width);
                    }
                }
                allWidth += (categoryChildren.length - 1) * num;
                // if (this.isFixed) {
                //     allWidth += 10 * num;
                // }

                if (window.innerWidth <= 640) {
                    allWidth += 16;
                }

                categoryZone.style.width = allWidth + 'px';
                if (allWidth < categoryZone.parentNode.offsetWidth) {
                    categoryZone.className = 'category_inner_box category_must_center';
                }

                if (window.innerWidth > 640 && allWidth > categoryZone.parentNode.offsetWidth) {
                    categoryZone.parentNode.parentNode.childNodes[2].style.display = 'block';
                }
                // console.log('allWidth', allWidth);
            },
            scrollOverHeader() {
                let menuHeaderHeight = this.$refs.menuHeader.offsetHeight;
                let categoryZone = this.$refs.categoryZone;
                let subCategoryZone = window.document.getElementById('sub_category_zone');
                if (window.scrollY > menuHeaderHeight) {
                    this.isFixed = true;
                    categoryZone.className = 'category_zone category_fixed_zone';
                    subCategoryZone.style.display = 'block';

                } else {
                    this.isFixed = false;
                    categoryZone.className = 'category_zone';
                    subCategoryZone.style.display = 'none';
                }
            },
            scrollOverCartBtn() {
                if (window.innerWidth > 1275) {
                    let flatCartEle = this.$refs.flatCart;
                    let flatCartOverHeight = 220;
                    let flatCartLeft = null;
                    if (window.innerWidth <= 1622) {
                        flatCartLeft = (window.innerWidth - 930 - 346) + 950;
                    } else {
                        flatCartLeft = (window.innerWidth - 930) / 2 + 950;
                    }
                    if (window.scrollY > flatCartOverHeight) {
                        this.isCartFixed = true;
                        // categoryZone.className = 'category_zone category_fixed_zone';
                        flatCartEle.style = 'position: fixed; top: 10px; left: '+ flatCartLeft + 'px; z-index: 10;';

                    } else {
                        this.isCartFixed = false;
                        // categoryZone.className = 'category_zone';
                        flatCartEle.style = '';
                    }
                }
            },
            goBack() {
                // 开始新订单
                let temp = {
                    tid: this.shared_tid,
                    sid: this.$store.state.app.sid,
                    cartid: this.$store.state.app.shared_cart_id
                };

                this.quit_table({
                    tid: this.shared_tid,
                    sid: this.$store.state.app.sid,
                    cartid: this.$store.state.app.shared_cart_id,
                    router: this.$router
                });

                this.setOrderType({ type:  undefined});
                this.setDineInTable('');
            },
            checkoutIsFixed() {
                // 判断购物车是否过长，购物车过长，支付按钮需要固定到底部
                let orderZone = this.$refs.orderZone.$el;
                let orderZoneHeight = orderZone.offsetHeight - 16;
                let childHeight = orderZone.lastChild.offsetHeight;
                // console.log('orderZone', orderZone, orderZoneHeight, childHeight);
                if (childHeight > orderZoneHeight) {
                    orderZone.className = "order_zone fixed_button";
                } else {
                    orderZone.className = "order_zone";
                }
            },
            couponShow(obj) {
                if (obj) {
                    this.$refs.coupon.fullMinusTotal = obj.full;
                    this.$refs.coupon.fullMinusItem = obj.minus;
                    this.$refs.coupon.fullMinusDiff = obj.diff;
                    this.$refs.coupon.fullMinusType = obj.type;
                    this.$refs.coupon.show();
                }
            },
            showPolicyLayer(str) {
                if (str === 'dinein') {
                    this.policyTitle = this.$t('menu.dineInPolicy');
                    this.policyText = this.dinein_setting.policy || '';
                } else if (str === 'pickup') {
                    this.policyTitle = this.$t('menu.pickupPolicy');
                    this.policyText = this.pickup_setting.policy || '';
                }
                this.$refs.policy.show();
            },
            showDiscountLayer(str, minus, full, type) {
                if (str === 'first') {
                    this.discountTitle = type === 'fixed' ?
                        this.$t('menu.fixedDiscountTitle', { price: minus }) :
                        this.$t('menu.percentDiscountTitle', { price: this.language == 'chn' ? (100 - minus * 100) / 10 : minus * 100 });
                    this.discountText = this.$t('menu.orderOver', { price: full });
                } else if (str === 'full') {
                    this.discountTitle = type === 'fixed' ?
                        this.$t('menu.fixedDiscountTitle', { price: minus }) :
                        this.$t('menu.percentDiscountTitle', { price: this.language == 'chn' ? (100 - minus) / 10 : minus });
                    this.discountText = this.$t('menu.minimalOrder', { price: full });
                }
                this.$refs.discount.show();
            },
            categoryBoxScroll(str) {
                let categoryZone = document.getElementById('category_box');
                let cWidth = parseFloat(categoryZone.style.width);
                let diff = Math.floor(cWidth - 930);
                let parentNodes = categoryZone.parentNode.parentNode.childNodes;
                if (str === 'right') {
                    if (parentNodes[0].scrollLeft < diff) {
                        parentNodes[0].scrollLeft += 100;
                        if (!parentNodes[1].style.display || parentNodes[1].style.display == 'none') {
                            parentNodes[1].style.display = 'block';
                        }
                        if (parentNodes[0].scrollLeft >= diff) {
                            parentNodes[2].style.display = 'none';
                        }
                    }
                } else if (str === 'left') {
                    if (parentNodes[0].scrollLeft > 0) {
                        parentNodes[0].scrollLeft -= 100;
                        if (!parentNodes[2].style.display || parentNodes[2].style.display == 'none') {
                            parentNodes[2].style.display = 'block';
                        }
                        if (parentNodes[0].scrollLeft <= 0) {
                            parentNodes[1].style.display = 'none';
                        }
                    }
                }
            },
            transTag(str, type) {
                let newStr = '';
                let arr = str ? str.split(',') : [];
                if (type === 'allergy') {
                    for (let i = 0; i < arr.length; i++) {
                        newStr += this.$t('allergy.' + arr[i]) + ', ';
                    }
                } else if (type === 'feature') {
                    for (let i = 0; i < arr.length; i++) {
                        newStr += this.$t('feature.' + arr[i]) + ', ';
                    }
                }
                
                newStr = newStr.substr(0, newStr.length - 2);
                return newStr;
            },
            showPlusMinus(e) {
                // console.log(e, 'e.target');
                e.target.nextSibling.style.display = 'flex';
                e.target.style.display = 'none';
            },
            chooseTable() {
                this.$refs.table.show();
            }
        },
        destroyed() {
            // 页面离开后销毁监听事件
            window.removeEventListener('scroll', this.scrollOverHeader);
            window.removeEventListener('scroll', this.scrollOverCartBtn);
        },
    };
</script>
