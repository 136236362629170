import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

import menu from './modules/menu';
import orders from './modules/orders';
import orderdetail from './modules/orderdetail';
import app from './modules/app';
import sharedorderdetail from './modules/sharedorderdetail';
import welcome from './modules/welcome';
import feedback from './modules/feedback';
import Food from './modules/food';
import Checkout from './modules/checkout';
import Coupon from './modules/coupon';

Vue.use(Vuex);

const state = {
    debug:
        window.location.hostname === '127.0.0.1' ||
        window.location.hostname === 'localhost' ||
        // window.location.hostname === 'minitable1.yunkeguan.com' ||
        window.location.href.indexOf('http://1') === 0
};

const getters = {
    serverRoot(state) {
        const localServerPort = '5757';
        const { hostname, protocol } = window.location;
        if(state.debug){
            return 'http://127.0.0.1:5757';
        }else {
            return `${protocol}//${hostname}${state.debug ? ':' + localServerPort : ''}`;
        }
    }
};

state.is_development = state.debug || window.location.hostname === 'takeout.minitable.link' || window.location.hostname === 'minitable1.yunkeguan.com';

export default new Vuex.Store({
    modules: {
        app,
        menu,
        orders,
        orderdetail,
        sharedorderdetail,
        welcome,
        feedback,
        Food,
        Checkout,
        Coupon
    },
    state,
    getters,
    strict: state.debug,
    plugins: state.debug ? [createLogger()] : []
});
