let special_store = {
    's9a7efec469191b0c13c705df17551072': {
        list: [
            {
                name: "Sheraton",
                state: "NY",
                city: "Brooklyn",
                zip_code: "11201",
                street: "228 Duffield St"
            },
            {
                name: "Aloft",
                state: "NY",
                city: "Brooklyn",
                zip_code: "11201",
                street: "216 Duffield St"
            },
            {
                name: "Indigo",
                state: "NY",
                city: "Brooklyn",
                zip_code: "11201",
                street: "229 Duffield St"
            }
        ]
    },
    's371b4351648ca935e4e84d54be8b71c3': {
        list: [
            {
                name: "Sheraton",
                state: "NY",
                city: "Brooklyn",
                zip_code: "11201",
                street: "228 Duffield St"
            },
            {
                name: "Aloft",
                state: "NY",
                city: "Brooklyn",
                zip_code: "11201",
                street: "216 Duffield St"
            },
            {
                name: "Indigo",
                state: "NY",
                city: "Brooklyn",
                zip_code: "11201",
                street: "229 Duffield St"
            }
        ]
    },
    // 'sda928d4efa9c4cfff2d6ca83729ecea6': {
    //     list: [
    //         {
    //             name: "Sheraton",
    //             state: "NY",
    //             city: "Brooklyn",
    //             zip_code: "11201",
    //             street: "228 Duffield St"
    //         },
    //         {
    //             name: "Aloft",
    //             state: "NY",
    //             city: "Brooklyn",
    //             zip_code: "11201",
    //             street: "216 Duffield St"
    //         },
    //         {
    //             name: "Indigo",
    //             state: "NY",
    //             city: "Brooklyn",
    //             zip_code: "11201",
    //             street: "229 Duffield St"
    //         }
    //     ]
    // }
};

module.exports = special_store;
