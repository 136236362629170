require('es6-promise').polyfill(); //es6 promise
require('isomorphic-fetch'); //fetch

import Vue from 'vue';
import App from './views/App.vue';
import store from './store/index.js';
import router from './router';
import MintUI from 'mint-ui';
import i18n from './locales';
// import { trans, transNames } from './locales';

import 'mint-ui/lib/style.css';
import VueLazyload from 'vue-lazyload';
import './assets/style/reset.css';
import './kits/bee';

Vue.use(MintUI);

Vue.use(VueLazyload, {
    preLoad: 1.3,
    attempt: 1
});

// Vue.prototype.trans = trans;
// Vue.prototype.transNames = transNames;

export function createApp() {
    const app = new Vue({
        el: '#application',
        router,
        store,
        i18n,
        render: (h) => h(App)
    });

    return { app, router };
}

window.onload = () => createApp();
