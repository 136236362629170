<template>
    <div class="order_zone">
        <div v-if="showOrder" class="order_close" @click="hideSelf"><span></span></div>
        <div class="custom_order">
            <div class="order_title">{{ $t('cart.cartOrder') }}</div>
            <div class="select_type">
                <div class="select_type_item dinein_type" v-if="type === 'dineIn'">
                    <span></span>
                    {{ $t('checkout.dineIn') }}
                </div>
                <div class="select_type_item pickup_type" v-else-if="type === 'pickup'">
                    <span></span>
                    <template v-if="shouldShowPickupNote && pickup_require > 0">
                        {{
                            $t('cart.pickupMinimum', {
                                left_money: pickup_require
                            })
                        }}
                    </template>
                    <template v-else >{{ $t('checkout.pickup') }}</template>
                </div>
                <div class="select_type_item delivery_type" v-else-if="type === 'delivery'">
                    <span></span>
                    <div class="delivery_type_title">Deliver to:</div>
                    <div class="delivery_mask" ref="deliverMask" @click="hideDeliverSelect" style="display: none"></div>
                    <div class="delivery_type_select">
                        <span class="select_arrow" v-if="!showDeliverSelectOption" @click="openDeliverOption"></span>
                        <span class="select_arrow select_arrow_up" v-else @click="hideDeliverSelect"></span>
                        <div class="deliver_select" ref="deliverSelect" @click="openDeliverOption">
                            <div class="deliver_select_item">
                                {{ deliverSelectName }}
                            </div>
                        </div>
                        <div class="deliver_option" ref="deliverOption" v-if="showDeliverSelectOption">
                            <div
                                :class=" left_item.name == deliverSelectName ? 'deliver_option_item select' : 'deliver_option_item'"
                                v-for="(left_item, index) in delivery_list"
                                :key="index"
                                @click="chooseDeliverName(left_item.name)"
                            >
                                {{ left_item.name }}
                            </div>
                        </div>
                    </div>
                    <div class="delivery_type_msg" v-for="(left_item, index) in delivery_list" :key="index">
                        <div class="msg_error" v-if="left_item.left_money > 0 && left_item.name == deliverSelectName">
                            {{
                                $t('cart.deliveryMinimum', {
                                    left_money: left_item.left_money,
                                    name: left_item.name
                                })
                            }}
                        </div>
                        <div class="msg_success" v-if="left_item.left_money == 0 && left_item.name == deliverSelectName">
                            {{ $t('cart.deliveryAvailableNew', { name: left_item.name }) }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="food_count == 0">{{ $t('cart.empty') }}</div>
            <template v-else>
                <div class="order_list">
                    <div class="order_list_inner">
                        <div v-for="(item, index) in cart_food_list" class="food_item" :key="index">
                            <div class="order_fpic">
                                <img v-if="item.fpic !== ''" :src="item.fpic" />
                                <!-- <img v-else-if="slogo" :src="slogo" /> -->
                                <img v-else src="../assets/images/placeholder_square.png" />
                            </div>
                            <div class="order_right">
                                <div class="order_text">
                                    <!-- {{ trans(item, 'fname') }} -->
                                    {{ translate('item', item.fname) }}
                                </div>
                                <div class="order_item order_item_inner" :class="{ withNote: item.note }">
                                    <!-- <div class="product_price" style="color: #000;">
                                        <span
                                            style="margin-right: 5px; text-decoration: line-through"
                                            v-if="item.fprice_original"
                                        >
                                            ${{ item.fprice_original }}
                                        </span>
                                        <span
                                            v-if="item.fprice_original"
                                            style="color: var(--theme_color)"
                                        >
                                            ${{ item.fprice }}
                                        </span>
                                        <span
                                            v-if="item.fprice && item.fprice_original == ''"
                                        >
                                            ${{ item.fprice }}
                                        </span>
                                        <span
                                            v-if="
                                                pickup_discount_enable &&
                                                item.fprice_pickup &&
                                                item.fprice_original == ''
                                            "
                                        >
                                            ${{ food.fprice_pickup }}
                                        </span>
                                    </div> -->
                                    <div v-if="!pickup_discount_enable || type !== 'pickup'">
                                        <div
                                            class="weui-media-box__info__meta"
                                            v-if="!is_vip || !item.fprice_vip"
                                            style="float: right; color: black; padding-right: 0"
                                        >
                                            <!-- <span v-if="item.addon_total_price !== undefined && item.addon_total_price">${{ ((item.fprice * item.fcount) + (item.addon_total_price * item.fcount)).toFixed(2) }}</span>
                                            <span v-else>${{ (item.fprice * item.fcount).toFixed(2) }}</span> -->
                                            
                                            <template v-if="item.fprice_original">
                                                <span v-if="item.addon_total_price !== undefined && item.addon_total_price">
                                                    <span style="margin-right: 5px; text-decoration: line-through;">${{ ((item.fprice_original * item.fcount) + (item.addon_total_price * item.fcount)).toFixed(2) }}</span>
                                                    <span style="color: var(--theme_color);">${{ parseFloat(((item.fprice * item.fcount) + (item.addon_total_price * item.fcount)).toFixed(2)) }}</span>
                                                </span>
                                                <template v-else>
                                                    <span style="margin-right: 5px; text-decoration: line-through;">${{ parseFloat((item.fprice_original * item.fcount).toFixed(2)) }}</span>
                                                    <span style="color: var(--theme_color);">${{ parseFloat((item.fprice * item.fcount).toFixed(2)) }}</span>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <span v-if="item.addon_total_price !== undefined && item.addon_total_price">
                                                    ${{ parseFloat(((item.fprice * item.fcount) + (item.addon_total_price * item.fcount)).toFixed(2)) }}
                                                </span>
                                                <span v-else>
                                                    ${{ parseFloat((item.fprice * item.fcount).toFixed(2)) }}
                                                </span>
                                            </template>
                                        </div>
                                        <div
                                            class="weui-media-box__info__meta"
                                            v-if="is_vip && item.fprice_vip"
                                            style="float: right; color: black; padding-right: 0"
                                        >
                                            <span v-if="item.addon_total_price !== undefined && item.addon_total_price">${{ ((item.fprice_vip * item.fcount) + (item.addon_total_price * item.fcount)).toFixed(2) }}</span>
                                            <span v-else>${{ (item.fprice_vip * item.fcount).toFixed(2) }}</span>
                                        </div>
                                        <div
                                            class="weui-media-box__info__meta"
                                            v-if="is_vip && item.fprice_vip"
                                            style="float: right; padding-right: 10px; text-decoration: line-through"
                                        >
                                            <span v-if="item.addon_total_price !== undefined && item.addon_total_price">${{ ((item.fprice * item.fcount) + (item.addon_total_price * item.fcount)).toFixed(2) }}</span>
                                            <span v-else>${{ (item.fprice * item.fcount).toFixed(2) }}</span>
                                        </div>
                                    </div>
                                    <div v-if="pickup_discount_enable && type === 'pickup'">
                                        <div
                                            class="weui-media-box__info__meta"
                                            v-if="item.fprice_pickup"
                                            style="float: right; color: var(--theme_color); padding-right: 0"
                                        >
                                            <template v-if="item.addon_total_price !== undefined && item.addon_total_price">
                                                <span
                                                    style="margin-right: 5px; text-decoration: line-through; color: #000;"
                                                    v-if="item.fprice_original"
                                                >
                                                    ${{ parseFloat(((item.fprice_original * item.fcount) + (item.addon_total_price * item.fcount)).toFixed(2)) }}
                                                </span>
                                                <span
                                                    style="margin-right: 5px; text-decoration: line-through; color: #000;"
                                                    v-else-if="item.fprice"
                                                >
                                                    ${{ parseFloat(((item.fprice * item.fcount) + (item.addon_total_price * item.fcount)).toFixed(2)) }}
                                                </span>
                                                <span>${{ parseFloat(((item.fprice_pickup * item.fcount) + (item.addon_total_price_pickup * item.fcount)).toFixed(2)) }}</span>
                                            </template>
                                            <template v-else>
                                                <span
                                                    style="margin-right: 5px; text-decoration: line-through; color: #000;"
                                                    v-if="item.fprice_original"
                                                >
                                                    ${{ parseFloat((item.fprice_original * item.fcount).toFixed(2)) }}
                                                </span>
                                                <span
                                                    style="margin-right: 5px; text-decoration: line-through; color: #000;"
                                                    v-else-if="item.fprice"
                                                >
                                                    ${{ parseFloat((item.fprice * item.fcount).toFixed(2)) }}
                                                </span>
                                                <span>${{ parseFloat((item.fprice_pickup * item.fcount).toFixed(2)) }}</span>
                                            </template>
                                        </div>
                                        <div
                                            class="weui-media-box__info__meta"
                                            v-if="!item.fprice_pickup"
                                            style="float: right; color: #000; padding-right: 0"
                                        >
                                            <template v-if="item.fprice_original">
                                                <span v-if="item.addon_total_price !== undefined && item.addon_total_price">
                                                    <span style="margin-right: 5px; text-decoration: line-through;">${{ parseFloat(((item.fprice_original * item.fcount) + (item.addon_total_price * item.fcount)).toFixed(2)) }}</span>
                                                    <span style="color: var(--theme_color);">${{ parseFloat(((item.fprice * item.fcount) + (item.addon_total_price_pickup * item.fcount)).toFixed(2)) }}</span>
                                                </span>
                                                <template v-else>
                                                    <span style="margin-right: 5px; text-decoration: line-through;">${{ parseFloat((item.fprice_original * item.fcount).toFixed(2)) }}</span>
                                                    <span style="color: var(--theme_color);">${{ parseFloat((item.fprice * item.fcount).toFixed(2)) }}</span>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <span v-if="item.addon_total_price !== undefined && item.addon_total_price">
                                                    <template v-if="item.addon_total_price != item.addon_total_price_pickup">
                                                        <span style="margin-right: 5px; text-decoration: line-through; color: #000;">${{ parseFloat(((item.fprice * item.fcount) + (item.addon_total_price * item.fcount)).toFixed(2)) }}</span>
                                                        <span style="color: var(--theme_color);">${{ parseFloat(((item.fprice * item.fcount) + (item.addon_total_price_pickup * item.fcount)).toFixed(2)) }}</span>
                                                    </template>
                                                    <template v-else>
                                                        ${{ parseFloat(((item.fprice * item.fcount) + (item.addon_total_price_pickup * item.fcount)).toFixed(2)) }}
                                                    </template>
                                                </span>
                                                <span v-else>
                                                    ${{ parseFloat((item.fprice * item.fcount).toFixed(2)) }}
                                                </span>
                                            </template>
                                            <!-- <span v-if="item.addon_total_price !== undefined && item.addon_total_price">
                                                ${{ ((item.fprice * item.fcount) + (item.addon_total_price * item.fcount)).toFixed(2) }}
                                            </span>
                                            <span v-else>
                                                ${{ (item.fprice * item.fcount).toFixed(2) }}
                                            </span> -->
                                        </div>
                                    </div>
                                    <PlusMinus
                                        v-if="plusMinusVisible"
                                        :count="item.fcount"
                                        :plusCb="() => change_food_count('plus', item)"
                                        :minusCb="() => change_food_count('minus', item)"
                                    />
                                    <span v-else class="food_fcount">{{ item.fcount }}</span>
                                </div>
                                <div class="food_option" v-if="item.feature_name">
                                    <!-- ({{ trans(item, 'feature_name') }}) -->
                                    ({{ translate('feature_value', item.feature_name) }})
                                </div>
                                <div
                                    v-for="(addon_name_item, addon_index) in item.addon_names.split('@@')"
                                    v-bind:key="addon_name_item + '_item_name'"
                                >
                                    <div
                                        v-for="(item_name, item_index) in addon_name_item.split('|')"
                                        v-bind:key="item_name + '_item_val'"
                                        class="food_addon"
                                    >
                                        <div class="addon_count" v-if="addon_name_item">
                                            <span style="font-weight: bold">
                                                {{
                                                    item.addon_counts.split('@@')[addon_index].split('|')[item_index]
                                                }}
                                            </span> *
                                        </div>
                                        <div class="addon_name">
                                            <!-- {{ item_name }} -->
                                            {{ translate('addon_value', item_name) }}
                                        </div>
                                        <template v-if="!pickup_discount_enable || type !== 'pickup'">
                                            <div class="addon_price" v-if="addon_name_item">
                                                (${{
                                                    (
                                                        item.addon_counts.split('@@')[addon_index].split('|')[item_index] *
                                                        item.addon_prices.split('@@')[addon_index].split('|')[item_index]
                                                    ).toFixed(2)
                                                }})
                                            </div>
                                        </template>
                                        <template v-if="pickup_discount_enable && type === 'pickup'">
                                            <div class="addon_price" v-if="addon_name_item">
                                                (
                                                    <template v-if="item.addon_pickup_discounts.split('@@')[addon_index].split('|')[item_index] < 1">
                                                        <span
                                                            style="margin-right: 5px; text-decoration: line-through;"
                                                        >
                                                            ${{
                                                                (
                                                                    item.addon_counts.split('@@')[addon_index].split('|')[item_index] *
                                                                    item.addon_prices.split('@@')[addon_index].split('|')[item_index]
                                                                ).toFixed(2)
                                                            }}
                                                        </span>
                                                        <span style="color: var(--theme_color);">
                                                            ${{
                                                                (
                                                                    item.addon_counts.split('@@')[addon_index].split('|')[item_index] *
                                                                    (item.addon_prices.split('@@')[addon_index].split('|')[item_index] *
                                                                        item.addon_pickup_discounts.split('@@')[addon_index].split('|')[
                                                                            item_index
                                                                        ])
                                                                ).toFixed(2)
                                                            }}
                                                        </span>
                                                    </template>
                                                    <span v-else>
                                                        ${{
                                                            (
                                                                item.addon_counts.split('@@')[addon_index].split('|')[item_index] *
                                                                (item.addon_prices.split('@@')[addon_index].split('|')[item_index] *
                                                                    item.addon_pickup_discounts.split('@@')[addon_index].split('|')[
                                                                        item_index
                                                                    ])
                                                            ).toFixed(2)
                                                        }}
                                                    </span>
                                                )
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <div v-if="item.note" class="note">{{ $t('cart.note') }} {{ item.note }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="order_promotion" v-if="promotion_data.full_coupon.length > 0">
                    <div class="promotion_title">
                        {{ $t('cart.promotion') }}
                    </div>
                    <div class="promotion_success" v-if="cart_full_coupon_discount < 0">
                        {{ $t('cart.saveMoney', { price: cart_full_coupon_discount * -1}) }}
                    </div>
                    <div class="promotion_error" v-if="isCouponObj && isCouponObj.diff > 0">
                        <template v-if="isCouponObj.type == 'fixed'">
                            {{ $t('cart.fixedDiscount', {left_money: isCouponObj.diff, price: isCouponObj.minus}) }}
                        </template>
                        <template v-else-if="isCouponObj.type == 'bysubtotal'">
                            {{ $t('cart.percentDiscount', {left_money: isCouponObj.diff, number: language == 'chn' ? (100 - isCouponObj.minus) / 10 : isCouponObj.minus}) }}
                        </template>
                    </div>
                </div>
                <div class="order_fee">
                    <div class="fee_item">
                        <div class="order_item fee_item_inner">
                            <span class="order_text">{{ $t('common.subtotal') }}</span>
                            <span class="order_price">
                                <!-- <template v-if="!pickup_discount_enable || type != 'pickup'">
                                    <img
                                        v-if="is_vip && cart_price_vip > 0"
                                        src="../assets/images/price-tag.png"
                                        class="price_tag"
                                    />
                                    ${{ is_vip === '1' ? cart_price_vip.toFixed(2) : cart_price.toFixed(2) }}
                                </template>
                                <template v-else> ${{ cart_price_pickup.toFixed(2) }} </template> -->
                                <!-- 显示未优惠的价格 -->
                                ${{ cart_price_original_total.toFixed(2) }}
                            </span>
                        </div>
                        <!-- <div class="order_desc">¥33.33 until minimum delivery</div> -->
                    </div>
                    <!-- 已经有pickup Discount，此处不需要显示 -->
                    <!-- <div v-if="type === 'pickup' && pickup_discount_enable && pickup_promotion > 0">
                        <span style="color: #000; font-size: 14px; line-height: 20px">
                            <div style="text-align: right; display: inline-block; width: 100%">
                                <p>
                                    <span>{{ $t('cart.pickupDiscount') }}</span>
                                    <span style="color: red; text-decoration: line-through">
                                        {{ '$' + cart_price }}
                                    </span>
                                    <span>{{ $t('cart.save') }}</span>
                                    <span style="color: green"> {{ '$' + pickup_promotion }} </span>
                                    <span>)</span>
                                </p>
                            </div>
                        </span>
                    </div> -->
                    <!-- 提示文字已经在购物车上方显示了，此处不需要 -->
                    <!-- <div v-if="shouldShowPickupNote">
                        <div v-if="pickup_require > 0" style="color: red; font-size: 14px; line-height: 20px">
                            <div style="text-align: right; display: inline-block; width: 100%">
                                <p style="margin: 0">
                                    <span style="font-weight: bold">
                                        {{
                                            $t('cart.pickupMinimum', {
                                                left_money: pickup_require
                                            })
                                        }}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div> -->
                    <!-- 提示文字已经在购物车上方显示了，此处不需要 -->
                    <!-- <div v-if="shouldShowDeliveryNote">
                        <div style="color: red; font-size: 14px; line-height: 20px">
                            <div style="text-align: right; display: inline-block; width: 100%">
                                <p v-for="(left_item, index) in delivery_require_list" style="margin: 0" :key="index">
                                    <span v-if="left_item.left_money > 0" style="font-weight: bold">
                                        {{
                                            $t('cart.deliveryMinimum', {
                                                left_money: left_item.left_money,
                                                name: left_item.name
                                            })
                                        }}
                                    </span>
                                    <span v-if="left_item.left_money == 0" style="font-weight: bold; color: #249c55">
                                        {{ $t('cart.deliveryAvailable', { name: left_item.name }) }}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div> -->
                    <div class="fee_item" v-if="specialOffer > 0">
                        <div class="order_item fee_item_inner order_theme_color">
                            <span class="order_text">{{ $t('cart.specialOffer') }}</span>
                            <span class="order_price"> -${{ specialOffer }} </span>
                        </div>
                    </div>
                    <div class="fee_item" v-if="type === 'pickup' && pickup_discount_enable && pickup_promotion > 0">
                        <div class="order_item fee_item_inner order_theme_color">
                            <span class="order_text">{{ $t('cart.pickupDiscountNew') }}</span>
                            <span class="order_price"> -${{ pickup_promotion }} </span>
                        </div>
                    </div>
                    <div class="fee_item" v-if="cart_full_coupon_discount < 0">
                        <div class="order_item fee_item_inner order_theme_color">
                            <span class="order_text">
                                <!-- {{ $t('common.fullDiscount') }} -->
                                <span>
                                    {{
                                        $t('cart.fullDiscountText', {
                                            total_price: cart_full_coupon_obj.full,
                                            discount_price: cart_full_coupon_obj.type === 'fixed' ? '$' + cart_full_coupon_obj.discount : cart_full_coupon_obj.discount + '%'
                                        })
                                    }}
                                </span>
                            </span>
                            <span class="order_price">-${{ cart_full_coupon_discount * -1 }}</span>
                        </div>
                        <!-- <div class="order_input">
                            <input placeholder="Enter code" type="text" />
                        </div> -->
                    </div>
                    <div class="fee_item" v-if="cart_new_discount < 0">
                        <div class="order_item fee_item_inner order_theme_color">
                            <span class="order_text">
                                <!-- {{ $t('common.newOrderDiscount') }} -->
                                {{ cart_first_order }}
                                <!-- <span>
                                    {{
                                        $t('cart.newDiscountText', {
                                            discount_price: cart_first_order
                                        })
                                    }}
                                </span> -->
                            </span>
                            <span class="order_price">-${{ cart_new_discount * -1 }}</span>
                        </div>
                    </div>
                    <div class="fee_item">
                        <div class="order_item fee_item_inner">
                            <span class="order_text">{{ $t('common.taxAndFee') }}</span>
                            <span class="order_price"> ${{ taxAndFee }} </span>
                        </div>
                    </div>
                    <div class="fee_item" v-if="type === 'delivery'">
                        <div class="order_item fee_item_inner">
                            <span class="order_text">{{ $t('common.deliveryFee') }}</span>
                            <span v-if="zip_code_index > -1" class="order_price">${{ cart_price_delivery_fee }}</span>
                            <span v-else class="order_price">TBD</span>
                        </div>
                    </div>
                    <div class="fee_item" v-if="processingFee">
                        <div class="order_item fee_item_inner">
                            <span class="order_text">{{ $t('common.processingFee') }}</span>
                            <span class="order_price"> ${{ processingFee }} </span>
                        </div>
                    </div>
                    <div class="fee_item" v-if="tip > 0">
                        <div class="order_item fee_item_inner">
                            <span class="order_text">{{ $t('cart.tip') }}</span>
                            <span class="order_price"> ${{ (tip * 1).toFixed(2) }} </span>
                        </div>
                    </div>
                    <!-- <div class="fee_item">
                        <div class="order_item fee_item_inner" style="font-size: 14px">
                            <span class="order_text" style="font-weight: bold">{{ $t('common.total') }}</span>
                            <span class="order_price">
                                <img
                                    v-if="is_vip && cart_price_vip > 0"
                                    src="../assets/images/price-tag.png"
                                    style="width: 20px; height: 20px; vertical-align: bottom"
                                />
                                <span style="font-weight: bold">
                                    <template v-if="totalCheckout">
                                        {{ totalCheckout }}
                                    </template>
                                    <template v-else>
                                        <div v-if="pickup_discount_enable && type === 'pickup'">
                                            ${{ cart_price_pickup_total }}
                                        </div>
                                        <div v-else>
                                            ${{ is_vip === '1' ? cart_price_vip_total : cart_price_total }}
                                        </div>
                                    </template>
                                </span>
                            </span>
                        </div>
                    </div> -->
                </div>
                <div class="order_fee order_total">
                    <div class="fee_item">
                        <div class="order_item fee_item_inner">
                            <span class="order_text">{{ mainTotalTxt }}</span>
                            <span class="order_price">
                                <img
                                    v-if="is_vip && cart_price_vip > 0"
                                    src="../assets/images/price-tag.png"
                                    style="width: 20px; height: 20px; vertical-align: bottom"
                                />
                                <span>
                                    <template v-if="totalCheckout">
                                        {{ totalCheckout }}
                                    </template>
                                    <template v-else>
                                        <div v-if="pickup_discount_enable && type === 'pickup'">
                                            ${{ cart_price_pickup_total }}
                                        </div>
                                        <div v-else>
                                            ${{ is_vip === '1' ? cart_price_vip_total : cart_price_total }}
                                        </div>
                                    </template>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="check_out" v-if="plusMinusVisible || showPayBtn">
                    <mt-button type="danger" @click="checkout" :data-bee="'module_id=button&item_id=' + beeButtonId">{{ mainButtonTxt }}</mt-button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapGetters, mapMutations, createNamespacedHelpers } from 'vuex';
    import PlusMinus from '@/components/Plus_Minus';
    import { isWeixin, isIOS } from '../kits/tools';
    const App = createNamespacedHelpers('app');

    let HAS_TOAST = false;
    export default {
        name: 'cart',
        components: {
            PlusMinus
        },
        props: {
            placeOrder: Function,
            totalCheckout: String,
            tip: Number,
            processingFee: Number,
            showPayBtn: Boolean,
            hideSelf: Function,
            showOrder: Boolean,
            couponShow: Function,
            zipCode: String
        },
        data() {
            return {
                sid: this.$store.state.app.sid,
                code: this.$store.state.app.code,
                submit_text: 'Place order',
                detail_data: {},
                target_food_count: 0,
                target_food_mix_fcount: 0,
                shared_tname: this.$store.state.app.shared_tname,
                is_wechat: 0,

                note: '',
                // zip_code: this.$store.state.app.zip_code,
                // zip_code_index: this.$store.state.app.zip_code_index,

                has_saved_address: this.$store.state.app.zip_code_index > -1,
                expend: -1,
                is_development: this.$store.state.is_development,
                day_to_text: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
                showDeliverSelectOption: false,
                deliverSelectName: '',
            };
        },
        created() {
            let _title = this.$t('common.cart');
            if (this.food_count > 0) {
                _title = _title + '(' + this.food_count + ' items)';
            }

            this.set_app_title(_title);

            const ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) === 'micromessenger') {
                this.is_wechat = 1;
            }

            let checkZipCode = '';
            if (this.zip_code) {
                checkZipCode = this.zip_code;
            } else if (this.zipCode) {
                checkZipCode = this.zipCode;
            }

            if (checkZipCode) {
                // console.log('zipCode created', checkZipCode);
                let dName = this.checkDeliverName(checkZipCode);

                if (dName) {
                    this.deliverSelectName = dName;
                }
            } else if (this.deliveryFirstName) {
                this.deliverSelectName = this.deliveryFirstName;
            }
        },
        computed: {
            plusMinusVisible() {
                const path = this.$route.path;
                if (path === '/checkout') {
                    return false;
                }
                return true;
            },
            mainButtonTxt() {
                const path = this.$route.path;
                if (path === '/menu') {
                    return this.$t('cart.checkout');
                } else if (path === '/checkout') {
                    return this.$t('cart.placeOrder');
                }
                return '';
            },
            beeButtonId() {
                const path = this.$route.path;
                if (path === '/menu') {
                    return 'check_out';
                } else if (path === '/checkout') {
                    return 'place_order';
                }
                return '';
            },
            mainTotalTxt() {
                const path = this.$route.path;
                if (path === '/menu') {
                    return this.$t('common.total');
                } else if (path === '/checkout') {
                    return this.$t('common.placeTotal');
                }
                return '';
            },
            taxAndFee() {
                if (this.pickup_discount_enable && this.type === 'pickup') {
                    return this.cart_price_pickup_tax;
                } else {
                    return this.is_vip === '1' ? this.cart_price_vip_tax : this.cart_price_tax;
                }
            },
            shouldShowPickupNote() {
                return this.pickup_setting.enable === '1' && !this.can_pickup && this.type === 'pickup';
            },
            shouldShowDeliveryNote() {
                return this.delivery_setting.enable === '1' && !this.can_delivery && this.type === 'delivery';
            },
            placeorderToolTip() {
                let text = '';
                if (this.pickup_discount_enable && this.type === 'pickup') {
                    text = this.cart_price_pickup_total;
                } else {
                    text = this.is_vip === '1' ? this.cart_price_vip_total : this.cart_price_total;
                }
                return '$' + text;
            },
            pickup_promotion() {
                if (this.cart_price_pickup < this.cart_price) {
                    return (this.cart_price - this.cart_price_pickup).toFixed(2);
                }

                return 0;
            },
            left_money() {
                let delivery_price = this.delivery_data.delivery_amount || 0;
                if (this.pickup_discount_enable && this.type === 'pickup') {
                    return (delivery_price - this.cart_price_pickup).toFixed(2);
                } else if (this.is_vip) {
                    return (delivery_price - this.cart_price_vip).toFixed(2);
                }

                return (delivery_price - this.cart_price).toFixed(2);
            },
            ...App.mapState(['type', 'show_cart_detail', 'zip_code', 'zip_code_index']),
            ...mapGetters({
                translate: 'app/translate',
                pay_method_setting: 'app/get_paymethod_setting',
                is_vip: 'app/get_if_is_vip',
                food_count: 'app/get_food_count',
                cart_food_list: 'app/get_cart_food_list',
                cart_price: 'app/get_cart_price',
                cart_price_vip: 'app/get_cart_price_vip',
                cart_price_pickup: 'app/get_cart_price_pickup',
                cart_food_dict: 'app/get_cart_food_dict',
                shared_oid: 'app/get_shared_oid',
                msg: 'app/get_app_msg',
                can_place_order: 'app/get_if_can_place_order',
                language: 'app/get_language',
                tax: 'app/get_tax',
                tax_with_discount: 'app/get_tax_with_discount',
                delivery_data: 'app/get_delivery_data',
                promotion_data: 'app/get_promotion_data',
                is_new: 'app/get_is_new',
                tip_method_setting: 'app/get_tip_method_setting',
                is_vip_new: 'app/get_is_vip_new',
                merchant_custom_value: 'app/get_merchant_custom_value',
                is_loading: 'app/get_if_loading',
                pickup_setting: 'app/get_pickup_setting',
                delivery_setting: 'app/get_delivery_setting',
                pickup_discount_enable: 'app/get_pickup_discount_enable',
                slogo: 'app/get_store_slogo',
                deliveryFirstName: 'app/get_delivery_first_name',
            }),
            cart_price_tax() {
                let _tax = (this.cart_price * this.tax).toFixed(2) * 1;
                if (this.tax_with_discount == 1) {
                    _tax = ((this.cart_price + this.cart_new_discount + this.cart_full_coupon_discount) * this.tax).toFixed(2) * 1;
                }
                return _tax < 0 ? 0 : _tax;
            },
            cart_price_vip_tax() {
                let _tax = ((this.cart_price_vip) * this.tax).toFixed(2) * 1;
                if (this.tax_with_discount == 1) {
                    _tax = ((this.cart_price_vip + this.cart_new_discount + this.cart_full_coupon_discount) * this.tax).toFixed(2) * 1;
                }
                return _tax < 0 ? 0 : _tax;
            },
            cart_price_pickup_tax() {
                let _tax = ((this.cart_price_pickup) * this.tax).toFixed(2) * 1;
                if (this.tax_with_discount == 1) {
                    _tax = ((this.cart_price_pickup + this.cart_new_discount + this.cart_full_coupon_discount) * this.tax).toFixed(2) * 1;
                }
                return _tax < 0 ? 0 : _tax;
            },
            cart_price_fee() {
                let _fee = 0;
                let _extra = 0;

                if (this.type === 'delivery') {
                    _fee = this.delivery_setting.processing_fee || 0;
                    _extra = this.delivery_setting.processing_extra || 0;

                    if (this.delivery_setting.processing_not_applied === '1' && this.pay_method === 'pickup') {
                        return 0;
                    }
                } else if (this.type === 'pickup') {
                    _fee = this.pickup_setting.processing_fee || 0;
                    _extra = this.pickup_setting.processing_extra || 0;

                    if (this.pickup_setting.processing_not_applied === '1' && this.pay_method === 'pickup') {
                        return 0;
                    }
                }

                return ((this.cart_price + this.cart_price_tax + this.cart_new_discount + this.cart_full_coupon_discount) * _fee + _extra * 1).toFixed(2) * 1;
            },
            cart_price_vip_fee() {
                let _fee = 0;
                let _extra = 0;

                if (this.type === 'delivery') {
                    _fee = this.delivery_setting.processing_fee || 0;
                    _extra = this.delivery_setting.processing_extra || 0;

                    if (this.delivery_setting.processing_not_applied === '1' && this.pay_method === 'pickup') {
                        return 0;
                    }
                } else if (this.type === 'pickup') {
                    _fee = this.pickup_setting.processing_fee || 0;
                    _extra = this.pickup_setting.processing_extra || 0;

                    if (this.pickup_setting.processing_not_applied === '1' && this.pay_method === 'pickup') {
                        return 0;
                    }
                }

                return ((this.cart_price_vip + this.cart_price_vip_tax + this.cart_new_discount + this.cart_full_coupon_discount) * _fee + _extra * 1).toFixed(2) * 1;
            },
            cart_price_pickup_fee() {
                let _fee = 0;
                let _extra = 0;

                if (this.type === 'delivery') {
                    _fee = this.delivery_setting.processing_fee || 0;
                    _extra = this.delivery_setting.processing_extra || 0;

                    if (this.delivery_setting.processing_not_applied === '1' && this.pay_method === 'pickup') {
                        return 0;
                    }
                } else if (this.type === 'pickup') {
                    _fee = this.pickup_setting.processing_fee || 0;
                    _extra = this.pickup_setting.processing_extra || 0;

                    if (this.pickup_setting.processing_not_applied === '1' && this.pay_method === 'pickup') {
                        return 0;
                    }
                }

                return ((this.cart_price_pickup + this.cart_price_pickup_tax + this.cart_new_discount + this.cart_full_coupon_discount) * _fee + _extra * 1).toFixed(2) * 1;
            },
            cart_price_delivery_fee() {
                if (this.type === 'delivery') {
                    if (this.delivery_data.delivery_vip_free === '1' && this.is_vip_new === '1') {
                        return 0;
                    }

                    if (this.zip_code_index > -1) {
                        const delivery_data = this.delivery_data.delivery_area_list[this.zip_code_index];
                        if (delivery_data && delivery_data.delivery_fee_type === 'bysubtotal') {
                            let subtotal = this.cart_price * 1;
                            let now_tax = this.cart_price_tax * 1;
                            if (this.pickup_discount_enable && this.type === 'pickup') {
                                subtotal = this.cart_price_pickup * 1;
                                now_tax = this.cart_price_pickup_tax * 1;
                            }

                            return (
                                (
                                    this.delivery_data.delivery_area_list[this.zip_code_index].delivery_fee *
                                    (subtotal + now_tax)
                                ).toFixed(2) * 1
                            );
                        }

                        return this.delivery_data.delivery_area_list[this.zip_code_index].delivery_fee || 0;
                    }
                }

                return 0;
            },
            cart_price_original_total() {
                let total_original = 0;
                if (this.cart_food_list.length > 0) {
                    for(let i = 0; i < this.cart_food_list.length; i++) {
                        let extra_price = 0;
                        if (this.cart_food_list[i].addon_prices) {
                            let _addon_items = this.cart_food_list[i].addon_prices.split('@@');
                            let _addon_counts = this.cart_food_list[i].addon_counts.split('@@');
                            for (let n = 0, nLen = _addon_items.length; n < nLen; n++) {
                                let price_arr = _addon_items[n].split('|');
                                let counts_arr = _addon_counts[n].split('|');

                                for (let m = 0, mLen = price_arr.length; m < mLen; m++) {
                                    extra_price += parseFloat(price_arr[m] * counts_arr[m]);
                                }
                            }
                        }
                        let _fprice = this.cart_food_list[i].fprice_original ? this.cart_food_list[i].fprice_original : this.cart_food_list[i].fprice;
                        total_original = total_original + this.cart_food_list[i].fcount * (parseFloat(_fprice) + extra_price);
                    }
                }
                    
                return total_original;
            },
            cart_price_total() {
                let _price = (
                    1 *
                    (this.cart_price +
                        this.cart_new_discount +
                        this.cart_full_coupon_discount +
                        this.cart_price_tax +
                        // this.cart_price_fee +
                        this.cart_price_delivery_fee)
                ).toFixed(2);
                return _price < 0 ? 0 : _price;
            },
            cart_price_vip_total() {
                let _price = (
                    1 *
                    (this.cart_price_vip +
                        this.cart_new_discount +
                        this.cart_full_coupon_discount +
                        this.cart_price_vip_tax +
                        // this.cart_price_vip_fee +
                        this.cart_price_delivery_fee)
                ).toFixed(2);
                return _price < 0 ? 0 : _price;
            },
            cart_price_pickup_total() {
                let _price = (
                    1 *
                    (this.cart_price_pickup +
                        this.cart_new_discount +
                        this.cart_full_coupon_discount +
                        this.cart_price_pickup_tax +
                        // this.cart_price_pickup_fee +
                        this.cart_price_delivery_fee)
                ).toFixed(2);
                return _price < 0 ? 0 : _price;
            },
            can_pickup() {
                if (this.pickup_setting.enable === '0') {
                    return false;
                }

                let temp_cart_price = 0;

                if (this.pickup_discount_enable && this.type === 'pickup') {
                    temp_cart_price =
                        this.cart_price_pickup * 1 + this.cart_full_coupon_discount * 0 + this.cart_new_discount * 0;
                } else {
                    if (this.is_vip) {
                        temp_cart_price =
                            this.cart_price_vip * 1 + this.cart_full_coupon_discount * 0 + this.cart_new_discount * 0;
                    } else {
                        temp_cart_price =
                            this.cart_price * 1 + this.cart_full_coupon_discount * 0 + this.cart_new_discount * 0;
                    }
                }

                let temp_pickup_amount = this.pickup_setting.amount * 1;

                if (temp_cart_price < temp_pickup_amount) {
                    return false;
                }

                return true;
            },
            can_delivery() {
                if (this.delivery_setting.enable === '0' || this.zip_code === '' || this.zip_code_index === -1) {
                    return false;
                }

                let temp_cart_price = 0;

                if (this.pickup_discount_enable && this.type === 'pickup') {
                    temp_cart_price =
                        this.cart_price_pickup * 1 + this.cart_full_coupon_discount * 0 + this.cart_new_discount * 0;
                } else {
                    if (this.is_vip) {
                        temp_cart_price =
                            this.cart_price_vip * 1 + this.cart_full_coupon_discount * 0 + this.cart_new_discount * 0;
                    } else {
                        temp_cart_price =
                            this.cart_price * 1 + this.cart_full_coupon_discount * 0 + this.cart_new_discount * 0;
                    }
                }

                let temp_delivery_amount =
                    this.delivery_data.delivery_area_list[this.zip_code_index].minimum_amount * 1;

                if (temp_cart_price < temp_delivery_amount) {
                    return false;
                }

                return true;
            },
            pickup_require() {

                let temp_minimum_amount = this.pickup_setting.amount * 1;
                let left_money = 0;

                if (this.pickup_discount_enable && this.type === 'pickup') {
                    left_money = (temp_minimum_amount - this.cart_price_pickup).toFixed(2);
                } else {
                    if (this.is_vip) {
                        left_money = (temp_minimum_amount - this.cart_price_vip).toFixed(2);
                    } else {
                        left_money = (temp_minimum_amount - this.cart_price).toFixed(2);
                    }
                }

                left_money = left_money < 0 ? 0 : left_money;

                return left_money;
            },
            delivery_list() {
                let _arr = [];
                for (let i = 0; i < this.delivery_data.delivery_area_list.length; i++) {

                    let temp_minimum_amount = this.delivery_data.delivery_area_list[i].minimum_amount * 1;
                    let left_money = 0;

                    if (this.pickup_discount_enable && this.type === 'pickup') {
                        left_money = parseFloat((temp_minimum_amount - this.cart_price_pickup).toFixed(2));
                    } else {
                        if (this.is_vip) {
                            left_money = parseFloat((temp_minimum_amount - this.cart_price_vip).toFixed(2));
                        } else {
                            left_money = parseFloat((temp_minimum_amount - this.cart_price).toFixed(2));
                        }
                    }

                    _arr.push({
                        name: this.delivery_data.delivery_area_list[i].name,
                        left_money: left_money <= 0 ? 0 : left_money,
                        minimum_amount: temp_minimum_amount
                    });
                }

                return _arr;
            },
            delivery_require_list() {
                let _arr = [];

                let zipCode = this.zipCode ? this.zipCode : '';
                for (let i = 0; i < this.delivery_data.delivery_area_list.length; i++) {
                    if (
                        this.zip_code != '' &&
                        this.delivery_data.delivery_area_list[i].zip_code_list.indexOf(this.zip_code) === -1 ||
                        zipCode != '' &&
                        this.delivery_data.delivery_area_list[i].zip_code_list.indexOf(zipCode) === -1
                    ) {
                        continue;
                    }

                    let temp_minimum_amount = this.delivery_data.delivery_area_list[i].minimum_amount * 1;
                    let left_money = 0;

                    if (this.pickup_discount_enable && this.type === 'pickup') {
                        left_money = (temp_minimum_amount - this.cart_price_pickup).toFixed(2);
                    } else {
                        if (this.is_vip) {
                            left_money = (temp_minimum_amount - this.cart_price_vip).toFixed(2);
                        } else {
                            left_money = (temp_minimum_amount - this.cart_price).toFixed(2);
                        }
                    }

                    _arr.push({
                        name: this.delivery_data.delivery_area_list[i].name,
                        left_money: left_money <= 0 ? 0 : left_money,
                        minimum_amount: temp_minimum_amount
                    });
                }

                return _arr;
            },
            cart_full_coupon_discount() {
                let _discount = 0;

                if (this.promotion_data.full_coupon.length > 0) {
                    let _price = this.is_vip ? this.cart_price_vip : this.cart_price;
                    if (this.pickup_discount_enable && this.type === 'pickup') {
                        _price = this.cart_price_pickup || this.cart_price;
                    }

                    let _full_coupon_list = JSON.parse(JSON.stringify(this.promotion_data.full_coupon));
                    _full_coupon_list = _full_coupon_list.sort((a, b) => {
                        return b.full - a.full;
                    });

                    let _type = '';
                    for (let i = 0; i < _full_coupon_list.length; i++) {
                        if (_price >= _full_coupon_list[i].full) {
                            _discount = _full_coupon_list[i].minus;
                            _type = _full_coupon_list[i].type || 'fixed';
                            break;
                        }
                    }

                    if (_type === 'fixed') {
                        return _discount * -1;
                    } else if (_type === 'bysubtotal') {
                        // return (this.cart_price * _discount * 0.01).toFixed(2) * -1;
                        return (_price * _discount * 0.01).toFixed(2) * -1;
                    }
                }

                return _discount * -1;
            },
            cart_full_coupon_price() {
                let _price = 0;
                let _discount = Math.abs(this.cart_full_coupon_discount);
                if (_discount) {
                    let _full_coupon_list = JSON.parse(JSON.stringify(this.promotion_data.full_coupon));
                    for (let i = 0; i < _full_coupon_list.length; i++) {
                        if (_full_coupon_list[i].minus == _discount) {
                            _price = _full_coupon_list[i].full;
                            break;
                        }
                    }
                    return _price;
                }
                return _price;
            },
            cart_full_coupon_obj() {
                let _price = 0;
                let _type = '';
                let _minus = null;
                let _discount = Math.abs(this.cart_full_coupon_discount);
                if (_discount) {
                    let _full_coupon_list = JSON.parse(JSON.stringify(this.promotion_data.full_coupon));
                    _full_coupon_list = _full_coupon_list.sort((a, b) => {
                        return b.full - a.full;
                    });

                    let _cart_price = this.is_vip ? this.cart_price_vip : this.cart_price;
                    if (this.pickup_discount_enable && this.type === 'pickup') {
                        _cart_price = this.cart_price_pickup || this.cart_price;
                    }

                    for (let i = 0; i < _full_coupon_list.length; i++) {
                        if (_full_coupon_list[i].type && _full_coupon_list[i].type == 'bysubtotal' && (_cart_price * _full_coupon_list[i].minus * 0.01).toFixed(2) == _discount && _cart_price >= _full_coupon_list[i].full) {
                            _price = _full_coupon_list[i].full;
                            _type = 'bysubtotal';
                            _minus = _full_coupon_list[i].minus;
                            break;
                        } else if (_full_coupon_list[i].minus == _discount && _full_coupon_list[i].type != 'bysubtotal' && _cart_price >= _full_coupon_list[i].full) {
                            _price = _full_coupon_list[i].full;
                            _type = 'fixed';
                            _minus = _full_coupon_list[i].minus;
                            break;
                        }
                    }
                    return {
                        full: _price,
                        discount: _minus,
                        type: _type || 'fixed'
                    }
                }
                return {
                        full: 0,
                        discount: 0,
                        type: 'fixed'
                }
            },
            cart_new_discount() {
                if (this.is_new && this.promotion_data.first_discount_amount > 0) {
                    if (this.promotion_data.first_discount_limit_amount > 0) {
                        if (this.pickup_discount_enable && this.type === 'pickup') {
                            if (this.cart_price_pickup < this.promotion_data.first_discount_limit_amount) {
                                return 0;
                            }
                        } else {
                            if (this.cart_price < this.promotion_data.first_discount_limit_amount) {
                                return 0;
                            }
                        }
                    }

                    if (this.promotion_data.first_discount_type === 'fixed') {
                        return this.promotion_data.first_discount_amount * -1;
                    } else if (this.promotion_data.first_discount_type === 'bysubtotal') {
                        if (this.pickup_discount_enable && this.type === 'pickup') {
                            return (this.cart_price_pickup * this.promotion_data.first_discount_amount).toFixed(2) * -1;
                        } else {
                            return (this.cart_price * this.promotion_data.first_discount_amount).toFixed(2) * -1;
                        }
                    }
                }

                return 0;
            },
            cart_first_order() {
                if (Math.abs(this.cart_new_discount)) {
                    let _msg = null;
                    if (this.promotion_data.first_discount_type === 'fixed') {
                        _msg = this.$t('cart.newDiscountText', {
                            discount_price: this.promotion_data.first_discount_amount
                        });
                                    
                        return _msg;
                    } else if (this.promotion_data.first_discount_type === 'bysubtotal') {
                        if (this.language == 'chn') {
                            _msg = this.$t('cart.newDiscountPer', {
                                discount_price: 10 - this.promotion_data.first_discount_amount * 10
                            });
                        } else {
                            _msg = this.$t('cart.newDiscountPer', {
                                discount_price: this.promotion_data.first_discount_amount * 100
                            });
                        }
                        return _msg;
                    }
                }
                return 0;
            },
            isCouponObj() {
                let _total = 0;

                if (!this.pickup_discount_enable || this.type != 'pickup') {
                    _total = this.is_vip === '1' ? this.cart_price_vip.toFixed(2) : this.cart_price.toFixed(2);
                } else {
                    _total = this.cart_price_pickup.toFixed(2);
                }

                // console.log('_total', _total);

                let _obj = {};

                let _full_coupon_list = JSON.parse(JSON.stringify(this.promotion_data.full_coupon));
                _full_coupon_list = _full_coupon_list.sort((a, b) => {
                    return a.full - b.full;
                });
                // console.log('_full_coupon_list', _full_coupon_list);
                // 满减弹框只显示最低档满减优惠
                if (_full_coupon_list.length > 0 && _full_coupon_list[0].full > _total) {
                    _obj.full = _full_coupon_list[0].full;
                    _obj.minus = _full_coupon_list[0].minus;
                    _obj.type = _full_coupon_list[0].type || 'fixed';
                    _obj.diff = (_full_coupon_list[0].full - _total).toFixed(2);
                }
                // console.log('_obj', _obj);
                // for (let i = 0; i < _full_coupon_list.length; i++) {
                //     if (_full_coupon_list[i].full > _total) {
                //         _obj.full = _full_coupon_list[i].full;
                //         _obj.minus = _full_coupon_list[i].minus;
                //         _obj.diff = (_full_coupon_list[i].full - _total).toFixed(2);
                //         break;
                //     }
                // }

                if (_obj.full) {
                    return _obj;
                } else {
                    return false;
                }
            },
            specialOffer() {
                let _price = (this.cart_price_original_total - this.cart_price).toFixed(2);
                return _price < 0 ? 0 : _price;
            }
        },
        watch: {
            food_count() {
                let _title = this.$t('common.cart');
                if (this.food_count > 0) {
                    _title = _title + '(' + this.food_count + ' items)';
                }
                this.set_app_title(_title);
            },
            cart_food_list(newList, oldList) {
                // console.log('newList, oldList', newList, oldList);
                let removedItem = [];
                let msg = '';
                // no items removed, do nothing
                if (newList.length === oldList.length || newList.length > oldList.length) {
                    return;
                }
                if (newList.length === 0) {
                    msg = this.$t('checkout.allOutOfStock');
                    return;
                }
                for (const item of oldList) {
                    if (!newList.some((i) => i.fid === item.fid)) {
                        removedItem.push(item);
                    }
                }
                // maybe useless since we did check above
                if (removedItem.length < 1) {
                    return;
                }
                for (const item of removedItem) {
                    msg += this.language === 'eng' ? item.fname : item.fname_chn;
                    msg += ', ';
                }
                msg +=
                    removedItem.length > 1
                        ? this.language === 'eng'
                            ? 'were'
                            : '被'
                        : this.language === 'eng'
                        ? 'was'
                        : '被';
                msg += this.language === 'eng' ? ' removed' : '移除了';
                this.set_msg({
                    msg
                });
            },
            zipCode() {
                let zipCode = this.zipCode ? this.zipCode : '';
                // console.log('zipCode', zipCode);

                let dName = this.checkDeliverName(zipCode);

                if (dName) {
                    this.deliverSelectName = dName;
                }
            },
            delivery_data() {
                let zipCode = '';
                if (this.zip_code) {
                    zipCode = this.zip_code;
                } else if (this.zipCode) {
                    zipCode = this.zipCode;
                } 

                let delivery_first_name = '';
                if (this.delivery_data.delivery_area_list && this.delivery_data.delivery_area_list.length > 0) {
                    delivery_first_name = this.delivery_data.delivery_area_list[0].name;
                }
                // console.log('zipCode', zipCode);
                // console.log('delivery_first_name', delivery_first_name);

                let dName = '';
                if (zipCode) {
                    dName = this.checkDeliverName(zipCode);
                }
                
                this.deliverSelectName = dName ? dName : delivery_first_name;
            }
        },
        methods: {
            ...mapActions({
                do_check: 'app/do_check',
                edit_cart_food_count: 'app/edit_cart_food_count'
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG',
                set_app_title: 'app/APP_SET_APP_TITLE',
                set_loading: 'app/APP_SET_IF_LOADING'
            }),
            verifyPrice() {
                if (this.shouldShowPickupNote) {
                    this.set_msg({
                        msg: this.$t('cart.notPickup')
                    });
                    return false;
                }

                // console.log('this.delivery_require_list', this.delivery_require_list);
                if (this.shouldShowDeliveryNote && this.zip_code || this.shouldShowDeliveryNote && this.zipCode && this.delivery_require_list[0].left_money !== 0) {
                    let left_money = this.delivery_require_list[0].left_money || 0;
                    // let msg = 'Minimum delivery amount is $' + minimum_amount;
                    this.set_msg({
                        msg: this.$t('cart.addMoneyToDelivery', { price: left_money })
                    });
                    return false;
                }

                return true;
            },
            verifyNumber() {
                // console.log('cart_food_list', this.cart_food_list);
                let flag = true;
                for (let i = 0; i < this.cart_food_list.length; i++) {
                    if (this.cart_food_list[i].daily_limit > 0) {
                        let foodNum = this.cart_food_list[i].fcount;
                        let hasNum = this.cart_food_list[i].daily_limit - this.cart_food_list[i].daily_sales_count;
                        if (foodNum > hasNum) {
                            this.set_msg({
                                msg: this.$t('cart.exceedOrder')
                            });
                            flag = false;
                            break;
                        }
                    }
                }

                return flag;
            },
            checkout(e) {
                if (!this.verifyNumber()) {
                    return;
                }

                if (!this.verifyPrice()) {
                    return;
                }

                // 是否需要提示用满减
                if (this.couponShow && (this.promotion_data.full_coupon.length > 0) && this.isCouponObj) {
                    this.couponShow(this.isCouponObj);
                    return;
                }

                if (this.placeOrder) {
                    this.placeOrder(e);
                    return;
                }
                // 只有当是微信和ios的时候加上loading
                if (isWeixin() && isIOS()) {
                    this.set_loading({ is_loading: true });
                    setTimeout(() => {
                        this.set_loading({ is_loading: false });
                    }, 2000);
                }

                this.do_check(this.$router);

                if (this.cart_food_list.length > 60) {
                    this.set_msg({
                        msg: this.$t('cart.cartFull')
                    });
                    return;
                }

                this.$router.push({
                    name: 'checkout'
                });
            },
            get_total_price({ food, count }) {
                let addon_prices = food.addon_prices.split('@@');
                let addon_counts = food.addon_counts.split('@@');
                let extra_price = 0;
                if (food.addon_prices) {
                    for (let i = 0, len = addon_prices.length; i < len; i++) {
                        let items = addon_prices[i].split('|');
                        let counts_arr = addon_counts[i].split('|');
                        for (let j = 0; j < items.length; j++) {
                            extra_price = parseFloat(extra_price) + parseFloat(items[j] * counts_arr[j]);
                        }
                    }
                }

                if (count > 0) {
                    let _price = this.is_vip && food.fprice_vip ? food.fprice_vip : food.fprice;
                    return ((parseFloat(_price) + extra_price) * count).toFixed(2);
                }

                return 0;
            },
            async change_food_count(action, food) {
                const mix_fcount = this.cart_food_dict[food.fid]?.mix_fcount || 0;
                let newCount = food.fcount;
                let _food_data = JSON.parse(JSON.stringify(food));
                if (action == 'plus') {
                    if (_food_data.once_limit > 0 && mix_fcount + 1 > _food_data.once_limit) {
                        this.set_msg({
                            msg: this.$t('cart.exceedOrder')
                        });
                        return;
                    }
                    if (
                        _food_data.daily_limit > 0 &&
                        mix_fcount + +1 > _food_data.daily_limit - _food_data.daily_sales_count
                    ) {
                        this.set_msg({
                            msg: this.$t('cart.exceedDay')
                        });
                        return;
                    }
                    newCount++;
                } else if (action == 'minus') {
                    newCount--;
                }

                _food_data.fcount = newCount;
                _food_data.fprice_vip = _food_data.fprice_vip ? _food_data.fprice_vip : '';
                _food_data.fprice_pickup =
                    this.pickup_discount_enable && _food_data.fprice_pickup ? _food_data.fprice_pickup : '';

                let save_data = {
                    cartid: this.$store.state.app.shared_cart_id,
                    tid: this.$store.state.app.shared_tid,
                    uid: this.$store.state.app.email,
                    uname: this.$store.state.app.uname,
                    router: this.$router,
                    type: 'set',
                    is_vip: this.is_vip,
                    language: this.language,
                    fid: _food_data.fid,
                    fname: _food_data.fname,
                    fname_chn: _food_data.fname_chn,
                    fprice: _food_data.fprice,
                    fprice_vip: _food_data.fprice_vip || '',
                    feature_name: _food_data.feature_name,
                    note: _food_data.note,
                    addon_names: _food_data.addon_names,
                    addon_counts: _food_data.addon_counts || '',
                    addon_prices: _food_data.addon_prices,
                    total_price: this.get_total_price({ food, count: newCount }),
                    set_count: newCount
                };
                await this.edit_cart_food_count(save_data);
                this.$parent.$forceUpdate();
            },
            getImageForItem(item) {
                return item.fpic || 'https://wx2.sinaimg.cn/bmiddle/63547bb9ly1fw87tl1eauj205k05kaa1.jpg';
            },
            can_not_commit() {
                if (this.can_place_order === '0') {
                    this.set_msg({
                        msg: this.$t('cart.notCommit')
                    });
                }
            },
            edit_food(_data) {
                this.detail_data = _data;
                this.doToggleDetail(true);
                this.target_food_count = _data.fcount || 0;
                this.target_food_mix_fcount =
                    (this.cart_food_dict[_data.fid] && this.cart_food_dict[_data.fid].mix_fcount) || 0;
            },
            close_layer() {
                this.doToggleDetail(false);
                this.detail_data = {};
                this.target_food_count = 0;
                this.target_food_mix_fcount = 0;
            },
            go_to_menu() {
                this.$router.push({ path: '/menu' });
            },
            openDeliverOption() {
                this.showDeliverSelectOption = true;
                // this.$refs.deliverOption.style.display = 'block';
                this.$refs.deliverMask.style.display = 'block';
                this.$refs.deliverSelect.className = 'deliver_select isOpen';
            },
            hideDeliverSelect() {
                this.showDeliverSelectOption = false;
                // this.$refs.deliverOption.style.display = 'none';
                this.$refs.deliverMask.style.display = 'none';
                this.$refs.deliverSelect.className = 'deliver_select';
            },
            chooseDeliverName(deliverName) {
                this.deliverSelectName = deliverName;
                this.hideDeliverSelect();
            },
            checkDeliverName(zipCode) {
                let dName = '';
                for (let i = 0; i < this.delivery_data.delivery_area_list.length; i++) {
                    if (
                        this.zip_code != '' &&
                        this.delivery_data.delivery_area_list[i].zip_code_list.indexOf(this.zip_code) === -1 ||
                        zipCode != '' &&
                        this.delivery_data.delivery_area_list[i].zip_code_list.indexOf(zipCode) === -1
                    ) {
                        continue;
                    }

                    dName = this.delivery_data.delivery_area_list[i].name;
                }
                return dName;
            }
        }
    };
</script>

<style scoped lang="scss">
    .order_zone {
        width: 336px;
        // width: 380px;
        height: 100%;
        position: fixed;
        right: 0;
        top: 0;
        background-color: #fff;
        box-shadow: 0 4px 14px rgba(0, 0, 0, 0.20);
        z-index: 2;
        overflow-y: scroll;
        display: none;
        &.fixed_button {
            // padding-bottom: 81px;
            box-sizing: border-box;
            position: fixed;
            .custom_order {
                padding-bottom: 81px;
            }
            .check_out {
                width: 336px;
                // width: 380px;
                position: fixed;
                bottom: 0;
                right: 0;
                margin: 0;
                padding: 8px;
                box-sizing: border-box;
                // background-color: #fff;
            }
        }
    }
    // .order_close{
    //     width: .875rem;
    //     height: .875rem;
    //     position: absolute;
    //     right: .9375rem;
    //     top: .875rem;
    //     mask: url('../assets/images/close.svg') no-repeat;
    //     background-size: contain;
    //     mask-size: 100%;
    //     background-repeat: no-repeat;
    //     background-color: var(--theme_color);
    // }
    .order_close {
        position: absolute;
        cursor: pointer;
        width: 34px;
        height: 34px;
        left: 17px;
        top: 24px;
        z-index: 3;
        background-color: #fff;
        border-radius: 50%;
    }
    .order_close span {
        display: block;
        position: absolute;
        top: 20%;
        left: 20%;
        width: 60%;
        height: 60%;
        mask: url('../assets/images/close.svg') no-repeat;
        background-size: contain;
        mask-size: 100%;
        background-repeat: no-repeat;
        background-color: var(--theme_color);
    }
    .custom_order {
        padding: 0 16px;
        font-size: 14px;
    }
    .order_title {
        color: #000;
        line-height: 34px;
        font-size: 22px;
        padding-left: 42px;
        margin-top: 24px;
        margin-bottom: 15px;
    }
    .select_type {
        margin-bottom: 5px;
        .select_type_item {
            padding-left: 40px;
            line-height: 27px;
            color: var(--theme_color);
            position: relative;
            font-size: 18px;
            &.delivery_type {
                padding-left: 0;
                .delivery_type_title {
                    padding-left: 48px;
                }
            }
            span {
                display: block;
                width: 36px;
                height: 19px;
                position: absolute;
                left: 7px;
                top: 4px;
                mask-repeat: no-repeat;
                mask-size: auto 100%;
                background-color: var(--theme_color);
            }
        }
        .pickup_type span {
            mask-image: url('../assets/images/pickup_icon.svg');
        }
        .dinein_type span {
            mask-image: url('../assets/images/dinein_icon.svg');
        }
        .delivery_type span {
            mask-image: url('../assets/images/delivery_icon.svg');
        }
    }
    // .order_list {
    //     border-top: 1px solid #707070;
    //     border-bottom: 1px solid #707070;
    //     // padding-bottom: 24px;
    // }

    .order_item {
        display: flex;
        align-items: flex-start;
        line-height: 25px;
        justify-content: space-between;
        font-size: 16px;
        color: #000;
        .order_price {
            display: flex;
            align-items: center;
        }
        .price_tag {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
        .order_text {
            font-weight: bold;
            line-height: 24px;
        }
        ::v-deep .number_button {
            width: 25px;
            height: 25px;
            line-height: 25px;
        }
        ::v-deep .number {
            font-size: 16px;
            margin: 0 8px;
        }
        // div:first-child {
        //     display: block;
        //     width: 160px;
        // }
        // div:nth-child(2) {
        //     width: 48px;
        // }
        // div:last-child {
        //     width: 60px;
        //     text-align: right;
        // }
    }
    .custom_order .order_desc {
        font-size: 12px;
        line-height: 20px;
    }
    .order_input input {
        margin: 0;
        padding: 0 10px;
        height: 28px;
        font-size: 15px;
        width: 160px;
        border-radius: 4px;
        border: 1px solid #b1b1b1;
    }
    .food_item {
        border-bottom: 1px solid #eaeaea;
        overflow: hidden;
        // &:last-child {
        //     border: none;
        // }
        .order_right {
            padding: 20px 0 20px 85px;
        }
        .order_fpic{
            width: 75px;
            padding: 20px 0;
            float: left;
            img {
                display: block;
                width: 100%;
                border-radius: 5px;
            }
        }
        .order_text {
            font-weight: 500;
            font-size: 16px;
            color: #000;
            line-height: 24px;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            max-height: 48px;
            overflow: hidden;
            word-break: break-all;
        }
        .note {
            word-break: break-all;
            padding-bottom: 10px;
        }
        .food_option {
            font-size: 14px;
            line-height: 25px;
            color: #626262;
        }
        .food_addon {
            // padding-left: 16px;
            display: flex;
            justify-content: normal;
            font-size: 14px;
            line-height: 25px;
            color: #626262;
            flex-wrap: wrap;
            .addon_name {
                margin: 0 10px;
                // max-width: 126px;
                max-width: 160px;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                max-height: 25px;
                overflow: hidden;
                word-break: break-all;
            }
            // .addon_count {
            //     width: 35px;
            // }
            // .addon_name {
            //     width: 110px;
            // }
            // .addon_price {
            //     min-width: 60px;
            //     text-align: right;
            //     // margin-left: 10px;
            // }
        }
    }
    .order_item.order_item_inner {
        // line-height: 52px;
        line-height: 24px;
        padding: 10px 0;
        position: relative;
    }
    .order_item.order_item_inner:last-child {
        border-bottom: none;
    }
    .order_item .order_item_number {
        display: inline-flex;
        justify-content: center;
        -webkit-box-pack: center;
        align-items: center;
        -webkit-box-align: center;
    }
    .order_item_number .order_custom_input,
    .order_item_number button {
        justify-content: center;
        -webkit-box-pack: center;
        align-items: center;
        -webkit-box-align: center;
        display: flex;
    }
    .order_item_number button {
        background-color: #f5f5f5;
        color: #707070;
        height: 16px;
        width: 16px;
        border-radius: 2px;
        border: 1px solid #bcbcbc;
        cursor: pointer;
        -webkit-appearance: button;
        text-transform: none;
        margin: 0;
        font-size: 12px;
        line-height: 16px;
        box-sizing: border-box;
    }
    .order_item_number .order_custom_input {
        display: inline-flex;
    }
    .order_item_number .order_number_input {
        width: 24px;
        border: 0;
        font-size: 14px;
        color: #707070;
        display: flex;
        text-align: center;
    }
    .order_promotion {
        padding: 20px 0;
        font-size: 16px;
        line-height: 25px;
        color: #000;
        .promotion_title {
            font-weight: bold;
            margin-bottom: 10px;
        }
        .promotion_success, .promotion_error {
            padding-left: 21px;
            background-repeat: no-repeat;
            background-size: auto 17px;
            background-position: 0 4px;
        }
        .promotion_success {
            color: #68B361;
            background-image: url('../assets/images/success_icon.svg');
        }
        .promotion_error {
            color: #E90112;
            background-image: url('../assets/images/warning.svg');
        }
    }
    .order_fee {
        border-top: 1px solid #eaeaea;
        padding: 20px 0 12px;
        .order_item {
            align-items: center;
            line-height: 26px!important;
            font-size: 16px;
            &.order_theme_color {
                color: var(--theme_color);
            }
            .order_text {
                font-weight: normal;
            }
        }
    }
    .check_out {
        width: 100%;
        margin-top: 25px;
        margin-bottom: 15px;
    }
    .check_out button {
        width: 100%;
        height: 65px;
        // text-transform: uppercase;
        font-size: 22px;
        border-radius: 8px;
        box-shadow: 0 4px 14px rgba(0, 0, 0, 0.35);
        span {
            font-weight: bold;
        }
    }
    .order_total.order_fee {
        .order_text, .order_price {
            font-size: 20px;
            font-weight: 500;
        }
    }
    .food_fcount {
        display: block;
        width: 36px;
        height: 36px;
        line-height: 36px;
        background-color: #d8d8d8;
        color: #000;
        text-align: center;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 4px;
    }
    .delivery_type_select {
        position: relative;
        z-index: 3;
        margin-top: 10px;
    }
    .deliver_select {
        width: 100%;
        height: 48px;
        line-height: 46px;
        border: 1px solid var(--theme_color);
        border-radius: 7px;
        color: #000;
        padding: 0 15px;
        font-size: 18px;
        box-sizing: border-box;
        background-color: #fff;
        position: relative;
    }
    .delivery_type_select span.select_arrow {
        display: block;
        width: 30px;
        height: 30px;
        position: absolute;
        z-index: 4;
        top: 9px;
        right: 10px;
        left: auto;
        mask: url('../assets/images/arrow_bottom.svg') no-repeat;
        background-size: contain;
        background-color: var(--theme_color);
        background-repeat: no-repeat;
        mask-size: 100% auto;
        mask-position: right center;
        cursor: pointer;
    }

    .delivery_type_select span.select_arrow.select_arrow_up {
        mask-image: url('../assets/images/arrow_top.svg');
    }
    .deliver_select_item span {
        position: absolute;
        width: 24px;
        height: 46px;
        left: 15px;
        top: 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: left center;
    }
    .deliver_option {
        position: absolute;
        width: 100%;
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
        z-index: 5;
        padding: 10px 0;
    }
    .deliver_option_item {
        color: #000;
        padding: 10px 15px;
        font-size: 18px;
        line-height: 24px;
        position: relative;
        &.select {
            background-color: #eaeaea;
        }
    }
    .delivery_mask {
        position: absolute;
        width: 100vh;
        height: 100vh;
        right: 0;
        top: 0;
        z-index: 2;

    }
    .delivery_type_msg {
        margin-top: 10px;
        .msg_error, .msg_success {
            font-size: 16px;
            background-color: #f5f5f5;
            border-radius: 5px;
            padding: 10px 10px 10px 36px;
            background-repeat: no-repeat;
            background-size: auto 17px;
            background-position: 10px 14px;
        }
        .msg_success {
            color: #000;
            background-image: url('../assets/images/success_icon.svg');
        }
        .msg_error {
            color: #E90112;
            background-image: url('../assets/images/warning.svg');
        }
    }
    @media screen and (max-width: 641px) {
        .order_zone {
            display: block;
            width: 96%;
            right: -100%;
            transition-property: right;
            transition-duration: .4s;
            transition-timing-function: ease;
            transition-delay: 0s;
            .check_out {
                width: 96%;
                padding: 8px;
                box-sizing: border-box;
                position: fixed;
                bottom: 0;
                margin: 0;
                right: -100%;
                transition-property: right;
                transition-duration: .4s;
                transition-timing-function: ease;
                transition-delay: 0s;
                button {
                    width: 100%;
                    height: 65px;
                    display: block;
                    box-sizing: border-box;
                }
            }
            &.fixed_button .check_out {
                width: 96%;
                right: -100%;
            }
        }
        .order_fee {
            margin-bottom: 8px;
        }
        .select_type {
            margin-bottom: 0px;
        }
        .order_close {
            width: 32px;
            height: 32px;
            top: 26px;
            right: 18px;
        }
    }
</style>
