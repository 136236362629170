import fetchApi from '../../kits/newFetch';
import i18n from '../../locales';
import { isValidStr } from '../../kits/tools';
import specialStore from '../../kits/specialStore';

let lock_aj = false;
let edit_cart_lock_aj = false;

// initial state
const state = {
    sid: window.localStorage.getItem('client_sid'),
    sname: '',
    uname: window.localStorage.getItem('uname') || '未知',
    user_name: window.localStorage.getItem('uname') || '未知',
    first_name: '',
    last_name: '',
    email: '',
    tel: '',
    store_data: {},
    cart_data: {
        cart_food_list: [],
        cart_food_dict: {
            random: new Date().getTime()
        },
        total_price: '0.0',
        total_price_vip: '0.0',
        remark: '',
        user_count: 0,
        total_food_count: 0,
        total_price_pickup: 0
    },
    shared_tid: window.localStorage.getItem('shared_tid') || '',
    shared_tstatus: '',
    shared_oid: window.localStorage.getItem('shared_oid') || '',
    shared_cart_id: window.localStorage.getItem('shared_cart_id') || '',
    shared_foods_timer: -1,
    msg: '',
    has_new_order: false,
    show_cart_detail: false,
    show_left_nav: false,
    app_title: '',
    show_menu_nav: false,
    is_vip: 0,
    pay_method_setting: '',
    payment_setting: [],
    is_open: 0,
    err_msg: '',
    check_timer: -1,
    tip_method_setting: '',
    tax_with_discount: 0,
    tip_with_tax: 0,

    delivery_enable: 1,
    delivery_vip_free: 0,
    delivery_area_list: [],

    full_coupon: [],
    first_discount_amount: 0,
    first_discount_limit_amount: 0,
    first_discount_type: 'fixed',
    is_new: false,

    pickup_enable: 1,
    pickup_amount: 0,
    is_vip_new: false,
    merchant_custom_value: 0,
    phone_num: '',
    code: '+1',
    annoucement: '',
    pickup_discount_enable: 0,

    show_food_list: [],
    now_menu: '',
    menu_dict: {},
    menu_list: [],
    food_dict: {},
    menu_timer: -1,

    choose_time_options: '',
    choose_time_ahead_days: 0,
    choose_time: 'ASAP',

    is_loading: false,

    feature_image: '',
    theme_color: '#0061C8',
    notice_layer_title_color: '#E90011',
    notice_layer_subtitle: '',
    notice_layer_ps_content: '',
    notice_enable: 0,
    auto_notice_enable: 0,

    pickup_setting: {},
    delivery_setting: {},

    type: '',
    state: '',
    city: '',
    street_address1: '',
    street_address2: '',
    zip_code: '',
    zip_code_index: -1,
    is_loading_menu: true,

    is_loading_welcome: true,

    recommend_popup_setting: {
        recommend_popup_enable: 0,
        recommend_popup_name: '',
        recommend_popup_pic_url: ''
    },
    gid: localStorage.gid || '',
    dinein_setting: null,
    dineInTable: '',
    short_url_v2: '',
    multi_lang: {},
    zipCodeOrder: '',
    special_store: specialStore,
    delivery_first_name: ''
};

// getters
const getters = {
    get_canDineIn(state) {
        if (!(state.dinein_setting?.enable == 1)) return false;
        const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
        let now = new Date();
        let day = now.getDay();
        let hour = now.getHours();
        let minute = now.getMinutes();
        hour = hour < 10 ? '0' + hour : hour;
        minute = minute < 10 ? '0' + minute : minute;
        let hourMinute = hour + ':' + minute;

        day = days[day];
        const enableTime = state.dinein_setting.enable_time[day];
        if (!enableTime || !enableTime.enable || !enableTime.time_list) return false;
        const timeList = enableTime.time_list;
        const result = timeList.some((item) => {
            const start = item.stime;
            const end = item.etime;
            if (hourMinute >= start && hourMinute <= end) {
                return true;
            }
        });
        return result;
    },
    get_dineInTables(state) {
        let tables = state.dinein_setting?.table_list || [];
        tables = tables.filter((table) => {
            return table.enable == 1;
        });
        return tables;
    },
    get_dineInTableEnable(state, getters) {
        return state.dinein_setting?.enable_table_select == 1 && getters.get_dineInTables.length > 0;
    },
    get_recommend_popup_setting(state) {
        return state.recommend_popup_setting;
    },
    get_type(state) {
        return state.type;
    },
    get_dinein_setting(state) {
        return state.dinein_setting;
    },
    get_pickup_setting(state) {
        return state.pickup_setting;
    },
    get_delivery_setting(state) {
        return state.delivery_setting;
    },
    get_theme_data(state) {
        return {
            feature_image: state.feature_image,
            theme_color: state.theme_color,
            notice_layer_title_color: state.notice_layer_title_color,
            notice_layer_subtitle: state.notice_layer_subtitle,
            notice_layer_ps_content: state.notice_layer_ps_content,
            notice_enable: state.notice_enable,
            auto_notice_enable: state.auto_notice_enable
        };
    },
    get_cart_price_pickup(state) {
        return state.cart_data.total_price_pickup * 1;
    },
    get_pickup_discount_enable(state) {
        return state.pickup_setting.discount_enable * 1 || 0;
    },
    get_if_loading(state) {
        return state.is_loading;
    },
    get_choose_time(state) {
        return state.choose_time;
    },
    get_choose_time_ahead_days(state) {
        return state.choose_time_ahead_days;
    },
    get_choose_time_options(state) {
        return state.choose_time_options;
    },
    get_show_food_list(state) {
        return state.show_food_list;
    },
    get_now_menu(state) {
        return state.now_menu;
    },
    get_menu_dict(state) {
        return state.menu_dict;
    },
    get_menu_list(state) {
        return state.menu_list;
    },
    get_food_dict(state) {
        return state.food_dict;
    },
    get_annoucement(state) {
        return state.annoucement;
    },
    get_is_vip_new(state) {
        return state.is_vip_new;
    },
    get_is_open(state) {
        return state.is_open;
    },
    get_err_msg(state) {
        return state.err_msg;
    },
    get_paymethod_setting(state) {
        return state.pay_method_setting;
    },
    get_if_is_vip(state) {
        return state.is_vip;
    },
    get_if_can_place_order(state) {
        return state.store_data.can_place_order || 0;
    },
    get_tax(state) {
        return state.store_data.tax || 0;
    },
    get_tax_with_discount(state) {
        return state.tax_with_discount || 0;
    },
    get_tip_with_tax(state) {
        return state.tip_with_tax || 0;
    },
    get_if_show_menu_nav(state) {
        return state.show_menu_nav;
    },
    get_app_title(state) {
        return state.app_title;
    },
    get_if_show_left_nav(state) {
        return state.show_left_nav;
    },
    get_if_has_new_order(state) {
        return state.has_new_order;
    },
    get_store_name(state) {
        return state.sname || '';
    },
    get_store_pic(state) {
        return state.store_data.spic || '';
    },
    get_food_count(state) {
        return state.cart_data.total_food_count;
    },
    get_cart_food_list(state) {
        return state.cart_data.cart_food_list;
    },
    get_cart_price(state) {
        return state.cart_data.total_price * 1;
    },
    get_cart_price_vip(state) {
        return state.cart_data.total_price_vip * 1;
    },
    get_cart_food_dict(state) {
        return state.cart_data.cart_food_dict;
    },
    get_shared_user_count(state) {
        return state.cart_data.user_count;
    },
    get_shared_tname(state) {
        return state.shared_tname;
    },
    get_shared_tstatus(state) {
        return state.shared_tstatus;
    },
    get_shared_tid(state) {
        return state.shared_tid;
    },
    get_shared_oid(state) {
        return state.shared_oid;
    },
    get_app_msg(state) {
        return state.msg;
    },
    get_store_slogo(state) {
        return state.store_data.slogo || '';
    },
    get_language(state) {
        return state.store_data.language || 'eng';
    },
    get_multi_lang(state) {
        return state.store_data.multi_lang || [{"key":"eng", "name":"English", "shortName":"EN"}, {"key":"chn","name":"中文","shortName":"中"}, {"key":"kor","name":"한국어","shortName":"한"}];
    },
    get_processing_fee(state) {
        return state.store_data.processing_fee || 0;
    },
    get_processing_extra(state) {
        return state.store_data.processing_extra || 0;
    },
    get_delivery_data(state) {
        return {
            delivery_enable: state.delivery_enable || 0,
            delivery_area_list: state.delivery_area_list || [],
            delivery_vip_free: state.delivery_vip_free || 0
        };
    },
    get_promotion_data(state) {
        return {
            full_coupon: state.full_coupon,
            first_discount_amount: state.first_discount_amount,
            first_discount_type: state.first_discount_type,
            first_discount_limit_amount: state.first_discount_limit_amount
        };
    },
    get_is_new(state) {
        return state.is_new;
    },
    get_tip_method_setting(state) {
        return state.tip_method_setting;
    },
    get_pickup_data(state) {
        return {
            pickup_enable: state.pickup_enable || 0,
            pickup_amount: state.pickup_amount || 0
        };
    },
    get_merchant_custom_value(state) {
        return state.merchant_custom_value;
    },
    get_canPickup(state) {
        if (!state.pickup_setting?.asap_enable_time) return true;
        const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
        let now = new Date();
        let day = now.getDay();
        let hour = now.getHours();
        let minute = now.getMinutes();
        hour = hour < 10 ? '0' + hour : hour;
        minute = minute < 10 ? '0' + minute : minute;
        let hourMinute = hour + ':' + minute;

        day = days[day];
        const enableTime = state.pickup_setting.asap_enable_time[day];
        if (!enableTime || !enableTime.enable || !enableTime.time_list) return false;
        const timeList = enableTime.time_list;
        const result = timeList.some((item) => {
            const start = item.stime;
            const end = item.etime;
            if (hourMinute >= start && hourMinute <= end) {
                return true;
            }
        });
        return result;
    },
    get_pickup_asap_time(state) {
        if (!('asap_enable_time' in state.pickup_setting)) return [];
        if (state.pickup_setting?.asap_enable_time == '') return [];
        let asap_enable_time = state.pickup_setting.asap_enable_time;
        let asap_time = [];
        const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
        for(let i = 0; i <= days.length; i++) {
            if (days[i] in asap_enable_time) {
                let item = asap_enable_time[days[i]];
                item.key = days[i];
                asap_time.push(item);
            }
        }
        return asap_time;
    },
    get_canDeliveryList() {
        if (state.delivery_area_list.length == 0) return [];
        let delivery_list = [];
        for (let i = 0; i < state.delivery_area_list.length; i++) {
            let asap_time_setting = state.delivery_area_list[i].asap_time_setting;
            if (!asap_time_setting) {
                delivery_list.push(true);
                continue;
            }
            asap_time_setting = JSON.parse(asap_time_setting);
            const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
            let now = new Date();
            let day = now.getDay();
            let hour = now.getHours();
            let minute = now.getMinutes();
            hour = hour < 10 ? '0' + hour : hour;
            minute = minute < 10 ? '0' + minute : minute;
            let hourMinute = hour + ':' + minute;

            day = days[day];
            const enableTime = asap_time_setting[day];
            if (!enableTime || !enableTime.enable || !enableTime.time_list) {
                delivery_list.push(false);
                continue;
            }
            const timeList = enableTime.time_list;
            const result = timeList.some((item) => {
                const start = item.stime;
                const end = item.etime;
                if (hourMinute >= start && hourMinute <= end) {
                    return true;
                }
            });

            delivery_list.push(result);
        }
        
        return delivery_list;
    },
    get_delivery_asap_list() {
        if (state.delivery_area_list.length == 0) return [];
        let asap_list = [];
        const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
        for (let j = 0; j < state.delivery_area_list.length; j++) {
            let asap_time_setting = state.delivery_area_list[j].asap_time_setting;
            if (!asap_time_setting) {
                asap_list.push([]);
                continue;
            }
            asap_time_setting = JSON.parse(asap_time_setting);
            let asap_time = [];
            for(let i = 0; i <= days.length; i++) {
                if (days[i] in asap_time_setting) {
                    let item = asap_time_setting[days[i]];
                    item.key = days[i];
                    asap_time.push(item);
                }
            }
            asap_list.push(asap_time);
        }
        
        return asap_list;
    },
    translate(state) {
        return function (business, key) {
            return state.multi_lang?.[state.sid]?.[business]?.[key]?.[state.store_data.language] || key
        }
    },
    get_zipCodeOrder(state) {
        return state.zipCodeOrder;
    },
    get_delivery_first_name(state) {
        return state.delivery_first_name;
    },
    get_uname(state) {
        return state.user_name;
    },
    get_first_name(state) {
        return state.first_name;
    },
    get_last_name(state) {
        return state.last_name;
    },
    get_is_loading_welcome(state) {
        return state.is_loading_welcome;
    },
    get_is_loading_menu(state) {
        return state.is_loading_menu;
    },
    get_special_store(state) {
        return state.special_store;
    },
};

// actions
const actions = {
    initData({ commit, rootState, rootGetters }, sid) {
        if (!state.sid) {
            return;
        }
        let aj_host = rootGetters.serverRoot;
        fetch(aj_host + '/weapp/takeout/store/get?sid=' + (state.sid || sid), {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                res = JSON.parse(res);
                if (res.data.code === 100000) {
                    let _store_data = res.data.data.store_data;
                    _store_data.language = res.data.data.store_data.language_new;
                    _store_data.show_store_desc_enable = res.data.data.show_store_desc_enable;
                    let multi_lang = res.data.data.multi_lang;
                    let _enable = res.data.data.enable;
                    let _err_msg = res.data.data.err_msg;
                    let _first_discount_amount = res.data.data.first_discount_amount || 0;
                    let _first_discount_limit_amount = res.data.data.first_discount_limit_amount || 0;
                    let _first_discount_type = res.data.data.first_discount_type || 'fixed';
                    let _full_coupon = res.data.data.full_coupon || [];
                    let _pickup_enable = res.data.data.pickup_enable || 0;
                    let _pickup_amount = res.data.data.pickup_amount || 0;
                    let _annoucement = res.data.data.annoucement || '';
                    let _delivery_vip_free = res.data.data.delivery_vip_free || 0;
                    let _delivery_enable = res.data.data.delivery_enable || 0;
                    let _delivery_area_list = res.data.data.delivery_area_list || 0;
                    // let _pickup_discount_enable = res.data.data.pickup_discount_enable || 0;
                    let _delivery_first_name = _delivery_area_list && _delivery_area_list.length > 0 ? _delivery_area_list[0].name : '';

                    let _feature_image = res.data.data.feature_image || '';
                    let _theme_color = res.data.data.theme_color || '#0061C8';
                    let _notice_layer_title_color = res.data.data.notice_layer_title_color || '#E90011';
                    let _notice_layer_subtitle = res.data.data.notice_layer_subtitle || '';
                    let _notice_layer_ps_content = res.data.data.notice_layer_ps_content || '';
                    let _notice_enable = res.data.data.notice_enable || 0;
                    let _auto_notice_enable = res.data.data.auto_notice_enable || 0;
                    let _paypal_client_id = res.data.data.paypal_client_id || '';
                    let dinein_setting = res.data.data.dinein_setting || '';
                    let _tax_with_discount = res.data.data.tax_with_discount || 0;
                    let _tip_with_tax = res.data.data.tip_with_tax || 0;
                    
                    let _pickup_setting = res.data.data.pickup_setting || {};
                    let _delivery_setting = res.data.data.delivery_setting || {};

                    let _recommend_popup_setting = res.data.data.recommend_popup_setting || {};

                    let _short_url_v2 = res.data.data.short_url_v2 || '';

                    if (_paypal_client_id) {
                        const head = document.getElementsByTagName('head')[0];
                        let script = document.createElement('script');
                        script.type = 'text/javascript';
                        script.src =
                            'https://www.paypal.com/sdk/js?client-id=' + _paypal_client_id + '&disable-funding=card';
                        head.appendChild(script);
                    }

                    document.title = _store_data.sname;
                    commit('APP_SET_MULTI_LANG', multi_lang);
                    commit('APP_INIT_STORE_DATA', _store_data);
                    commit('APP_INIT_TAKEOUT_ENABLE', {
                        is_open: _enable,
                        err_msg: _err_msg
                    });

                    document.documentElement.style.setProperty('--theme_color', _theme_color);

                    commit('APP_INIT_OTHER_SETTING', {
                        full_coupon: _full_coupon,
                        first_discount_amount: _first_discount_amount,
                        first_discount_limit_amount: _first_discount_limit_amount,
                        first_discount_type: _first_discount_type,
                        // pickup_enable: _pickup_enable,
                        // pickup_amount: _pickup_amount,
                        annoucement: _annoucement,
                        dinein_setting,
                        // delivery_enable: _delivery_enable,
                        delivery_vip_free: _delivery_vip_free,
                        delivery_area_list: _delivery_area_list,
                        // pickup_discount_enable: _pickup_discount_enable,
                        delivery_first_name: _delivery_first_name,
                        feature_image: _feature_image,
                        theme_color: _theme_color,
                        notice_layer_title_color: _notice_layer_title_color,
                        notice_layer_subtitle: _notice_layer_subtitle,
                        notice_layer_ps_content: _notice_layer_ps_content,
                        notice_enable: _notice_enable,
                        auto_notice_enable: _auto_notice_enable,
                        pickup_setting: _pickup_setting,
                        delivery_setting: _delivery_setting,
                        recommend_popup_setting: _recommend_popup_setting,
                        short_url_v2: _short_url_v2,
                        tax_with_discount: _tax_with_discount,
                        tip_with_tax: _tip_with_tax,
                    });
                }
            });

        fetch(aj_host + '/weapp/takeout/sharedcart/check?sid=' + state.sid + '&tid=' + state.shared_tid, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                res = JSON.parse(res);
                if (res.data.code === 100020) {
                    // window.location.replace(
                    //     '/recepit?sid=' + state.sid + '&tid=' + state.shared_tid + '&oid=' + res.data.data.oid
                    // );
                    const urlPre = window.location.origin + window.location.pathname + '#';
                    window.location.replace(urlPre + '/review?oid=' + res.data.data.oid + '&sid=' + state.sid);
                }
            });
    },
    quitTable({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'https://takeout.minitable.link' : '';
        fetch(
            aj_host +
                '/weapp/takeout/sharedcart/quit?tid=' +
                post_data.tid +
                '&cartid=' +
                post_data.cartid +
                '&sid=' +
                post_data.sid,
            {
                method: 'GET'
            }
        )
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                res = JSON.parse(res);
                if (res.data.code === 100000) {
                    commit('APP_SHARED_CART_CLOSED', {
                        router: post_data.router,
                        sid: post_data.sid
                    });
                }
            });
    },
    joinTable({ dispatch, commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'https://takeout.minitable.link' : '';
        fetch(aj_host + '/weapp/takeout/sharedcart/join?sid=' + post_data.sid + '&uname=' + post_data.email, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                res = JSON.parse(res);
                if (res.data.code === 100000) {
                    window.localStorage.setItem('shared_tid', res.data.data.tid);
                    window.localStorage.setItem('shared_cart_id', res.data.data.cartid);
                    window.localStorage.setItem('uname', post_data.email);
                    commit('APP_SET_UNAME', {
                        name: post_data.email
                    });

                    commit('APP_SET_JOIN_TABLE_INFO', {
                        cartid: res.data.data.cartid,
                        email: post_data.email,
                        tid: res.data.data.tid
                    });
                    post_data.router.replace({ path: '/menu' });

                    dispatch('get_shared_cart', {
                        cartid: res.data.data.cartid,
                        router: post_data.router
                    }).then(() => {});
                    dispatch('get_menu', post_data.sid).then(() => {});
                } else if (res.data.code === 100010) {
                    window.location.reload();
                }
            });
    },
    get_shared_cart({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootGetters.serverRoot;
        if (!post_data.cartid) {
            console.log('no cartid');
            return;
        }

        if (lock_aj) {
            return;
        }
        lock_aj = true;

        fetch(
            aj_host + '/weapp/takeout/sharedcart/get?cartid=' + post_data.cartid + '&tid=' + rootState.app.shared_tid,
            {
                method: 'GET'
            }
        )
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                lock_aj = false;

                res = JSON.parse(res);

                const { data } = res;
                if (!data) {
                    //TODO SHOW SOME ERROR MESSAGE
                    return;
                }

                if (data.code === 100000) {
                    window.localStorage.setItem('shared_oid', res.data.data.shared_oid);

                    if (res.data.data.shared_oid != rootState.app.shared_oid) {
                        commit('APP_SET_HAS_NEW_ORDER', {});
                    }

                    commit('APP_SET_CART_DATA', {
                        food_list: res.data.data.food_list,
                        food_dict: res.data.data.food_dict,
                        total_price: res.data.data.total_price,
                        total_price_vip: res.data.data.total_price_vip,
                        total_price_pickup: res.data.data.total_price_pickup,
                        user_count: res.data.data.user_count,
                        shared_oid: res.data.data.shared_oid,
                        shared_tstatus: res.data.data.shared_tstatus,
                        shared_tname: res.data.data.shared_tname
                    });
                } else if (data.code === 100010) {
                    // 共享购物车已关闭
                    commit('APP_SHARED_CART_CLOSED', {
                        router: post_data.router,
                        sid: post_data.sid
                    });
                }
            });
    },
    edit_cart_food_count({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'https://takeout.minitable.link' : '';
        if (edit_cart_lock_aj) {
            return;
        }
        edit_cart_lock_aj = true;

        if (post_data.cartid == '' && post_data.router) {
            post_data.router.replace({ path: '/welcome' });
        }

        let params =
            '' +
            'cartid=' +
            post_data.cartid +
            '&tid=' +
            post_data.tid +
            '&uid=' +
            post_data.uid +
            '&uname=' +
            post_data.uname +
            '&fid=' +
            post_data.fid +
            '&fprice=' +
            post_data.fprice +
            '&fprice_vip=' +
            post_data.fprice_vip +
            '&fprice_pickup=' +
            post_data.fprice_pickup +
            '&is_vip=' +
            post_data.is_vip +
            '&fcount=' +
            post_data.set_count +
            '&type=set' +
            '&feature_name=' +
            encodeURIComponent(post_data.feature_name) +
            '&addon_names=' +
            encodeURIComponent(post_data.addon_names) +
            '&addon_counts=' +
            encodeURIComponent(post_data.addon_counts) +
            '&addon_prices=' +
            post_data.addon_prices +
            '&note=' +
            encodeURIComponent(post_data.note);

        // 产品要求提交完，马上关闭详情层
        setTimeout(function () {
            commit('APP_TOGGLE_CART_DETAIL', false);
        }, 100);

        return fetch(aj_host + '/weapp/takeout/sharedcart/edit?' + params, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                edit_cart_lock_aj = false;

                res = JSON.parse(res);

                // const msg_chn = '修改 "' + post_data.fname_chn + '" 成功';
                // const msg_eng = 'set "' + post_data.fname + '" successfully';
                // let msg = post_data.language == 'chn' ? msg_chn : msg_eng;
                let msg = i18n.t('cart.setSuccessfully', { fname: post_data.fname });

                if (res.data.code === 100000) {
                    commit('APP_SET_MSG', { msg });
                    commit('APP_NEW_SET_FOOD', post_data);
                } else if (res.data.code === 100010) {
                    // 共享购物车已关闭
                    commit('APP_SHARED_CART_CLOSED', {
                        router: post_data.router,
                        sid: post_data.sid
                    });
                } else {
                    commit('APP_SET_MSG', { msg });
                }
            });
    },
    edit_cart_food({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootGetters.serverRoot;
        if (edit_cart_lock_aj) {
            return;
        }
        edit_cart_lock_aj = true;

        post_data.food_data.uid = post_data.uid;
        post_data.food_data.uname = post_data.uname;
        post_data.food_data.feature_name = post_data.extra_data.feature_name;
        post_data.food_data.addon_names = post_data.extra_data.addon_names;
        post_data.food_data.addon_prices = post_data.extra_data.addon_prices;
        post_data.food_data.addon_counts = post_data.extra_data.addon_counts;
        post_data.food_data.addon_pickup_discounts = post_data.extra_data.addon_pickup_discounts;
        post_data.food_data.note = post_data.extra_data.note;
        post_data.food_data.add_count = post_data.extra_data.add_count;
        post_data.food_data.addon_total_price = post_data.extra_data.addon_total_price;
        post_data.food_data.addon_total_price_pickup = post_data.extra_data.addon_total_price_pickup;
        post_data.food_data.is_vip = post_data.is_vip;
        post_data.food_data.once_limit = post_data.extra_data.once_limit;
        post_data.food_data.daily_limit = post_data.extra_data.daily_limit;

        if (post_data.cartid == '' && post_data.router) {
            post_data.router.replace({ path: '/welcome' });
        }

        let check_food_data = JSON.parse(JSON.stringify(post_data.food_data));

        const { isAdd, food_data } = _checkAdd(check_food_data, rootState);
        if (!isAdd) {
            post_data.type = 'set';
            post_data.food_data.fcount = food_data.fcount;
            post_data.food_data.set_count = food_data.fcount;
        }

        // 产品要求提交完，马上关闭详情层
        setTimeout(function () {
            commit('Food/TOGGLE_PAGE', false, { root: true });
        }, 100);

        let params =
            '' +
            'cartid=' +
            post_data.cartid +
            '&tid=' +
            post_data.tid +
            '&uid=' +
            post_data.uid +
            '&uname=' +
            post_data.uname +
            '&fid=' +
            post_data.food_data.fid +
            '&fprice=' +
            post_data.food_data.fprice +
            '&fprice_vip=' +
            post_data.food_data.fprice_vip +
            '&fprice_pickup=' +
            post_data.food_data.fprice_pickup +
            '&fprice_original=' +
            post_data.food_data.fprice_original +
            '&is_vip=' +
            post_data.is_vip +
            '&fcount=' +
            post_data.food_data.fcount +
            '&type=' +
            (isAdd ? 'add' : 'set') +
            '&feature_name=' +
            encodeURIComponent(post_data.extra_data.feature_name) +
            '&addon_names=' +
            encodeURIComponent(post_data.extra_data.addon_names) +
            '&addon_counts=' +
            encodeURIComponent(post_data.extra_data.addon_counts) +
            '&addon_pickup_discounts=' +
            encodeURIComponent(post_data.extra_data.addon_pickup_discounts) +
            '&addon_prices=' +
            post_data.extra_data.addon_prices +
            '&note=' +
            encodeURIComponent(post_data.extra_data.note);

        return fetch(aj_host + '/weapp/takeout/sharedcart/edit?' + params, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                edit_cart_lock_aj = false;

                res = JSON.parse(res);
                if (res.data.code === 100000) {
                    // const msg_chn = '添加 "' + post_data.food_data.fname_chn + '" 成功';
                    // const msg_eng = 'add "' + post_data.food_data.fname + '" successfully';
                    // let msg = post_data.language == 'chn' ? msg_chn : msg_eng;
                    // let food_name = this.translate('item', post_data.food_data.fname);
                    let msg = i18n.t('cart.addSuccessfully', { fname: post_data.food_data.fname });
                    if (post_data.type == 'add') {
                        commit('APP_SET_MSG', { msg });
                        commit('APP_NEW_ADD_FOOD', post_data.food_data);
                    } else if (post_data.type == 'set') {
                        commit('APP_SET_MSG', { msg });
                        commit('APP_NEW_SET_FOOD', post_data.food_data);
                    } else {
                        commit('APP_DEL_FOOD_INTO_CART', post_data.food_data);
                        commit('APP_SET_MSG', {
                            msg: i18n.t('cart.removeSuccessfully', { fname: post_data.food_data.fname })
                        });
                    }
                } else if (res.data.code === 100010) {
                    // 共享购物车已关闭
                    commit('APP_SHARED_CART_CLOSED', {
                        router: post_data.router,
                        sid: post_data.sid
                    });
                } else {
                    commit('APP_SET_MSG', {
                        msg: 'set "' + res.data.msg + '" successfully'
                    });
                }
            });
    },
    submit_cart({ commit, rootState, rootGetters, state }, obj) {
        let { post_data, el } = obj;
        let aj_host = rootGetters.serverRoot;
        if (isValidStr(state.gid)) {
            post_data.gid = state.gid;
        }

        commit('APP_SET_IF_LOADING', { is_loading: true });

        let classTxt = el.className;
        el.className = classTxt + ' is-disabled';
        el.disabled = 'true';

        fetchApi(
            { commit },
            {
                request_name: 'submit_order',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                commit('APP_CLEAR_CART_DATA', {});
                commit('APP_SET_MSG', {
                    msg: i18n.t('cart.submitSuccessfully')
                });
                commit('APP_SET_SHARED_OID', {
                    shared_oid: res.data.data
                });

                commit('APP_SHARED_CART_CLOSED', {});
                setTimeout(function () {
                    // window.location.replace('/recepit?sid=' + post_data.sid + '&tid=' + post_data.tid);
                    const urlPre = window.location.origin + window.location.pathname + '#';
                    window.location.replace(urlPre + '/review?oid=' + res.data.data + '&sid=' + post_data.sid);
                }, 100);
            })
            .catch(() => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                el.className = classTxt;
                el.disabled = '';
            });

        // let paramsArr = [];
        // for (var o in post_data) {
        //     paramsArr.push(o + '=' + encodeURIComponent(post_data[o]));
        // }
        // fetch(aj_host + '/weapp/takeout/order/edit?' + paramsArr.join('&'), {
        //     method: 'GET'
        // })
        // .then((res)=>{
        //     return res.text()
        // })
        // .then((res)=>{
        //     res = JSON.parse(res);
        //     if (res.data.code === 100000) {
        //         commit('APP_CLEAR_CART_DATA',{});
        //         commit('APP_SET_MSG',{
        //             msg: 'Submit successfully'
        //         });
        //         commit('APP_SET_SHARED_OID', {
        //             shared_oid: res.data.data
        //         });

        //         // paypal的方式需要再确认一下
        //         if (post_data.opay_type === 'counter' || post_data.opay_type === 'pickup') {
        //             commit('APP_SHARED_CART_CLOSED', {});
        //             setTimeout(function() {
        //                 // window.history.pushState(null, rootState.app.sname, '/#/welcome?sid=' + post_data.sid)
        //                 window.location.replace('/recepit?sid=' + post_data.sid + '&tid=' + post_data.tid);
        //             }, 100);
        //         }
        //     }
        // });
    },
    async submit_cart_paypal({ commit, rootState, rootGetters, state }, post_data) {
        let aj_host = rootGetters.serverRoot;
        commit('APP_SET_IF_LOADING', { is_loading: true });
        if (isValidStr(state.gid)) {
            post_data.gid = state.gid;
        }
        return await fetchApi(
            { commit },
            {
                request_name: 'submit_order_by_paypal',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                commit('APP_CLEAR_CART_DATA', {});
                commit('APP_SET_MSG', {
                    msg: i18n.t('cart.submitSuccessfully')
                });
                commit('APP_SET_SHARED_OID', {
                    shared_oid: res.data.data.oid
                });

                commit('APP_SHARED_CART_CLOSED', {});
                
                if (res.data.data.pay == 'success') {
                    setTimeout(function () {
                        // window.location.replace('/recepit?sid=' + post_data.sid + '&tid=' + post_data.tid);
                        const urlPre = window.location.origin + window.location.pathname + '#';
                        window.location.replace(urlPre + '/review?oid=' + res.data.data.oid + '&sid=' + post_data.sid);
                    }, 100);
                } else {
                    return  {
                        paid: false,
                        oid: res.data.data.oid
                    };
                }
            })
            .catch(() => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
            });
    },
    async submit_cart_stripe({ commit, rootState, rootGetters, state }, obj) {
        let { post_data, el } = obj;
        let aj_host = rootGetters.serverRoot;

        commit('APP_SET_IF_LOADING', { is_loading: true });
        let classTxt = el.className;
        el.className = classTxt + ' is-disabled';
        el.disabled = 'true';

        if (isValidStr(state.gid)) {
            post_data.gid = state.gid;
        }
        return await fetchApi(
            { commit },
            {
                request_name: 'submit_order_by_stripe',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                commit('APP_CLEAR_CART_DATA', {});
                commit('APP_SET_SHARED_OID', {
                    shared_oid: res.data.data.oid
                });

                commit('APP_SHARED_CART_CLOSED', {});
                if (res.data.data.pay == 'success') {
                    commit('APP_SET_MSG', {
                        msg: i18n.t('cart.submitSuccessfully')
                    });
                    setTimeout(function () {
                        // window.location.replace('/recepit?sid=' + post_data.sid + '&tid=' + post_data.tid);
                        const urlPre = window.location.origin + window.location.pathname + '#';
                        window.location.replace(urlPre + '/review?oid=' + res.data.data.oid + '&sid=' + post_data.sid);
                    }, 100);
                } else {
                    return  {
                        paid: false,
                        oid: res.data.data.oid
                    };
                }
            })
            .catch(() => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                el.className = classTxt;
                el.disabled = '';
            });
    },
    async submit_cart_yuansfer_creditCard({ commit, rootState, rootGetters, state }, obj) {
        let { post_data, el } = obj;
        let aj_host = rootGetters.serverRoot;

        commit('APP_SET_IF_LOADING', { is_loading: true });
        let classTxt = el.className;
        el.className = classTxt + ' is-disabled';
        el.disabled = 'true';

        if (isValidStr(state.gid)) {
            post_data.gid = state.gid;
        }
        return await fetchApi(
            { commit },
            {
                request_name: 'submit_order_by_yuansfer_creditCard',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                commit('APP_CLEAR_CART_DATA', {});
                commit('APP_SET_MSG', {
                    msg: i18n.t('cart.submitSuccessfully')
                });
                commit('APP_SET_SHARED_OID', {
                    shared_oid: res.data.data.oid
                });

                commit('APP_SHARED_CART_CLOSED', {});

                if (res.data.data.pay == 'success') {
                    setTimeout(function () {
                        // window.location.replace('/recepit?sid=' + post_data.sid + '&tid=' + post_data.tid);
                        const urlPre = window.location.origin + window.location.pathname + '#';
                        window.location.replace(urlPre + '/review?oid=' + res.data.data.oid + '&sid=' + post_data.sid);
                    }, 100);
                } else {
                    return  {
                        paid: false,
                        oid: res.data.data.oid
                    };
                }
            })
            .catch(() => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
                el.className = classTxt;
                el.disabled = '';
            });
    },
    async complete_pay({ commit, rootState, rootGetters, state }, post_data) {
        // let { post_data } = obj;
        let aj_host = rootGetters.serverRoot;

        commit('APP_SET_IF_LOADING', { is_loading: true });

        return await fetchApi(
            { commit },
            {
                request_name: 'complete_pay',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });

                let data = res.data.data;
                data.msg = res.data.msg;
                return  data;
                
            })
            .catch(() => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
            });
    },
    async cancel_order({ commit, rootState, rootGetters, state }, post_data) {
        // let { post_data } = obj;
        let aj_host = rootGetters.serverRoot;

        commit('APP_SET_IF_LOADING', { is_loading: true });

        return await fetchApi(
            { commit },
            {
                request_name: 'cancel_order',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                commit('APP_SET_IF_LOADING', { is_loading: false });

                let data = res.data.data;
                console.log('data', data);
                location.reload();
                // return data;
                
            })
            .catch(() => {
                commit('APP_SET_IF_LOADING', { is_loading: false });
            });
    },
    doToggleDetail({ commit, rootState }, isSHow) {
        commit('APP_TOGGLE_CART_DETAIL', isSHow);
    },
    init_paymethod({ commit, rootState, rootGetters }) {
        let aj_host = rootGetters.serverRoot;
        fetch(aj_host + '/weapp/takeout/paymethod/get?sid=' + rootState.app.sid, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                res = JSON.parse(res);
                if (res.data.code === 100000) {
                    commit('APP_INIT_PAYMETHOD', {
                        pay_method_setting: res.data.data.pay_method,
                        payment_setting: res.data.data.payment_setting,
                        tip_method_setting: res.data.data.tip_method,
                        merchant_custom_value: res.data.data.merchant_custom_value,
                        choose_time: res.data.data.choose_time,
                        choose_time_ahead_days: res.data.data.choose_time_ahead_days,
                        choose_time_options: res.data.data.choose_time_options,
                        tax_with_discount: res.data.data.tax_with_discount,
                        tip_with_tax: res.data.data.tip_with_tax,
                    });
                }
            });
    },
    do_check({ commit, rootState, rootGetters }, router) {
        let aj_host = rootGetters.serverRoot;
        fetch(aj_host + '/weapp/takeout/store/check?sid=' + rootState.app.sid + '&tid=' + rootState.app.shared_tid, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                res = JSON.parse(res);
                if (res.data.code === 100000) {
                    let _enable = res.data.data.enable;
                    let _err_msg = res.data.data.err_msg;

                    commit('APP_INIT_TAKEOUT_ENABLE', {
                        is_open: _enable,
                        err_msg: _err_msg
                    });

                    let welcome_path = '/welcome/' + rootState.app.sid;

                    if (router.history.current.path !== welcome_path && _enable == 0 && router.history.current.path !== '/review') {
                        commit('APP_SET_MSG', {
                            msg: _err_msg
                        });

                        commit('APP_SHARED_CART_CLOSED', {
                            router: router,
                            sid: rootState.app.sid
                        });
                    }
                }
            });
    },
    check_new({ commit, rootState }, tel) {
        let aj_host = rootState.debug ? 'https://takeout.minitable.link' : '';
        fetch(aj_host + '/weapp/takeout/user/check_new?sid=' + rootState.app.sid + '&tel=' + encodeURIComponent(tel), {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                res = JSON.parse(res);
                if (res.data.code === 100000) {
                    let _is_new = res.data.data.is_new || false;

                    commit('APP_SET_USER_IS_NEW', {
                        is_new: _is_new
                    });
                }
            });
    },
    check_vip({ commit, rootState, rootGetters }, tel) {
        let aj_host = rootGetters.serverRoot;
        fetch(aj_host + '/weapp/takeout/user/check_vip?sid=' + rootState.app.sid + '&tel=' + encodeURIComponent(tel), {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                res = JSON.parse(res);
                if (res.data.code === 100000) {
                    let _is_vip = res.data.data.is_vip || false;

                    commit('APP_SET_USER_IS_VIP', {
                        is_vip_new: _is_vip
                    });
                }
            });
    },
    get_menu({ commit, rootState, rootGetters }, sid) {
        let aj_host = rootGetters.serverRoot;
        fetch(aj_host + '/weapp/takeout/menu/get?sid=' + sid, {
            method: 'GET'
        })
            .then((res) => {
                return res.text();
            })
            .then((res) => {
                const res_data = JSON.parse(res).data;
                if (!res_data || !res_data.data || res_data.data.menu_list.length < 1) {
                    console.error('empty menu fetched');
                    return;
                }
                const { first_menu, menu, menu_list, foods } = res_data.data;
                let menu_id = first_menu;
                if (!first_menu) {
                    menu_id = Object.keys(menu)[0];
                }

                if (res_data.code === 100000) {
                    commit('APP_INIT_MENU_DATA', {
                        show_food_list: menu[menu_id].list,
                        now_menu: menu_id,
                        menu_dict: menu,
                        menu_list: menu_list,
                        food_dict: foods
                    });

                    commit('APP_UPDATE_CART_DATA', {
                        food_dict: res_data.data.foods
                    });
                }
            });
    }
};

// mutations
const mutations = {
    APP_SET_CART_DATA(state, _data) {
        let food_count = 0;
        for (let i = 0; i < _data.food_list.length; i++) {
            food_count = food_count + _data.food_list[i].fcount;
        }
        state.cart_data.cart_food_list = _data.food_list;
        state.cart_data.cart_food_dict = _data.food_dict;
        state.cart_data.total_price = _data.total_price;
        state.cart_data.total_price_vip = _data.total_price_vip;
        state.cart_data.total_price_pickup = _data.total_price_pickup;
        state.cart_data.user_count = _data.user_count;
        state.shared_oid = _data.shared_oid;
        if (_data.shared_tname) {
            state.shared_tname = _data.shared_tname;
        }
        state.shared_tstatus = _data.shared_tstatus;
        state.is_vip = _data.is_vip;
        state.cart_data.total_food_count = food_count;
    },
    APP_INIT_STORE_DATA(state, _data) {
        state.app_title = _data.sname;
        state.sname = _data.sname;
        state.language = window.localStorage.getItem('language') || _data.language;
        state.store_data = _data;
        state.is_loading_welcome = false;
    },
    APP_NEW_SET_FOOD(state, food_data) {
        let res = [];
        let _ori_food_list = JSON.parse(JSON.stringify(state.cart_data.cart_food_list));
        let food_count = 0;
        let plus_count = 0;
        let total_price = 0;
        let total_price_vip = 0;
        let total_price_pickup = 0;

        for (let i = 0, len = _ori_food_list.length; i < len; i++) {
            let extra_price = 0;
            let extra_price_pickup = 0;
            if (_ori_food_list[i].addon_prices != '') {
                let _addon_items = _ori_food_list[i].addon_prices.split('@@');
                let _addon_counts = _ori_food_list[i].addon_counts.split('@@');
                let _addon_pickup_discounts = _ori_food_list[i].addon_pickup_discounts.split('@@');
                for (let m = 0, mLen = _addon_items.length; m < mLen; m++) {
                    let _addon_prices = _addon_items[m].split('|');
                    let _counts_arr = _addon_counts[m].split('|');
                    let _pickup_discounts = _addon_pickup_discounts[m].split('|');

                    for (let j = 0, jLen = _addon_prices.length; j < jLen; j++) {
                        extra_price = parseFloat(extra_price) + parseFloat(_addon_prices[j] * _counts_arr[j]);
                        extra_price_pickup =
                            parseFloat(extra_price_pickup) +
                            parseFloat(_addon_prices[j] * _pickup_discounts[j] * _counts_arr[j]);
                    }
                }
            }

            let _price =
                food_data.is_vip && _ori_food_list[i].fprice_vip
                    ? _ori_food_list[i].fprice_vip
                    : _ori_food_list[i].fprice;
            let _pickup_price = _ori_food_list[i].fprice_pickup
                ? _ori_food_list[i].fprice_pickup
                : _ori_food_list[i].fprice;

            if (food_data.fid == _ori_food_list[i].fid) {
                if (
                    _ori_food_list[i].feature_name == food_data.feature_name &&
                    _ori_food_list[i].uname == food_data.uname &&
                    _ori_food_list[i].note == food_data.note &&
                    _ori_food_list[i].addon_names == food_data.addon_names && 
                    _ori_food_list[i].addon_counts == food_data.addon_counts
                ) {
                    plus_count = food_data.set_count - _ori_food_list[i].fcount;

                    _ori_food_list[i].fcount = food_data.set_count;
                    if (_ori_food_list[i].fcount > 0) {
                        res.push(_ori_food_list[i]);
                        total_price += _ori_food_list[i].fcount * (parseFloat(_ori_food_list[i].fprice) + extra_price);
                        total_price_vip += _ori_food_list[i].fcount * (parseFloat(_price) + extra_price);
                        total_price_pickup +=
                            _ori_food_list[i].fcount * (parseFloat(_pickup_price) + extra_price_pickup);
                    }
                } else {
                    res.push(_ori_food_list[i]);
                    total_price += _ori_food_list[i].fcount * (parseFloat(_ori_food_list[i].fprice) + extra_price);
                    total_price_vip += _ori_food_list[i].fcount * (parseFloat(_price) + extra_price);
                    total_price_pickup += _ori_food_list[i].fcount * (parseFloat(_pickup_price) + extra_price_pickup);
                }
                food_count = food_count + _ori_food_list[i].fcount;
            } else {
                res.push(_ori_food_list[i]);
                food_count = food_count + _ori_food_list[i].fcount;
                total_price += _ori_food_list[i].fcount * (parseFloat(_ori_food_list[i].fprice) + extra_price);
                total_price_vip += _ori_food_list[i].fcount * (parseFloat(_price) + extra_price);
                total_price_pickup += _ori_food_list[i].fcount * (parseFloat(_pickup_price) + extra_price_pickup);
            }
        }

        state.cart_data.cart_food_dict[food_data.fid].mix_fcount =
            state.cart_data.cart_food_dict[food_data.fid].mix_fcount + plus_count;

        state.cart_data.total_food_count = food_count;
        state.cart_data.cart_food_list = res;
        state.cart_data.total_price = parseFloat(total_price).toFixed(2);
        state.cart_data.total_price_vip = parseFloat(total_price_vip).toFixed(2);
        state.cart_data.total_price_pickup = parseFloat(total_price_pickup).toFixed(2);
        state.cart_data.cart_food_dict.random = new Date().getTime();
    },
    APP_NEW_ADD_FOOD(state, food_data) {
        let res = [];
        let _ori_food_list = JSON.parse(JSON.stringify(state.cart_data.cart_food_list));
        let add_flag = false;
        let food_count = 0;

        for (let i = 0, len = _ori_food_list.length; i < len; i++) {
            if (food_data.fid == _ori_food_list[i].fid) {
                if (
                    _ori_food_list[i].feature_name == food_data.feature_name &&
                    _ori_food_list[i].uname == food_data.uname &&
                    _ori_food_list[i].note == food_data.note &&
                    _ori_food_list[i].addon_names == food_data.addon_names &&
                    _ori_food_list[i].addon_counts == food_data.addon_counts
                ) {
                    _ori_food_list[i].fcount = _ori_food_list[i].fcount + food_data.add_count;
                    res.push(_ori_food_list[i]);
                } else {
                    res.push(_ori_food_list[i]);
                    if (!add_flag) {
                        res.push(food_data);
                        food_count = food_count + food_data.add_count;
                    }
                }
                add_flag = true;
                food_count = food_count + _ori_food_list[i].fcount;
            } else {
                res.push(_ori_food_list[i]);
                food_count = food_count + _ori_food_list[i].fcount;
            }
        }

        if (!add_flag) {
            res.push(food_data);
            food_count = food_count + food_data.add_count;
        }

        if (!state.cart_data.cart_food_dict[food_data.fid]) {
            state.cart_data.cart_food_dict[food_data.fid] = {
                mix_fcount: food_data.add_count,
                price: food_data.fprice,
                price_vip: food_data.fprice_vip,
                price_pickup: food_data.fprice_pickup
            };
        } else {
            state.cart_data.cart_food_dict[food_data.fid].mix_fcount =
                state.cart_data.cart_food_dict[food_data.fid].mix_fcount + food_data.add_count;
        }

        let _price = food_data.is_vip && food_data.fprice_vip ? food_data.fprice_vip : food_data.fprice;
        let _pickup_price = food_data.fprice_pickup ? food_data.fprice_pickup : food_data.fprice;

        state.cart_data.total_food_count = food_count;
        state.cart_data.cart_food_list = res;
        state.cart_data.total_price = (
            parseFloat(state.cart_data.total_price) +
            (parseFloat(food_data.fprice) + parseFloat(food_data.addon_total_price)) * food_data.add_count
        ).toFixed(2);
        state.cart_data.total_price_vip = (
            parseFloat(state.cart_data.total_price_vip) +
            (parseFloat(_price) + parseFloat(food_data.addon_total_price)) * food_data.add_count
        ).toFixed(2);
        state.cart_data.total_price_pickup = (
            parseFloat(state.cart_data.total_price_pickup) +
            (parseFloat(_pickup_price) + parseFloat(food_data.addon_total_price_pickup)) * food_data.add_count
        ).toFixed(2);
        state.cart_data.cart_food_dict.random = new Date().getTime();
    },
    APP_DEL_FOOD_INTO_CART(state, food_data) {
        let now_count = food_data.fcount;
        let res = [];
        let _ori_food_list = JSON.parse(JSON.stringify(state.cart_data.cart_food_list));
        let food_count = 0;

        for (let i = 0, len = _ori_food_list.length; i < len; i++) {
            if (food_data.fid == _ori_food_list[i].fid) {
                if (
                    _ori_food_list[i].feature_default_value == food_data.default_value &&
                    _ori_food_list[i].uname == food_data.uname
                ) {
                    if (now_count == 1) {
                        continue;
                    } else {
                        food_count = food_count + food_data.fcount;
                        res.push(food_data);
                    }
                } else {
                    food_count = food_count + _ori_food_list[i].fcount;
                    res.push(_ori_food_list[i]);
                }
            } else {
                food_count = food_count + _ori_food_list[i].fcount;
                res.push(_ori_food_list[i]);
            }
        }

        if (state.cart_data.cart_food_dict[food_data.fid]) {
            if (state.cart_data.cart_food_dict[food_data.fid].mix_fcount == 1) {
                state.cart_data.cart_food_dict[food_data.fid] = null;
            } else {
                state.cart_data.cart_food_dict[food_data.fid].mix_fcount--;
            }
        }

        let _price = food_data.is_vip && food_data.fprice_vip ? food_data.fprice_vip : food_data.fprice;
        let _pickup_price = food_data.fprice_pickup ? food_data.fprice_pickup : food_data.fprice;

        state.cart_data.cart_food_list = res;
        state.cart_data.total_food_count = food_count;
        state.cart_data.total_price = (parseFloat(state.cart_data.total_price) - parseFloat(food_data.fprice)).toFixed(
            2
        );
        state.cart_data.total_price_vip = (parseFloat(state.cart_data.total_price_vip) - parseFloat(_price)).toFixed(2);
        state.cart_data.total_price_pickup = (
            parseFloat(state.cart_data.total_price_pickup) - parseFloat(_pickup_price)
        ).toFixed(2);
        state.cart_data.cart_food_dict.random = new Date().getTime();
    },
    APP_CLEAR_CART_DATA(state) {
        state.cart_data = {
            cart_food_list: [],
            cart_food_dict: {
                random: new Date().getTime()
            },
            total_price: '0.00',
            total_price_vip: '0.00',
            total_price_pickup: '0.00',
            remark: '',
            user_count: state.cart_data.user_count,
            total_food_count: 0
        };
    },
    APP_SET_JOIN_TABLE_INFO(state, _data) {
        state.email = _data.email;
        state.uname = _data.email;
        state.shared_cart_id = _data.cartid;
        state.shared_tid = _data.tid;
    },
    APP_SET_SHARED_TIMER(state, _timer) {
        state.shared_foods_timer = _timer;
    },
    APP_SHARED_CART_CLOSED(state, _data) {
        state.cart_data = {
            cart_food_list: [],
            cart_food_dict: {
                random: new Date().getTime()
            },
            total_price: '0.00',
            total_price_vip: '0.00',
            total_price_pickup: '0.00',
            remark: '',
            user_count: 0,
            total_food_count: 0
        };
        state.shared_oid = '';
        state.shared_tid = '';
        state.shared_tname = '';
        state.shared_cart_id = '';
        state.shared_table_pswd = '';
        state.gid = '';

        localStorage.removeItem('shared_tid');
        localStorage.removeItem('shared_tname');
        localStorage.removeItem('shared_cart_id');
        localStorage.removeItem('shared_oid');
        localStorage.removeItem('shared_table_pswd');
        localStorage.removeItem('gid');

        if (_data.router && _data.sid) {
            _data.router.replace({ path: '/welcome/' + _data.sid });
        } else {
            window.localStorage.removeItem('client_sid');
        }
    },
    APP_SET_SHARED_OID(state, _data) {
        state.shared_oid = _data.shared_oid;
        state.has_new_order = true;
        window.localStorage.setItem('shared_oid', _data.shared_oid);
    },
    APP_SET_HAS_NEW_ORDER(state, _data) {
        state.has_new_order = true;
    },
    APP_SET_MSG(state, _data) {
        state.msg = _data.msg;
    },
    APP_SET_SID(state, { sid, gid }) {
        if (sid === localStorage.client_sid && gid === localStorage.gid) {
            return;
        }

        if (isValidStr(gid)) {
            state.gid = gid;
            localStorage.gid = gid;
        } else {
            state.gid = '';
            localStorage.removeItem('gid');
        }
        if (isValidStr(sid)) {
            localStorage.client_sid = sid;
            state.sid = sid;
        }

        state.cart_data = {
            cart_food_list: [],
            cart_food_dict: {
                random: new Date().getTime()
            },
            total_price: '0.00',
            total_price_vip: '0.00',
            total_price_pickup: '0.00',
            remark: '',
            user_count: 0,
            total_food_count: 0
        };
        state.shared_oid = '';
        state.shared_tid = '';
        state.shared_tname = '';
        state.shared_cart_id = '';
        state.shared_table_pswd = '';

        window.localStorage.removeItem('shared_tid');
        window.localStorage.removeItem('shared_tname');
        window.localStorage.removeItem('shared_cart_id');
        window.localStorage.removeItem('shared_oid');
        window.localStorage.removeItem('shared_table_pswd');
    },
    APP_HAVE_READ_NEW_ORDER(state) {
        state.has_new_order = false;
    },
    APP_TOGGLE_CART_DETAIL(state, show) {
        state.show_cart_detail = show;
    },
    APP_TOGGLE_LEFT_NAV(state, _show) {
        state.show_left_nav = _show;
    },
    APP_SET_APP_TITLE(state, _title) {
        state.app_title = _title;
    },
    APP_SHOW_MENU_NAV(state, _show_menu_nav) {
        state.show_menu_nav = _show_menu_nav;
    },
    APP_INIT_PAYMETHOD(state, _data) {
        state.pay_method_setting = _data.pay_method_setting;
        state.payment_setting = _data.payment_setting;
        state.tip_method_setting = _data.tip_method_setting;
        state.merchant_custom_value = _data.merchant_custom_value;
        state.choose_time = _data.choose_time;
        state.choose_time_ahead_days = _data.choose_time_ahead_days;
        state.choose_time_options = _data.choose_time_options;
        state.tax_with_discount = _data.tax_with_discount;
        state.tip_with_tax = _data.tip_with_tax;
    },
    APP_INIT_TAKEOUT_ENABLE(state, _data) {
        state.is_open = _data.is_open;
        state.err_msg = _data.err_msg;
    },
    APP_SET_CHECK_TIMER(state, timer) {
        state.check_timer = timer;
    },
    APP_SET_USER_IS_NEW(state, _data) {
        state.is_new = _data.is_new;
    },
    APP_INIT_OTHER_SETTING(state, _data) {
        state.full_coupon = _data.full_coupon || [];
        state.first_discount_amount = _data.first_discount_amount || 0;
        state.first_discount_type = _data.first_discount_type || 0;
        state.first_discount_limit_amount = _data.first_discount_limit_amount || 0;
        // state.pickup_amount = _data.pickup_amount || 0;
        // state.pickup_enable = _data.pickup_enable || 0;
        state.annoucement = _data.annoucement || '';
        state.delivery_vip_free = _data.delivery_vip_free || 0;
        // state.delivery_enable = _data.delivery_enable || 0;
        state.delivery_area_list = _data.delivery_area_list || [];
        // state.pickup_discount_enable = _data.pickup_discount_enable || 0;
        state.delivery_first_name = _data.delivery_first_name;

        state.feature_image = _data.feature_image;
        state.theme_color = _data.theme_color;
        state.notice_layer_title_color = _data.notice_layer_title_color;
        state.notice_layer_subtitle = _data.notice_layer_subtitle;
        state.notice_layer_ps_content = _data.notice_layer_ps_content;
        state.notice_enable = _data.notice_enable;
        state.auto_notice_enable = _data.auto_notice_enable;

        state.pickup_setting = _data.pickup_setting;
        state.delivery_setting = _data.delivery_setting;
        state.dinein_setting = _data.dinein_setting && JSON.parse(_data.dinein_setting);
        state.recommend_popup_setting = _data.recommend_popup_setting;

        state.short_url_v2 = _data.short_url_v2;
        state.tax_with_discount = _data.tax_with_discount;
        state.tip_with_tax = _data.tip_with_tax;
    },
    APP_SET_USER_IS_VIP(state, _data) {
        state.is_vip_new = _data.is_vip_new;
    },
    APP_SET_PHONE_NUM(state, _data) {
        state.phone_num = _data.phone_num;
        state.code = _data.code;
    },
    APP_INIT_MENU_DATA(state, res_data) {
        state.show_food_list = res_data.show_food_list;
        state.now_menu = res_data.now_menu;
        state.menu_dict = res_data.menu_dict;
        state.menu_list = res_data.menu_list;
        state.food_dict = res_data.food_dict;
        state.is_loading_menu = false;
    },
    APP_SET_MENU_TIMER(state, timer) {
        state.menu_timer = timer;
    },
    APP_UPDATE_CART_DATA(state, _data) {
        let ori_cart_data = JSON.parse(JSON.stringify(state.cart_data));
        let { cart_food_list, cart_food_dict, total_price, total_price_vip, total_food_count } = ori_cart_data;
        let food_dict = _data.food_dict;

        let new_list = [];
        let changed = false;
        for (let i = 0; i < cart_food_list.length; i++) {
            let temp_data = JSON.parse(JSON.stringify(cart_food_list[i]));
            let {
                fid,
                fprice,
                addon_names,
                addon_prices,
                addon_counts,
                fprice_pickup,
                addon_pickup_discounts,
                fcount
            } = temp_data;

            if (!food_dict[fid] || food_dict[fid].is_out_of_stock == 1) {
                changed = true;
                continue;
            }

            if (addon_names) {
                let ori_addon_names_arr = addon_names.split('@@');
                let ori_addon_prices_arr = addon_prices.split('@@');
                let ori_addon_counts_arr = addon_counts.split('@@');
                let ori_addon_pickup_discounts_arr = addon_pickup_discounts.split('@@');

                let now_addon_list = food_dict[fid].addon_data;
                let now_addon_dict = {};
                for (let j = 0; j < now_addon_list.length; j++) {
                    let {
                        addon_values_name,
                        addon_values_name_chn,
                        addon_values_price,
                        addon_values_availability,
                        pickup_discount,
                        multiple_choice,
                        addon_values_name_kor
                    } = now_addon_list[j];

                    let addon_values_name_arr = addon_values_name.split('|');
                    let addon_values_name_chn_arr = addon_values_name_chn.split('|');
                    let addon_values_price_arr = addon_values_price.split('|');
                    let addon_values_availability_arr = addon_values_availability.split('|');
                    let addon_values_kor_arr = (multiple_choice == 1 && addon_values_name_kor) ? addon_values_name_kor.split('|') : '';

                    for (let m = 0; m < addon_values_name_arr.length; m++) {
                        now_addon_dict[addon_values_name_arr[m]] = {
                            price: addon_values_price_arr[m] || 0,
                            pickup_discount: pickup_discount,
                            availability: addon_values_availability_arr[m] == 1 ? true : false
                        };
                        now_addon_dict[addon_values_name_chn_arr[m]] = {
                            price: addon_values_price_arr[m] || 0,
                            pickup_discount: pickup_discount,
                            availability: addon_values_availability_arr[m] == 1 ? true : false
                        };
                        if (multiple_choice == 1 && addon_values_kor_arr) {
                            now_addon_dict[addon_values_kor_arr[m]] = {
                                price: addon_values_price_arr[m] || 0,
                                pickup_discount: pickup_discount,
                                availability: addon_values_availability_arr[m] == 1 ? true : false
                            };
                        }
                    }
                }

                let new_addon_name = [];
                let new_addon_price = [];
                let new_addon_count = [];
                let new_addon_pickup_discount = [];
                let new_addon_total_price = 0;
                let new_addon_total_price_pickup = 0;

                for (let n = 0; n < ori_addon_names_arr.length; n++) {
                    let temp_name = ori_addon_names_arr[n];
                    let temp_price = ori_addon_prices_arr[n];
                    let temp_count = ori_addon_counts_arr[n];
                    let temp_pickup_discounts = ori_addon_pickup_discounts_arr[n];

                    let item_temp_names = temp_name.split('|');
                    let item_temp_counts = temp_count.split('|');
                    let item_temp_pickup_discounts = temp_pickup_discounts.split('|');

                    let child_addon_price_arr = [];
                    let child_addon_name_arr = [];
                    let child_addon_count_arr = [];
                    let child_addon_pickup_discounts_arr = [];

                    for (let l = 0, jLen = item_temp_names.length; l < jLen; l++) {
                        let child_addon_name = item_temp_names[l];
                        let child_addon_count = item_temp_counts[l];
                        let child_addon_pickup_discount = item_temp_pickup_discounts[l];

                        if (!now_addon_dict[child_addon_name] || !now_addon_dict[child_addon_name].availability) {
                            changed = true;
                            continue;
                        }

                        child_addon_name_arr.push(child_addon_name);
                        child_addon_count_arr.push(child_addon_count);

                        let now_pickup_discount = 1;
                        if (child_addon_pickup_discount != now_addon_dict[child_addon_name].pickup_discount) {
                            changed = true;
                            child_addon_pickup_discounts_arr.push(now_addon_dict[child_addon_name].pickup_discount);
                            now_pickup_discount = now_addon_dict[child_addon_name].pickup_discount;
                        } else {
                            child_addon_pickup_discounts_arr.push(child_addon_pickup_discount);
                            now_pickup_discount = child_addon_pickup_discount;
                        }

                        if (temp_price != now_addon_dict[child_addon_name].price) {
                            changed = true;
                            child_addon_price_arr.push(now_addon_dict[child_addon_name].price);
                            new_addon_total_price =
                                new_addon_total_price + now_addon_dict[child_addon_name].price * child_addon_count;
                            new_addon_total_price_pickup =
                                new_addon_total_price +
                                now_addon_dict[child_addon_name].price * child_addon_count * now_pickup_discount;
                        } else {
                            child_addon_price_arr.push(temp_price);
                            new_addon_total_price = new_addon_total_price + temp_price * child_addon_count;
                            new_addon_total_price_pickup =
                                new_addon_total_price + temp_price * child_addon_count * now_pickup_discount;
                        }
                    }

                    new_addon_name.push(child_addon_name_arr.join('|'));
                    new_addon_price.push(child_addon_price_arr.join('|'));
                    new_addon_count.push(child_addon_count_arr.join('|'));
                    new_addon_pickup_discount.push(child_addon_pickup_discounts_arr.join('|'));
                }

                temp_data.addon_total_price = new_addon_total_price.toFixed(2);
                temp_data.addon_names = new_addon_name.join('@@');
                temp_data.addon_prices = new_addon_price.join('@@');
                temp_data.addon_counts = new_addon_count.join('@@');
                temp_data.addon_pickup_discounts = new_addon_pickup_discount.join('@@');
            }

            if (fprice != food_dict[fid].fprice) {
                temp_data.fprice = food_dict[fid].fprice;
                changed = true;
            }

            if (fprice_pickup != food_dict[fid].fprice_pickup) {
                temp_data.fprice_pickup = food_dict[fid].fprice_pickup;
                changed = true;
            }

            if (food_dict[fid].once_limit > 0 && fcount > food_dict[fid].once_limit) {
                temp_data.fcount = food_dict[fid].once_limit;
                changed = true;
            }

            new_list.push(temp_data);
        }

        let new_cart_dict = {};
        for (let _fid in cart_food_dict) {
            let temp_data = JSON.parse(JSON.stringify(cart_food_dict[_fid]));

            if (_fid != 'random') {
                if (!food_dict[_fid] || food_dict[_fid].is_out_of_stock == 1) {
                    changed = true;
                    continue;
                }

                if (temp_data.price != food_dict[_fid].fprice) {
                    temp_data.price = food_dict[_fid].fprice;
                    changed = true;
                }

                if (temp_data.price_vip != food_dict[_fid].fprice_vip) {
                    temp_data.price_vip = food_dict[_fid].fprice_vip;
                    changed = true;
                }

                if (temp_data.price_pickup != food_dict[_fid].fprice_pickup) {
                    temp_data.price_pickup = food_dict[_fid].fprice_pickup;
                    changed = true;
                }
                new_cart_dict[_fid] = temp_data;
            } else {
                new_cart_dict.random = new Date().getTime();
            }
        }

        if (changed) {
            let food_count = 0;
            let total_price = 0;
            let total_price_vip = 0;
            let total_price_pickup = 0;

            for (let k = 0; k < new_list.length; k++) {
                let temp_food_data = new_list[k];
                let extra_price = 0;
                let extra_price_pickup = 0;
                if (temp_food_data.addon_prices != '') {
                    let _addon_items = temp_food_data.addon_prices.split('@@');
                    let _addon_counts = temp_food_data.addon_counts.split('@@');
                    let _addon_pickup_discounts = temp_food_data.addon_pickup_discounts.split('@@');
                    for (let m = 0, mLen = _addon_items.length; m < mLen; m++) {
                        let _addon_prices = _addon_items[m].split('|');
                        let _counts_arr = _addon_counts[m].split('|');
                        let _pickup_discounts_arr = _addon_pickup_discounts[m].split('|');
                        for (let j = 0, jLen = _addon_prices.length; j < jLen; j++) {
                            extra_price = parseFloat(extra_price) + parseFloat(_addon_prices[j] * _counts_arr[j]);
                            extra_price_pickup =
                                parseFloat(extra_price_pickup) +
                                parseFloat(_addon_prices[j] * _counts_arr[j] * _pickup_discounts_arr[j]);
                        }
                    }
                }

                let _price =
                    temp_food_data.is_vip && temp_food_data.fprice_vip
                        ? temp_food_data.fprice_vip
                        : temp_food_data.fprice;
                let _pickup_price = temp_food_data.fprice_pickup ? temp_food_data.fprice_pickup : temp_food_data.fprice;

                food_count = food_count + temp_food_data.fcount;
                total_price += temp_food_data.fcount * (parseFloat(temp_food_data.fprice) + extra_price);
                total_price_vip += temp_food_data.fcount * (parseFloat(_price) + extra_price);
                total_price_pickup += temp_food_data.fcount * (parseFloat(_pickup_price) + extra_price_pickup);
            }

            state.cart_data.cart_food_list = new_list;
            state.cart_data.total_food_count = food_count;
            state.cart_data.total_price = total_price.toFixed(2);
            state.cart_data.total_price_vip = total_price_vip.toFixed(2);
            state.cart_data.total_price_pickup = total_price_pickup.toFixed(2);
            state.cart_data.cart_food_dict = new_cart_dict;
        }
    },
    APP_SET_UNAME(state, _data) {
        state.user_name = _data.name;
        // window.localStorage.setItem('uname', _data.name);
    },
    APP_SET_FIRST_NAME(state, _data) {
        state.first_name = _data.first_name;
    },
    APP_SET_LAST_NAME(state, _data) {
        state.last_name = _data.last_name;
    },
    APP_SET_IF_LOADING(state, _data) {
        state.is_loading = _data.is_loading;
    },
    APP_SET_ORDER_TYPE(state, _data) {
        state.type = _data.type;
        if (_data.type) {
            window.localStorage.setItem('orderType', _data.type);
        } else {
            window.localStorage.removeItem('orderType');
        }
        
    },
    APP_SET_ADDRESS(state, _data) {
        state.state = _data.state;
        state.city = _data.city;
        state.street_address1 = _data.street_address1;
        state.street_address2 = _data.street_address2;
        state.zip_code = _data.zip_code;
        state.zip_code_index = _data.zip_code_index;
    },
    APP_SET_LANGUAGE(state, _data) {
        state.store_data = Object.assign(state.store_data, _data);
    },
    APP_SET_CODE(state, payload) {
        state.code = payload;
    },
    APP_SET_DineInTable(state, payload) {
        state.dineInTable = payload;
        if (payload && payload) {
            window.localStorage.setItem('dineInTable', payload);
        } else {
            window.localStorage.removeItem('dineInTable');
        }
    },
    APP_SET_MULTI_LANG(state, data) {
        const map = {};
        data.forEach(item =>{
            const sid = item.sid;
            const business = item.business;
            if(!map[sid]) {
                map[sid] = {}
            }
            if(!map[sid][business]) {
                map[sid][business] = {}
            }
            map[sid][business][item.eng] = item;
        });
        state.multi_lang = map;
    },
    APP_SET_ZIPCODE(state, _data) {
        state.zipCodeOrder = _data.zipCodeOrder;
    },
};

function _checkAdd(food_data, rootState) {
    let cart_food_list = rootState.app.cart_data.cart_food_list || [];
    let isAdd = true;

    for (let i = 0; i < cart_food_list.length; i++) {
        if (food_data.fid == cart_food_list[i].fid) {
            if (
                cart_food_list[i].feature_name == food_data.feature_name &&
                cart_food_list[i].uname == food_data.uname &&
                cart_food_list[i].note == food_data.note &&
                cart_food_list[i].addon_names == food_data.addon_names &&
                cart_food_list[i].addon_counts == food_data.addon_counts
            ) {
                food_data.fcount = cart_food_list[i].fcount + food_data.add_count;
                isAdd = false;
            }
        }
    }

    return {
        isAdd,
        food_data
    };
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
