<template>
    <div class="check_page">
        <div class="check_header" :style="{ backgroundImage: feature_image }">
            <!-- <div class="white_bg"></div> -->
            <!-- <span class="go_menu" @click="goMenu"></span> -->
            <!-- <h3>{{ $t('cart.placeOrderTitle') }}</h3> -->
        </div>
        <div class="checkout_section">
            <div class="checkout_container">
                <div class="checkout_inner">
                    <div class="check_pot">
                        <div class="go_menu" @click="goMenu"></div>
                        <div class="check_pot_name">{{ sname }}</div>
                    </div>
                    <div class="mobile_your_item">
                        <YourItem />
                    </div>
                    <div :class="isInfoDone ? 'check_box check_done' : 'check_box'">
                        <span class="check_line"></span>
                        <span class="check_circle">1</span>
                        <div class="check_box_title">{{ $t('checkout.orderInfo') }}</div>
                        <div class="check_box_inner">
                            <div class="checkout_item" style="position: relative;">
                                <!-- <h4>{{ $t('checkout.orderOption') }}</h4> -->
                                <div class="checkout_type">
                                    <div class="pick-type" :class="{ noDelivery: delivery_setting.enable != 1 }">
                                        <div
                                            class="pick-type-item"
                                            @click="choosePickType('dineIn')"
                                            v-if="canDineIn"
                                            :class="{ active: orderType === 'dineIn' }"
                                        >
                                            {{ $t('checkout.dineIn') }}
                                        </div>
                                        <div
                                            class="pick-type-item"
                                            :class="{ active: orderType === 'pickup' }"
                                            @click="choosePickType('pickup')"
                                            v-if="pickup_setting.enable == 1"
                                        >
                                            {{ $t('checkout.pickup') }}
                                        </div>
                                        <div
                                            class="pick-type-item"
                                            @click="choosePickType('delivery')"
                                            :class="{ active: orderType === 'delivery' }"
                                            v-if="deliveryEnable"
                                        >
                                            {{ $t('checkout.delivery') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="hide_time_option" v-if="showTimeSelect" @click="hideTimeSelect"></div>
                                <div class="checkout_choose_time"  v-if="new_choose_time !== '' && type !== 'dineIn'">
                                <!-- <div class="checkout_choose_time" style="display: none;"> -->
                                    <div class="choose_time_select" ref="chooseTimeSelect" @click="toggleTimeSelect">
                                            <span class="select_arrow"></span>
                                            <div class="">{{ choose_a_time }}</div>
                                    </div>
                                    <div class="choose_time_option" ref="chooseTimeOption" v-if="showTimeSelect">
                                        <div class="choose_time_date">
                                            <div
                                                :class="item.val == custom_pickup_date ? 'time_option_item curr' : 'time_option_item'"
                                                @click="chooseTypeDate(item.val, item.newTxt, item.chnTxt)"
                                                v-for="(item, index) in new_custom_date_arr"
                                                :key="'date_' + index"
                                                :value="item.val"
                                            >
                                                {{ language == 'chn' ? item.chnTxt : item.newTxt }}
                                            </div>
                                        </div>
                                        <div class="choose_time_hour">
                                            <div
                                                class="time_option_item"
                                                :class="pickup_time == 'ASAP' ? 'time_option_item curr' : 'time_option_item'"
                                                @click="chooseTypeTime('ASAP', 'ASAP')"
                                                v-if="new_choose_time.indexOf('ASAP') > -1 && (custom_select_date.indexOf('Today') > -1 || custom_select_date.indexOf('今日') > -1)"
                                            >
                                                ASAP
                                            </div>
                                            <template v-if="new_choose_time.indexOf('schedule') > -1">
                                                <div
                                                    :class="item.val == custom_pickup_time ? 'time_option_item curr' : 'time_option_item'"
                                                    @click="chooseTypeTime(item.val, item.txt)"
                                                    v-for="(item, index) in new_custom_time_arr"
                                                    :key="'date_' + index"
                                                    :value="item.val"
                                                >
                                                    {{ item.txt }}
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="checkout_option">
                                    <div class="option_inner">
                                        <div class="radio_box" v-if="canDineIn" data-bee="module_id=input&item_id=dine_in">
                                            <div class="radio_item">
                                                <div class="m_radio">
                                                    <span class="big_circle">
                                                        <b></b>
                                                        <b></b>
                                                    </span>
                                                    <input
                                                        id="order_option01"
                                                        type="radio"
                                                        name="order_option"
                                                        value="dineIn"
                                                        v-model="orderType"
                                                        readonly
                                                    />
                                                    <span class="m_icon m_radio_icon"></span>
                                                </div>
                                                <label class="m_label" for="order_option01">{{ $t('checkout.dineIn') }}</label>
                                            </div>
                                            <div class="dine_table" @click="chooseTable" v-if="dineTable">
                                                {{ dineTable }}
                                            </div>
                                        </div>
                                        <div class="radio_box" v-if="pickup_setting.enable == 1" data-bee="module_id=input&item_id=pickup">
                                            <div class="radio_item">
                                                <div class="m_radio">
                                                    <span class="big_circle">
                                                        <b></b>
                                                        <b></b>
                                                    </span>
                                                    <input
                                                        id="order_option02"
                                                        type="radio"
                                                        name="order_option"
                                                        value="pickup"
                                                        v-model="orderType"
                                                        readonly
                                                    />
                                                    <span class="m_icon m_radio_icon"></span>
                                                </div>
                                                <label class="m_label" for="order_option02">{{
                                                    pickup_discount_enable && pickup_promotion > 0
                                                        ? $t('checkout.pickupSave', { price: pickup_promotion })
                                                        : $t('checkout.pickup')
                                                }}</label>
                                            </div>
                                        </div>
                                        <div class="radio_box" v-if="deliveryEnable" data-bee="module_id=input&item_id=delivery">
                                            <div class="radio_item">
                                                <div class="m_radio">
                                                    <span class="big_circle">
                                                        <b></b>
                                                        <b></b>
                                                    </span>
                                                    <input
                                                        id="order_option03"
                                                        type="radio"
                                                        name="order_option"
                                                        value="delivery"
                                                        v-model="orderType"
                                                        readonly
                                                    />
                                                    <span class="m_icon m_radio_icon"></span>
                                                </div>
                                                <label class="m_label" for="order_option03">{{
                                                    $t('checkout.delivery')
                                                }}</label>
                                            </div>
                                            <div
                                                class="add_address"
                                                v-if="orderType === 'delivery' && !has_saved_address"
                                                @click="showAddressForm"
                                            >
                                                {{ $t('checkout.enterAddress') }}
                                            </div>
                                            <div class="option_other" v-if="orderType === 'delivery' && has_saved_address">
                                                <div class="delivery">
                                                    <p>{{ addressShow }}</p>
                                                    <mt-button type="danger" plain @click="showAddressForm">
                                                        {{ $t('checkout.editAddress') }}</mt-button
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <!-- Choose a time 改版，此处不需要 -->
                            <!-- <div class="checkout_item" v-if="new_choose_time !== ''">
                                <h4>{{ $t('checkout.chooseTime') }}</h4>
                                <div class="checkout_time">
                                    <div class="time_inner">
                                        <div class="radio_box" v-if="new_choose_time.indexOf('ASAP') > -1" data-bee="module_id=input&item_id=asap">
                                            <div class="radio_item">
                                                <div class="m_radio">
                                                    <span class="big_circle">
                                                        <b></b>
                                                        <b></b>
                                                    </span>
                                                    <input
                                                        id="choose_time01"
                                                        type="radio"
                                                        name="choose_time"
                                                        value="ASAP"
                                                        v-model="pickup_time"
                                                        readonly
                                                    />
                                                    <span class="m_icon m_radio_icon"></span>
                                                </div>
                                                <label class="m_label" for="choose_time01">{{ $t('checkout.asap') }}</label>
                                            </div>
                                        </div>
                                        <div
                                            class="radio_box"
                                            v-if="new_choose_time.indexOf('schedule') > -1 && type !== 'dineIn'"
                                            data-bee="module_id=input&item_id=schedule"
                                        >
                                            <div class="radio_item">
                                                <div class="m_radio">
                                                    <span class="big_circle">
                                                        <b></b>
                                                        <b></b>
                                                    </span>
                                                    <input
                                                        id="choose_time02"
                                                        type="radio"
                                                        name="choose_time"
                                                        v-model="pickup_time"
                                                        value="custom"
                                                        readonly
                                                    />
                                                    <span class="m_icon m_radio_icon"></span>
                                                </div>
                                                <label class="m_label" for="choose_time02">{{ $t('checkout.schedule') }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="time_other"
                                        v-if="
                                            pickup_time === 'custom' &&
                                            (orderType == 'pickup' || (orderType == 'delivery' && has_saved_address))
                                        "
                                    >
                                        <div class="time_select">
                                            <div class="select_inner">
                                                <select v-model="custom_pickup_date">
                                                    <option
                                                        v-for="(item, index) in new_custom_date_arr"
                                                        :key="'date_' + index"
                                                        :value="item.val"
                                                    >
                                                        {{ item.txt }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="time_select">
                                            <div class="select_inner">
                                                <select v-model="custom_pickup_time" v-if="new_custom_time_arr.length > 0">
                                                    <option
                                                        v-for="(item, index) in new_custom_time_arr"
                                                        :key="'date_' + index"
                                                        :value="item.val"
                                                    >
                                                        {{ item.txt }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="time_select">
                                            <div class="select_inner">
                                                <select v-model="custom_pickup_time" v-if="new_custom_time_arr.length === 0">
                                                    <option :key="'date_not_avi'" value="">None</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="flex_box isPhone">
                                <div class="checkout_item">
                                    <!-- <h4>{{ $t('checkout.name') }} *</h4> -->
                                    <h4>{{ $t('checkout.firstName') }} *</h4>
                                    <div class="checkout_name">
                                        <input type="text" :placeholder="$t('checkout.typeHere')" data-bee="module_id=input&item_id=first_name" v-model="first_name" />
                                    </div>
                                </div>
                                <div class="checkout_item">
                                    <h4>{{ $t('checkout.lastName') }} *</h4>
                                    <div class="checkout_name">
                                        <input type="text" :placeholder="$t('checkout.typeHere')" data-bee="module_id=input&item_id=last_name" v-model="last_name" />
                                    </div>
                                </div>
                                <!-- <div class="checkout_item">
                                    <h4>{{ $t('checkout.phone') }} *</h4>
                                    <div class="checkout_phone">
                                        <div class="checkout_select">
                                            <div class="select_inner">
                                                <select v-model="code" @change="handleBlur">
                                                    <option value="+1">+1</option>
                                                    <option value="+86">+86</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="phone_inner">
                                            <input
                                                type="text"
                                                :placeholder="$t('checkout.typeHere')"
                                                :maxlength="phone_num_length"
                                                data-bee="module_id=input&item_id=phone_number"
                                                @focus="handleFocus"
                                                @keyup="input_change"
                                                v-model="phone_num"
                                                @blur="handleBlur"
                                                id="phoneNum"
                                            />
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="checkout_item">
                                    <h4>{{ $t('checkout.phone') }} *</h4>
                                    <div class="checkout_phone">
                                        <div class="checkout_select">
                                            <div class="select_inner">
                                                <select v-model="code" @change="handleBlur">
                                                    <option value="+1">+1</option>
                                                    <option value="+86">+86</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="phone_inner">
                                            <input
                                                type="text"
                                                :placeholder="$t('checkout.typeHere')"
                                                :maxlength="phone_num_length"
                                                data-bee="module_id=input&item_id=phone_number"
                                                @focus="handleFocus"
                                                @keyup="input_change"
                                                v-model="phone_num"
                                                @blur="handleBlur"
                                                id="phoneNum"
                                            />
                                            <!-- <input type="number" placeholder="Type here" value="111" /> -->
                                        </div>
                                    </div>
                                </div>
                            <div class="checkout_item" v-if="orderType === 'dineIn' && dineInTableEnable">
                                <h4>{{ $t('menu.tableSelection') }} *</h4>
                                <!-- <div class="dine_table" @click="chooseTable" v-if="dineTable">
                                    {{ dineTable }}
                                </div> -->
                                <div class="tableBox" v-if="dineInTable">
                                    <div class="tableName" @click="chooseTable">
                                        {{ dineInTable }}
                                    </div>
                                    <div class="tableEdit" @click="chooseTable">{{ $t('menu.edit') }}</div>
                                </div>
                                <div v-else class="tableEdit" @click="chooseTable">{{ $t('menu.edit') }}</div>
                            </div>
                            <div class="checkout_item" v-if="orderType === 'delivery'">
                                <h4>{{ $t('checkout.deliveryAddress') }} *</h4>
                                <div
                                    class="add_address"
                                    v-if="!has_saved_address"
                                    @click="showAddressForm"
                                >
                                    {{ $t('checkout.add') }}
                                </div>
                                <div class="option_other" v-else>
                                    <div class="delivery">
                                        <p>{{ addressShow }}</p>
                                        <mt-button type="danger" plain @click="showAddressForm">
                                            {{ $t('menu.edit') }}</mt-button
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="checkout_item">
                                <h4>{{ $t('checkout.specialNote') }}</h4>
                                <div class="checkout_note">
                                    <div class="note_inner">
                                        <textarea
                                            :placeholder="$t('common.letUsKnow')"
                                            rows="2"
                                            data-bee="module_id=input&item_id=notes"
                                            v-model="note"
                                            cols="40"
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div :class="isPayDone ? 'check_box check_done' : 'check_box'">
                        <span class="check_circle">2</span>
                        <div class="check_box_title">{{ $t('checkout.payment') }}</div>
                        <div class="check_box_inner">
                            <div class="checkout_item">
                                <h4>{{ $t('checkout.selectTip') }}</h4>
                                <!-- Tip 改版 -->
                                <div class="checkout_tip">
                                    <div class="tip_inner" id="tip-inner" ref="tipInner">
                                        <div
                                            v-if="tip_method_setting && tip_method_setting.indexOf('cash') > -1"
                                            class="tip_item"
                                            @click="chooseTip({ tip_method: 'cash' })"
                                        >
                                            <div class="tip_item_inner" :class="{ current: tip_method === 'cash' }">
                                                <div class="tip_item_content">
                                                    <div class="content_title">{{ $t('checkout.tipByCash') }}</div>
                                                    <div class="content_text"></div>
                                                </div>
                                            </div>
                                            <span class="tip_line"></span>
                                        </div>

                                        <div
                                            v-if="tip_method_setting && tip_method_setting.indexOf('merchant_fixed_value') > -1"
                                            class="tip_item"
                                            @click="chooseTip({ tip_method: 'merchant_fixed_value' })"
                                        >
                                            <div
                                                class="tip_item_inner"
                                                :class="{ current: tip_method === 'merchant_fixed_value' }"
                                            >
                                                <div class="tip_item_content">
                                                    <div class="content_title">{{ $t('checkout.tipFixed') }}</div>
                                                    <div class="content_text">${{ merchant_custom_value }}</div>
                                                </div>
                                            </div>
                                            <span class="tip_line"></span>
                                        </div>

                                        <div
                                            class="tip_item"
                                            @click="chooseTip({ tip_method: 'merchant_fixed_percent' })"
                                            v-if="
                                                tip_method_setting && tip_method_setting.indexOf('merchant_fixed_percent') > -1
                                            "
                                        >
                                            <div
                                                class="tip_item_inner"
                                                :class="{ current: tip_method === 'merchant_fixed_percent' }"
                                            >
                                                <div class="tip_item_content">
                                                    <div class="content_title">
                                                        <!-- {{ $t('checkout.customPercent') }}
                                                        <br /> -->
                                                        {{
                                                            (merchant_custom_value * 100).toFixed(2)
                                                        }}%
                                                    </div>
                                                    <div class="content_text">${{ merchant_custom_value_price }}</div>
                                                </div>
                                            </div>
                                            <span class="tip_line"></span>
                                        </div>

                                        <template v-if="tip_method_setting && tip_method_setting.indexOf('fixed_radio') > -1">
                                            <div
                                                class="tip_item"
                                                v-for="(item, index) in fixedRatios"
                                                :key="index"
                                                @click="chooseTip({ tip_method: 'fixed_radio', ratio: item.ratio })"
                                            >
                                                <div
                                                    class="tip_item_inner"
                                                    :class="{
                                                        current: tip_method === 'fixed_radio' && tip_fixed_radio === item.ratio
                                                    }"
                                                >
                                                    <div class="tip_item_content">
                                                        <div class="content_title">{{ item.ratio * 100 }}%</div>
                                                        <div class="content_text">${{ item.price }}</div>
                                                    </div>
                                                </div>
                                                <span class="tip_line"></span>
                                            </div>
                                        </template>
                                        <!-- <div
                                            class="tip_item tip_min_width"
                                            v-if="tip_method_setting && tip_method_setting.indexOf('input') > -1"
                                            @click="chooseTip({ tip_method: 'input' })"
                                        >
                                            <div class="tip_item_inner" :class="{ current: tip_method === 'input' }">
                                                <div class="tip_item_content">
                                                    <div class="content_title">{{ $t('checkout.custom') }}</div>
                                                    <div class="content_input">
                                                        <span>$</span>
                                                        <input type="text" placeholder="" v-model="tip_custom_input" />
                                                    </div>
                                                </div>
                                            </div>
                                            <span class="tip_line"></span>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="tip_custom">
                                    <div
                                        class="tip_item tip_min_width"
                                        v-if="tip_method_setting && tip_method_setting.indexOf('input') > -1"
                                        @click="chooseTip({ tip_method: 'input' })"
                                    >
                                        <div class="tip_item_inner" :class="{ current: tip_method === 'input' }">
                                            <div class="tip_item_content">
                                                <div class="content_title">{{ $t('checkout.custom') }}</div>
                                                <div class="content_input">
                                                    <span>$</span>
                                                    <input type="text" placeholder="" @keyup="input_change_tip" v-model="tip_custom_input" />
                                                </div>
                                            </div>
                                        </div>
                                        <span class="tip_line"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="checkout_item">
                                <!-- <h4>{{ $t('checkout.payMethod') }}</h4> -->
                                <div class="checkout_method">
                                    <div class="method_main">
                                        <!-- <div class="method_select">
                                            <select>
                                                <option>{{ $t('checkout.choosePaymentMethod') }}</option>
                                                <option>{{ $t('checkout.debitCard') }}</option>
                                                <option>{{ $t('checkout.paypal') }}</option>
                                                <option>{{ $t('checkout.payAtPickup') }}</option>
                                            </select>
                                        </div> -->
                                        <div class="method_select" ref="methodSelect" @click="toggleSelect">
                                            <span class="select_arrow"></span>
                                            <div v-if="pay_method == 'creditCard'" class="method_select_item">
                                                <span class="pay_credit"></span>
                                                {{ $t('checkout.debitCard') }}
                                            </div>
                                            <div v-else-if="pay_method == 'paypal'" class="method_select_item">
                                                <span class="pay_paypal"></span>
                                                PayPal
                                            </div>
                                            <div v-else-if="pay_method == 'pickup'" class="method_select_item">
                                                <span class="pay_pickup"></span>
                                                {{ $t('checkout.payAtPickup') }}
                                            </div>
                                            <div v-else class="method_select_item pay_default">
                                                {{ $t('checkout.choosePaymentMethod') }}
                                            </div>
                                        </div>
                                        <div class="method_option" ref="methodOption" style="display: none;">
                                            <div
                                                v-if="payment_setting.some(item=>item.payment==='Debit/Credit Card')"
                                                class="method_option_item"
                                                @click="choosePaymentType('creditCard')"
                                            >
                                                <span class="pay_credit"></span>
                                                {{ $t('checkout.debitCard') }}
                                            </div>
                                            <div
                                                v-if="payment_setting.some(item=>item.payment==='paypal')"
                                                class="method_option_item"
                                                @click="choosePaymentType('paypal')"
                                            >
                                                <span class="pay_paypal"></span>
                                                PayPal
                                            </div>
                                            <div
                                                v-if="payment_setting.some(item=>item.payment==='pickup')"
                                                class="method_option_item"
                                                @click="choosePaymentType('pickup')"
                                            >
                                                <span class="pay_pickup"></span>
                                                {{ $t('checkout.payAtPickup') }}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="method_inner" data-bee="module_id=input&item_id=payment">
                                        <div
                                            class="method_item"
                                            v-if="payment_setting.some(item=>item.payment==='pickup')"
                                        >
                                            <div class="radio_box">
                                                <div class="radio_item">
                                                    <div class="m_radio">
                                                        <span class="big_circle">
                                                            <b></b>
                                                            <b></b>
                                                        </span>
                                                        <input
                                                            id="payment_method01"
                                                            type="radio"
                                                            name="payment_method"
                                                            v-model="pay_method"
                                                            value="pickup"
                                                            readonly
                                                        />
                                                        <span class="m_icon m_radio_icon"></span>
                                                    </div>
                                                    <label class="m_label" for="payment_method01">{{
                                                        $t('checkout.payAtPickup')
                                                    }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="method_item"
                                            v-if="payment_setting.some(item=>item.payment==='paypal')"
                                        >
                                            <div class="radio_box">
                                                <div class="radio_item">
                                                    <div class="m_radio">
                                                        <span class="big_circle">
                                                            <b></b>
                                                            <b></b>
                                                        </span>
                                                        <input
                                                            id="payment_method02"
                                                            type="radio"
                                                            name="payment_method"
                                                            v-model="pay_method"
                                                            value="paypal"
                                                            readonly
                                                        />
                                                        <span class="m_icon m_radio_icon"></span>
                                                    </div>
                                                    <label class="m_label" for="payment_method02">paypal</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="method_item"
                                            v-if="payment_setting.some(item=>item.payment==='Debit/Credit Card')"
                                        >
                                            <div class="radio_box">
                                                <div class="radio_item">
                                                    <div class="m_radio">
                                                        <span class="big_circle">
                                                            <b></b>
                                                            <b></b>
                                                        </span>
                                                        <input
                                                            id="payment_method03"
                                                            type="radio"
                                                            name="payment_method"
                                                            v-model="pay_method"
                                                            value="creditCard"
                                                            readonly
                                                        />
                                                        <span class="m_icon m_radio_icon"></span>
                                                    </div>
                                                    <label class="m_label" for="payment_method03">credit card</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="pay_info" style="margin-top: 20px">
                                        <div
                                            v-show="is_wechat === 0 && pay_method === 'paypal'"
                                            id="paypal-button-container"
                                        ></div>
                                        <div
                                            v-if="is_wechat === '1' && pay_method === 'paypal'"
                                            style="font-size: 13px; color: red"
                                        >
                                            Paypal is not support in Wechat. Please reopen this page in browser!
                                        </div>
                                        <Stripe v-show="creditCard_strip_show" @setStripe="setStripe" />
                                        <div v-if="stripe_card_errors">
                                            <mdc-text typo="body2" tag="span" adjust-margin>
                                                {{ stripe_card_errors }}
                                            </mdc-text>
                                        </div>
                                        <CreditCardYuansfer :total="total" v-if="creditCard_yuansfer_show" ref="yuansfer_creditCard" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="checkout_item">
            <div class="checkout_btn">
              <mt-button type="danger">{{ $t('checkout.checkout') }}</mt-button>
            </div>
          </div> -->
                </div>
            </div>
            <CheckoutOrder
                :totalCheckout="placeorderToolTip"
                :tip="tipTotal"
                :placeOrder="do_checkout"
                :processingFee="processingFee"
                :showPayBtn="pay_method !== 'paypal'"
                ref="orderZone"
                :zipCode="zipCodeOrder"
            >
            </CheckoutOrder>
        </div>
        <div class="phone_bottom"></div>
        <PageFooter></PageFooter>
        <TableLayer ref="table" />
        <AddressForm ref="addressForm" />
        <AddressFormSpecial
            ref="addressFormSpecial"
            :specialStoreArr="special_store_arr"
        />
        <SubmitLayer
            ref="submitLayer"
            :postData="submitData"
            :submitOrder="submitOrder"
        />
        <FailedLayer ref="failedLayer" :orderId = "failedOrderId" :storeId="sid" />
        <ASAPLayer ref="asapLayer" :orderType="type" :deliveryIndex="zip_code_index" />
        <FullScreenLoading :is_loading="is_loading" />
    </div>
</template>

<style lang="scss" scoped>
    .tip_item_inner {
        cursor: pointer;
    }
    .checkout_section .order_item .order_item_number,
    .checkout_section .fee_item .order_desc,
    .checkout_section .fee_item .order_input,
    .checkout_section .custom_order .check_out {
        display: none;
    }
    // .check_page {
    //     padding-bottom: 2.5rem;
    // }
    .order_zone {
        position: fixed;
    }
    .check_page {
        ::v-deep .order_title {
            padding-left: 0;
        }
    }
    .check_header {
        width: 100%;
        height: 50px;
        // line-height: 9.0625rem;
        padding-top: 0;
        box-sizing: border-box;
        background-image: url('../assets/images/header_bg2.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        position: relative;
        h3 {
            font-size: 1rem;
            text-transform: uppercase;
            text-align: center;
            color: #3b3b3b;
            position: relative;
            z-index: 2;
        }
        .go_menu {
            display: block;
            width: 1.0625rem;
            height: 1.0625rem;
            background-repeat: no-repeat;
            background-image: url('../assets/images/go_back_icon.png');
            background-size: 100% auto;
            cursor: pointer;
            position: absolute;
            top: 1.25rem;
            left: 1.25rem;
            z-index: 3;
        }
    }

    .check_pot {
        position: relative;
        margin: 45px 0;
        .check_pot_name{
            padding-left: 50px;
            font-size: 30px;
            height: 38px;
            line-height: 38px;
            color: var(--theme_color);
        }
        .go_menu {
            width: 38px;
            height: 38px;
            border-radius: 19px;
            background: url('../assets/images/arrow_left.png') no-repeat;
            background-size: auto 50%;
            background-position: 40% center;
            background-color: var(--theme_color);
            cursor: pointer;
            position: absolute;
            top: 0;
            left: -23px;
            z-index: 2;
        }
    }

    .checkout_section {
        position: relative;
        width: 100%;
        background-color: #fff;
    }
    .checkout_container {
        width: 100%;
        padding-right: 336px;
        box-sizing: border-box;
        color: #000;
    }
    .checkout_inner {
        // width: 724px;
        width: 820px;
        margin: 0 auto;
    }
    .checkout_inner h4 {
        color: #000;
        font-size: 20px;
        line-height: 46px;
    }
    .flex_box {
        display: flex;
        justify-content: space-between;
    }
    .check_box {
        position: relative;
        padding-left: 50px;
    }
    .check_line {
        display: block;
        width: 2px;
        height: 100%;
        // background: url('../assets/images/dash_line_long.svg') repeat-y;
        position: absolute;
        top: 0;
        left: 0;
        mask: url('../assets/images/dash_line_long.svg') repeat-y;
        // background-color: var(--theme_color);
        background-color: #eaeaea;
        background-repeat: repeat-y;
    }
    .check_circle {
        display: block;
        width: 26px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        border-radius: 50%;
        background-color: #eaeaea;
        color: #eaeaea;
        position: absolute;
        top: 0;
        left: -13px;
    }
    .check_done .check_line {
        background-color: var(--theme_color);
    }
    .check_done .check_circle {
        background-color: var(--theme_color);
        width: 42px;
        height: 42px;
        line-height: 42px;
        left: -21px;
        top: -6px;
        color: #fff;
    }
    .check_box_title {
        color: #000;
    }
    .check_box_inner {
        padding-left: 20px;
    }
    .checkout_item {
        padding: 12px 0;
    }
    .checkout_item input[type='text'],
    .checkout_item input[type='number'],
    .checkout_item select {
        width: 100%;
        height: 40px;
        padding: 0 5px;
        box-sizing: border-box;
        // border: 1px solid #d4d4d4;
        border: none;
        background-color: #f7f7f7;
        border-radius: 4px;
        font-size: 16px;
        line-height: 40px;
        // color: #b2b2b2;
        color: #000;
    }
    .checkout_item .select_inner {
        padding-right: 16px;
    }
    .checkout_item select {
        display: block;
        min-width: 80px;
        padding: 0 10px;
        margin-right: 10px;
        background: url('../assets/images/arrow_bottom_grey.svg') no-repeat #f7f7f7 85% center;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
    }
    .checkout_item .tableName, .checkout_item .tableEdit {
        font-size: 18px;
        margin-top: 0;
    }
    .checkout_item .checkout_type {
        position: relative;
        height: 40px;
        margin-top: 20px;
    }
    .checkout_type .pick-type {
        top: 0;
    }
    .checkout_name {
        width: 278px;
    }
    .checkout_phone {
        width: 379px;
        display: flex;
        justify-content: space-between;
    }
    .phone_inner {
        width: 296px;
    }

    .checkout_note {
        margin-top: 10px;
        margin-bottom: 30px;
    }
    .checkout_note textarea {
        display: block;
        width: 100%;
        height: 112px;
        padding: 10px 5px;
        // border: 1px solid #b1b1b1;
        border: none;
        background-color: #f7f7f7;
        box-sizing: border-box;
        border-radius: 4px;
        font-size: 15px;
    }
    .checkout_tip {
        width: 100%;
        // padding: 10px 0 5px;
        background-color: #f7f7f7;
        border-radius: 4px;
        overflow: hidden;
    }
    .tip_inner {
        display: flex;
    }
    .tip_item {
        // padding: 0 5px;
        // color: #707070;
        text-align: center;
        // line-height: 32px;
        position: relative;
        flex: 1;
    }
    .tip_item_inner {
        // border: 1px solid #fafafa;
        border-radius: 4px;
        padding: 10px 2px;
    }
    .tip_item_content {
        height: 50px;
    }
    .tip_item_inner.current {
        // border-color: var(--theme_color);
        // color: #fff;
        background-color: var(--theme_color);
        position: absolute;
        width: 100%;
        left: -2px;
        .content_title, .content_text {
            color: #fff;
        }
    }
    .tip_item .content_title {
        font-size: 18px;
        font-weight: bold;
        white-space: nowrap;
        padding-bottom: 6px;
        color: var(--theme_color);
        // height: 52px;
        // line-height: 52px;
    }
    .tip_item .content_text {
        font-size: 16px;
        color: #000;
    }
    .tip_line {
        display: block;
        position: absolute;
        width: 1px;
        height: 50px;
        background-color: var(--theme_color);
        top: 10px;
        right: 0;
    }
    .tip_item:last-child .tip_line {
        display: none;
    }
    .tip_item.tip_min_width {
        min-width: 125px;
        .tip_item_inner {
            padding: 10px;
            border: 2px solid #f7f7f7;
            border-radius: 4px;
            background-color: #f7f7f7;
        }
        .tip_item_inner.current {
            position: relative;
            width: auto;
            left: 0;
            border-color: var(--theme_color);
        }
    }
    .tip_item_inner .content_input {
        display: flex;
    }
    .tip_custom {
        margin-top: 10px;
        // background-color: #f7f7f7;
        .tip_item_content {
            display: flex;
            align-items: center;
            height: auto;
            // justify-content: center;
            .content_title {
                padding-bottom: 0;
            }
        }
        .tip_item_inner.current {
            background-color: #f7f7f7;
            .content_title, .content_text {
                color: var(--theme_color);
            }
        }
    }
    .content_input span {
        margin: 0 2px 0 10px;
        font-size: 16px;
        line-height: 24px;
    }
    .checkout_item .content_input input {
        background-color: transparent;
        padding: 0;
        height: 24px;
        line-height: 24px;
        font-size: 16px;
    }
    // .tip_item_inner.current input {
    //     background-color: #eaeaea;
    //     border-color: #b1b1b1;
    // }

    .checkout_choose_time {
        position: absolute;
        right: 0;
        bottom: 8px;
        .choose_time_select {
            position: relative;
            box-sizing: border-box;
            width: 350px;
            height: 50px;
            line-height: 48px;
            font-size: 18px;
            border: 1px solid var(--theme_color);
            border-radius: 7px;
            background-color: #fff;
            padding: 0 40px 0 15px;
            &.isOpen span.select_arrow {
                mask-image: url('../assets/images/arrow_top.svg');
            }
        }
        span.select_arrow {
            display: block;
            width: 30px;
            height: 30px;
            position: absolute;
            top: 9px;
            right: 10px;
            mask: url('../assets/images/arrow_bottom.svg') no-repeat;
            background-size: contain;
            background-color: var(--theme_color);
            background-repeat: no-repeat;
            mask-size: 100% auto;
            mask-position: right center;
            cursor: pointer;
        }
        .choose_time_option {
            position: absolute;
            width: 100%;
            height: 200px;
            font-size: 18px;
            background-color: #fff;
            box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
            display: flex;
            overflow: hidden;
        }
        .choose_time_date, .choose_time_hour {
            height: 200px;
            overflow-y: scroll;
            .time_option_item {
                line-height: 40px;
            }
        }
        .choose_time_date {
            width: 45%;
            .time_option_item {
                color: #626262;
                background-color: #f7f7f7;
                padding-left: 10px;
                &.curr {
                    background-color: #fff;
                    color: #000;
                    font-weight: 600;
                }
            }
        }
        .choose_time_hour {
            width: 55%;
            .time_option_item {
                padding-left: 20px;
                &.curr {
                    color: var(--theme_color);
                    font-weight: 600;
                }
            }
        }
        
    }
    .hide_time_option {
        // background: black;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
    }

    .method_main {
        position: relative;
        z-index: 101;
    }
    .method_select {
        width: 600px;
        height: 48px;
        line-height: 46px;
        border: 1px solid var(--theme_color);
        border-radius: 7px;
        color: #000;
        padding: 0 15px;
        font-size: 18px;
        box-sizing: border-box;
        background-color: #fff;
        position: relative;
    }
    .method_select span.select_arrow {
        display: block;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 9px;
        right: 10px;
        mask: url('../assets/images/arrow_bottom.svg') no-repeat;
        background-size: contain;
        background-color: var(--theme_color);
        background-repeat: no-repeat;
        mask-size: 100% auto;
        mask-position: right center;
        cursor: pointer;
    }

    .method_select.isOpen span.select_arrow {
        mask-image: url('../assets/images/arrow_top.svg');
    }
    .method_select_item {
        padding-left: 40px;
    }
    .method_select_item span {
        position: absolute;
        width: 24px;
        height: 46px;
        left: 15px;
        top: 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: left center;
    }
    .method_select_item.pay_default {
        padding-left: 0;
    }
    .method_option {
        position: absolute;
        width: 600px;
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
        z-index: 5;
        padding: 10px 0;
    }
    .method_option_item {
        padding: 10px 15px 10px 55px;
        font-size: 18px;
        line-height: 24px;
        position: relative;
    }
    .method_option_item span {
        position: absolute;
        width: 24px;
        height: 44px;
        left: 15px;
        top: 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: left center;
    }
    .method_main .pay_credit {
        background-image: url('../assets/images/credit_card.png');
    }
    .method_main .pay_pickup {
        // background-image: url('../assets/images/payatpickup.png');
        mask: url('../assets/images/pickup_icon.svg') no-repeat;
        background-size: contain;
        background-color: var(--theme_color);
        background-repeat: no-repeat;
        mask-size: 100% auto;
        mask-position: right center;
    }
    .method_main .pay_paypal {
        background-image: url('../assets/images/paypal.png');
    }
    .method_inner {
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
    }
    .method_item {
        width: 227px;
        height: 53px;
        background-color: #fafafa;
        font-size: 15px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        box-sizing: border-box;
        margin-top: 10px;
        margin-right: 10px;
    }
    .method_inner .method_item:last-child {
        margin-right: 0;
    }
    .checkout_btn button {
        width: 100%;
    }

    .option_inner,
    .time_inner {
        display: flex;
        align-items: flex-start;
        font-size: 15px;
        color: #707070;
    }
    .option_inner .radio_box,
    .time_inner .radio_box {
        width: 238px;
    }
    .radio_box {
        display: flex;
        display: -webkit-flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
    }
    .radio_item {
        display: flex;
        height: 44px;
        line-height: 44px;
        padding-left: 44px;
        position: relative;
        white-space: nowrap;
    }
    .m_radio {
        width: 44px;
        height: 44px;
        padding: 0;
        border-radius: 50%;
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
    }
    .m_radio .big_circle {
        width: 44px;
        height: 44px;
        position: absolute;
        border-radius: 50%;
        top: 0;
        left: 0;
        cursor: pointer;
    }
    .big_circle b {
        width: 20%;
        height: 20%;
        border-radius: 50%;
        display: block;
        background-color: rgba(255, 255, 255, 0.6);
        position: absolute;
        left: 40%;
        top: 40%;
        z-index: 1;
    }
    .m_radio input {
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        z-index: 3;
    }
    .m_icon {
        display: block;
        width: 22px;
        height: 22px;
        position: absolute;
        box-sizing: content-box;
        top: 11px;
        left: 0;
    }
    .m_radio_icon {
        border: 1px solid #707070;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background-color: #fff;
    }
    .m_radio .m_radio_icon {
        top: 11px;
        left: 11px;
        z-index: 2;
    }
    .m_icon::before,
    .m_icon::after {
        content: '';
        display: block;
        position: absolute;
    }
    .m_radio_icon::after {
        width: 14px;
        height: 14px;
        background-color: none;
        top: 4px;
        left: 4px;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        z-index: 2;
    }
    .m_radio input:checked + span.m_icon::after {
        background-color: var(--theme_color);
    }
    .checkout_item .add_address {
        width: 88px;
        cursor: pointer;
        text-align: center;
        font-size: 18px;
        border: 1px solid var(--theme_color);
        height: 30px;
        line-height: 30px;
        border-radius: 5px;
        color: var(--theme_color);
    }
    .dine_table {
        cursor: pointer;
        padding: 0 10px;
        height: 25px;
        line-height: 25px;
        color: #fff;
        background-color: var(--theme_color);
        border-radius: 13px;
        margin-left: 16px;
    }
    .time_other {
        display: flex;
    }
    .time_other {
        padding-left: 248px;
    }
    .time_select {
        margin-right: 50px;
    }
    .checkout_item .time_select select {
        min-width: 120px;
    }
    .delivery {
        width: 246px;
        // background-color: #f4f4f4;
        // border-radius: 7px;
        // border: 1px solid #e2e2e2;
        // padding: 16px 20px;
        box-sizing: border-box;
    }
    .delivery p {
        font-size: 18px;
        line-height: 24px;
    }
    .delivery button.mint-button {
        height: 32px;
        margin-top: 10px;
        min-width: 90px;
    }
    .delivery .mint-button--danger.is-plain {
        font-size: 18px;
        color: var(--theme_color) !important;
    }

    .address_main {
        padding: 0 20px;
    }
    .phone_bottom {
        // display: none;
        height: 45px;
    }
    .checkout_section .footer {
        padding: 0;
    }
    .mobile_your_item {
        display: none;
    }

    @media screen and (max-width: 1140px) {
        // .check_header {
        //     height: 7.0625rem;
        //     line-height: 7.0625rem;
        // }
        .checkout_container {
            padding-right: 0;
        }
        .order_zone {
            display: block;
            position: relative;
            width: 724px;
            margin: 0 auto;
        }
    }
    // @media screen and (max-width: 960px) {
    //     .check_header {
    //         height: 6.0625rem;
    //         line-height: 6.0625rem;
    //     }
    // }
    // @media screen and (max-width: 840px) {
    //     .check_header {
    //         height: 6.0625rem;
    //         line-height: 6.0625rem;
    //     }
    // }
    @media screen and (max-width: 724px) {
        // .check_header {
        //     height: 5.0625rem;
        //     line-height: 5.0625rem;
        //     h3 {
        //         font-size: 1.125rem;
        //     }
        // }
        .check_header, .check_line, .check_circle {
            display: none;
        }
        .check_pot {
            margin: 1rem 0;
            .go_menu {
                left: 0;
                width: 30px;
                height: 30px;
            }
            .check_pot_name {
                font-size: 24px;
                height: auto;
                line-height: 30px;
                padding-left: 40px;
            }
        }
        .check_box, .check_box_inner {
            padding-left: 0;
        }
        .check_box_title {
            font-size: 1.25rem;
        }
        .checkout_container {
            padding: 0 0.75rem;
        }
        .checkout_inner {
            width: 100%;
        }
        .checkout_inner h4 {
            font-size: 1rem;
        }
        .flex_box,
        .option_inner,
        .time_inner {
            flex-wrap: wrap;
        }
        // .option_other,
        .time_other {
            padding-left: 40px;
        }
        .method_item {
            width: 48%;
        }
        .checkout_tip {
            overflow-x: scroll;
        }
        .tip_inner {
            // min-width: 676px;
            overflow: hidden;
        }
        .order_zone {
            width: 100%;
        }
        // .phone_bottom {
        //     display: block;
        // }
        .flex_box.isPhone {
           display: block; 
        }
        .flex_box.isPhone h4 {
            font-size: 0.875rem;
        }
        // .flex_box.isPhone .checkout_item {
        //     display: flex;
        //     justify-content: space-between;
        // }
        .flex_box.isPhone .checkout_name, .flex_box.isPhone .checkout_phone, .checkout_phone {
            width: 100%;
        }
        .checkout_phone .phone_inner {
            padding-left: 10px;
        }
        .flex_box.isPhone .checkout_item {
            padding-bottom: 0;
        }
        .flex_box.isPhone .checkout_select {
            width: 72px;
            padding-right: 16px;
            box-sizing: border-box;
        }
        .flex_box.isPhone .phone_inner {
            width: auto;
        }
        .check_header .go_menu {
            width: 1.75rem;
            height: 1.75rem;
            top: 0.75rem;
            left: 0.75rem;
        }
        .tip_item .content_title {
            font-size: 16px;
        }
        .tip_item .content_text, .content_input span, .checkout_item .content_input input {
            font-size: 14px;
        }
        .method_select, .method_option {
            width: 100%;
        }
        .checkout_choose_time {
            position: relative;
            margin-top: 2rem;
            .choose_time_select {
                width: 100%;
            }
        }
    }
    @media screen and (max-width: 641px) {
        // .flex_box.isPhone .checkout_select {
        //     width: 20%;
        // }
        // .flex_box.isPhone .phone_inner {
        //     width: 80%;
        // }
        .mobile_your_item {
            display: block;
            margin-bottom: 1rem;
            .your_items {
                padding: 0 10px 0;
            }
        }
    }
    @media screen and (max-width: 450px) {
        .method_inner .method_item {
            padding-left: 0;
            margin-right: 1%;
        }
        // .method_inner .method_item:last-child {
        //     width: 100%;
        // }
        .time_select {
            margin-right: 20px;
        }
        .checkout_item .select_inner {
            padding-right: 0;
        }
        .flex_box.isPhone .checkout_select {
            width: 36%;
        }
        .flex_box.isPhone .phone_inner {
            width: 74%;
        }
        // .checkout_phone {
        //     width: 100%;
        // }
        // .checkout_select {
        //     width: 62px;
        // }
    }
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters, createNamespacedHelpers } from 'vuex';
    import { Cell, Button, Toast, MessageBox } from 'mint-ui';
    import FullScreenLoading from '../components/FullScreenLoading';
    const App = createNamespacedHelpers('app');
    const Coupon = createNamespacedHelpers('Coupon');
    import Stripe from '../components/Stripe';
    import CreditCardYuansfer from '../components/CreditCardYuansfer';

    import PageFooter from '@/components/PageFooter.vue';
    import AddressForm from '@/components/AddressForm';
    import CheckoutOrder from '@/components/CheckoutOrder.vue';
    import TableLayer from '@/components/TableLayer.vue';
    import SubmitLayer from '@/components/SubmitLayer.vue';
    import ASAPLayer from '@/components/ASAPLayer.vue';
    import YourItem from '@/components/YourItem.vue';
    import AddressFormSpecial from '@/components/AddressFormSpecial';
    import FailedLayer from '@/components/FailedLayer.vue';

    export default {
        name: 'checkout',
        components: {
            CreditCardYuansfer,
            FullScreenLoading,
            PageFooter,
            CheckoutOrder,
            TableLayer,
            SubmitLayer,
            YourItem,
            AddressForm,
            ASAPLayer,
            Stripe,
            AddressFormSpecial,
            FailedLayer
        },
        data() {
            return {
                // addressVisible: false,
                tipChoosed: undefined,
                // tips: [0, 0.05, 0.08, 0.1, 0.15, 0.2],
                pay_method: '',
                if_show_checkout: false,
                submit_text: 'Place order',
                detail_data: {},
                target_food_count: 0,
                target_food_mix_fcount: 0,
                content_height: document.body.clientHeight - 100,
                is_wechat: 0,
                inputting: false,
                pickup_time: '',
                stripe: null,
                elements: null,
                card: null,
                stripe_card_errors: '',
                note: '',
                custom_date_arr: [],
                custom_time_arr: [],
                if_show_address: false,
                tip_method: '',
                tip_custom_input: '',
                tip_fixed_radio: '0.1',
                expend: -1,
                day_to_text: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
                over_414: window.screen.width > 768,
                hasCoupon: false,
                coupon: {},
                custom_pickup_date: '',
                custom_pickup_time: '',
                submit_btn_el: null,
                phone_num_length: 13,
                order_zone: this.$refs.orderZone,
                showSelectOption: false,
                submitData: {},
                custom_select_date: '',
                custom_select_time: '',
                showTimeSelect: false,
                special_store_arr: [],
                first_custom_pickup_flag: true,
                failedOrderId: '',
            };
        },
        watch: {
            type() {
                if (this.type === 'dineIn') {
                    this.pickup_time = 'ASAP';
                    // this.$refs.table.show();
                } else if (this.type === 'pickup') {
                    if (this.pickup_time === 'ASAP' && !this.canPickup) {
                        this.$refs.asapLayer.show();
                    }
                } else if (this.type === 'delivery') {
                    // console.log('canDeliveryList', this.canDeliveryList);
                    // console.log('delivery_asap_list', this.delivery_asap_list);
                    // console.log('zip_code_index', this.zip_code_index);
                    if (!this.has_saved_address) {
                        this.showAddressForm();
                    }
                    if (this.zip_code_index >= 0 && this.pickup_time === 'ASAP' && !this.canDeliveryList[this.zip_code_index]) {
                        this.$refs.asapLayer.show();
                    }
                }
            },
            pickup_time() {
                if (this.pickup_time === 'ASAP') {
                    if (this.type === 'pickup' && !this.canPickup) {
                        this.$refs.asapLayer.show();
                    } else if (this.type === 'delivery' && (this.zip_code_index >= 0) && !this.canDeliveryList[this.zip_code_index]) {
                        this.$refs.asapLayer.show();
                    }

                }
            },
            zip_code_index() {
                // console.log('zip_code_index2', this.zip_code_index);
                // console.log('this.canDeliveryList[this.zip_code_index]', !this.canDeliveryList[this.zip_code_index]);
                // console.log('type', this.type);
                // console.log('pickup_time', this.pickup_time);
                if (this.zip_code_index >= 0 && this.type === 'delivery' && this.pickup_time === 'ASAP' && !this.canDeliveryList[this.zip_code_index]) {
                    this.$refs.asapLayer.show();
                }
            },

            // TODO don't copy code
            cart_food_list(newList, oldList) {
                // 订单比较长时，按钮固定到订单下面
                // if (window.innerWidth > 641) {
                //     let orderOffsetHeight = this.$refs.orderZone.$el.offsetHeight;
                //     let orderScrollHeight = this.$refs.orderZone.$el.scrollHeight;
                //     console.log('orderOffsetHeight', orderOffsetHeight, orderScrollHeight, orderClassName);
                //     if (orderScrollHeight > orderOffsetHeight) {
                //         this.$refs.orderZone.$el.className = "order_zone fixed_button";
                //     }
                // }

                let removedItem = [];
                let msg = '';
                // no items removed, do nothing
                if (newList.length === oldList.length || newList.length > oldList.length) {
                    return;
                }
                if (newList.length === 0) {
                    // msg = this.language === 'eng' ? 'All items are sold out' : '所有菜品都卖光了';
                    msg = this.$t('checkout.allOutOfStock');
                    return;
                }
                for (const item of oldList) {
                    if (!newList.some((i) => i.fid === item.fid)) {
                        removedItem.push(item);
                    }
                }
                // maybe useless since we did check above
                if (removedItem.length < 1) {
                    return;
                }
                for (const item of removedItem) {
                    msg += this.language === 'eng' ? item.fname : item.fname_chn;
                    msg += ', ';
                }
                msg +=
                    removedItem.length > 1
                        ? this.language === 'eng'
                            ? 'were'
                            : '被'
                        : this.language === 'eng'
                        ? 'was'
                        : '被';
                msg += this.language === 'eng' ? ' removed' : '移除了';
                this.set_msg({
                    msg
                });
            },
            new_custom_date_arr() {
                // this.init_custom_key
                // console.log('new_custom_date_arr', this.new_custom_date_arr);
                this.custom_pickup_date = this.new_custom_date_arr[0] ? this.new_custom_date_arr[0].val : '';
                this.custom_select_date = this.new_custom_date_arr[0] ? (this.language == 'chn' ? this.new_custom_date_arr[0].chnTxt : this.new_custom_date_arr[0].newTxt) : '';
            },
            new_custom_time_arr() {
                this.custom_pickup_time = this.new_custom_time_arr[0] ? this.new_custom_time_arr[0].val : '';
                if (this.new_choose_time.indexOf('ASAP') > -1) {
                    this.custom_select_time = 'ASAP';
                    this.custom_pickup_time = '';
                    this.pickup_time = 'ASAP';
                } else {
                    this.pickup_time = 'custom';
                    this.custom_select_time = this.new_custom_time_arr[0] ? this.new_custom_time_arr[0].txt : '';
                }
            }
        },
        created() {},
        mounted() {
            this.init_paymethod();
            setTimeout(this.loadPaypal, 2000);

            if (this.type === 'dineIn') {
                this.pickup_time = 'ASAP';
            } else if (this.type != '') {
                this.init_custom_key();
            }

            // this.css_paymethod();
            // setTimeout(this.css_paymethod, 1000);
        },
        beforeMount() {
            window.addEventListener('beforeunload', this.preventReload);
            this.$once('hook:beforeDestroy', () => {
                window.removeEventListener('beforeunload', this.preventReload);
            });
        },
        beforeRouteLeave(to, from, next) {
            if (this.is_loading) {
                this.showLeaveMessage()
                    .then(() => {
                        next();
                    })
                    .catch(() => {
                        next(false);
                    });
            } else {
                next();
            }
        },
        computed: {
            ...mapState(['is_development']),
            ...App.mapState([
                'dineInTable',
                'sid',
                'sname',
                'type',
                'state',
                'city',
                'street_address1',
                'street_address2',
                'zip_code',
                'zip_code_index',
                'cart_data',
                'shared_tstatus',
                'payment_setting'
            ]),
            ...App.mapGetters({
                canDineIn: 'get_canDineIn',
                dineInTableEnable: 'get_dineInTableEnable',
                language: 'get_language',
                pay_method_setting: 'get_paymethod_setting',
                is_vip: 'get_if_is_vip',
                food_count: 'get_food_count',
                cart_food_list: 'get_cart_food_list',
                cart_price: 'get_cart_price',
                cart_price_vip: 'get_cart_price_vip',
                cart_price_pickup: 'get_cart_price_pickup',
                cart_food_dict: 'get_cart_food_dict',
                shared_oid: 'get_shared_oid',
                shared_tid: 'get_shared_tid',
                msg: 'get_app_msg',
                can_place_order: 'get_if_can_place_order',
                tax: 'get_tax',
                tax_with_discount: 'get_tax_with_discount',
                tip_with_tax: 'get_tip_with_tax',
                delivery_data: 'get_delivery_data',
                promotion_data: 'get_promotion_data',
                is_new: 'get_is_new',
                tip_method_setting: 'get_tip_method_setting',
                is_vip_new: 'get_is_vip_new',
                merchant_custom_value: 'get_merchant_custom_value',
                choose_time: 'get_choose_time',
                choose_time_ahead_days: 'get_choose_time_ahead_days',
                choose_time_options: 'get_choose_time_options',
                is_loading: 'get_if_loading',
                pickup_discount_enable: 'get_pickup_discount_enable',
                pickup_setting: 'get_pickup_setting',
                delivery_setting: 'get_delivery_setting',
                dinein_setting: 'get_dinein_setting',
                shared_tname: 'get_shared_tname',
                theme_data: 'get_theme_data',
                canPickup: 'get_canPickup',
                canDeliveryList: 'get_canDeliveryList',
                delivery_asap_list: 'get_delivery_asap_list',
                zipCodeOrder: 'get_zipCodeOrder',
                uname: 'get_uname',
                firstName: 'get_first_name',
                lastName: 'get_last_name',
                special_store: 'get_special_store',
            }),
            choose_a_time() {
                let _time = this.custom_select_date && this.custom_select_time ? this.custom_select_date + ', ' + this.custom_select_time : '';
                return _time;
            },
            isInfoDone() {
                if (this.type === 'dineIn' && this.dineInTable && this.last_name && this.first_name && this.phone_num && this.pickup_time) {
                    return true;
                } else if (this.type === 'dineIn' && !this.dineInTableEnable && this.last_name && this.first_name && this.phone_num && this.pickup_time) {
                    return true;
                } else if (this.type === 'pickup' && this.last_name && this.first_name && this.phone_num && this.pickup_time) {
                    return true;
                } else if (this.type === 'delivery' && this.zip_code_index >= 0 && this.last_name && this.first_name && this.phone_num && this.pickup_time) {
                    return true;
                }
                return false;
            },
            isPayDone() {
                if (this.tip_method && this.tip_method == 'input' && this.tip_custom_input && this.tip_custom_input >= 0 && this.pay_method) {
                    return true;
                } else if (this.tip_method && this.tip_method != 'input' && this.pay_method) {
                    return true;
                }
                return false;
            },
            creditCard_strip_show() {
                return this.pay_method === 'creditCard' && this.payment_setting.some(item=>item.payment==='Debit/Credit Card' && item.platform==='stripe');
            },
            creditCard_yuansfer_show() {
                return this.pay_method === 'creditCard' && this.payment_setting.some(item=>item.payment==='Debit/Credit Card' && item.platform==='yuansfer');
            },
            feature_image() {
                return 'url(' + this.theme_data.feature_image + ')';
            },
            processingFee() {
                if (this.cart_price_pickup_fee > 0 || this.cart_price_vip_fee > 0 || this.cart_price_fee > 0) {
                    if (this.pickup_discount_enable && this.type === 'pickup') {
                        return this.cart_price_pickup_fee;
                    } else {
                        return this.is_vip === '1' ? this.cart_price_vip_fee : this.cart_price_fee;
                    }
                } else {
                    return 0;
                }
            },
            addressShow() {
                const arr = [];
                this.street_address1 && arr.push(this.street_address1);
                this.street_address2 && arr.push(this.street_address2);
                this.city && arr.push(this.city);
                this.state && arr.push(this.state);
                this.zip_code && arr.push(this.zip_code);
                return arr.join(', ');
            },

            code: {
                get() {
                    return this.getAppStore('code');
                },
                set(code) {
                    this.$store.commit('app/APP_SET_CODE', code);
                }
            },
            name: {
                get() {
                    return this.uname !== '未知' && this.uname !== 'undefined' ? this.uname : (this.first_name + ' ' + this.last_name);
                },
                set(name) {
                    this.setName({ name });
                }
            },
            first_name: {
                get() {
                    return this.firstName ? this.firstName : '';
                },
                set(first_name) {
                    this.setFirstName({ first_name });
                }
            },
            last_name: {
                get() {
                    return this.lastName ? this.lastName : '';
                },
                set(last_name) {
                    this.setLastName({ last_name });
                }
            },
            phone_num: {
                get() {
                    return this.getAppStore('phone_num') ? this.getAppStore('phone_num') : '';
                },
                set(phone_num) {
                    this.set_phone_num({
                        code: this.code,
                        phone_num: phone_num
                    });
                }
            },
            has_saved_address: {
                get() {
                    return this.zip_code_index > -1;
                }
            },
            orderType: {
                get() {
                    return this.getAppStore('type');
                },
                set(type) {
                    this.setOrderType({ type });
                }
            },

            real_phone_num() {
                return this.code + ' ' + this.phone_num;
            },

            cart_price_vip_total() {
                let _price = (
                    1 *
                    (this.cart_price_vip +
                        this.cart_new_discount +
                        this.cart_full_coupon_discount +
                        this.cart_price_vip_tax +
                        this.cart_price_vip_fee +
                        this.cart_price_vip_tip +
                        this.cart_price_delivery_fee)
                ).toFixed(2);
                return _price < 0 ? 0 : _price;
            },

            delivery_require_list() {
                let _arr = [];

                for (let i = 0; i < this.delivery_data.delivery_area_list.length; i++) {
                    if (
                        this.zip_code !== '' &&
                        this.delivery_data.delivery_area_list[i].zip_code_list.indexOf(this.zip_code) === -1
                    ) {
                        continue;
                    }

                    let temp_minimum_amount = this.delivery_data.delivery_area_list[i].minimum_amount * 1;
                    let left_money = 0;

                    if (this.pickup_discount_enable && this.type === 'pickup') {
                        left_money = (temp_minimum_amount - this.cart_price_pickup).toFixed(2);
                    } else {
                        if (this.is_vip) {
                            left_money = (temp_minimum_amount - this.cart_price_vip).toFixed(2);
                        } else {
                            left_money = (temp_minimum_amount - this.cart_price).toFixed(2);
                        }
                    }

                    _arr.push({
                        name: this.delivery_data.delivery_area_list[i].name,
                        left_money: left_money < 0 ? 0 : left_money
                    });
                }

                return _arr;
            },

            charge_detail() {
                let _tax = 0;
                let _processing_fee = 0;
                let _tip = 0;
                let _cart_price = 0;

                if (this.pickup_discount_enable && this.type === 'pickup') {
                    _tax = this.cart_price_pickup_tax;
                    _processing_fee = this.cart_price_pickup_fee;
                    _tip = this.cart_price_pickup_tip;
                    _cart_price = this.cart_price_pickup;
                } else {
                    _tax = this.is_vip ? this.cart_price_vip_tax : this.cart_price_tax;
                    _processing_fee = this.is_vip ? this.cart_price_vip_fee : this.cart_price_fee;
                    _tip = this.is_vip ? this.cart_price_vip_tip : this.cart_price_tip;
                    _cart_price = this.cart_price;
                }

                return {
                    tax: _tax,
                    processing_fee: _processing_fee,
                    delivery_fee: this.cart_price_delivery_fee,
                    tip: _tip,
                    price: _cart_price,
                    price_vip: this.cart_price_vip,
                    price_pickup: this.cart_price_pickup,
                    new_discount: this.cart_new_discount,
                    full_coupon_discount: this.cart_full_coupon_discount
                };
            },

            remark() {
                return this.cart_data.remark;
            },
            new_custom_date_arr() {
                const _now_date = new Date();
                let _date_arr = [];
                const DAY_MAPPER = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
                const DAY_MAPPER_CHN = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
                const MONTH_MAPPER = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                let temp_mapper = this.new_choose_date_enable;

                let old_date = _now_date.getDate();

                let new_choose_time_ahead_days = this.new_choose_time_ahead_days;
                if (this.type != 'dineIn' && this.new_choose_time == 'ASAP') {
                    new_choose_time_ahead_days = 0;
                }

                for (let i = this.new_choose_time_before_days; i < new_choose_time_ahead_days + 1; i++) {
                    let temp_date = new Date(_now_date.getTime() + i * 24 * 3600 * 1000);
                    let temp_day = DAY_MAPPER[temp_date.getDay()];
                    let temp_day_chn = DAY_MAPPER_CHN[temp_date.getDay()];
                    let temp_month = temp_date.getMonth() + 1;
                    let _date = temp_date.getDate();

                    if (temp_mapper[temp_day.toLowerCase()] == 1) {
                        let new_temp_month = temp_month < 10 ? '0' + temp_month : temp_month;
                        let new_date = _date < 10 ? '0' + _date : _date;

                        let date_txt = _date;
                        if (date_txt == 1 || date_txt == 21 || date_txt == 31) {
                            date_txt += 'st';
                        } else if (date_txt == 2 || date_txt == 22) {
                            date_txt += 'nd';
                        } else if (date_txt == 3 || date_txt == 23) {
                            date_txt += 'rd';
                        } else {
                            date_txt += 'th';
                        }

                        let new_temp_day = _date == old_date ? 'Today' : temp_day;
                        let new_temp_day_chn = _date == old_date ? '今日' : temp_day_chn;

                        _date_arr.push({
                            txt: temp_day + ',' + temp_month + '/' + _date,
                            newTxt: new_temp_day + ', ' + MONTH_MAPPER[temp_month - 1] + ' ' + date_txt,
                            chnTxt: new_temp_day_chn + ', ' + temp_month + '月' + _date + '日',
                            val: temp_date.getFullYear() + '-' + new_temp_month + '-' + new_date
                        });
                    }
                }
                return _date_arr;
            },
            new_custom_time_arr() {
                if (this.new_choose_time_options === '') {
                    return [];
                }

                let time_arr = [];
                let choose_time_arrs = this.new_choose_time_options.split('@@');
                choose_time_arrs.sort();

                // const today = new Date(new Date().getTime() + this.pickup_setting.minimum_waiting_time * 60 * 1000);
                const today = new Date(new Date().getTime() + this.new_choose_time_minimum_waiting_time * 60 * 1000);
                let thisMonth = today.getMonth() + 1;
                thisMonth = thisMonth >= 10 ? thisMonth : '0' + thisMonth;
                let now_minutes = today.getMinutes();
                let now_hours = today.getHours();
                let now_date = today.getDate();
                now_date = now_date < 10 ? '0' + now_date : now_date;
                for (let i = 0; i < choose_time_arrs.length; i++) {
                    let [_hour, _minute] = choose_time_arrs[i].split(':');
                    _hour = _hour * 1;
                    _minute = _minute * 1;
                    let temp_str = _hour < 12 ? 'am' : 'pm';

                    const today_date = `${today.getFullYear()}-${thisMonth}-${now_date}`;

                    if (today_date === this.custom_pickup_date) {
                        if (_hour < now_hours) {
                            continue;
                        }

                        if (_hour === now_hours && _minute < now_minutes) {
                            continue;
                        }
                    }

                    if (_hour === 0) {
                        _hour = 12;
                    } else if (_hour > 12) {
                        _hour = _hour - 12;
                    }

                    _minute = _minute < 10 ? '0' + _minute : _minute;

                    time_arr.push({
                        txt: _hour + ':' + _minute + ' ' + temp_str,
                        val: choose_time_arrs[i] + ':00'
                    });
                }
                return time_arr;
            },
            can_pickup() {
                if (this.pickup_setting.enbale === 0) {
                    return false;
                }

                if (this.pickup_setting.amount > 0) {
                    if (this.pickup_discount_enable && this.type === 'pickup') {
                        if (
                            this.cart_price_pickup * 1 +
                                this.cart_full_coupon_discount * 0 +
                                this.cart_new_discount * 0 <
                            this.pickup_setting.amount * 1
                        ) {
                            return false;
                        }
                    } else {
                        if (
                            this.is_vip &&
                            this.cart_price_vip * 1 + this.cart_full_coupon_discount * 0 + this.cart_new_discount * 0 <
                                this.pickup_setting.amount * 1
                        ) {
                            return false;
                        }

                        if (
                            !this.is_vip &&
                            this.cart_price * 1 + this.cart_full_coupon_discount * 0 + this.cart_new_discount * 0 <
                                this.pickup_setting.amount * 1
                        ) {
                            return false;
                        }
                    }
                }

                return true;
            },
            can_delivery() {
                if (this.delivery_setting.enable == 0 || this.zip_code === '' || this.zip_code_index === -1) {
                    return false;
                }

                let temp_cart_price = 0;

                if (this.pickup_discount_enable && this.type === 'pickup') {
                    temp_cart_price =
                        this.cart_price_pickup * 1 + this.cart_full_coupon_discount * 0 + this.cart_new_discount * 0;
                } else {
                    if (this.is_vip) {
                        temp_cart_price =
                            this.cart_price_vip * 1 + this.cart_full_coupon_discount * 0 + this.cart_new_discount * 0;
                    } else {
                        temp_cart_price =
                            this.cart_price * 1 + this.cart_full_coupon_discount * 0 + this.cart_new_discount * 0;
                    }
                }

                let temp_delivery_amount = +this.delivery_data.delivery_area_list[this.zip_code_index].minimum_amount;

                if (temp_cart_price < temp_delivery_amount) {
                    return false;
                }

                return true;
            },
            placeorderToolTip() {
                let text = '';
                if (this.pickup_discount_enable && this.type === 'pickup') {
                    text = this.cart_price_pickup_total;
                } else {
                    text = this.is_vip === '1' ? this.cart_price_vip_total : this.cart_price_total;
                }
                return '$' + text;
            },
            pickup_promotion() {
                if (this.cart_price_pickup < this.cart_price) {
                    return (this.cart_price - this.cart_price_pickup).toFixed(2);
                }

                return 0;
            },
            new_choose_time() {
                // if (/pickup|dineIn/.test(this.type)) {
                if (this.type == 'dineIn') {
                    return 'ASAP';
                } else if (this.type == 'pickup') {
                    return this.pickup_setting && this.pickup_setting.choose_time;
                } else if (this.type === 'delivery') {
                    if (this.zip_code_index > -1) {
                        let _area_data = this.delivery_data.delivery_area_list[this.zip_code_index];

                        return _area_data.choose_time;
                    }
                }

                return '';
            },
            new_choose_time_before_days() {
                if (this.type === 'pickup') {
                    return this.pickup_setting && this.pickup_setting.choose_time_before_days * 1;
                } else if (this.type === 'delivery') {
                    if (this.zip_code_index > -1) {
                        let _area_data = this.delivery_data.delivery_area_list[this.zip_code_index];

                        return _area_data.choose_time_before_days * 1;
                    }
                }

                return 0;
            },
            new_choose_time_minimum_waiting_time() {
                if (this.type === 'pickup') {
                    return this.pickup_setting && this.pickup_setting.minimum_waiting_time * 1;
                } else if (this.type === 'delivery') {
                    if (this.zip_code_index > -1) {
                        let _area_data = this.delivery_data.delivery_area_list[this.zip_code_index];

                        return _area_data.minimum_waiting_time;
                    }
                }

                return 0;
            },
            new_choose_time_ahead_days() {
                if (this.type === 'pickup') {
                    return this.pickup_setting && this.pickup_setting.choose_time_ahead_days * 1;
                } else if (this.type === 'delivery') {
                    if (this.zip_code_index > -1) {
                        let _area_data = this.delivery_data.delivery_area_list[this.zip_code_index];

                        return _area_data.choose_time_ahead_days;
                    }
                }

                return 0;
            },
            new_choose_time_options() {
                let _options = '';
                if (this.type === 'pickup') {
                    let choosetime_setting = this.pickup_setting.choosetime_setting || [];
                    let target_day = this.day_to_text[new Date(this.custom_pickup_date.replace(/\-/g, '/')).getDay()];

                    if (choosetime_setting.length === 0) {
                        return this.choose_time_options;
                    }

                    for (let i = 0; i < choosetime_setting.length; i++) {
                        if (choosetime_setting[i].date === target_day && choosetime_setting[i].enable === 1) {
                            _options = choosetime_setting[i].allow_times;
                            break;
                        }
                    }
                } else if (this.type === 'delivery') {
                    if (this.zip_code_index > -1) {
                        let choosetime_setting = [];
                        if (this.delivery_data.delivery_area_list[this.zip_code_index]) {
                            choosetime_setting = this.delivery_data.delivery_area_list[this.zip_code_index]
                                .choosetime_setting;
                        }
                        let target_day = this.day_to_text[
                            new Date(this.custom_pickup_date.replace(/\-/g, '/')).getDay()
                        ];

                        if (choosetime_setting.length === 0) {
                            return this.choose_time_options;
                        }

                        for (let i = 0; i < choosetime_setting.length; i++) {
                            if (choosetime_setting[i].date === target_day && choosetime_setting[i].enable === 1) {
                                _options = choosetime_setting[i].allow_times;
                                break;
                            }
                        }
                    }
                }

                return _options;
            },
            new_choose_date_enable() {
                let choose_day_mapper = {};
                if (this.type === 'pickup') {
                    let choosetime_setting = this.pickup_setting.choosetime_setting || [];
                    for (let i = 0; i < choosetime_setting.length; i++) {
                        choose_day_mapper[choosetime_setting[i].date] = choosetime_setting[i].enable;
                    }
                } else if (this.type === 'delivery') {
                    if (this.zip_code_index > -1) {
                        let choosetime_setting = [];
                        if (this.delivery_data.delivery_area_list[this.zip_code_index]) {
                            choosetime_setting = this.delivery_data.delivery_area_list[this.zip_code_index]
                                .choosetime_setting;
                        }

                        for (let i = 0; i < choosetime_setting.length; i++) {
                            choose_day_mapper[choosetime_setting[i].date] = choosetime_setting[i].enable;
                        }
                    }
                }

                return choose_day_mapper;
            },
            real_pickup_time() {
                if (this.pickup_time === 'ASAP') {
                    return 'ASAP';
                } else if (this.pickup_time === '30min' || this.pickup_time === '15min') {
                    let target_date = new Date(new Date().getTime() + parseInt(this.pickup_time) * 60 * 1000);
                    return (
                        target_date.getFullYear() +
                        '-' +
                        (target_date.getMonth() + 1) +
                        '-' +
                        target_date.getDate() +
                        ' ' +
                        target_date.getHours() +
                        ':' +
                        target_date.getMinutes() +
                        ':' +
                        target_date.getSeconds()
                    );
                } else if (this.pickup_time === 'custom') {
                    return this.custom_pickup_date + ' ' + this.custom_pickup_time;
                }

                return 'ASAP';
            },
            cart_price_tax() {
                let _tax = (this.cart_price * this.tax).toFixed(2) * 1;
                if (this.tax_with_discount == 1) {
                    _tax = ((this.cart_price + this.cart_new_discount + this.cart_full_coupon_discount) * this.tax).toFixed(2) * 1;
                }
                return _tax < 0 ? 0 : _tax;
            },
            cart_price_pickup_fee() {
                let _fee = 0;
                let _extra = 0;

                if (this.type === 'delivery') {
                    _fee = this.delivery_setting.processing_fee || 0;
                    _extra = this.delivery_setting.processing_extra || 0;

                    if (this.delivery_setting.processing_not_applied === '1' && this.pay_method === 'pickup') {
                        return 0;
                    }
                } else if (this.type === 'pickup') {
                    _fee = this.pickup_setting.processing_fee || 0;
                    _extra = this.pickup_setting.processing_extra || 0;

                    if (this.pickup_setting.processing_not_applied === '1' && this.pay_method === 'pickup') {
                        return 0;
                    }
                } else if (this.type === 'dineIn') {
                    _fee = this.dinein_setting.processing_fee || 0;
                    _extra = this.dinein_setting.processing_extra || 0;

                    if (this.dinein_setting.processing_not_applied === '1' && this.pay_method === 'pickup') {
                        return 0;
                    }
                }

                // return ((this.cart_price_pickup + this.cart_price_pickup_tax + this.cart_new_discount + this.cart_full_coupon_discount) * _fee + _extra * 1).toFixed(2) * 1;
                // (subtotal+discount)*n%  不需要加上 tax
                return ((this.cart_price_pickup + this.cart_new_discount + this.cart_full_coupon_discount) * _fee + _extra * 1).toFixed(2) * 1;
            },
            cart_price_vip_fee() {
                let _fee = 0;
                let _extra = 0;

                if (this.type === 'delivery') {
                    _fee = this.delivery_setting.processing_fee || 0;
                    _extra = this.delivery_setting.processing_extra || 0;

                    if (this.delivery_setting.processing_not_applied === '1' && this.pay_method === 'pickup') {
                        return 0;
                    }
                } else if (this.type === 'pickup') {
                    _fee = this.pickup_setting.processing_fee || 0;
                    _extra = this.pickup_setting.processing_extra || 0;

                    if (this.pickup_setting.processing_not_applied === '1' && this.pay_method === 'pickup') {
                        return 0;
                    }
                } else if (this.type === 'dineIn') {
                    _fee = this.dinein_setting.processing_fee || 0;
                    _extra = this.dinein_setting.processing_extra || 0;

                    if (this.dinein_setting.processing_not_applied === '1' && this.pay_method === 'pickup') {
                        return 0;
                    }
                }

                // return ((this.cart_price_vip + this.cart_price_vip_tax + this.cart_new_discount + this.cart_full_coupon_discount) * _fee + _extra * 1).toFixed(2) * 1;
                return ((this.cart_price_vip + this.cart_new_discount + this.cart_full_coupon_discount) * _fee + _extra * 1).toFixed(2) * 1;
            },
            cart_price_fee() {
                let _fee = 0;
                let _extra = 0;

                if (this.type === 'delivery') {
                    _fee = this.delivery_setting.processing_fee || 0;
                    _extra = this.delivery_setting.processing_extra || 0;

                    if (this.delivery_setting.processing_not_applied === '1' && this.pay_method === 'pickup') {
                        return 0;
                    }
                } else if (this.type === 'pickup') {
                    _fee = this.pickup_setting.processing_fee || 0;
                    _extra = this.pickup_setting.processing_extra || 0;

                    if (this.pickup_setting.processing_not_applied === '1' && this.pay_method === 'pickup') {
                        return 0;
                    }
                } else if (this.type === 'dineIn') {
                    _fee = this.dinein_setting.processing_fee || 0;
                    _extra = this.dinein_setting.processing_extra || 0;

                    if (this.dinein_setting.processing_not_applied === '1' && this.pay_method === 'pickup') {
                        return 0;
                    }
                }

                // return ((this.cart_price + this.cart_price_tax + this.cart_new_discount + this.cart_full_coupon_discount) * _fee + _extra * 1).toFixed(2) * 1;
                return ((this.cart_price + this.cart_new_discount + this.cart_full_coupon_discount) * _fee + _extra * 1).toFixed(2) * 1;
            },
            cart_price_vip_tip() {
                if (this.tip_method !== '') {
                    if (this.tip_method === 'cash') {
                        return 0;
                    }

                    if (this.tip_method === 'input') {
                        return (this.tip_custom_input && this.tip_custom_input * 1) || 0;
                    }

                    if (this.tip_method === 'fixed_radio') {
                        let _radio = this.tip_fixed_radio;
                        let _tip_price = (this.cart_price_vip * _radio).toFixed(2) * 1;
                        if (this.tip_with_tax == 1) {
                            _tip_price = ((this.cart_price_vip + this.cart_price_vip_tax) * _radio).toFixed(2) * 1;
                        }
                        // return ((this.cart_price_vip + this.cart_new_discount + this.cart_full_coupon_discount + this.cart_price_vip_tax) * _radio).toFixed(2) * 1;
                        // return (this.cart_price_vip * _radio).toFixed(2) * 1;
                        return _tip_price;
                    }

                    if (this.tip_method === 'merchant_fixed_percent') {
                        let _tip_price = (this.cart_price_vip * this.merchant_custom_value).toFixed(2) * 1
                        if (this.tip_with_tax == 1) {
                            _tip_price = ((this.cart_price_vip + this.cart_price_vip_tax) * this.merchant_custom_value).toFixed(2) * 1;
                        }
                        // return (this.cart_price_vip * this.merchant_custom_value).toFixed(2) * 1;
                        return _tip_price;
                    }

                    if (this.tip_method === 'merchant_fixed_value') {
                        return this.merchant_custom_value.toFixed(2) * 1;
                    }
                }

                return 0;
            },
            cart_price_tip() {
                if (this.tip_method !== '') {
                    if (this.tip_method === 'cash') {
                        return 0;
                    }

                    if (this.tip_method === 'input') {
                        return (this.tip_custom_input && this.tip_custom_input * 1) || 0;
                    }

                    if (this.tip_method === 'fixed_radio') {
                        let _radio = this.tip_fixed_radio;
                        let _tip_price = (this.cart_price * _radio).toFixed(2) * 1
                        if (this.tip_with_tax == 1) {
                            _tip_price = ((this.cart_price + this.cart_price_tax) * _radio).toFixed(2) * 1;
                        }

                        // return ((this.cart_price + this.cart_new_discount + this.cart_full_coupon_discount + this.cart_price_tax) * _radio).toFixed(2) * 1;
                        // return (this.cart_price * _radio).toFixed(2) * 1;
                        return _tip_price;
                    }

                    if (this.tip_method === 'merchant_fixed_percent') {
                        let _tip_price = (this.cart_price * this.merchant_custom_value).toFixed(2) * 1
                        if (this.tip_with_tax == 1) {
                            _tip_price = ((this.cart_price + this.cart_price_tax) * this.merchant_custom_value).toFixed(2) * 1;
                        }
                        // return ((this.cart_price + this.cart_new_discount + this.cart_full_coupon_discount + this.cart_price_tax) * this.merchant_custom_value).toFixed(2) * 1;
                        // return (this.cart_price * this.merchant_custom_value).toFixed(2) * 1;
                        return _tip_price;
                    }

                    if (this.tip_method === 'merchant_fixed_value') {
                        return this.merchant_custom_value.toFixed(2) * 1;
                    }
                }

                return 0;
            },
            cart_new_discount() {
                if (this.is_new && this.promotion_data.first_discount_amount > 0) {
                    if (this.promotion_data.first_discount_limit_amount > 0) {
                        if (this.pickup_discount_enable && this.type === 'pickup') {
                            if (this.cart_price_pickup < this.promotion_data.first_discount_limit_amount) {
                                return 0;
                            }
                        } else {
                            if (this.cart_price < this.promotion_data.first_discount_limit_amount) {
                                return 0;
                            }
                        }
                    }

                    if (this.promotion_data.first_discount_type === 'fixed') {
                        return this.promotion_data.first_discount_amount * -1;
                    } else if (this.promotion_data.first_discount_type === 'bysubtotal') {
                        if (this.pickup_discount_enable && this.type === 'pickup') {
                            return (this.cart_price_pickup * this.promotion_data.first_discount_amount).toFixed(2) * -1;
                        } else {
                            return (this.cart_price * this.promotion_data.first_discount_amount).toFixed(2) * -1;
                        }
                    }
                }

                return 0;
            },
            cart_full_coupon_discount() {
                let _discount = 0;

                if (this.promotion_data.full_coupon.length > 0) {
                    let _price = this.is_vip ? this.cart_price_vip : this.cart_price;
                    if (this.pickup_discount_enable && this.type === 'pickup') {
                        _price = this.cart_price_pickup || this.cart_price;
                    }

                    let _full_coupon_list = JSON.parse(JSON.stringify(this.promotion_data.full_coupon));
                    _full_coupon_list = _full_coupon_list.sort((a, b) => {
                        return b.full - a.full;
                    });

                    let _type = '';
                    for (let i = 0; i < _full_coupon_list.length; i++) {
                        if (_price >= _full_coupon_list[i].full) {
                            _discount = _full_coupon_list[i].minus;
                            _type = _full_coupon_list[i].type || 'fixed';
                            break;
                        }
                    }

                    if (_type === 'fixed') {
                        return _discount * -1;
                    } else if (_type === 'bysubtotal') {
                        // return (this.cart_price * _discount * 0.01).toFixed(2) * -1;
                        return (_price * _discount * 0.01).toFixed(2) * -1;
                    }
                }

                return _discount * -1;
            },
            cart_price_vip_tax() {
                let _tax = ((this.cart_price_vip) * this.tax).toFixed(2) * 1;
                
                if (this.tax_with_discount == 1) {
                    _tax = ((this.cart_price_vip + this.cart_new_discount + this.cart_full_coupon_discount) * this.tax).toFixed(2) * 1;
                }

                return _tax < 0 ? 0 : _tax;
            },
            cart_price_pickup_tax() {
                let _tax = ((this.cart_price_pickup) * this.tax).toFixed(2) * 1;

                if (this.tax_with_discount == 1) {
                    _tax = ((this.cart_price_pickup + this.cart_new_discount + this.cart_full_coupon_discount) * this.tax).toFixed(2) * 1;
                }
                return _tax < 0 ? 0 : _tax;
            },
            cart_price_pickup_total() {
                let _price = (+(
                    this.cart_price_pickup +
                    this.cart_new_discount +
                    this.cart_full_coupon_discount +
                    this.cart_price_pickup_tax +
                    this.cart_price_pickup_fee +
                    this.cart_price_delivery_fee +
                    this.cart_price_pickup_tip -
                    (this.coupon.amount > 0 ? this.coupon.amount : 0)
                )).toFixed(2);
                return _price < 0 ? 0 : _price;
            },
            cart_price_original_total() {
                let total_original = 0;
                if (this.cart_food_list.length > 0) {
                    for(let i = 0; i < this.cart_food_list.length; i++) {
                        let extra_price = 0;
                        if (this.cart_food_list[i].addon_prices) {
                            let _addon_items = this.cart_food_list[i].addon_prices.split('@@');
                            let _addon_counts = this.cart_food_list[i].addon_counts.split('@@');
                            for (let n = 0, nLen = _addon_items.length; n < nLen; n++) {
                                let price_arr = _addon_items[n].split('|');
                                let counts_arr = _addon_counts[n].split('|');

                                for (let m = 0, mLen = price_arr.length; m < mLen; m++) {
                                    extra_price += parseFloat(price_arr[m] * counts_arr[m]);
                                }
                            }
                        }
                        let _fprice = this.cart_food_list[i].fprice_original ? this.cart_food_list[i].fprice_original : this.cart_food_list[i].fprice;
                        total_original = total_original + this.cart_food_list[i].fcount * (parseFloat(_fprice) + extra_price);
                    }
                }
                    
                return total_original;
            },
            cart_price_total() {
                let _price = (+(
                    this.cart_price +
                    this.cart_new_discount +
                    this.cart_full_coupon_discount +
                    this.cart_price_tax +
                    this.cart_price_fee +
                    this.cart_price_delivery_fee +
                    this.cart_price_tip -
                    (this.coupon.amount > 0 ? this.coupon.amount : 0)
                )).toFixed(2);
                return _price < 0 ? 0 : _price;
            },
            cart_price_delivery_fee() {
                if (this.type === 'delivery') {
                    if (this.delivery_data.delivery_vip_free === '1' && this.is_vip_new === '1') {
                        return 0;
                    }

                    if (this.zip_code_index > -1) {
                        const delivery_data = this.delivery_data.delivery_area_list[this.zip_code_index];
                        if (delivery_data && delivery_data.delivery_fee_type === 'bysubtotal') {
                            let subtotal = this.cart_price * 1;
                            let now_tax = this.cart_price_tax * 1;
                            if (this.pickup_discount_enable && this.type === 'pickup') {
                                subtotal = this.cart_price_pickup * 1;
                                now_tax = this.cart_price_pickup_tax * 1;
                            }

                            return (
                                (
                                    this.delivery_data.delivery_area_list[this.zip_code_index].delivery_fee *
                                    (subtotal + now_tax)
                                ).toFixed(2) * 1
                            );
                        }

                        return this.delivery_data.delivery_area_list[this.zip_code_index].delivery_fee || 0;
                    }
                }

                return 0;
            },
            cart_price_pickup_tip() {
                if (this.tip_method !== '') {
                    if (this.tip_method === 'cash') {
                        return 0;
                    }

                    if (this.tip_method === 'input') {
                        return (this.tip_custom_input && this.tip_custom_input * 1) || 0;
                    }

                    if (this.tip_method === 'fixed_radio') {
                        let _radio = this.tip_fixed_radio;
                        let _tip_price = (this.cart_price_pickup * _radio).toFixed(2) * 1
                        if (this.tip_with_tax == 1) {
                            _tip_price = ((this.cart_price_pickup + this.cart_price_pickup_tax) * _radio).toFixed(2) * 1;
                        }
                        return _tip_price;
                        // return ((this.cart_price_pickup + this.cart_new_discount + this.cart_full_coupon_discount + this.cart_price_pickup_tax) * _radio).toFixed(2) * 1;
                        // return (this.cart_price_pickup * _radio).toFixed(2) * 1;
                    }

                    if (this.tip_method === 'merchant_fixed_percent') {
                        let _tip_price = (this.cart_price_pickup * this.merchant_custom_value).toFixed(2) * 1
                        if (this.tip_with_tax == 1) {
                            _tip_price = ((this.cart_price_pickup + this.cart_price_pickup_tax) * this.merchant_custom_value).toFixed(2) * 1;
                        }
                        return _tip_price;
                        // return (this.cart_price_pickup * this.merchant_custom_value).toFixed(2) * 1;
                    }

                    if (this.tip_method === 'merchant_fixed_value') {
                        return this.merchant_custom_value.toFixed(2) * 1;
                    }
                }

                return 0;
            },
            dineTable() {
                if (!this.dineInTableEnable) {
                    return '';
                }

                const name = this.$store.state.app.dineInTable;
                if (this.orderType === 'dineIn' && name) {
                    return name;
                } else if (this.orderType === 'dineIn') {
                    return this.$t('checkout.selectTable');
                }
                return '';
            },

            fixedRatios() {
                // const ratios = [0, 0.05, 0.08, 0.1, 0.15, 0.2];
                const ratios = [0.05, 0.08, 0.1, 0.15];
                if (this.pickup_discount_enable && this.type === 'pickup') {
                    return ratios.map((ratio) => {
                        return {
                            ratio,
                            price: this.tip_with_tax == 1 ? ((this.cart_price_pickup + this.cart_price_pickup_tax) * ratio).toFixed(2) * 1 : (this.cart_price_pickup * ratio).toFixed(2) * 1
                            // price: (this.cart_price_pickup * ratio).toFixed(2) * 1
                            // price: ((this.cart_price_pickup + this.cart_new_discount + this.cart_full_coupon_discount + this.cart_price_pickup_tax) * ratio).toFixed(2) * 1
                            // price: ((this.cart_price_pickup + this.cart_price_pickup_tax) * ratio).toFixed(2) * 1
                        };
                    });
                } else {
                    if (this.is_vip === '1') {
                        return ratios.map((ratio) => {
                            return {
                                ratio,
                                price: this.tip_with_tax == 1 ? ((this.cart_price_vip + this.cart_price_vip_tax) * ratio).toFixed(2) * 1 : (this.cart_price_vip * ratio).toFixed(2) * 1
                                // price: (this.cart_price_vip * ratio).toFixed(2) * 1
                                // price: ((this.cart_price_vip + this.cart_new_discount + this.cart_full_coupon_discount + this.cart_price_vip_tax) * ratio).toFixed(2) * 1
                                // price: ((this.cart_price_vip + this.cart_price_vip_tax) * ratio).toFixed(2) * 1
                            };
                        });
                    } else {
                        return ratios.map((ratio) => {
                            return {
                                ratio,
                                price: this.tip_with_tax == 1 ? ((this.cart_price + this.cart_price_tax) * ratio).toFixed(2) * 1 : (this.cart_price * ratio).toFixed(2) * 1
                                // price: (this.cart_price * ratio).toFixed(2) * 1
                                // price: ((this.cart_price + this.cart_new_discount + this.cart_full_coupon_discount + this.cart_price_tax) * ratio).toFixed(2) * 1
                                // price: ((this.cart_price + this.cart_price_tax) * ratio).toFixed(2) * 1
                            };
                        });
                    }
                }
                return '';
            },
            merchant_custom_value_price() {
                const ratio = this.merchant_custom_value;
                if (this.pickup_discount_enable && this.type === 'pickup') {
                    let _tip_price = (this.cart_price_pickup * ratio).toFixed(2) * 1;
                    if (this.tip_with_tax == 1) {
                        _tip_price = ((this.cart_price_pickup + this.cart_price_pickup_tax) * ratio).toFixed(2) * 1;
                    }
                    return _tip_price;
                    // return (this.cart_price_pickup * ratio).toFixed(2) * 1;
                    // return ((this.cart_price_pickup + this.cart_new_discount + this.cart_full_coupon_discount + this.cart_price_pickup_tax) * ratio).toFixed(2) * 1;
                    // return ((this.cart_price_pickup + this.cart_price_pickup_tax) * ratio).toFixed(2) * 1;
                } else {
                    if (this.is_vip === '1') {
                        let _tip_price = (this.cart_price_vip * ratio).toFixed(2) * 1;
                        if (this.tip_with_tax == 1) {
                            _tip_price = ((this.cart_price_vip + this.cart_price_vip_tax) * ratio).toFixed(2) * 1;
                        }
                        return _tip_price;
                        // return (this.cart_price_vip * ratio).toFixed(2) * 1;
                        // return ((this.cart_price_vip + this.cart_new_discount + this.cart_full_coupon_discount + this.cart_price_vip_tax) * ratio).toFixed(2) * 1;
                        // return ((this.cart_price_vip + this.cart_price_vip_tax) * ratio).toFixed(2) * 1;
                    } else {
                        let _tip_price = (this.cart_price * ratio).toFixed(2) * 1;
                        if (this.tip_with_tax == 1) {
                            _tip_price = ((this.cart_price + this.cart_price_tax) * ratio).toFixed(2) * 1;
                        }
                        return _tip_price;
                        // return (this.cart_price * ratio).toFixed(2) * 1;
                        // return ((this.cart_price + this.cart_new_discount + this.cart_full_coupon_discount + this.cart_price_tax) * ratio).toFixed(2) * 1;
                        // return ((this.cart_price + this.cart_price_tax) * ratio).toFixed(2) * 1;
                    }
                }
                return '';
            },
            tipTotal() {
                if (this.pickup_discount_enable && this.type === 'pickup') {
                    return this.cart_price_pickup_tip;
                } else {
                    return this.is_vip === '1' ? this.cart_price_vip_tip : this.cart_price_tip;
                }
                return 0;
            },
            total() {
                return  this.pickup_discount_enable && this.type === 'pickup' ? this.cart_price_pickup_total : this.cart_price_total;
            },
            deliveryEnable() {
                if (this.delivery_setting.enable == 1) {
                    if (this.delivery_data.delivery_area_list.length > 1) {
                        return true;
                    } else if (this.delivery_data.delivery_area_list.length == 1) {
                        if (this.delivery_data.delivery_area_list[0].zip_code_list && this.delivery_data.delivery_area_list[0].name) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }
                return false;
            }
        },
        methods: {
            ...mapActions({}),
            ...mapMutations({}),
            chooseTip({ tip_method, ratio }) {
                // this.tipChoosed = tip;
                this.tip_method = tip_method;
                this.tip_fixed_radio = ratio;
            },
            showAddressForm() {
                // console.log('special_store', this.special_store);
                if (this.special_store[this.sid]) {
                    this.special_store_arr = this.special_store[this.sid].list;
                    // console.log('special_store_arr', this.special_store_arr);
                    this.$refs.addressFormSpecial.show();
                } else {
                    this.$refs.addressForm.show();
                }
            },
            showAddress() {
                // this.addressVisible = true;
            },
            hideAddress() {
                // this.addressVisible = false;
            },
            ...App.mapActions({
                check_new: 'check_new',
                init_paymethod: 'init_paymethod',
                submit_cart_paypal: 'submit_cart_paypal',
                submit_cart_stripe: 'submit_cart_stripe',
                submit_cart_yuansfer_creditCard: 'submit_cart_yuansfer_creditCard',
                submit_cart: 'submit_cart'
            }),
            ...App.mapMutations({
                setOrderType: 'APP_SET_ORDER_TYPE',
                set_phone_num: 'APP_SET_PHONE_NUM',
                setName: 'APP_SET_UNAME',
                setFirstName: 'APP_SET_FIRST_NAME',
                setLastName: 'APP_SET_LAST_NAME',
                set_msg: 'APP_SET_MSG',
                setLoading: 'APP_SET_IF_LOADING'
            }),
            ...Coupon.mapActions(['useCoupon']),

            setCoupon(coupon) {
                if (this.hasCoupon) {
                    return;
                }
                this.hasCoupon = true;
                this.coupon = { ...coupon };
            },
            // addressSaved() {
            //     this.toggleAddressLayer();
            // },
            // toggleAddressLayer() {
            //     this.addressVisible = !this.addressVisible;
            // },
            getAppStore(name) {
                return this.$store.state.app[name];
            },
            close_nav_layer() {
                this.$router.go(-1);
            },

            buildRequestData(isPaypal, paypal_orderid) {
                let cartid = this.$store.state.app.shared_cart_id;
                let temp_food_list = this.cart_food_list;

                let all_foods = [];
                let all_foods_count = [];
                let all_user_names = [];
                let all_food_features = [];
                let all_addons_names = [];
                let all_addons_prices = [];
                let all_addons_counts = [];
                let all_addons_pickup_discounts = [];
                let all_notes = [];
                let ototal = temp_food_list.length;
                let opic = '';
                let odesc = '';

                for (let i = 0, len = temp_food_list.length; i < len; i++) {
                    all_foods.push(temp_food_list[i].fid);
                    all_foods_count.push(temp_food_list[i].fcount);
                    all_user_names.push(temp_food_list[i].uname);
                    all_food_features.push(temp_food_list[i].feature_name || '');
                    all_addons_names.push(temp_food_list[i].addon_names || '');
                    all_addons_prices.push(temp_food_list[i].addon_prices || '');
                    all_addons_counts.push(temp_food_list[i].addon_counts || '');
                    all_addons_pickup_discounts.push(temp_food_list[i].addon_pickup_discounts || '');
                    all_notes.push(temp_food_list[i].note || '');

                    if (i === 0) {
                        opic = temp_food_list[i].fpic;
                        odesc = temp_food_list[i].fname;
                    }

                    if (i === '1') {
                        odesc = odesc + '、' + temp_food_list[i].fname;
                    }
                }

                if (ototal > 2) {
                    odesc = odesc + ' and ' + ototal + ' other foods';
                }

                if (this.type === 'delivery' && !this.has_saved_address) {
                    this.set_msg({ msg: this.$t('checkout.inputDeliveryAddress') });
                    // this.set_msg({ msg: this.language === 'chn' ? '请保存送货地址' : 'Please input delivery address' });
                    return;
                }

                // 增加地址
                let delivery_address = {
                    name: this.name,
                    city: this.city,
                    state: this.state,
                    zip_code: this.zip_code,
                    street_address1: this.street_address1,
                    street_address2: this.street_address2
                };

                if (isNaN(this.cart_price_total)) {
                    this.set_msg({
                        msg: this.$t('checkout.wrongTotalPrice')
                    });
                    return;
                }

                let post_data = {
                    oid: this.shared_oid || '',
                    uid: this.real_phone_num,
                    sid: this.sid,
                    oprice:
                        this.pickup_discount_enable && this.type === 'pickup'
                            ? this.cart_price_pickup_total
                            : this.cart_price_total,
                    oprice_vip: this.cart_price_vip_total,
                    oprice_pickup: this.cart_price_pickup_total,
                    oprice_original: this.cart_price_original_total,
                    ofoods: all_foods.join(','),
                    ocount: all_foods_count.join(','),
                    ouser_names: all_user_names.join(','),
                    ofood_features: all_food_features.join(','),
                    ofood_notes: all_notes.join('-_-'),
                    ofood_addon_names: all_addons_names.join('-_-'),
                    ofood_addon_prices: all_addons_prices.join('-_-'),
                    ofood_addon_counts: all_addons_counts.join('-_-'),
                    ofood_addon_pickup_discounts: all_addons_pickup_discounts.join('-_-'),
                    otype: 'takeout',
                    opay_type: this.creditCard_strip_show ? 'stripe' : (this.creditCard_yuansfer_show ? 'yuansfer': this.pay_method),
                    omessage: this.remark,
                    ostatus: 0,
                    name: this.name,
                    address: isPaypal ? (this.tax > 0 ? 'with tax' : '') : '',
                    tel: this.real_phone_num,
                    odesc: odesc,
                    ototal: ototal,
                    opic: opic,
                    is_shared: 1,
                    cartid: cartid,
                    tid: this.shared_tid,
                    tname: '',
                    tstatus: this.shared_tstatus,
                    is_vip: this.is_vip_new,
                    pickup_time: this.real_pickup_time,
                    type: this.type === 'dineIn' ? 'Dine-in' : this.type,
                    dineInTable: this.type === 'dineIn' ? this.dineInTable : '',
                    note: this.note,
                    delivery_address: JSON.stringify(delivery_address),
                    charge_detail: JSON.stringify(this.charge_detail)
                };
                if (isPaypal) {
                    post_data.paypal_orderid = paypal_orderid;
                }

                if (this.hasCoupon) {
                    post_data.couponId = this.coupon.shortid;
                }
                return post_data;
            },

            setStripe(data) {
                const { stripe, card } = data;
                this.stripe = stripe;
                this.card = card;
            },
            loadPaypal() {
                if (!window.paypal) {
                    return;
                }
                paypal
                    .Buttons({
                        onClick: (data, actions) => {
                            if (!this.verifyInput() || !this.verifyASAP()) {
                                return actions.reject();
                            }
                            return actions.resolve();
                        },
                        createOrder: (data, actions) => {
                            let temp_food_list = JSON.parse(JSON.stringify(this.cart_food_list));
                            let item_arr = [];

                            /*
                             * 增加支付订单的订单详情
                             * 将菜品和add-on分开展示
                             */
                            for (let i = 0, len = temp_food_list.length; i < len; i++) {
                                let item_price = this.is_vip
                                    ? temp_food_list[i].fprice_vip
                                        ? temp_food_list[i].fprice_vip
                                        : temp_food_list[i].fprice
                                    : temp_food_list[i].fprice;
                                if (this.pickup_discount_enable && this.type === 'pickup') {
                                    item_price = temp_food_list[i].fprice_pickup
                                        ? temp_food_list[i].fprice_pickup
                                        : temp_food_list[i].fprice;
                                }

                                item_arr.push({
                                    name: temp_food_list[i].fname,
                                    quantity: temp_food_list[i].fcount,
                                    unit_amount: {
                                        currency_code: 'USD',
                                        value: item_price * 1
                                    }
                                });

                                let _addon_names = temp_food_list[i].addon_names.replace(/\@\@/g, '|').split('|');
                                let _addon_prices = temp_food_list[i].addon_prices.replace(/\@\@/g, '|').split('|');
                                let _addon_counts = temp_food_list[i].addon_counts.replace(/\@\@/g, '|').split('|');
                                let _addon_pickup_discounts = temp_food_list[i].addon_pickup_discounts
                                    .replace(/\@\@/g, '|')
                                    .split('|');
                                for (let j = 0; j < _addon_names.length; j++) {
                                    if (_addon_names[j]) {
                                        item_arr.push({
                                            name: temp_food_list[i].fname + ' - ' + _addon_names[j],
                                            quantity: temp_food_list[i].fcount,
                                            unit_amount: {
                                                currency_code: 'USD',
                                                value:
                                                    _addon_prices[j] * _addon_counts[j] * _addon_pickup_discounts[j] ||
                                                    0
                                            }
                                        });
                                    }
                                }
                            }

                            if (this.tax > 0) {
                                let _temp_tax = this.is_vip ? this.cart_price_vip_tax : this.cart_price_tax;
                                if (this.pickup_discount_enable && this.type === 'pickup') {
                                    _temp_tax = this.cart_price_pickup_tax;
                                }
                                item_arr.push({
                                    name: 'tax',
                                    quantity: 1,
                                    unit_amount: {
                                        currency_code: 'USD',
                                        value: _temp_tax
                                    }
                                });
                            }

                            // 处理费单独计算
                            let _temp_fee = this.is_vip ? this.cart_price_vip_fee : this.cart_price_fee;
                            if (this.pickup_discount_enable && this.type === 'pickup') {
                                _temp_fee = this.cart_price_pickup_fee;
                            }

                            if (_temp_fee > 0) {
                                item_arr.push({
                                    name: 'processing fee',
                                    quantity: 1,
                                    unit_amount: {
                                        currency_code: 'USD',
                                        value: _temp_fee
                                    }
                                });
                            }

                            if (this.cart_price_delivery_fee > 0) {
                                item_arr.push({
                                    name: 'delivery fee',
                                    quantity: 1,
                                    unit_amount: {
                                        currency_code: 'USD',
                                        value: this.cart_price_delivery_fee
                                    }
                                });
                            }

                            if (this.cart_price_tip > 0) {
                                let temp_tip = this.is_vip ? this.cart_price_vip_tip : this.cart_price_tip;
                                if (this.pickup_discount_enable && this.type === 'pickup') {
                                    temp_tip = this.cart_price_pickup_tip;
                                }

                                item_arr.push({
                                    name: 'tip',
                                    quantity: 1,
                                    unit_amount: {
                                        currency_code: 'USD',
                                        value: temp_tip
                                    }
                                });
                            }

                            let _discount = 0;
                            if (this.cart_new_discount < 0) {
                                _discount = _discount + this.cart_new_discount;
                            }

                            if (this.cart_full_coupon_discount < 0) {
                                _discount = _discount + this.cart_full_coupon_discount;
                            }

                            let _temp_price = this.is_vip ? this.cart_price_vip_total : this.cart_price_total;
                            if (this.pickup_discount_enable && this.type === 'pickup') {
                                _temp_price = this.cart_price_pickup_total;
                            }

                            return actions.order.create({
                                purchase_units: [
                                    {
                                        amount: {
                                            currency_code: 'USD',
                                            value: _temp_price,
                                            breakdown: {
                                                item_total: {
                                                    currency_code: 'USD',
                                                    value: (_temp_price * 1 + _discount * -1).toFixed(2)
                                                },
                                                discount: {
                                                    currency_code: 'USD',
                                                    value: _discount * -1
                                                }
                                            }
                                        },
                                        items: item_arr,
                                        description: this.sname + ' @@ ' + this.sid
                                    }
                                ],
                                application_context: {
                                    brand_name: this.sid
                                }
                            });
                        },
                        onApprove: (data, actions) => {
                            // Capture the funds from the transaction
                            return actions.order.capture().then((details) => {
                                // alert('Transaction completed by ' + details.payer.name.given_name);
                                return this.paypal_checkout(data.orderID);
                            });
                        },
                        onError: (err) => {}
                    })
                    .render('#paypal-button-container');
            },
            check_pickup_time() {
                if (this.pickup_time === '') {
                    return false;
                }

                if (this.pickup_time === 'custom') {
                    if (this.custom_pickup_date === '' || this.custom_pickup_time === '') {
                        return false;
                    }
                    const dateStr = this.custom_pickup_date + ' ' + this.custom_pickup_time;
                    const pickTime = new Date(dateStr.replace(/-/g, '/'));
                    return pickTime > new Date();
                }

                return true;
            },
            handleBlur(e) {
                if (e.type == 'change') {
                    this.phone_num_length = this.code == "+86" ? 14 : 13;
                } else {
                    let phone = this.phone_num;
                    let newPhone = phone.replace("(", '');
                    newPhone = newPhone.replace(")", '');
                    newPhone = newPhone.replace("-", '');
                    const flag = isNaN(newPhone);
                    if (flag) {
                        this.set_msg({
                            msg: this.$t('checkout.phoneBeNumeric')
                        });
                        // document.getElementById("phoneNum").focus();
                        // return;
                    }
                }

                if (!this.phone_num) { return }

                document.body.scrollTop = 0;
                this.inputting = false;

                // 判断是否是新用户
                if (this.promotion_data && this.promotion_data.first_discount_amount > 0) {
                    this.check_new(this.real_phone_num);
                }

                // 判断是否是vip用户
                if (this.delivery_data && this.delivery_data.delivery_vip_free === '1') {
                    this.check_vip(this.real_phone_num);
                }

                this.set_phone_num({
                    code: this.code,
                    phone_num: this.phone_num
                });
            },
            handleFocus(e) {
                this.inputting = true;
            },
            input_change(e) {
                if (e.target.value !== '' && e.keyCode !== 8) {
                    let temp = e.target.value.replace(/\D/g, '');
                    this.phone_num = temp.replace(/(\d{1,3})(\d{1,3})?(\d{1,4})?/, function (a, b, c, d) {
                        if (b) {
                            if (!c) {
                                return '(' + b + ')';
                            }
                            if (!d) {
                                return '(' + b + ')' + c;
                            }
                            return '(' + b + ')' + c + '-' + d;
                        }
                    });
                }
            },
            input_change_tip(e) {
                if (e.target.value !== '' && e.keyCode !== 8) {
                    let reg = /^(([1-9]{1}\d*)|(0{1}))((.\d*)?)$/;
                    let temp = reg.test(e.target.value);
                    this.tip_custom_input = temp ? e.target.value : '';
                }
            },

            async paypal_checkout(paypal_orderid) {
                const post_data = this.buildRequestData(true, paypal_orderid);
                // return this.submit_cart_paypal(post_data);
                let submitResult = await this.submit_cart_paypal(post_data);
                // console.log('submitResult_paypal', submitResult);
                if (submitResult && submitResult.paid == false) {
                    this.failedOrderId = submitResult.oid;
                    this.$refs.failedLayer.show();
                }
                return;
            },

            async do_checkout(e) {


                if (!this.verifyInput()) {
                    return;
                }

                if (!this.name || this.name == '未知' || this.name == 'undefined') {
                    this.setName({ name : this.first_name + ' ' + this.last_name });
                }

                if (!this.check_pickup_time()) {
                    return;
                }

                if (this.type === 'pickup') {
                    if (this.pickup_time === 'ASAP' && !this.canPickup) {
                        this.$refs.asapLayer.show();
                        return;
                    }
                } else if (this.type === 'delivery') {
                    if (this.zip_code_index >= 0 && this.pickup_time === 'ASAP' && !this.canDeliveryList[this.zip_code_index]) {
                        this.$refs.asapLayer.show();
                        return;
                    }
                }

                let stripe_token = '';
                let yuansfer_creditCard_data = null;

                if (this.creditCard_strip_show) {
                    this.setLoading({ is_loading: true });
                    const { token, error } = await this.stripe.createToken(this.card);
                    this.setLoading({ is_loading: false });
                    if (error) {
                        this.stripe_card_errors = error.message;
                        return;
                    } else {
                        stripe_token = token.id;
                    }
                }else if(this.creditCard_yuansfer_show) {
                    this.setLoading({ is_loading: true });
                    try{
                        yuansfer_creditCard_data = await this.$refs.yuansfer_creditCard.getToken();
                    }finally{
                        this.setLoading({ is_loading: false });
                    }
                    // new version
                    // try{
                    //     yuansfer_creditCard_data = await this.$refs.yuansfer_creditCard.get_submit_data() || {};
                    //     yuansfer_creditCard_data.firstName = this.first_name;
                    //     yuansfer_creditCard_data.lastName = this.last_name;
                    //     console.log('yuansfer_creditCard_data', yuansfer_creditCard_data);
                    //     if (!yuansfer_creditCard_data.transactionNo) {
                    //         return;
                    //     }
                    // }finally{
                    //     this.setLoading({ is_loading: false });
                    // }
                    if (!yuansfer_creditCard_data) {
                        return;
                    }
                }
                let post_data = this.buildRequestData(false);

                this.submitData = {post_data, stripe_token, el: e.srcElement, yuansfer_creditCard_data};
                this.$refs.submitLayer.show();

                // MessageBox({
                //     title: '',
                //     message: this.$t('checkout.submitAll'),
                //     showCancelButton: true,
                //     confirmButtonText: this.$t('common.confirm'),
                //     cancelButtonText: this.$t('checkout.back'),
                //     confirmButtonClass: 'custom_confirm_class',
                //     cancelButtonClass: 'custom_cancel_class'
                // }).then(async (action) => {
                //     if (action !== 'confirm') {
                //         this.setLoading({ is_loading: false });
                //         return;
                //     }
                //     this.submitOrder({post_data, stripe_token, el: e.srcElement, yuansfer_creditCard_data});
                // });
            },

            async submitOrder({post_data, stripe_token, el, yuansfer_creditCard_data}) {
                if (this.creditCard_strip_show) {
                    post_data.stripe_token = stripe_token;
                    let submitResult = await this.submit_cart_stripe({ post_data, el });
                    // console.log('submitResult', submitResult);
                    if (submitResult && submitResult.paid == false) {
                        this.failedOrderId = submitResult.oid;
                        this.$refs.failedLayer.show();
                    }
                }else if(this.creditCard_yuansfer_show){
                    post_data.yuansfer_creditCard_data = JSON.stringify(yuansfer_creditCard_data);
                    let submitResult = await this.submit_cart_yuansfer_creditCard({post_data, el});
                    // console.log('submitResult_creditCard_yuansfer', submitResult);
                    if (submitResult && submitResult.paid == false) {
                        this.failedOrderId = submitResult.oid;
                        this.$refs.failedLayer.show();
                    }
                } else {
                    this.submit_cart({ post_data, el });
                }
            },

            verifyInput() {
                // if (!this.name) {
                //     this.set_msg({
                //         msg: this.$t('checkout.inputName')
                //     });
                //     return false;
                // }

                if (!this.first_name) {
                    this.set_msg({
                        msg: this.$t('checkout.inputFirstName')
                    });
                    return false;
                }

                if (!this.last_name) {
                    this.set_msg({
                        msg: this.$t('checkout.inputLastName')
                    });
                    return false;
                }

                if (!this.name || this.name == '未知' || this.name == 'undefined') {
                    this.setName({ name : this.first_name + ' ' + this.last_name });
                }

                if (this.phone_num === '' || this.code === '') {
                    this.set_msg({
                        msg: this.$t('checkout.inputNumber')
                    });
                    return false;
                }

                if (this.type === '') {
                    this.set_msg({
                        msg: this.$t('checkout.chooseShippingMethod')
                    });
                    return false;
                }

                if (this.type === 'delivery' && !this.can_delivery) {
                    this.set_msg({
                        msg: this.$t('cart.notDelivery')
                    });
                    return false;
                }

                if (this.type === 'dineIn' && this.dineInTableEnable && this.dineInTable === '') {
                    this.set_msg({
                        msg: this.$t('checkout.chooseDineinTable')
                    });
                    return false;
                }

                if (this.tip_method_setting !== '' && this.tip_method === '') {
                    this.set_msg({
                        msg: this.$t('checkout.chooseTipMethod')
                    });
                    return false;
                }

                if (this.tip_method === 'input' && (this.tip_custom_input == '' || this.tip_custom_input < 0)) {
                    this.set_msg({
                        msg: this.$t('checkout.inputTip')
                    });
                    return false;
                }

                if (this.pay_method === '') {
                    this.set_msg({
                        msg: this.$t('checkout.choosePayMethod')
                    });
                    return false;
                }

                if (!this.check_pickup_time()) {
                    this.set_msg({
                        msg: this.$t('checkout.wrongChooseTime')
                    });
                    return false;
                }
                return true;
            },
            verifyASAP() {

                if (this.type === 'pickup') {
                    if (this.pickup_time === 'ASAP' && !this.canPickup) {
                        this.$refs.asapLayer.show();
                        return false;
                    }
                } else if (this.type === 'delivery') {
                    if (this.zip_code_index >= 0 && this.pickup_time === 'ASAP' && !this.canDeliveryList[this.zip_code_index]) {
                        this.$refs.asapLayer.show();
                        return false;
                    }
                }

                return true;
            },
            showLeaveMessage() {
                return new Promise((resolve, reject) => {
                    MessageBox({
                        title: this.$t('checkout.submittingFrom'),
                        message: this.$t('checkout.leavePage'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('checkout.stay'),
                        cancelButtonText: this.$t('checkout.leave'),
                        confirmButtonClass: 'custom_confirm_class',
                        cancelButtonClass: 'custom_cancel_class'
                    }).then(async (action) => {
                        if (action !== 'confirm') {
                            resolve();
                        }
                        reject();
                    });
                });
            },
            preventReload(e) {
                if (!this.is_loading) return;
                e.preventDefault();
                e.returnValue = '';
            },
            chooseTable() {
                this.$refs.table.show();
            },
            choosePickType(value) {
                this.setOrderType({ type: value });
                // if (value === 'dineIn' && !this.dineInTable) {
                //     this.$refs.table.show();
                // }
            },
            goMenu() {
                console.log('go');
                this.$router.replace({ path: '/menu' });
                console.log('menu');
            },
            css_paymethod() {
                let tipInner = document.getElementById('tip-inner'),
                    tipChildren = tipInner.childNodes,
                    tipParent = tipInner.parentNode,
                    tipItem = null,
                    allWidth = 0;
                const tipParentWidth = tipParent.getBoundingClientRect().width;

                for (let i = 0; i <= tipChildren.length; i++) {
                    tipItem = tipChildren[i];
                    if (tipItem != null && tipItem.nodeType == '1') {
                        allWidth += parseFloat(tipItem.getBoundingClientRect().width);
                    }
                }
                if (allWidth > tipParentWidth) {
                    tipInner.style.width = allWidth + 'px';
                    tipParent.style.overflowX = 'scroll';
                }
            },
            toggleSelect() {
                if (this.showSelectOption) {
                    // this.showSelectOption = false;
                    // this.$refs.methodOption.style.display = 'none';
                    // this.$refs.methodSelect.className = 'method_select';
                    this.hideSelect();
                } else{
                    this.showSelectOption = true;
                    this.$refs.methodOption.style.display = 'block';
                    this.$refs.methodSelect.className = 'method_select isOpen';
                }
            },
            hideSelect() {
                this.showSelectOption = false;
                this.$refs.methodOption.style.display = 'none';
                this.$refs.methodSelect.className = 'method_select';
            },
            choosePaymentType(value) {
                this.pay_method = value;
                this.hideSelect();
            },
            hideTimeSelect() {
                this.showTimeSelect = false;
                this.$refs.chooseTimeSelect.className = 'choose_time_select';
            },
            toggleTimeSelect() {
                if (this.showTimeSelect) {
                    this.showTimeSelect = false;
                    this.$refs.chooseTimeSelect.className = 'choose_time_select';
                } else {
                    this.showTimeSelect = true;
                    this.$refs.chooseTimeSelect.className = 'choose_time_select isOpen';
                }
            },
            chooseTypeDate(date, date_txt, date_txt_chn) {
                this.custom_pickup_date = date;
                this.custom_select_date = this.language == 'chn' ? date_txt_chn : date_txt;
            },
            chooseTypeTime(time, time_txt) {
                if (time == 'ASAP') {
                    this.pickup_time = 'ASAP';
                    this.custom_pickup_time = '';
                } else {
                    this.custom_pickup_time = time;
                    this.pickup_time = 'custom';
                }
                this.custom_select_time = time_txt;
                this.toggleTimeSelect();
            },
            init_custom_key() {
                this.custom_pickup_date = this.new_custom_date_arr[0] ? this.new_custom_date_arr[0].val : '';
                this.custom_pickup_time = this.new_custom_time_arr[0] ? this.new_custom_time_arr[0].val : '';

                this.custom_select_date = this.new_custom_date_arr[0] ? (this.language == 'chn' ? this.new_custom_date_arr[0].chnTxt : this.new_custom_date_arr[0].newTxt) : '';
                // this.custom_select_time = this.new_custom_time_arr[0] ? this.new_custom_time_arr[0].txt : '';
                if (this.new_choose_time.indexOf('ASAP') > -1) {
                    this.pickup_time == 'ASAP'
                    this.custom_pickup_time = '';
                    this.custom_select_time = 'ASAP';
                } else {
                    this.pickup_time = 'custom';
                    this.custom_select_time = this.new_custom_time_arr[0] ? this.new_custom_time_arr[0].txt : '';
                }
            }
        }
    };
</script>
