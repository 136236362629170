<template>
    <div class="pop" v-if="visible">
        <!-- <div class="pop_copy" @click="hide"></div> -->
        <div class="pop_main">
            <!-- <div class="pop_close" @click="hide"><span></span></div> -->
            <div class="success_layer_main">
                <div class="success_icon"></div>
                <div class="success_title">
                    {{ $t('review.thankYou') }}
                </div>
                <div class="pop_title">
                    {{ $t('review.successful') }}
                </div>
                <div class="pop_btn">
                    <mt-button type="danger" @click="confirm">
                        OK
                    </mt-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters, createNamespacedHelpers } from 'vuex';
    const App = createNamespacedHelpers('app');

    export default {
        name: 'SuccessLayer',
        components: {},
        props: {
            payBoxHide: Function,
        },
        data() {
            return {
                visible: false,
            };
        },
        computed: {
            ...mapState({}),
            ...App.mapGetters({}),
        },
        mounted() {},
        methods: {
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG',
            }),
            show() {
                this.visible = true;
            },
            hide() {
                this.visible = false;
            },

            confirm() {
                this.hide();
                this.payBoxHide();
            },
        }
    };
</script>
<style lang="scss" scoped>
    .success_layer_main {
        padding-top: 20px;
        margin-bottom: 20px;
        text-align: center;
    }
    .success_layer_main .success_icon {
        width: 68px;
        height: 68px;
        background: url('../assets/images/success_icon.svg') no-repeat;
        background-size: 100% auto;
        margin: 0 auto 10px;
    }
    .success_layer_main .success_title {
        font-size: 30px;
        color: #68B361;
        margin-bottom: 20px;
    }
    .success_layer_main .pop_title {
        color: #000;
        margin: 5px 0 35px;
    }
    .success_layer_main .pop_btn {
        display: flex;
        display: -webkit-flex;
        justify-content: center;
    }
    .success_layer_main .pop_btn button {
        width: 120px;
        height: 38px;
        font-size: 16px;
    }
</style>
