export function isWeixin() {
    const ua = navigator.userAgent.toLowerCase();
    return ua.match(/MicroMessenger/i) == 'micromessenger';
}

export function isIOS() {
    return navigator.userAgent.match(/(iPhone|iPod|iPad);?/i);
}

export function loadImg(src) {
    return new Promise((rs, rj) => {
        const img = new Image();
        img.onload = rs;
        img.onerror = rj;
        img.src = src;
    });
}

/*
filter string with `undefined` in localStorage
 */
export function isValidStr(str) {
    return typeof str === 'string' && str.length > 0 && str !== 'undefined';
}
