<template>
    <div class="pop" v-if="visible">
        <!-- <div class="pop_copy" @click="hide"></div> -->
        <div class="pop_main">
            <!-- <div class="pop_close" @click="hide"><span></span></div> -->
            <div class="failed_layer_main">
                <div class="failed_icon"></div>
                <div class="failed_title">
                    {{ $t('checkout.error') }}
                </div>
                <div class="pop_title">
                    {{ $t('checkout.paymentFailed') }}
                </div>
                <div class="pop_btn">
                    <mt-button type="danger" @click="go_review">
                        {{ $t('checkout.proceedToPay') }}
                    </mt-button>
                </div>
                <div class="failed_text">
                    {{ $t('checkout.beenSubmitted') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters, createNamespacedHelpers } from 'vuex';
    const App = createNamespacedHelpers('app');

    export default {
        name: 'FailedLayer',
        components: {},
        props: {
            orderId: String,
            storeId: String
        },
        data() {
            return {
                visible: false,
            };
        },
        computed: {
            ...mapState({}),
            ...App.mapGetters({}),
        },
        mounted() {},
        methods: {
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG',
            }),
            show() {
                this.visible = true;
                console.log('orderId', this.orderId);
            },
            hide() {
                this.visible = false;
            },

            go_review() {
                this.hide();
                if (this.orderId) {
                    console.log('go to review html');
                    const urlPre = window.location.origin + window.location.pathname + '#';
                    window.location.replace(urlPre + '/review?oid=' + this.orderId + '&sid=' + this.storeId);
                }
                // if(window.innerWidth <= 640) {
                //     this.hideOrder();
                // }
                // this.$router.replace({ path: '/menu' });
            },
        }
    };
</script>
<style lang="scss" scoped>
    .failed_layer_main {
        padding-top: 20px;
        margin-bottom: 20px;
        text-align: center;
    }
    .failed_layer_main .failed_icon {
        width: 68px;
        height: 68px;
        background: url('../assets/images/warning.svg') no-repeat;
        background-size: 100% auto;
        margin: 0 auto 10px;
    }
    .failed_layer_main .failed_title {
        font-size: 30px;
        color: red;
        margin-bottom: 20px;
    }
    .failed_layer_main .pop_title {
        color: #000;
        margin: 5px 0 35px;
    }
    .failed_layer_main .pop_btn {
        display: flex;
        display: -webkit-flex;
        justify-content: center;
    }
    .failed_layer_main .pop_btn button {
        width: 250px;
        height: 38px;
        font-size: 16px;
    }
    .failed_layer_main .failed_text {
        font-size: 14px;
        margin-top: 10px;
    }
</style>
